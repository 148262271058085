import { FC } from 'react';
import { WordProps } from './Word.interface';
import { useWordStore } from './Word.hooks';
import { StyledWord } from './Word.styles';

export const Word: FC<WordProps> = ({
  item,
  width,
  height,
  top,
  left,
  pageId,
  selected = false,
}) => {
  const { addField, addFieldArrow } = useWordStore();

  return (
    <StyledWord
      $selected={selected}
      $width={width}
      $height={height}
      $top={top}
      $left={left}
      onClick={(event) => {
        event.stopPropagation();
        addField({ pageId, lookups: [item] });
        addFieldArrow(undefined);
      }}
    />
  );
};
