import { FC } from 'react';
import { Section, Line, SectionTitle } from './DocumentItem.styles';
import { DocumentItemProps } from './DocumentItem.interface';

export const DocumentItem: FC<DocumentItemProps> = ({ label }) => {
  return (
    <Section>
      <Line />
      <SectionTitle>{label}</SectionTitle>
    </Section>
  );
};
