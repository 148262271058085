import { FC, useState, useEffect } from 'react';
import { Row } from './Row';
import { Columns } from './Columns';
import { QueueTableProps } from './QueueTable.interface';
import {
  Container,
  StyledPagination,
  RowsContainer,
  EmptyContainer,
  LoaderContainer,
} from './QueueTable.styles';
import { ViewResultsModal } from 'modals/ViewResultsModal';
import { Loader } from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { useQueuesFilters } from 'store/reducers/queues';

export const QueueTable: FC<QueueTableProps> = ({
  loading,
  totalSize,
  data,
  selectedRowId,
  onRowClick,
  onSidePanel,
}) => {
  const { t } = useTranslation();
  const [showViewResults, setViewResults] = useState<{
    collectionId?: string;
    fileName?: string;
    open: boolean;
  }>({ open: false });
  const [checkedRows, setCheckedRows] = useState<Array<string>>([]);
  const { page, pageSize, setPageFilter, setPageSizeFilter } = useQueuesFilters();

  useEffect(() => {
    setCheckedRows([]);
  }, []);

  const onSelectAll = (checked: boolean) => {
    if (checked) {
      setCheckedRows(data.map((item) => item.collectionId));
    } else {
      setCheckedRows([]);
    }
  };

  const onChecked = (id: string) => {
    if (checkedRows.includes(id)) {
      setCheckedRows((prevCheckedRows) => prevCheckedRows.filter((item) => item !== id));
    } else {
      setCheckedRows((prevCheckedRows) => [...prevCheckedRows, id]);
    }
  };

  return (
    <>
      <Container>
        <Columns
          onSelectAll={onSelectAll}
          checkedRows={checkedRows}
          onRowRemoved={onChecked}
        />
        <RowsContainer>
          {data && data.length > 0 ? (
            data.map((item) => (
              <Row
                item={item}
                key={item.collectionId}
                checked={checkedRows.includes(item.collectionId)}
                selected={item.collectionId === selectedRowId}
                onViewResults={(collectionId: string, fileName: string) =>
                  setViewResults({ collectionId, fileName, open: true })
                }
                onRowChecked={() => onChecked(item.collectionId)}
                onRowClick={() => onRowClick(item.collectionId)}
                onSidePanel={() => onSidePanel(item.collectionId)}
              />
            ))
          ) : (
            <EmptyContainer>{t('emptyData')}</EmptyContainer>
          )}
          {loading && (
            <LoaderContainer>
              <Loader label={t('loading')} />
            </LoaderContainer>
          )}
        </RowsContainer>
        <StyledPagination
          locale={{ items_per_page: t('pagePagination') }}
          pageSizeOptions={['10', '20', '50', '100', '200']}
          current={page}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} ${t('of')} ${total} ${t('collections')}`
          }
          pageSize={pageSize}
          showSizeChanger
          showLessItems
          total={totalSize}
          onChange={(page, size) => {
            setPageFilter(page);
            setPageSizeFilter(size || 10);
          }}
        />
      </Container>
      <ViewResultsModal
        fileName={showViewResults.fileName}
        collectionId={showViewResults.collectionId}
        open={showViewResults.open}
        onClose={() => setViewResults({ open: false })}
      />
    </>
  );
};
