import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateField, StoreProps } from 'store';
import {
  createDataObject,
  selectComplexAnnotationSchemas,
} from 'store/reducers/collection';
import { selectCommonTypes } from 'store/reducers/common';
import {
  selectCreateFields,
  updateAnnotationEditMode,
  updateFieldArrow,
  updateZoom,
} from 'store/reducers/supervision';

export const useCreateFieldStore = (pageId: string) => {
  const dispatch = useDispatch();
  const setZoom = useCallback((e) => dispatch(updateZoom(e)), [dispatch]);
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const addAnnotation = useCallback((e) => dispatch(createDataObject(e)), [dispatch]);
  const setAnnotationEditMode = useCallback(
    (e) => dispatch(updateAnnotationEditMode(e)),
    [dispatch],
  );
  const commonTypes = useSelector((state: StoreProps) => selectCommonTypes(state));
  const createFields = useSelector<StoreProps, CreateField>((state) =>
    selectCreateFields(state, pageId),
  );
  const complexAnnotationSchemas = useSelector(selectComplexAnnotationSchemas);

  return {
    setFieldArrow,
    setZoom,
    addAnnotation,
    setAnnotationEditMode,
    commonTypes,
    createFields,
    complexAnnotationSchemas,
  };
};
