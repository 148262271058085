import React, { FC } from 'react';
import { ProcessProgressProps } from './ProcessProgress.interface';
import {
  Background,
  Container,
  StyledTypography,
  EmptyTypography,
} from './ProcessProgress.styles';

export const ProcessProgress: FC<ProcessProgressProps> = ({ progress = 0, ...other }) => {
  return (
    <Container {...other}>
      <Background $progress={progress}>
        <EmptyTypography>&nbsp;</EmptyTypography>
      </Background>
      <StyledTypography $progress={progress}>{progress}%</StyledTypography>
    </Container>
  );
};
