import { FC, useCallback, useState } from 'react';
import { InputSearch } from 'components/InputSearch';
import { useTranslation } from 'react-i18next';
import {
  StyledCheckbox,
  Divider,
  FilterContainer,
  SearchContainer,
  StyledDropdownSelect,
} from './QueuesFilters.styles';
import { menuPriority, menuStatus } from './QueuesFilters.menu';
import { FilterDatePicker } from 'components/FilterDatePicker';
import { debounce } from 'lodash';
import { useQueuesFilters } from 'store/reducers/queues';
import { ENV_VARIABLE } from 'variables';

export const QueuesFilters: FC = () => {
  const { t } = useTranslation();
  const {
    from,
    to,
    status,
    priority,
    search,
    setMarkToDeleteFilter,
    setPriorityFilter,
    setSearchFilter,
    setFromFilter,
    setToFilter,
    setStatusFilter,
  } = useQueuesFilters();
  const [localSearch, setLocalSearch] = useState<string | undefined>(search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((value) => {
      setSearchFilter(value);
    }, 500),
    [],
  );

  const handleChange = (value?: string) => {
    setLocalSearch(value);
    debouncedSave(value);
  };

  return (
    <FilterContainer>
      <SearchContainer>
        <InputSearch value={localSearch} onChange={handleChange} />
      </SearchContainer>
      <Divider />
      <FilterDatePicker
        dateFrom={from}
        dateTo={to}
        onDates={(from, to) => {
          if (from && to) {
            setFromFilter(from);
            setToFilter(to);
          } else {
            setFromFilter(undefined);
            setToFilter(undefined);
          }
        }}
      />
      <StyledDropdownSelect
        title={t('status')}
        checkedItems={status}
        items={menuStatus(t)}
        onMenuItems={(status) => {
          setStatusFilter(status);
        }}
      />
      <StyledDropdownSelect
        checkedItems={priority}
        title={t('priority')}
        singleSelect
        items={menuPriority(t)}
        onMenuItems={(priority) => {
          setPriorityFilter(priority);
        }}
      />
      {ENV_VARIABLE.MARK_TO_DELETE_ENABLED === 'true' && (
        <StyledCheckbox onChange={(event) => setMarkToDeleteFilter(event.target.checked)}>
          {t('markToDelete')}
        </StyledCheckbox>
      )}
    </FilterContainer>
  );
};
