import { DatePicker, Menu, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { IconChevronDown, IconDelete, IconMore } from 'components/Icons';
import { Label } from 'components/Label';
import styled from 'styled-components';

interface StyledMenuContainerProps {
  $hovered: boolean;
}

interface StyledRangeProps {
  $selected: boolean;
}

const { RangePicker } = DatePicker;

export const Container = styled.div`
  height: 40px;
  position: relative;
`;

export const MenuContainer = styled.div<StyledMenuContainerProps>`
  cursor: pointer;
  border-radius: 6px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-right: ${({ theme }) => theme.padding.tiny};
  background-color: ${({ $hovered, theme }) =>
    $hovered ? theme.colors.white : 'transparent'};
  box-shadow: ${({ $hovered }) =>
    $hovered ? '0 2px 6px 0 rgba(151, 159, 182, 0.3)' : ''};
  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  }
`;

export const StyledMenuIcon = styled(IconMore)`
  color: ${({ theme }) => theme.colors.darkGray};
  width: 20px;
`;

export const StyledArrowDownIcon = styled(IconChevronDown)`
  margin-left: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.gray200};
`;

export const StyledFilterLabel = styled(Label)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray300};
  padding-left: ${({ theme }) => theme.padding.tiny};
  user-select: none;
`;

export const StyledIconDeleteWhite = styled(IconDelete)`
  width: 15px;
  margin-left: ${({ theme }) => theme.padding.xtiny};
  margin-right: ${({ theme }) => theme.padding.xtiny};
  color: ${({ theme }) => theme.colors.gray200};
`;

export const StyledRange = styled(Label)<StyledRangeProps>`
  padding-left: ${({ theme }) => theme.padding.tiny};
  padding-right: ${({ theme }) => theme.padding.tiny};
  margin-right: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme, $selected = false }) =>
    $selected ? theme.colors.white : theme.colors.blackPurple};
  background-color: ${({ theme, $selected = false }) =>
    $selected ? theme.colors.blackPurple : theme.colors.lilac};
  display: inline-block;
  text-transform: uppercase;
  border-radius: 4px;
  user-select: none;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blackPurple};
  }
`;

export const StlyedCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.padding.tiny};
`;

export const StyledMenuLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray};
  user-select: none;
`;

export const StyledCounterLabel = styled.span`
  color: ${({ theme }) => theme.colors.blackPurple};
  background-color: ${({ theme }) => theme.colors.lilac};
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  border-radius: 4px;
  line-height: 1.4vh;
  height: 1.8vh;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  border: ${({ theme }) => `1px solid ${theme.colors.blackPurple}`};
  user-select: none;
`;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 3px;
  :hover {
    background-color: ${({ theme }) => theme.colors.menuItem};
  }
`;

interface StyledSelectedFilterProps {
  $selected?: boolean;
}

export const StyledRangeFilter = styled(Label)`
  border: ${({ theme }) => `1px solid ${theme.colors.blackPurple}`};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blackPurple};
  margin-left: ${({ theme }) => theme.padding.tiny};
  padding-left: 4px;
  padding-right: 4px;
  text-transform: uppercase;
  border-radius: 4px;
  user-select: none;
  align-items: center;
  display: flex;
`;

export const StyledSelectedFilter = styled(Typography)<StyledSelectedFilterProps>`
  border: ${({ theme, $selected }) =>
    $selected ? `1px solid ${theme.colors.blackPurple}` : 0};
  color: ${({ theme, $selected }) =>
    $selected ? theme.colors.white : theme.colors.white};
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.colors.blackPurple : theme.colors.transparent};
  margin-left: ${({ theme }) => theme.padding.tiny};
  height: 18px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
  user-select: none;
  align-items: center;
  display: flex;
`;

export const StyledRangePicker = styled(RangePicker)<StyledSelectedFilterProps>`
  border: none;
  height: 16px;
  background-color: transparent;
  padding-right: 0;
  margin-left: -4px;
  border-radius: 0;
  border-bottom: ${({ theme, $selected }) =>
    $selected ? 0 : `1px solid ${theme.colors.line}`};
  .ant-picker-active-bar {
    background-color: ${({ theme }) => theme.colors.blackPurple};
  }

  .ant-picker-input > input {
    color: ${({ theme, $selected }) =>
      $selected ? theme.colors.white : theme.colors.gray};
    padding: 0;
    margin: 0;
  }

  .ant-picker-range-separator {
    color: ${({ theme, $selected }) =>
      $selected ? theme.colors.white : theme.colors.gray};
    padding-left: 0px;
  }

  .ant-picker-active-bar {
    margin-left: 6px;
  }
`;
