import { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Logo,
  StyledTitle,
  StyledButton,
  StyledSubTitle,
  ImageContainer,
  LoginContainer,
  AppVersion,
  VersionContainer,
} from './Login.styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from 'assets/logo.png';
import { ENV_VARIABLE } from 'variables';
import { keycloakInstance } from 'keycloak';
import { api, Version } from 'api';
import { useSelector } from 'react-redux';
import { selectAuthenticated } from 'store/reducers/common';

export const Login = () => {
  const history = useHistory();
  const [version, setVersion] = useState<Version>();
  const { t } = useTranslation();
  const location = useLocation<{ redirect?: string }>();
  const authenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    api.fetchVersion().then(setVersion);
  }, []);

  useEffect(() => {
    if (!ENV_VARIABLE.KEY_CLOAK_CLIENT_ID || authenticated) {
      history.push('/documents');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onLogin = useCallback(() => {
    const { redirect } = location?.state || {};

    keycloakInstance.login({
      redirectUri: redirect
        ? window.location.href.replace('login', redirect)
        : window.location.href.replace('login', 'documents'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <LoginContainer>
        <Logo src={logo} />
        <StyledTitle level={2}>
          Welcome to
          <br />
          Alphamoon Intelligent Automation
        </StyledTitle>
        <StyledSubTitle>Login to access your account</StyledSubTitle>
        <StyledButton onClick={onLogin} title="Login" />
        {version && (
          <VersionContainer>
            <AppVersion>{`${t('versionAIA')} ${version?.aiaVersion}`}</AppVersion>
            <AppVersion>{`${t('versionAPI')} ${version?.apiVersion}`}</AppVersion>
            <AppVersion>{`${t('build')} ${version?.build}`}</AppVersion>
          </VersionContainer>
        )}
      </LoginContainer>
      <ImageContainer />
    </Container>
  );
};
