import { Button } from 'components/Button';
import { IconDelete } from 'components/Icons';
import styled from 'styled-components';

interface ContainerProps {
  $error: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 17vw;
  z-index: 10000;
  padding-top: 2rem;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.colors.gray400};
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(38, 26, 89, 0.3);
  display: flex;
  flex-direction: column;
  > :not(:first-child) {
    margin-top: 16px;
  }
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
`;

export const CloseIcon = styled(IconDelete)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray300};
`;
