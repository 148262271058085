import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';

export const API_ACCESS_TOKEN = 'access_token';
export const API_REFRESH_TOKEN = 'refresh_token';

export const useAuth = () => {
  const { initialized, keycloak } = useKeycloak();

  useEffect(() => {
    if (keycloak && initialized) {
      const { token, refreshToken } = keycloak;

      if (token) {
        window.localStorage.setItem(API_ACCESS_TOKEN, token);
      }

      if (refreshToken) {
        window.localStorage.setItem(API_REFRESH_TOKEN, refreshToken);
      }
    }
  }, [keycloak, initialized]);

  return { initialized };
};
