import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface FilterByFieldTypeProps {
  fieldTypes?: Array<string>;
}

interface FilterByFieldTypesDispatch extends BaseDispatch {
  payload: FilterByFieldTypeProps;
}

export const SET_FILTER_BY_FIELD_TYPE = 'SET_FILTER_BY_FIELD_TYPE';

export const selectFilterByFieldTypes = (state: StoreProps) =>
  state.supervision.filterByFieldTypes;

export const updateFilterByFieldTypesState = (
  state: SupervisionStore,
  data: FilterByFieldTypeProps,
) => {
  return { ...state, filterByFieldTypes: [...(data.fieldTypes || [])] };
};

export const updateFilterByFieldTypes = (fieldTypes?: Array<string>) => (
  dispatch: (data: FilterByFieldTypesDispatch) => void,
) => {
  dispatch({
    type: SET_FILTER_BY_FIELD_TYPE,
    payload: { fieldTypes },
  });
};
