import { Input } from 'antd';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTagGroupOptions } from 'store/reducers/collection';

export const useDocumentSection = () => {
  const inputRef = useRef<Input>(null);
  const [rename, setRename] = useState(false);
  const tagGroupsOptions = useSelector(selectTagGroupOptions);

  const onRename = () => {
    setRename(!rename);

    if (!rename) {
      setTimeout(() => inputRef.current?.focus());
    }
  };

  return {
    tagGroupsOptions,
    inputRef,
    rename,
    onRename,
  };
};
