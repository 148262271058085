import {
  IconCheck,
  IconEdit,
  IconDelete,
  IconTrash,
  IconAccept,
  IconCopy,
} from 'components/Icons';
import { Input } from 'components/Input';
import styled from 'styled-components';

interface FieldProps {
  $selected: boolean;
  $requiresAttention: boolean;
}

interface DotProps {
  $requiresAttention: boolean;
}

interface EditedProps {
  edited: boolean;
}

interface ButtonProps {
  $disabled: boolean;
}

interface TextContainerProps {
  $hasVisibleStatus?: boolean;
  $hover: boolean;
}

export const Type = styled.div<EditedProps>`
  min-width: 35%;
  max-width: 35%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  color: ${({ theme, edited }) =>
    edited ? theme.colors.darkGray : theme.colors.blackPurple};
  padding-top: 2px;
  padding-left: 12px;
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-family: ${({ theme, edited }) =>
    edited ? theme.fontFamily.semiBold : theme.fontFamily.regular};
`;

export const TextHoverContainer = styled.div`
  max-width: 100%;
  display: flex;
`;

export const StyledCopy = styled(IconCopy)`
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 4px;
`;

export const Text = styled.div<EditedProps>`
  max-width: 98%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme, edited }) =>
    edited ? theme.fontFamily.semiBold : theme.fontFamily.regular};
  white-space: nowrap;
  user-select: none;
  cursor: text;
  color: ${({ theme, edited }) =>
    edited ? theme.colors.darkGray : theme.colors.blackPurple};
  padding-left: 16px;
  font-size: 14px;
  :hover {
    text-decoration: underline;
  }
`;

export const TextContainer = styled.div<TextContainerProps>`
  width: ${({ $hover }) => ($hover ? '53%' : '65%')};
  flex: 1;
  overflow: hidden;
  display: flex;
  aling-items: center;
  position: relative;
  padding-right: 8px;
`;

export const StatusContainer = styled.div<TextContainerProps>`
  display: flex;
  justify-content: end;
  margin-right: ${({ $hover }) => ($hover ? '0px' : '8px')};
`;

export const StyledAcceptContainer = styled.div<ButtonProps>`
  height: 34px;
  min-width: 34px;
  margin-left: 8px;
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.gray500 : theme.colors.darkPurple};
  box-shadow: ${({ $disabled }) => ($disabled ? 0 : '0 2px 6px 0 rgba(70,45,140,0.1)')};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  :focus {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.gray500 : theme.colors.blackPurple};
  }
  :hover {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.gray500 : theme.colors.blackPurple};
  }
`;

export const StyledCancelContainer = styled.div`
  min-width: 34px;
  height: 34px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  :focus {
    background-color: ${({ theme }) => theme.colors.line};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.line};
  }
`;

export const StyledAcceptWithoutContainer = styled.div`
  min-width: 34px;
  height: 34px;
  margin-right: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  :focus {
    background-color: ${({ theme }) => theme.colors.line};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.line};
  }
`;

export const StyledAcceptIcon = styled(IconCheck)<ButtonProps>`
  width: 20px;
  height: 20px;
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.gray300 : theme.colors.white};
`;

export const StyledCancelIcon = styled(IconDelete)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledAcceptWithoutIcon = styled(IconAccept)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const SelectNameContainer = styled.div`
  width: 34%;
`;

export const SelectEnumContainer = styled.div`
  padding-left: 8px;
  width: 100%;
  flex-shrink: 1;
`;

export const SelectInputContainer = styled.div`
  padding-left: 8px;
  width: auto;
  flex: 1;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  height: 34px;
  border-radius: 6px;
  line-height: 34px;
`;

export const StyledField = styled.div<FieldProps>`
  position: relative;
  min-width: calc(100% - 34px);
  max-width: calc(100% - 34px);
  background-color: ${({ theme, $requiresAttention, $selected }) => {
    if ($selected && !$requiresAttention) {
      return theme.colors.gray400;
    }

    if ($selected && $requiresAttention) {
      return theme.colors.darkBlushPink;
    }

    return $requiresAttention ? theme.colors.blushPink : theme.colors.gray600;
  }};
  :hover {
    background-color: ${({ theme, $requiresAttention }) => {
      return $requiresAttention ? theme.colors.darkBlushPink : theme.colors.gray400;
    }};
  }
  border-radius: 6px;
  padding-left: 8px;
  margin-bottom: 8px;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuContainer = styled.div<DotProps>`
  right: 0;
  padding-right: 4px;
  margin-left: 8px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 48px;
  display: flex;
  align-items: center;
  background-color: ${({ theme, $requiresAttention }) =>
    $requiresAttention ? theme.colors.transparent : theme.colors.transparent};
`;

export const StyledDeleteIcon = styled(IconTrash)`
  color: ${({ theme }) => theme.colors.darkPurple};
`;

export const StyledIconCheck = styled(IconCheck)`
  color: ${({ theme }) => theme.colors.darkPurple};
`;

export const StyledIconEdit = styled(IconEdit)`
  color: ${({ theme }) => theme.colors.darkPurple};
`;

export const Dot = styled.div<DotProps>`
  position: absolute;
  left: 0;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: ${({ theme, $requiresAttention }) =>
    $requiresAttention ? theme.colors.darkPink : theme.colors.darkPurple};
  border: ${({ theme }) => `3px solid ${theme.colors.white}`};
  box-shadow: 0 2px 4px 0 rgba(182, 20, 103, 0.3);
`;
