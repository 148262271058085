import styled from 'styled-components';

interface LookUpWrapperProps {
  $left: number;
  $top: number;
  $height: number;
  $width: number;
}
export const LookUpWrapper = styled.div<LookUpWrapperProps>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => $left}px;
  height: ${({ $height }) => $height}px;
  width: ${({ $width }) => $width}px;
  z-index: 1;
  cursor: pointer;
`;

export const CreateFieldContainer = styled.div<{
  positions: { top: number; left: number };
}>`
  position: absolute;
  z-index: 999;
  top: ${({ positions }) => `${positions.top}px`};
  left: ${({ positions }) => `${positions.left}px`};
`;
