import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  AutocompleteContainer,
  FiltersContainer,
  CollectionsContainer,
  HorizontalContainer,
} from './ExtractedFields.styles';
import { ExtractedFieldsProps } from './ExtractedFields.interface';
import { api, Document } from 'api';
import { isEmpty, values } from 'lodash';
import { ConfirmationModal } from 'modals/ConfirmationModal';
import {
  filterPages,
  filterAnnotations,
  isPhrasesEmpty,
} from './ExtractedFields.helpers';
import { useExtractedFieldsStore } from './ExtractedFields.hooks';
import { ExtractedFieldsList } from '../ExtractedFieldsList';

import { mapDocuments } from './ExtractedFields.helpers';
import { CollapseButton } from './CollapseButton';
import { sortAnnotationsAZ, sortAnnotationsByPosition } from 'utils/helpers';
import { Filters } from './Filters';
import { useCollectionSync, useTyroHistory } from 'hooks';
import Autocomplete from 'components/AutoComplete/AutoComplete';
import { searchSuggestions } from '.';
import { useAnnotationDeleteModalStore } from 'store/reducers/modals';
export const EXTRACTED_FIELDS_ID = 'extractedFields';

export const ExtractedFields: FC<ExtractedFieldsProps> = () => {
  const { t } = useTranslation();
  const {
    annotationDeleteModal,
    setAnnotationDeleteModal,
  } = useAnnotationDeleteModalStore();

  const {
    pageArrow,
    fieldArrow,
    setFieldArrow,
    setSearch,
    editMode,
    documents,
    dataObjects,
    tagGroups,
    deleteAnnotationField,
    search,
    sortBy,
    filterByFieldTypes,
    filterByStatus,
    filterByPhrases,
    filterByDupliactes,
    commonTypes,
  } = useExtractedFieldsStore();
  const [documentsLocal, setDocumentsLocal] = useState<Array<Document>>([]);
  const [collapsedGroups, setGroupsOpened] = useState<Array<string>>();
  const { addQueryParams } = useTyroHistory();
  const { syncCollection } = useCollectionSync();

  useEffect(() => {
    if (
      fieldArrow &&
      !isEmpty(fieldArrow) &&
      !collapsedGroups?.includes(fieldArrow.documentId)
    ) {
      setGroupsOpened((prev = []) => [...prev, fieldArrow.documentId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldArrow]);

  useEffect(() => {
    if (pageArrow && !collapsedGroups?.includes(pageArrow.documentId)) {
      setGroupsOpened((prev = []) => [...prev, pageArrow.documentId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageArrow]);

  useEffect(() => {
    if (!collapsedGroups && documentsLocal && documentsLocal.length > 0) {
      expandAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsLocal, collapsedGroups]);

  const onExpand = (expand: boolean) => {
    if (expand) {
      setGroupsOpened([]);
    } else {
      setGroupsOpened(values(documents).map((document) => document.id));
    }
  };

  const expandAll = () => {
    setGroupsOpened(documentsLocal.map((document) => document.id));
  };

  const toggleGroup = useCallback(
    (id: string) => {
      setFieldArrow(undefined);

      if (collapsedGroups?.includes(id)) {
        setGroupsOpened((collapsedGroups || []).filter((item) => item !== id));
      } else {
        setGroupsOpened((prev) => [...(prev || []), id]);
      }
    },
    [setFieldArrow, collapsedGroups],
  );

  const onDeleteField = useCallback(() => {
    const { pageId, resultId, annotationId, type } = annotationDeleteModal;

    if (resultId && annotationId && pageId) {
      setAnnotationDeleteModal({ open: false });

      api.deleteAnnotation(resultId, annotationId, type).then(() => {
        syncCollection();

        deleteAnnotationField({ pageId, annotationId });
      });
    }
  }, [
    annotationDeleteModal,
    setAnnotationDeleteModal,
    deleteAnnotationField,
    syncCollection,
  ]);

  useEffect(() => {
    const filter = filterPages;

    if (
      search ||
      filterByStatus?.length > 0 ||
      isPhrasesEmpty(filterByPhrases) ||
      (filterByFieldTypes && filterByFieldTypes.length > 0) ||
      (filterByDupliactes && filterByDupliactes.length > 0)
    ) {
      const filterDocuments = filterAnnotations(
        t,
        [
          ...commonTypes.annotationsTypes,
          ...values(commonTypes.tableAnnotationsTypes).map((item) => item.type),
        ],
        documents,
        dataObjects,
        tagGroups,
        filterByStatus,
        filterByPhrases,
        filterByFieldTypes,
        filterByDupliactes,
        search,
      );

      setDocumentsLocal(filter(filterDocuments) || []);
    } else {
      const mergeAnnotations = values(documents).map((doc) => ({
        ...doc,
        tagGroups: tagGroups[doc.id],
        pages: doc.pages.map((page) => ({
          ...page,
          dataObjects: values(dataObjects[page.id]),
        })),
      }));

      setDocumentsLocal(values(mergeAnnotations) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    filterByStatus,
    documents,
    dataObjects,
    tagGroups,
    filterByFieldTypes,
    filterByPhrases,
    filterByDupliactes,
  ]);

  const sortedAnnotations = useMemo(() => {
    if (sortBy === 'position') {
      return sortAnnotationsByPosition(documentsLocal);
    }

    return sortAnnotationsAZ(commonTypes, documentsLocal);
  }, [sortBy, commonTypes, documentsLocal]);

  return (
    <>
      <Container editMode={editMode}>
        {documentsLocal && (
          <>
            <FiltersContainer>
              <AutocompleteContainer>
                <Autocomplete
                  suggestions={searchSuggestions(
                    dataObjects,
                    commonTypes?.annotationsTypes,
                  )}
                  search={search}
                  onChange={(value, onChangeType) => {
                    let searchValue = value;

                    if (onChangeType === 'select') {
                      searchValue = `"${searchValue}"`;
                    }

                    if (fieldArrow?.id) {
                      setFieldArrow(undefined);
                    }

                    addQueryParams({
                      search: searchValue,
                    });

                    setSearch(searchValue);
                  }}
                />
              </AutocompleteContainer>
              <HorizontalContainer>
                <Filters />
                <CollapseButton onClick={onExpand} />
              </HorizontalContainer>
            </FiltersContainer>
            <CollectionsContainer id={EXTRACTED_FIELDS_ID}>
              <ExtractedFieldsList
                data={mapDocuments(sortedAnnotations, t)}
                onGroupCollapse={toggleGroup}
                collapsedGroups={collapsedGroups}
              />
            </CollectionsContainer>
          </>
        )}
      </Container>
      <ConfirmationModal
        onClose={() => setAnnotationDeleteModal({ open: false })}
        onConfirm={onDeleteField}
        open={annotationDeleteModal.open}
        title={t('deleteFieldQuestion')}
        content={t('deleteFieldInfo')}
      />
    </>
  );
};
