import { pageDataObjectId } from 'components/FieldArrow';
import { FC } from 'react';
import { requiresAttention } from 'utils/helpers';
import { useTableStore } from './Table.hooks';
import { TableProps } from './Table.interface';
import { ColumnLine, Container, Dot, RowLine } from './Table.styles';

export const Table: FC<TableProps> = ({
  id,
  item,
  pageHeight,
  pageWidth,
  resultId,
  pageId,
  documentId,
}) => {
  const { setFieldArrow, fieldArrow, setZoom } = useTableStore();

  const fieldArrowId = `field_${id}`;

  return item.columnGrid ? (
    <Container
      id={pageDataObjectId(id)}
      className={fieldArrowId}
      $selected={fieldArrowId === `field_${fieldArrow?.id}`}
      $top={item.boundingBox?.y * pageHeight}
      $left={item.boundingBox.x * pageWidth}
      $width={item.boundingBox.width * pageWidth}
      $height={item.boundingBox.height * pageHeight}
      onClick={(event) => {
        event.stopPropagation();

        if (fieldArrow?.id !== id) {
          setZoom({ scale: 1, isButtonAction: true });
          setFieldArrow({
            id,
            item,
            resultId,
            documentId,
            pageId,
          });
        } else {
          setFieldArrow(undefined);
        }
      }}
    >
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        {item.columnGrid.slice(1, -1).map((item, index) => (
          <ColumnLine key={`${fieldArrowId}_item${index}`} position={item * 100} />
        ))}
        {item.rowGrid.slice(1, -1).map((item, index) => (
          <RowLine key={`${fieldArrowId}_item${index}`} position={item * 100} />
        ))}
      </div>
      {fieldArrowId === `field_${fieldArrow?.id}` && (
        <Dot
          $requiresAttention={requiresAttention(item.status.status)}
          $top={(item.boundingBox.height * pageHeight) / 2}
        />
      )}
    </Container>
  ) : null;
};
