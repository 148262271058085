import { useContext, createContext } from 'react';

export const createContextWith = <ContextType,>() => {
  const context = createContext<ContextType | undefined>(undefined);

  const useWith = () => {
    const contextWith = useContext(context);

    if (!contextWith) throw new Error('useContextwith must be inside a Provider');

    return contextWith;
  };

  return [useWith, context.Provider] as const;
};
