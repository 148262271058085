import { Button } from 'components/Button';
import { IconFilter, IconTrash } from 'components/Icons';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import styled from 'styled-components';

interface IconProps {
  enabled: boolean;
}

export const StyledIconButton = styled.div<IconProps>`
  :hover {
    border: ${({ theme }) => `1px solid ${theme.colors.blackPurple}`};
  }
  position: relative;
  border: ${({ theme, enabled }) =>
    enabled
      ? `1px solid ${theme.colors.blackPurple}`
      : `1px solid ${theme.colors.transparent}`};
  background-color: ${({ theme }) => theme.colors.lilac};
  border-radius: 6px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const StyledFilter = styled(IconFilter)`
  width: 20px;
`;

export const FiltersContainer = styled.div`
  height: auto;
  width: 567px;
  border: 1px solid #d9deea;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 16px;
  padding-bottom: 32px;
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.5);
`;

export const FilterTitle = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  user-select: none;
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.gray300};
  font-size: 14px;
`;

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  user-select: none;
  color: ${({ theme }) => theme.colors.gray300};
  font-size: 14px;
  margin-bottom: 8px;
`;

export const FilterOptions = styled.div`
  display: flex;
`;

export const SortingContainer = styled.div`
  display: flex;
  padding-left: 16px;
  flex-direction: column;
  width: 50%;
  margin-top: 16px;
`;

export const FilterContainer = styled.div`
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 8px;
`;

export const ClearAllButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const StyledIconTrash = styled(IconTrash)`
  height: 20px;
  width: 20px;
`;

export const FilterCount = styled.div`
  background-color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: -6px;
  right: -6px;
  user-select: none;
`;

export const PhraseFieldTypeContainer = styled.div`
  min-width: 30%;
  max-width: 30%;
  margin-right: 8px;
`;

export const PhraseFieldValuesContainer = styled.div`
  width: 60%;
  margin-right: 8px;
`;

export const StyledAddButton = styled(SecondaryAltButton)`
  margin-left: 8px;
`;
