import { FC } from 'react';
import { LookUpProps } from './LookUp.interface';
import { useLookUpStore } from './LookUp.hooks';
import { StyledLookUp, Dot } from './LookUp.styles';

export const LookUp: FC<LookUpProps> = ({
  width,
  height,
  id,
  onClick,
  top,
  left,
  requiresAttention = false,
  selected = false,
}) => {
  const { fieldArrow } = useLookUpStore();

  return (
    <StyledLookUp
      className={id}
      $width={width}
      $height={height}
      $selected={selected}
      $top={top}
      $left={left}
      $requiresAttention={requiresAttention}
      onClick={onClick}
    >
      {id === `field_${fieldArrow?.id}` && (
        <Dot $requiresAttention={requiresAttention} $top={height / 2} />
      )}
    </StyledLookUp>
  );
};
