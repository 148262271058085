import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  StyledTitle,
  Container,
  Content,
  TopContainer,
  StyledButtonWrapper,
  StyledIconButton,
} from './ExtractionSetup.styles';
import { keys, values } from 'lodash';
import { api } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCollapsedExtractionFields,
  selectEditExtractionFields,
  selectExtractions,
  toggleAllExtracion,
  updateAddExtractionField,
  updateEditExtractionField,
} from 'store/reducers/configuration';
import { Button } from 'components/Button';
import { ExtractionItem } from './ExtractionItem';
import { IconCollapse } from 'components/Icons';
export const ExtractionSetup: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const extractions = useSelector(selectExtractions);
  const editExtractionFields = useSelector(selectEditExtractionFields);
  const [loading, setLoading] = useState(false);
  const addEditExtractionField = useCallback(
    (e) => dispatch(updateEditExtractionField(e)),
    [dispatch],
  );
  const addExtractionField = useCallback((e) => dispatch(updateAddExtractionField(e)), [
    dispatch,
  ]);
  const allCollapsed = useSelector(selectCollapsedExtractionFields);

  const handleToggleCollapse = () => {
    dispatch(toggleAllExtracion({ collapsed: !allCollapsed }));
  };

  const onSave = async () => {
    setLoading(true);

    values(editExtractionFields).forEach(async (item) => {
      const extractionId = item.extractionId;

      if (extractionId) {
        await api
          .updateExtractorField(extractionId, item)
          .then(() => {
            addExtractionField({ field: { ...item }, extractionId });
            addEditExtractionField({ field: { ...item, saved: true } });
          })
          .catch(() => {
            addEditExtractionField({ field: { ...item, error: true } });
            toast.error(`${t('extractorFieldError')} - ${item.fieldNameText}`, {
              position: 'bottom-left',
            });
          });
      }
    });

    setLoading(false);
  };

  return (
    <Container>
      <TopContainer>
        <StyledTitle>{t('extractionsSetup')}</StyledTitle>
        <StyledButtonWrapper>
          <Button
            disabled={
              values(editExtractionFields).filter((item) => !item.saved).length === 0 ||
              loading
            }
            title={t('save')}
            onClick={onSave}
            loading={loading}
          />
          <StyledIconButton
            withBackground={false}
            onClick={handleToggleCollapse}
            icon={<IconCollapse />}
          >
            {t('collapse')}
          </StyledIconButton>
        </StyledButtonWrapper>
      </TopContainer>
      <Content>
        {keys(extractions).map((item) => (
          <ExtractionItem key={item} extractionId={item} />
        ))}
      </Content>
    </Container>
  );
};
