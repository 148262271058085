import { SortBy } from 'api';
import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface SortByProps {
  sortBy?: SortBy;
}

interface SortByDispatch extends BaseDispatch {
  payload: SortByProps;
}

export const SET_SORT_BY = 'SET_SORT_BY';

export const selectSortBy = (state: StoreProps) => state.supervision.sortBy;

export const updateSortByState = (state: SupervisionStore, data: SortByProps) => {
  return { ...state, sortBy: data.sortBy };
};

export const updateSortBy = (sortBy?: SortBy) => (
  dispatch: (data: SortByDispatch) => void,
) => {
  dispatch({
    type: SET_SORT_BY,
    payload: { sortBy },
  });
};
