// @ts-nocheck
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SuggestionContainer, Container } from './AutoComplete.styles';
import { InputSearch } from 'components/InputSearch';

export type SearchType = 'input' | 'select';

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
    onChange: PropTypes.func,
    search: PropTypes.string,
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props: any) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
    };

    this.itemRefs = {};
  }

  onChange = (value: any) => {
    const { suggestions } = this.props;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      (suggestion) => suggestion.toLowerCase().indexOf(value?.toLowerCase()) > -1,
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
    });

    this.props.onChange(value, 'input');
  };

  onClick = (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    });

    this.props.onChange(e.currentTarget.innerText, 'select');
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
      });

      this.props.onChange(filteredSuggestions[activeSuggestion], 'select');
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
      this.itemRefs[activeSuggestion - 1]?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      });
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
      this.itemRefs[activeSuggestion + 1]?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: { activeSuggestion, filteredSuggestions, showSuggestions },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && this.props.search) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <SuggestionContainer>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              if (index === activeSuggestion) {
                className = 'active';
              }

              return (
                <li
                  ref={(el) => (this.itemRefs[index] = el)}
                  className={className}
                  key={suggestion}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </SuggestionContainer>
        );
      }
    }

    return (
      <Fragment>
        <Container>
          <InputSearch
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={this.props.search}
          />
          {suggestionsListComponent}
        </Container>
      </Fragment>
    );
  }
}

export default Autocomplete;
