import { AnnotationProcessStatus, StatusMsg } from 'api';
import { FC } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { FieldStatusProps } from './FieldStatus.interface';
import { StyledTypography } from './FieldStatus.styles';

export const translateStatus = (
  translation: TFunction,
  status?: AnnotationProcessStatus,
) => {
  switch (status) {
    case 'Corrected':
      return translation('corrected');
    case 'Needs Attention':
      return translation('uncertain');
    case 'Accepted':
      return translation('accepted');
    case 'Manual':
      return translation('manual');
    default:
      return '';
  }
};

export const mapMsg = (translation: TFunction, msg?: StatusMsg) => {
  switch (msg) {
    case 'Missing value':
      return translation('missing');
    case 'Invalid Format':
      return translation('invalid');
    case 'Uncertain Annotation':
    default:
      return translation('uncertain');
  }
};

export const FieldStatus: FC<FieldStatusProps> = ({ status, message }) => {
  const { t } = useTranslation();

  return (
    <StyledTypography $status={status}>
      {status === 'Needs Attention'
        ? mapMsg(t, message)?.toUpperCase()
        : translateStatus(t, status)?.toUpperCase()}
    </StyledTypography>
  );
};
