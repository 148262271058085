import { Pagination } from 'antd';
import { Label } from 'components/Label';
import { ProcessProgress } from 'components/ProcessProgress';
import styled from 'styled-components';

export const FieldColumns = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.padding.tiny};
  user-select: none;
`;

export const FieldName = styled(Label)`
  width: 50%;
  padding-left: ${({ theme }) => theme.padding.small};
  color: ${({ theme }) => theme.colors.gray300};
`;

export const FieldStatus = styled(Label)`
  width: 25%;
  padding-left: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.gray300};
`;

export const StyledRow = styled.div`
  background-color: ${({ theme }) => theme.colors.content};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.padding.tiny};
  height: 5vh;
  display: flex;
  align-items: center;
`;

export const RowName = styled.div`
  width: 50%;
  padding-left: ${({ theme }) => theme.padding.small};
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.normal};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;

export const RowStatus = styled.div`
  width: 25%;
  padding-left: ${({ theme }) => theme.padding.tiny};
  padding-right: ${({ theme }) => theme.padding.small};
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const StyledProgress = styled(ProcessProgress)`
  width: 100%;
`;

export const StyledPagination = styled(Pagination)`
  float: right;
  padding-bottom: ${({ theme }) => theme.padding.large};
`;

export const SearchContainer = styled.div`
  width: 35%;
  padding-bottom: 16px;
`;
