import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllDataObjects } from 'store/reducers/collection';
import { selectCommonTypes } from 'store/reducers/common';
import {
  updateSortBy,
  updateFilterByFieldTypes,
  selectFilterByFieldTypes,
  selectSortBy,
  updateFilterByPhrases,
  selectFilterByPhrases,
  updateFieldArrow,
} from 'store/reducers/supervision';
import {
  selectFilterByFieldDuplicates,
  updateFilterByFieldDuplicates,
} from 'store/reducers/supervision/filterByFieldDuplicates';
import {
  selectFilterByStatus,
  updateFilterByStatus,
} from 'store/reducers/supervision/filterByStatus';
import { flattenCommonTypes } from 'utils/helpers';
import { findDuplicatedAnnotationTypes } from './Filters.helpers';

export const useFiltersStore = () => {
  const dispatch = useDispatch();
  const setSortBy = useCallback((e) => dispatch(updateSortBy(e)), [dispatch]);
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const setFilterByFieldType = useCallback((e) => dispatch(updateFilterByFieldTypes(e)), [
    dispatch,
  ]);
  const setFilterByStatus = useCallback((e) => dispatch(updateFilterByStatus(e)), [
    dispatch,
  ]);
  const setFilterByPhrases = useCallback((e) => dispatch(updateFilterByPhrases(e)), [
    dispatch,
  ]);
  const setFilterByFieldDuplicates = useCallback(
    (e) => dispatch(updateFilterByFieldDuplicates(e)),
    [dispatch],
  );
  const commonTypes = useSelector(selectCommonTypes);
  const dataObjects = useSelector(selectAllDataObjects);
  const filterByPhrases = useSelector(selectFilterByPhrases);
  const filterByFieldTypes = useSelector(selectFilterByFieldTypes);
  const filterByFieldDuplicates = useSelector(selectFilterByFieldDuplicates);
  const filterByStatus = useSelector(selectFilterByStatus);
  const sortBy = useSelector(selectSortBy);

  const duplicatedAnnotationTypes = findDuplicatedAnnotationTypes(
    dataObjects,
    flattenCommonTypes(commonTypes),
  );

  return {
    setFieldArrow,
    setFilterByPhrases,
    setFilterByStatus,
    setFilterByFieldType,
    setFilterByFieldDuplicates,
    setSortBy,
    sortBy,
    filterByFieldTypes,
    filterByStatus,
    filterByPhrases,
    filterByFieldDuplicates,
    commonTypes,
    duplicatedAnnotationTypes,
  };
};
