import { FC } from 'react';
import { PageThumbnailProps } from './PageThumbnail.interface';
import {
  StyledImage,
  PageNumber,
  AnnotationAttentions,
  Container,
} from './PageThumbnail.styles';
import { useSupervisionImage } from 'hooks';
import { Tooltip } from 'antd';
import { theme } from 'styles';

export const PageThumbnail: FC<PageThumbnailProps> = ({
  url,
  selected,
  preventLoading,
  height,
  pageId,
  pageNumber,
  documentName,
  onThumbnail,
  annotationsAttentions,
}) => {
  const { image } = useSupervisionImage({ imageUrl: url, preventLoading });
  return (
    <>
      <Container>
        <Tooltip
          title={documentName}
          placement="right"
          overlayStyle={{
            whiteSpace: 'nowrap',
            maxWidth: 800,
          }}
          color={theme.colors.blackPurple}
        >
          <StyledImage
            onClick={() => onThumbnail(pageId)}
            style={{ height }}
            $selected={selected}
            draggable={false}
            alt="page"
            src={image}
          />
          {annotationsAttentions > 0 && (
            <AnnotationAttentions>{annotationsAttentions}</AnnotationAttentions>
          )}
        </Tooltip>
      </Container>
      <PageNumber>{pageNumber}</PageNumber>
    </>
  );
};
