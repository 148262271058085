import { FC } from 'react';
import { usePageItem } from './PageItem.hooks';
import { PageItemProps } from './PageItem.interface';
import { Image, Container, PageNumber } from './PageItem.styles';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useInView } from 'react-intersection-observer';

export const PageItem: FC<PageItemProps> = ({ id, onZoom, activeId }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 500,
  });
  const { page, image } = usePageItem({ id, inView });

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Container ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Image
        ref={ref}
        isActive={activeId === id}
        src={image}
        onClick={() => onZoom && onZoom(image)}
      />
      <PageNumber>{page?.collectionPageNum}</PageNumber>
    </Container>
  );
};
