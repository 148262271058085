import { useSelector } from 'react-redux';
import { selectUser } from 'store/reducers/common';
import { ENV_VARIABLE } from 'variables';

export type UserScope =
  | 'COLLECTION_CREATE'
  | 'COLLECTION_START_STOP'
  | 'COLLECTION_DELETE'
  | 'RESULT_READ'
  | 'RESULT_UPDATE'
  | 'RESULT_EXPORT'
  | 'USERS_MANAGE'
  | 'USERS_READ'
  | 'USERS_INVITE';

export const FILE_UPLOAD = 'aia.file:upload';
export const COLLECTION_CREATE = 'aia.collection:create';
export const COLLECTION_START_STOP = 'aia.collection:update';
export const COLLECTION_DELETE = 'aia.collection:delete';
export const RESULT_READ = 'aia.result:read';
export const RESULT_UPDATE = 'aia.result:update';
export const USERS_MANAGE = 'aia.admin.user:manage';
export const USERS_INVITE = 'aia.admin.user:invite';
export const USERS_READ = 'aia.admin.user:read';

export const SCOPES = ``;

export const useUserScope = (scope: UserScope) => {
  const user = useSelector(selectUser);
  let isAllowed = !ENV_VARIABLE.KEY_CLOAK_CLIENT_ID;

  if (ENV_VARIABLE.KEY_CLOAK_CLIENT_ID) {
    if (scope === 'COLLECTION_CREATE') {
      isAllowed =
        !!user?.scope.includes(COLLECTION_CREATE) && !!user?.scope.includes(FILE_UPLOAD);
    }

    if (scope === 'COLLECTION_START_STOP') {
      isAllowed = !!user?.scope.includes(COLLECTION_START_STOP);
    }

    if (scope === 'COLLECTION_DELETE') {
      isAllowed = !!user?.scope.includes(COLLECTION_DELETE);
    }

    if (scope === 'RESULT_READ') {
      isAllowed = !!user?.scope.includes(RESULT_READ);
    }

    if (scope === 'RESULT_UPDATE') {
      isAllowed = !!user?.scope.includes(RESULT_UPDATE);
    }

    if (scope === 'USERS_READ') {
      isAllowed =
        !!user?.scope.includes(USERS_READ) &&
        !!ENV_VARIABLE.KEY_CLOAK_CLIENT_ID &&
        ENV_VARIABLE.USER_MANAGEMENT_ENABLED === 'true';
    }

    if (scope === 'USERS_INVITE') {
      isAllowed = !!user?.scope.includes(USERS_INVITE);
    }

    if (scope === 'USERS_MANAGE') {
      isAllowed = !!user?.scope.includes(USERS_MANAGE);
    }

    if (scope === 'RESULT_EXPORT') {
      isAllowed = ENV_VARIABLE.EXPORT === 'true' && !!user?.scope.includes(RESULT_READ);
    }
  }

  return { isAllowed };
};
