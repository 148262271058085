import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateField, StoreProps } from 'store';
import { clearCreatedFields, selectCreateFields } from 'store/reducers/supervision';

export const useLookUpSelectionStore = (pageId: string) => {
  const dispatch = useDispatch();
  const clearCreateFields = useCallback(() => dispatch(clearCreatedFields()), [dispatch]);
  const createFields = useSelector<StoreProps, CreateField>((state) =>
    selectCreateFields(state, pageId),
  );

  return { createFields, clearCreateFields };
};
