import { SelectOption } from 'components/Select';
import { Dictionary } from 'lodash';
import { BaseDispatch, CommonStore, StoreProps } from 'store/store.interface';

export interface CommonTypesData {
  types: Dictionary<Array<SelectOption>>;
}

interface CommonTypesDispatch extends BaseDispatch {
  payload: CommonTypesData;
}

export const SET_COMMON_TYPES = 'SET_COMMON_TYPES';

export const selectCommonTypes = (state: StoreProps) => state.common.commonTypes;

export const updateCommonTypesState = (state: CommonStore, data: CommonTypesData) => {
  return { ...state, commonTypes: { ...data } };
};

export const updateCommonTypes = (data: CommonTypesData) => (
  dispatch: (data: CommonTypesDispatch) => void,
) => {
  dispatch({
    type: SET_COMMON_TYPES,
    payload: data,
  });
};
