import { Typography } from 'antd';
import { IconDelete, IconEdit } from 'components/Icons';
import { Label } from 'components/Label';
import { Menu } from 'react-contexify';
import styled from 'styled-components';

interface ContainerProps {
  $highlighted?: boolean;
}

interface CellProps {
  size: number;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  padding-left: ${({ theme }) => theme.padding.normal};
  border-radius: 0.3em;
  width: 100%;
  height: 56px;
  margin-bottom: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${({ theme, $highlighted }) =>
    $highlighted ? theme.colors.gray400 : theme.colors.white};
  :hover {
    background-color: ${({ theme }) => theme.colors.gray400};
  }
`;

export const Cell = styled.div<CellProps>`
  width: ${({ size }) => `${size}%`};
  align-items: center;
  display: flex;
`;

export const MenuContainer = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.padding.tiny};
`;

export const StyledName = styled(Label)`
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const StyledDate = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const StyledDeleteIcon = styled(IconDelete)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledEditIcon = styled(IconEdit)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledMenu = styled(Menu).attrs({})`
  border: ${({ theme }) => `1px solid ${theme.colors.line}`};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.2);
  border-radius: 3px;
  min-width: 5vw;

  .react-contexify__item__content {
    color: ${({ theme }) => theme.colors.gray};
  }

  .react-contexify__item:not(.react-contexify__item--disabled):hover
    > .react-contexify__item__content,
  .react-contexify__item:not(.react-contexify__item--disabled):focus
    > .react-contexify__item__content {
    background-color: ${({ theme }) => theme.colors.menuItem};
    color: ${({ theme }) => theme.colors.gray};
    overflow: hidden;
    border-radius: 3px;
  }
`;
