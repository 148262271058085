import { useEffect, useMemo, useRef, useState } from 'react';
import { TableAnnotation } from 'api';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import { StoreProps } from 'store';
import { selectDataObjectById } from 'store/reducers/collection';
import { selectFieldArrow, selectPaneDragging } from 'store/reducers/supervision';
import { ExtractionTableProps } from './ExtractionTable.interface';
import { StyledTable, StyledTableWrapper } from './ExtractionTable.styles';
import { uniqueId } from 'lodash';
import { selectPaneSizes } from 'store/reducers/supervision/paneSizes';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './ExtractionTable.css';
import { Cell } from 'components/ExtractionTable/Cell';
import { HeaderCell } from 'components/ExtractionTable/HeaderCell';
import { ICellRendererParams } from 'ag-grid-community';

export const ExtractionTable = ({ title = '', transition }: ExtractionTableProps) => {
  const fieldArrow = useSelector(selectFieldArrow);
  const paneDragging = useSelector(selectPaneDragging);
  const paneSizes = useSelector(selectPaneSizes);
  const tableObject = useSelector((state: StoreProps) =>
    selectDataObjectById(state, fieldArrow?.pageId, fieldArrow?.id),
  ) as TableAnnotation;
  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState<any>();
  const [columnDefs, setColumnDefs] = useState<any>([]);

  const fitColumns = () => gridRef.current?.api?.sizeColumnsToFit();

  const ColumnHeader = (props: any) => {
    const { colId } = props?.column;

    return <HeaderCell cellId={colId} label={props.displayName} />;
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      suppressMovable: true,
    };
  }, []);

  useEffect(() => {
    fitColumns?.();
  }, [paneDragging, paneSizes]);

  useEffect(() => {
    const onResize = () => {
      fitColumns?.();
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    if (tableObject?.id && tableObject?.columnGrid) {
      let columnsData = tableObject?.headers.map((item, index) => ({
        field: item.id,
        headerName: item.label,
        cellRenderer: (params: ICellRendererParams) => {
          if (!params.value) return null;
          const width = params.column?.getActualWidth();
          return <Cell width={width} cellId={params.value} id={item.id} />;
        },
      }));

      const detectedColumns = tableObject?.columnGrid.slice(1) ?? [];
      if (!columnsData.length) {
        columnsData = detectedColumns.map((_, id) => ({
          field: uniqueId(),
          headerName: '',
          cellRenderer: (params: ICellRendererParams) => {
            if (!params.value) return null;
            const width = params.column?.getActualWidth();
            return <Cell width={width} cellId={params.value} id={String(id)} />;
          },
        }));
      }

      const rows = new Array(tableObject.rows.length).fill({});

      tableObject.rows.forEach((item, index) => {
        item.forEach((row, rowIndex) => {
          if (rows[index] && columnsData[rowIndex]) {
            rows[index] = {
              [columnsData[rowIndex].field]: row.id,
              ...rows[index],
            };
          } else if (columnsData[rowIndex]) {
            rows[index] = {
              [columnsData[rowIndex].field]: row.id,
            };
          }
        });
      });

      setColumnDefs(columnsData);
      setRowData(rows);

      setTimeout(() => {
        fitColumns?.();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableObject]);

  const onGridReady = () => {
    fitColumns?.();
  };
  const components = useMemo(() => {
    return {
      agColumnHeader: ColumnHeader,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledTableWrapper transition={transition}>
      <StyledTable>
        <div className="outer-div">
          <div className="grid-wrapper">
            <div style={gridStyle} className="ag-theme-alpine">
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                colResizeDefault="shift"
                suppressMovableColumns
                components={components}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </StyledTable>
    </StyledTableWrapper>
  );
};
