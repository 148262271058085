import styled from 'styled-components';
import { TyroTheme } from 'styles';
import { HeadlineProps, HeadlineSize } from './Headline.interface';

const fontSize = (theme: TyroTheme, size: HeadlineSize) => {
  switch (size) {
    case 'h1':
      return theme.fontSize.headlineh1;
    case 'h2':
      return theme.fontSize.headlineh2;
    case 'h3':
      return theme.fontSize.headlineh3;
    case 'h4':
    default:
      return theme.fontSize.headlineh4;
  }
};

const fontFamily = (theme: TyroTheme, size: HeadlineSize) => {
  switch (size) {
    case 'h1':
      return theme.fontFamily.semiBold;
    case 'h2':
      return theme.fontFamily.semiBold;
    case 'h3':
      return theme.fontFamily.semiBold;
    case 'h4':
    default:
      return theme.fontFamily.regular;
  }
};

const lineHeight = (size: HeadlineSize) => {
  switch (size) {
    case 'h1':
      return '43px';
    case 'h2':
      return '28px';
    case 'h3':
      return '28px';
    case 'h4':
    default:
      return '23px';
  }
};

export const StyledHeadline = styled.span<HeadlineProps>`
  font-size: ${({ theme, size }) => fontSize(theme, size)};
  font-family: ${({ theme, size }) => fontFamily(theme, size)};
  line-height: ${({ size }) => lineHeight(size)};
`;
