import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface SearchProps {
  search?: string;
}

interface SearchDispatch extends BaseDispatch {
  payload: SearchProps;
}

export const SET_SEARCH = 'SET_SEARCH';

export const selectSearch = (state: StoreProps) => state.supervision.search;

export const updateSearchState = (state: SupervisionStore, data: SearchProps) => {
  return { ...state, search: data.search };
};

export const updateSearch = (search?: string) => (
  dispatch: (data: SearchDispatch) => void,
) => {
  dispatch({
    type: SET_SEARCH,
    payload: { search },
  });
};
