import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import { SettingsModalProps } from './SettingsModal.interface';
import { settingsMenu } from './Settings.menu';
import { Language } from './Language';
import {
  Header,
  IconCloseContainer,
  StyledIconClose,
  StyledTitle,
  MenuContainer,
  ContentContainer,
  HorizontalContainer,
  MenuItemContainer,
  MenuItemLabel,
  Container,
  MenuContent,
} from './SettingsModal.styles';
import { ExtractionSetup } from './ExtractionSetup';
import { Supervision } from './Supervision';
import { Version } from './Version';

export const SettingsModal: FC<SettingsModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState(1);
  const [hoverMenu, setHoverMenu] = useState<number | undefined>();
  const history = useHistory();

  useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === 'POP') {
        history.replace(history.location.pathname /* the new state */);
      }
    };
  }, [history]);

  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'fullscreen-modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <Header>
          <StyledTitle>{t('settings')}</StyledTitle>
          <IconCloseContainer onClick={onClose}>
            <StyledIconClose />
          </IconCloseContainer>
        </Header>
        <HorizontalContainer>
          <MenuContainer>
            <MenuContent>
              <div>
                {settingsMenu(t).map((item) => (
                  <MenuItemContainer
                    onMouseEnter={() => setHoverMenu(item.id)}
                    onMouseLeave={() => setHoverMenu(undefined)}
                    onClick={() => setSelectedMenu(item.id)}
                    key={item.label}
                    $selected={selectedMenu === item.id}
                  >
                    <item.Icon
                      $hover={hoverMenu === item.id}
                      $selected={selectedMenu === item.id}
                    />
                    <MenuItemLabel
                      $hover={hoverMenu === item.id}
                      $selected={selectedMenu === item.id}
                    >
                      {item.label}
                    </MenuItemLabel>
                  </MenuItemContainer>
                ))}
              </div>
            </MenuContent>
          </MenuContainer>
          <ContentContainer>
            {selectedMenu === 1 && <Language />}
            {selectedMenu === 2 && <ExtractionSetup />}
            {selectedMenu === 3 && <Supervision />}
            {selectedMenu === 4 && <Version />}
          </ContentContainer>
        </HorizontalContainer>
      </Container>
    </Modal>
  );
};
