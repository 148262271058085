import { TFunction } from 'react-i18next';
import { ENV_VARIABLE } from 'variables';
import {
  StyledIconFlag,
  StyledIconExtractionSetup,
  StyledIconSupervise,
  StyledIconVersion,
} from './SettingsModal.styles';

export const settingsMenu = (translate: TFunction) => {
  const menu = [
    {
      id: 1,
      label: translate('language') || '',
      Icon: StyledIconFlag,
    },
  ];

  if (ENV_VARIABLE.CONFIGURATION_ENABLED === 'true') {
    menu.push({
      id: 2,
      label: translate('extractionsSetup') || '',
      Icon: StyledIconExtractionSetup,
    });
  }

  menu.push({
    id: 3,
    label: 'Supervision',
    Icon: StyledIconSupervise,
  });

  menu.push({
    id: 4,
    label: translate('version') || '',
    Icon: StyledIconVersion,
  });

  return menu;
};
