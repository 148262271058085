import { api } from 'api';
import moment from 'moment';
import { toast } from 'react-toastify';
import { theme } from 'styles';
import { IconCheckCircle } from 'components/Icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { setExport } from './modalsSlice';
import { useAppDispatch, useAppSelector } from 'store/store.hooks';
import { ExportModalProps } from './modals.interface';
import i18n from 'i18n';

// TODO: move as component
const ToastText = styled.span`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.gray200};
`;

const downloadFile = (data: string, format: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;

  link.setAttribute(
    'download',
    `${format}_${moment(new Date()).format('DD-MM-YYYY')}.${format}`,
  );
  document.body.appendChild(link);
  link.click();
};

export const useExportModalStore = () => {
  const dispatch = useAppDispatch();
  const setExportModal = (e: ExportModalProps) => dispatch(setExport(e));
  const exportModal = useAppSelector((state) => state.modals.exportModal);
  const language = i18n?.language;

  const { t } = useTranslation();

  const onExport = (format: string) => {
    if (
      exportModal &&
      exportModal.collectionIds &&
      exportModal.collectionIds?.length > 0
    ) {
      api.exportCollections(exportModal.collectionIds, format, language).then((data) => {
        setExportModal({
          open: false,
        });
        const id = data.location.split('/');

        const toastPromise = new Promise<void>((resolve, reject) => {
          const fetchExportedFile = () => {
            api
              .fetchExportedFile(id[2])
              .then((response) => {
                if (response.status === 200) {
                  setTimeout(() => {
                    downloadFile(response.data, format);
                    resolve();
                  }, 2000);
                } else {
                  setTimeout(() => {
                    fetchExportedFile();
                  }, 2000);
                }
              })
              .catch(() => {
                reject();
              });
          };

          fetchExportedFile();
        });

        toast.promise(
          toastPromise,
          {
            pending: {
              render: (
                <ToastText>
                  {t('preparingFile', { format: format.toUpperCase() })}
                </ToastText>
              ),
            },
            success: {
              render: <ToastText>{t('fileDownload')}</ToastText>,
              icon: <IconCheckCircle />,
            },
            error: {
              render: (
                <ToastText>
                  {t('filePreparingError', { format: format.toUpperCase() })}
                </ToastText>
              ),
            },
          },
          {
            position: 'bottom-left',
            progressStyle: { backgroundColor: theme.colors.darkPurple },
          },
        );
      });
    }
  };

  return { exportModal, setExportModal, onExport };
};
