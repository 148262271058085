import { TransitionStatus } from 'react-transition-group';
import { EXITED, ENTERED } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';

interface StyledTableProps {
  columns?: string;
  rows?: string;
}

export const onEnter = (transition: TransitionStatus) =>
  transition === ENTERED &&
  css`
    bottom: 0;
  `;

export const onExit = (transition: TransitionStatus) =>
  transition === EXITED &&
  css`
    bottom: -60%;
    transition: bottom 250ms ease-in-out;
  `;

export const StyledTable = styled.div<StyledTableProps>`
  height: 400px;
  width: 100%;
  margin-left: 0px;
`;

export const StyledTableWrapper = styled.div<{ transition: TransitionStatus }>`
  background: ${({ theme }) => theme.colors.white};
  bottom: 0;
  box-shadow: 0px -4px 13px rgba(217, 222, 234, 0.5);
  position: absolute;
  transition: bottom 400ms ease-in-out;
  width: calc(100% - 16px);
  ${({ transition }) => [onEnter(transition), onExit(transition)]}
`;

export const StyledTableHeader = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.gray300};
`;
