import i18n from 'i18n';
import { Dictionary } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { stringifyParams } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { clearCollection } from 'store/reducers/collection';

export const useTyroHistory = () => {
  const dispatch = useDispatch();
  const resetCollection = useCallback(() => dispatch(clearCollection()), [dispatch]);
  const language = i18n?.language;
  const history = useHistory();
  const { search } = useLocation();

  const openDocuments = () => {
    history.push(`/documents?lang=${language}&order=position`);
  };

  const openSupervision = (id: string) => {
    resetCollection();
    history.push(`/supervision/${id}?lang=${language}&order=position`, {
      from: history.location.pathname,
    });
  };

  const openUsers = () => {
    history.push(`/users?lang=${language}&order=pd&offset=0&limit=10`, {
      from: history.location.pathname,
    });
  };

  const addQueryParams = (
    params: Dictionary<boolean | string | number | undefined | Array<string>>,
  ) => {
    const queryObj = queryString.parse(search, {
      arrayFormat: 'comma',
    });

    history.replace({
      pathname: history.location.pathname,
      search: stringifyParams({ ...queryObj, ...params, lang: language }),
    });
  };

  const addWithQueryParams = (
    query: string,
    params: Dictionary<string | number | undefined | Array<string>>,
  ) => {
    const queryObj = queryString.parse(query, {
      arrayFormat: 'comma',
    });

    history.replace({
      pathname: history.location.pathname,
      search: stringifyParams({ ...queryObj, ...params, lang: language }),
    });
  };

  return {
    openSupervision,
    openDocuments,
    openUsers,
    addQueryParams,
    addWithQueryParams,
  };
};
