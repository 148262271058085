import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDataObject,
  selectAllDataObjects,
  selectAllTagGroups,
  selectDocuments,
} from 'store/reducers/collection';
import {
  selectEditMode,
  updateFieldArrow,
  selectFieldArrow,
  selectSortBy,
  selectSearch,
  updateSearch,
  updateSortBy,
  selectFilterByFieldTypes,
  selectFilterByPhrases,
  selectPageArrow,
} from 'store/reducers/supervision';
import { selectFilterByStatus } from 'store/reducers/supervision/filterByStatus';
import { selectCommonTypes } from 'store/reducers/common';
import { StoreProps } from 'store';
import { selectFilterByFieldDuplicates } from 'store/reducers/supervision/filterByFieldDuplicates';

export const useExtractedFieldsStore = () => {
  const dispatch = useDispatch();
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const setSearch = useCallback((e) => dispatch(updateSearch(e)), [dispatch]);
  const setSortBy = useCallback((e) => dispatch(updateSortBy(e)), [dispatch]);
  const deleteAnnotationField = useCallback((e) => dispatch(deleteDataObject(e)), [
    dispatch,
  ]);
  const editMode = useSelector(selectEditMode);
  const pageArrow = useSelector(selectPageArrow);
  const fieldArrow = useSelector(selectFieldArrow);
  const documents = useSelector(selectDocuments);
  const dataObjects = useSelector(selectAllDataObjects);
  const tagGroups = useSelector(selectAllTagGroups);
  const search = useSelector(selectSearch);
  const sortBy = useSelector(selectSortBy);
  const filterByFieldTypes = useSelector(selectFilterByFieldTypes);
  const filterByStatus = useSelector(selectFilterByStatus);
  const filterByPhrases = useSelector(selectFilterByPhrases);
  const filterByDupliactes = useSelector(selectFilterByFieldDuplicates);
  const commonTypes = useSelector((state: StoreProps) => selectCommonTypes(state));

  return {
    documents,
    dataObjects,
    tagGroups,
    fieldArrow,
    pageArrow,
    setFieldArrow,
    setSearch,
    editMode,
    deleteAnnotationField,
    search,
    sortBy,
    setSortBy,
    filterByFieldTypes,
    filterByStatus,
    filterByPhrases,
    filterByDupliactes,
    commonTypes,
  };
};
