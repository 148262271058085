/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useCallback, useRef, useEffect } from 'react';
import { PageProps } from './Page.interface';
import { store } from 'react-notifications-component';
import { Container, Dot, PageContainer } from './Page.styles';
import { boundingBoxByDataObjectId } from 'utils/helpers';
import { usePageStore } from './Page.hooks';
import { Word } from 'components/Word/Word';
import { Lookup, TableAnnotation } from 'api';
import { LookUpSelection } from './LookUpSelection';
import { isWordSelected } from './Page.helpers';
import { AuthImage } from './AuthImage';
import { DataObjects } from './DataObjects';
import QuickPinchZoom, { make3dTransformValue } from 'components/QuickPinchZoom';
import { useUserScope } from 'hooks';
import {
  SelectionMask,
  SelectionRegion as SelectionMaskRegion,
} from 'components/TableMask';
import { useSelector } from 'react-redux';
import { selectCommonTypes } from 'store/reducers/common';
import { Notification } from 'components/Notification';
import { useTranslation } from 'react-i18next';
import { ENV_VARIABLE } from 'variables';

export const Page: FC<PageProps> = ({ documentId, resultId, page, isScrolling }) => {
  const {
    addSelectedField,
    setFieldArrow,
    setZoom,
    setPageArrow,
    fieldArrow,
    pageArrow,
    words,
    editMode,
    pageDataObjects,
    createFields,
    paneDragging,
    zoom,
    tagGroups,
  } = usePageStore(page.id);
  const zoomRef = useRef<QuickPinchZoom>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [tableMode, setTableMode] = useState(false);
  const zoomSetRef = useRef(false);
  const [regions, setRegions] = useState<Array<SelectionMaskRegion>>([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const containsFieldArrowId =
    fieldArrow?.id && boundingBoxByDataObjectId(page, fieldArrow.id);
  const pageEl = document.getElementById(`page_${page.id}`);
  const width = pageEl?.clientWidth || 0;
  const height = Math.round((width * page.image.height) / page.image.width);
  const { isAllowed } = useUserScope('RESULT_UPDATE');
  const commonTypes = useSelector(selectCommonTypes);
  const pageRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const newScale = ((zoomRef.current?.currentScale() || 1) * 100).toFixed(0);
    const currentScale = (zoom?.scale * 100).toFixed(0);

    if (newScale !== currentScale && width > 0) {
      zoomSetRef.current = true;
      zoomRef.current?.alignCenter({
        x: width / 2,
        y: height / 2,
        scale: zoom?.scale,
        animated: false,
        disableOnUpdate: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, width]);

  useEffect(() => {
    if (editMode) {
      const tables = pageDataObjects.filter(
        (item) => item.type === 'table',
      ) as Array<TableAnnotation>;

      setRegions([
        //@ts-ignore
        ...tables?.map((item, index) => ({
          id: item.id,
          pageId: page.id,
          data: {
            index: index + 1,
          },
          index,
          height: item.boundingBox?.height * 100,
          isChanging: false,
          new: false,
          width: item.boundingBox?.width * 100,
          x: item.boundingBox?.x * 100,
          y: item.boundingBox?.y * 100,
          region: 'table',
          field: 'abc',
        })),
      ]);
    } else {
      setRegions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const onRegionConfirm = () => {
    const selectedWords: Array<Lookup> = [];

    for (let i = 0; i < words.length; i += 1) {
      if (isWordSelected(regions[0], words[i].boundingBox, width, height)) {
        selectedWords.push(words[i]);
      }
    }

    setTimeout(() => {
      setRegions([]);
      setTableMode(false);
      addSelectedField({ pageId: page.id, lookups: selectedWords });
    }, 200);
  };

  const onRegionClose = () => {
    setTableMode(false);
    setRegions([]);
  };

  const onUpdate = useCallback(
    (scale) => {
      if (!isScrolling || zoomSetRef.current) {
        zoomSetRef.current = false;
        imgRef.current?.style.setProperty(
          'transform',
          make3dTransformValue({ x: scale.x, y: scale.y, scale: scale.scale }),
        );

        const newScale = (scale?.scale * 100).toFixed(0);
        const currentScale = (zoom?.scale * 100).toFixed(0);

        if (!scale.disableOnUpdate && newScale !== currentScale && scale.scale > 0.99) {
          if (!fieldArrow?.item) {
            setZoom({ scale: scale.scale < 1 ? 1 : scale.scale });
          }

          if (fieldArrow?.item && scale.scale > 1) {
            setFieldArrow(undefined);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldArrow, zoom, isScrolling],
  );

  const onImage = useCallback(() => {
    if (tagGroups?.length) {
      if (pageArrow?.pageId !== page.id && documentId !== pageArrow?.documentId) {
        setPageArrow({ pageId: page.id, documentId });
      } else {
        setPageArrow(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, documentId, pageArrow]);

  const onImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  const onCopy = () => {
    const selectedWords: Array<Lookup> = [];

    for (let i = 0; i < words.length; i += 1) {
      if (isWordSelected(regions[0], words[i].boundingBox, width, height)) {
        selectedWords.push(words[i]);
      }
    }

    navigator.clipboard.writeText(selectedWords.map((item) => item.value).join(' '));

    const successNotification = () => <Notification content={t('clipboardInfo') || ''} />;

    store.addNotification({
      insert: 'bottom',
      content: successNotification,
      container: 'bottom-left',
      animationIn: ['animate__animated animate__zoomIn'],
      animationOut: ['animate__animated animate__zoomOut'],
      dismiss: {
        duration: 1300,
      },
    });

    setTimeout(() => {
      setRegions([]);
      setTableMode(false);
    }, 200);
  };

  return (
    <PageContainer className={`line_${page.id}`}>
      <Container
        ref={pageRef}
        height={height}
        $selected={pageArrow?.pageId === page.id}
        id={`page_${page.id}`}
      >
        <QuickPinchZoom
          ref={zoomRef}
          maxZoom={3}
          enabled={zoom?.scale > 1}
          onUpdate={onUpdate}
        >
          <div ref={imgRef}>
            <SelectionMask
              constraint
              fieldTypeOptions={{
                selection: commonTypes.annotationsToAdd,
                table: commonTypes.annotationsToAdd,
              }}
              maxRegions={1}
              regions={regions}
              onClose={onRegionClose}
              onCopy={onCopy}
              onChange={(event: Array<SelectionMaskRegion>) => {
                setTableMode(true);
                if (
                  event.length > 0 &&
                  event[0].width === 0 &&
                  event[0].height === 0 &&
                  !event[0].isChanging
                ) {
                  onImage();
                }

                setRegions(event);
              }}
              style={{
                pointerEvents:
                  isAllowed && ENV_VARIABLE.DISABLE_BB_SELECTION !== 'true'
                    ? 'auto'
                    : 'none',
              }}
              onRegionConfirm={onRegionConfirm}
            >
              <AuthImage
                onImageLoad={onImageLoad}
                preventLoading={isScrolling}
                url={page.image?.imageUrl}
              />
            </SelectionMask>
            {((!paneDragging && !isScrolling) || createFields?.lookups.length > 0) &&
              createFields &&
              imageLoaded &&
              isAllowed && (
                <LookUpSelection
                  documentId={documentId}
                  pageId={page.id}
                  resultId={resultId}
                  pageWidth={width}
                  pageHeight={height}
                  pageRef={pageRef}
                />
              )}
            {((!paneDragging && !isScrolling) ||
              (!paneDragging && containsFieldArrowId)) &&
              imageLoaded &&
              isAllowed &&
              !tableMode &&
              page?.words.map((item) => (
                <Word
                  key={`word_${item.id}`}
                  item={item}
                  pageId={page.id}
                  resultId={resultId}
                  top={item.boundingBox.y * height}
                  left={item.boundingBox.x * width}
                  width={item.boundingBox.width * width}
                  height={item.boundingBox.height * height}
                />
              ))}
            {imageLoaded && !paneDragging && !tableMode && !createFields && (
              <DataObjects
                documentId={documentId}
                resultId={resultId}
                pageId={page.id}
                pageWidth={width}
                pageHeight={height}
              />
            )}
          </div>
        </QuickPinchZoom>
        {pageArrow?.pageId === page.id && <Dot $top={height / 2} />}
      </Container>
    </PageContainer>
  );
};
