import { FC } from 'react';
import { UserStatus as Status } from 'api';
import { useTranslation, TFunction } from 'react-i18next';
import { UserStatusProps } from './UserStatus.interface';
import { StyledTypography } from './UserStatus.styles';

const translateStatus = (translation: TFunction, status?: Status) => {
  switch (status) {
    case 'INVITED':
      return translation('invited');
    case 'ACTIVE':
      return translation('active');
    default:
    case 'DISABLED':
      return translation('disabled');
  }
};

export const UserStatus: FC<UserStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <StyledTypography size="small" bold $status={status}>
      {translateStatus(t, status).toUpperCase()}
    </StyledTypography>
  );
};
