import { keys, values } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { DocumentSection } from './DocumentSection';
import { useSplittingModal } from './SplittingModal.hooks';
import { SplittingModalProps } from './SplittingModal.interface';
import {
  Header,
  IconCloseContainer,
  StyledIconClose,
  StyledTitle,
  Container,
} from './SplittingModal.styles';
import { ZoomModal } from './ZoomModal';
import { LoadingModal } from './LoadingModal';
import { DndContext, DragOverlay, closestCorners } from '@dnd-kit/core';
import { PageItem } from './PageItem';

export const SplittingModal: FC<SplittingModalProps> = ({
  open,
  collectionId,
  onClose,
}) => {
  const {
    activeResultId,
    sensors,
    items,
    zoomModal,
    activePageId,
    loading,
    onDelete,
    onZoomModal,
    onDocumentType,
    onDocumentName,
    onMoveUp,
    onSplit,
    onMoveDown,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleCancel,
  } = useSplittingModal(collectionId);
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'fullscreen-modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <Header>
          <StyledTitle>{t('documentSplitting')}</StyledTitle>
          <IconCloseContainer onClick={onClose}>
            <StyledIconClose />
          </IconCloseContainer>
        </Header>

        <div
          style={{
            width: '100%',
            overflow: 'auto',
            paddingTop: 36,
          }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleCancel}
          >
            {values(items).map((item, index) => (
              <DocumentSection
                documentIndex={index}
                allDocuments={values(items).length}
                key={item.documentId}
                documentId={item.documentId}
                resultId={item.resultId}
                activeResultId={activeResultId}
                documentName={item.documentName}
                documentType={item.documentType}
                onZoom={onZoomModal}
                onDocumentName={(value) => onDocumentName(keys(items)[index], value)}
                onDocumentType={(value) => onDocumentType(keys(items)[index], value)}
                isLastItem={index === keys(items).length - 1}
                isFirstItem={index === 0}
                id={item.documentId}
                activePageId={activePageId}
                pages={item.items}
                showDeleteButton={values(items).length > 1}
                onDelete={() => onDelete(keys(items)[index])}
                onMoveDown={() => onMoveDown(keys(items)[index])}
                onMoveUp={() => onMoveUp(keys(items)[index])}
                onSplit={(id) => onSplit(id, item.documentId, item.resultId)}
              />
            ))}
            <DragOverlay>
              {activePageId ? (
                <div style={{ opacity: 0.8 }}>
                  <PageItem id={activePageId} />
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>

        <ZoomModal onClose={onZoomModal} open={!!zoomModal} url={zoomModal} />
        <LoadingModal open={loading} />
      </Container>
    </Modal>
  );
};
