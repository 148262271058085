import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AnnotationDeleteModalProps,
  EditUserModalProps,
  InviteUserModalProps,
  ExportModalProps,
} from './modals.interface';

interface ModalSliceProps {
  exportModal: ExportModalProps;
  annotationDeleteModal: AnnotationDeleteModalProps;
  inviteUserModal: InviteUserModalProps;
  editUserModal: EditUserModalProps;
}

const INITIAL_STATE = {
  exportModal: { open: false },
  annotationDeleteModal: { open: false },
  inviteUserModal: { open: false },
  editUserModal: { open: false },
} as ModalSliceProps;

export const modalsSlice = createSlice({
  name: 'MODALS',
  initialState: INITIAL_STATE,
  reducers: {
    setExport: (state, action: PayloadAction<ExportModalProps>) => {
      state.exportModal = { ...action.payload };
    },

    setAnnotationDelete: (state, action: PayloadAction<AnnotationDeleteModalProps>) => {
      state.annotationDeleteModal = { ...action.payload };
    },

    setInviteUser: (state, action: PayloadAction<InviteUserModalProps>) => {
      state.inviteUserModal = { ...action.payload };
    },

    setEditUser: (state, action: PayloadAction<EditUserModalProps>) => {
      state.editUserModal = { ...action.payload };
    },
  },
});

export const {
  setExport,
  setAnnotationDelete,
  setInviteUser,
  setEditUser,
} = modalsSlice.actions;

export default modalsSlice.reducer;
