import { uniqueId } from 'lodash';
import objectAssign from 'object-assign';
import { FC, useEffect, useState } from 'react';
import { LineDivider, RegionProps } from './Region.interface';
import {
  Container,
  ResizeBottomRight,
  ResizeBottomLeft,
  ResizeTopRight,
  ResizeTopLeft,
  RowLine,
  ColumnLine,
  CloseContainer,
  IconClose,
  TypeSelection,
  Dot,
  AddButton,
} from './Region.styles';
import { useSelector } from 'react-redux';
import { selectDataObjectById } from 'store/reducers/collection';
import { StoreProps } from 'store';
import { TableAnnotation } from 'api';
import { selectFieldArrow } from 'store/reducers/supervision';
import { requiresAttention } from 'utils/helpers';
import { pageDataObjectId } from 'components/FieldArrow';
import { IconCopy, IconPlus } from 'components/Icons';
import { SecondaryAltButton } from 'components/SecondaryAltButton';

// const REGION_OPTIONS = (t: TFunction) => [
//   { value: 'table', label: t('table') },
//   { value: 'selection', label: t('selection') },
// ];

export const Region: FC<RegionProps> = ({
  onRegionConfirm,
  onClose,
  onCropStart,
  onCopy,
  dataRenderer,
  data,
  changing,
  index,
  width,
  height,
  x,
  y,
  region,
  field,
  id,
  pageId,
}) => {
  const [columns, setColumns] = useState<Array<LineDivider>>([]);
  const [rows, setRows] = useState<Array<LineDivider>>([]);
  // const [regionType, setRegionType] = useState<RegionType | undefined>(region);
  // const [fieldType, setFieldType] = useState<string | undefined>(field);
  const [selectionConfirmed, setSelectionConfirmed] = useState(!!region || !!field);
  const item = useSelector((state: StoreProps) =>
    selectDataObjectById(state, pageId, id),
  ) as TableAnnotation;
  const fieldArrow = useSelector(selectFieldArrow);
  const root = document.getElementById(pageDataObjectId(item?.id));
  const { offsetHeight = 0 } = root || {};

  useEffect(() => {
    if (item) {
      setColumns(
        item.columnGrid
          .slice(1, -1)
          .map((item) => ({ position: item * 100, id: uniqueId() })),
      );
      setRows(
        item.rowGrid
          .slice(1, -1)
          .map((item) => ({ position: item * 100, id: uniqueId() })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localStyle = {
    width: width + '%',
    height: height + '%',
    left: `${x}%`,
    top: `${y}%`,
  };

  // const onColumnAdd = (position: number) => {
  //   setColumns((prev) => [...prev, { position, id: uniqueId() }]);
  // };

  // const onColumnDelete = (id: string) => {
  //   setColumns((prev) => prev.filter((item) => item.id !== id));
  // };

  // const onRowAdd = (position: number) => {
  //   setRows((prev) => [...prev, { position, id: uniqueId() }]);
  // };

  // const onRowDelete = (id: string) => {
  //   setRows((prev) => prev.filter((item) => item.id !== id));
  // };

  const onConfirm = () => {
    setSelectionConfirmed(true);

    onRegionConfirm();
  };

  const renderHandles = () => {
    return (
      <>
        {!selectionConfirmed && !changing && (
          <TypeSelection showAtBottom={y < 4}>
            <SecondaryAltButton
              size="small"
              onMouseDown={onCopy}
              icon={<IconCopy style={{ width: 15 }} />}
            />
            <AddButton
              size="small"
              onMouseDown={onConfirm}
              icon={<IconPlus style={{ width: 15 }} />}
            />
            {/* <SelectContainer>
              <Select
                placeholder={t('labelName')}
                options={regionType ? fieldTypeOptions[regionType] : []}
                onChange={setFieldType}
              />
            </SelectContainer>
            <SelectContainer>
              <Select
                placeholder={t('extractionType')}
                options={REGION_OPTIONS(t)}
                value={regionType}
                onChange={(value) => setRegionType(value as RegionType)}
              />
            </SelectContainer>
            <Button
              disabled={!regionType || !fieldType}
              size="large"
              title={t('done')}
              onClick={onConfirm}
            /> */}
          </TypeSelection>
        )}
        {/* {regionType === 'table' && selectionConfirmed && (
          <>
            <ColumnHandle
              onAdd={onColumnAdd}
              onDelete={onColumnDelete}
              height={height}
              columns={columns}
            />
            <RowHandle
              width={width}
              rows={rows}
              onAdd={onRowAdd}
              onDelete={onRowDelete}
            />
          </>
        )} */}
        <ResizeBottomRight data-dir="se" />
        <ResizeBottomLeft data-dir="sw" />
        <ResizeTopRight data-dir="ne" />
        <ResizeTopLeft />
        <CloseContainer onClick={onClose}>
          <IconClose />
        </CloseContainer>
      </>
    );
  };

  return (
    <Container
      id={pageDataObjectId(item?.id)}
      className={`field_${item?.id}`}
      style={objectAssign({}, localStyle, data?.regionStyle)}
      onMouseDown={onCropStart}
      onTouchStart={onCropStart}
      data-wrapper="wrapper"
    >
      {columns.map((item) => (
        <ColumnLine key={item.id} position={item.position} />
      ))}
      {rows.map((item) => (
        <RowLine key={item.id} position={item.position} />
      ))}
      {renderHandles()}
      {dataRenderer ? dataRenderer({ data, changing, index, x, y, width, height }) : null}
      {item && `field_${item?.id}` === `field_${fieldArrow?.id}` && (
        <Dot
          $requiresAttention={requiresAttention(item?.status.status)}
          $top={offsetHeight / 2}
        />
      )}
    </Container>
  );
};
