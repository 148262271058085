import { Typography } from 'antd';
import { AnnotationProcessStatus } from 'api';
import { IconCheck, IconDelete, IconEdit } from 'components/Icons';
import styled from 'styled-components';
import { TyroTheme } from 'styles';

interface ContainerProps {
  $selected?: boolean;
  $requiresAttention: boolean;
}

interface ButtonProps {
  $disabled: boolean;
}

interface TagProps {
  $status?: AnnotationProcessStatus;
}

const statusBackgroundColor = (theme: TyroTheme, status?: AnnotationProcessStatus) => {
  switch (status) {
    case 'Corrected':
      return theme.colors.warningYellow;
    case 'Needs Attention':
      return theme.colors.darkPink;
    case 'Accepted':
      return theme.colors.accepted;
    case 'Manual':
      return theme.colors.gray;
    default:
      return theme.colors.lilac;
  }
};

const statusTextColor = (theme: TyroTheme, status?: AnnotationProcessStatus) => {
  switch (status) {
    case 'Corrected':
    case 'Needs Attention':
    case 'Accepted':
    case 'Manual':
      return theme.colors.white;
    default:
      return theme.colors.darkPurple;
  }
};

export const Container = styled.div<ContainerProps>`
  width: calc(100% - 32px);
  height: 80px;
  background-color: ${({ theme }) => theme.colors.content};
  background-color: ${({ theme, $requiresAttention, $selected }) => {
    if ($selected) {
      return $requiresAttention ? theme.colors.darkBlushPink : theme.colors.gray400;
    }

    return $requiresAttention ? theme.colors.blushPink : theme.colors.content;
  }};
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 8px;
  position: relative;
  cursor: pointer;
`;

export const Label = styled.div`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  user-select: none;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledAcceptContainer = styled.div<ButtonProps>`
  height: 40px;
  min-width: 40px;
  margin-left: 8px;
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.gray500 : theme.colors.darkPurple};
  box-shadow: ${({ $disabled }) => ($disabled ? 0 : '0 2px 6px 0 rgba(70,45,140,0.1)')};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  :focus {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.gray500 : theme.colors.blackPurple};
  }
  :hover {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.gray500 : theme.colors.blackPurple};
  }
`;

export const StyledCancelContainer = styled.div`
  height: 40px;
  min-width: 40px;
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  :focus {
    background-color: ${({ theme }) => theme.colors.line};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.line};
  }
`;

export const StyledAcceptIcon = styled(IconCheck)<ButtonProps>`
  width: 20px;
  height: 20px;
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.gray300 : theme.colors.white};
`;

export const StyledCancelIcon = styled(IconDelete)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const EditContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SelectContainer = styled.div`
  min-width: calc(100% - 88px);
`;

export const MenuContainer = styled.div`
  position: absolute;
  bottom: 17px;
  right: 8px;
`;

export const StyledIconCheck = styled(IconCheck)`
  color: ${({ theme }) => theme.colors.darkPurple};
`;

export const StyledIconEdit = styled(IconEdit)`
  color: ${({ theme }) => theme.colors.darkPurple};
`;

export const TagsContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  margin-top: 4px;
  align-items: center;
`;

export const StyledTag = styled(Typography)<TagProps>`
  display: inline-block;
  user-select: none;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${({ theme, $status }) => statusBackgroundColor(theme, $status)};
  color: ${({ theme, $status }) => statusTextColor(theme, $status)};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
`;

export const StyledEmpty = styled(Typography)`
  display: inline-block;
  user-select: none;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
`;

export const Dot = styled.div`
  position: absolute;
  top: calc(50% - 6px);
  left: 0;
  z-index: 1001;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.darkPurple};
  border: ${({ theme }) => `3px solid ${theme.colors.white}`};
  box-shadow: 0 2px 4px 0 rgba(182, 20, 103, 0.3);
`;
