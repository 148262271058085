import { FC } from 'react';
import { ColumnHeaderProps } from './ColumnHeader.interface';
import { LabelContainer, StyledName, SortIcon } from './ColumnHeader.styles';
import iconSortEnabled from 'assets/icon_sort_enabled.png';
import iconSort from 'assets/icon_sort.png';

export const ColumnHeader: FC<ColumnHeaderProps> = ({
  onClick,
  name,
  sortType,
  sortEnabled,
  showSorting = true,
}) => {
  return (
    <LabelContainer onClick={onClick}>
      <StyledName size="small" bold>
        {name}
      </StyledName>
      {showSorting &&
        (sortEnabled ? (
          <SortIcon alt="sortEnabled" sortType={sortType} src={iconSortEnabled} />
        ) : (
          <SortIcon alt="sort" src={iconSort} />
        ))}
    </LabelContainer>
  );
};
