import { Collapse } from 'react-collapse';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtractionFieldItem } from '../ExtractionFieldItem';
import { ExtractionItemProps } from './ExtractionItem.interface';
import {
  Container,
  Header,
  HorizontalContainer,
  IconContainer,
  StyledArrowDown,
  Name,
  StyledButtonContainer,
} from './ExtractionItem.styles';
import { values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExtractionById,
  selectExtractionFields,
  updateAddExtractionField,
  updateDeleteExtractionField,
} from 'store/reducers/configuration';
import { StoreProps } from 'store';
import { Button } from 'components/Button';
import { api } from 'api';
import { ConfirmationModal } from 'modals/ConfirmationModal';

export const ExtractionItem: FC<ExtractionItemProps> = ({ extractionId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addExtractionField = useCallback((e) => dispatch(updateAddExtractionField(e)), [
    dispatch,
  ]);

  const deleteExtractionField = useCallback(
    (e) => dispatch(updateDeleteExtractionField(e)),
    [dispatch],
  );
  const extraction = useSelector((state: StoreProps) =>
    selectExtractionById(state, extractionId),
  );
  const extractionFields = useSelector((state: StoreProps) =>
    selectExtractionFields(state, extractionId),
  );

  const [open, setOpen] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState<{
    open: boolean;
    fieldId?: string;
    extractionId?: string;
  }>({
    open: false,
  });

  const onCollapse = () => {
    setOpen(!open);
  };

  const onAddField = () => {
    api
      .createExtractorField(extractionId, {
        fieldNameText: '',
        mandatory: false,
        configType: 'field_extraction_configuration',
        configs: [],
      })
      .then((data) => {
        addExtractionField({
          extractionId,
          field: data,
        });
      });
  };

  const onDeleteField = () => {
    const extractionId = openDeleteModal.extractionId;
    const fieldId = openDeleteModal.fieldId;

    if (fieldId && extractionId) {
      api.deleteExtractionField(extractionId, fieldId).then(() => {
        deleteExtractionField({ extractionId, field: { id: fieldId } });

        setOpenDeleteModal({ open: false });
      });
    }
  };

  return (
    <>
      <Container>
        <Header>
          <HorizontalContainer>
            <IconContainer onClick={onCollapse}>
              <StyledArrowDown className={open ? 'rotate' : ''} />
            </IconContainer>
            <Name>{extraction.visibleName}</Name>
          </HorizontalContainer>
        </Header>
        <Collapse isOpened={open}>
          {values(extractionFields).map((item) => (
            <ExtractionFieldItem
              key={item.id}
              fieldId={item.id}
              extractionId={extractionId}
              onDeleteField={(fieldId) =>
                setOpenDeleteModal({ extractionId, fieldId, open: true })
              }
            />
          ))}
          <StyledButtonContainer>
            <Button color="light" onClick={onAddField} title={t('addExtractionField')} />
          </StyledButtonContainer>
        </Collapse>
      </Container>
      <ConfirmationModal
        onClose={() => setOpenDeleteModal({ open: false })}
        onConfirm={onDeleteField}
        open={openDeleteModal.open}
        title={`${t('deleteExtractorFieldQuestion')}`}
        content={t('deleteExtractorFieldInfo')}
      />
    </>
  );
};
