import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ExtractedFieldsListProps,
  instanceOfPageItem,
  instanceOfTagGroupsItem,
  TagGroupsItem,
} from './ExtractedFieldsList.interface';
import { AcceptNext } from '../AcceptNext';
import { ExtractedFieldListItem } from '../ExtractedFieldListItem';
import { useExtractedFieldsList } from './ExtractedFieldsList.hooks';
import {
  COMPLEX_FIELD_EXPANDED_STATIC_HEIGHT,
  COMPLEX_FIELD_HEADER_HEIGHT,
  COMPLEX_FIELD_ITEM_HEIGHT,
  DEFAULT_ANNOTATION_ITEM_HEIGHT,
  EXTRACTED_FIELD_ITEM_GAP,
  GROUP_HEADER_HEIGHT,
  PAGE_HEADER_HEIGHT,
  TAG_GROUP_HEADER_HEIGHT,
  TAG_GROUP_ITEM_HEIGHT,
} from 'pages/Supervision/ExtractedFieldListItem/ExtractedFieldListItem.constants';

export const ExtractedFieldsList = ({
  data,
  onGroupCollapse,
  collapsedGroups,
}: ExtractedFieldsListProps) => {
  const {
    status,
    resizeListRef,
    flattenedData,
    itemData,
    showAcceptNext,
    collapsedComplexFields,
  } = useExtractedFieldsList({
    data,
    onGroupCollapse,
    collapsedGroups,
  });

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          <VariableSizeList
            ref={resizeListRef}
            style={{
              paddingBottom: status === 'Needs attention' || status === 'Edited' ? 80 : 0,
            }}
            height={height}
            itemCount={flattenedData.length}
            itemSize={(index) => {
              const item = flattenedData[index];
              if (item.type === 'complex') {
                const isCollapsed = collapsedComplexFields.includes(item.id);

                return isCollapsed
                  ? COMPLEX_FIELD_EXPANDED_STATIC_HEIGHT +
                      (item.annotations?.length ?? 0) * COMPLEX_FIELD_ITEM_HEIGHT
                  : COMPLEX_FIELD_HEADER_HEIGHT + EXTRACTED_FIELD_ITEM_GAP;
              } else if (item.isGroup) {
                return GROUP_HEADER_HEIGHT;
              } else if (instanceOfPageItem(item)) {
                return PAGE_HEADER_HEIGHT;
              } else if (instanceOfTagGroupsItem(item)) {
                const tagGroup = item as TagGroupsItem;
                return (
                  TAG_GROUP_HEADER_HEIGHT + tagGroup.tags.length * TAG_GROUP_ITEM_HEIGHT
                );
              }

              return DEFAULT_ANNOTATION_ITEM_HEIGHT + EXTRACTED_FIELD_ITEM_GAP;
            }}
            width={width}
            itemKey={(index) => flattenedData[index].id}
            itemData={itemData}
          >
            {ExtractedFieldListItem}
          </VariableSizeList>
          {showAcceptNext && <AcceptNext />}
        </>
      )}
    </AutoSizer>
  );
};
