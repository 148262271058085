import { Typography } from 'antd';
import { Label } from 'components/Label';
import styled from 'styled-components';

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 20px;
  font-weight: 600;
  padding-bottom: ${({ theme }) => theme.padding.medium};
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 24px;
`;

export const SelectContainer = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 24px;
`;

export const SwitchLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-size: 14px;
  padding-bottom: 8px;
`;
