import styled from 'styled-components';

interface PositionProps {
  $height: number;
  $width: number;
  $top?: number;
  $left?: number;
  $selected?: boolean;
}

export const StyledWord = styled.div.attrs<PositionProps>(
  ({ $top, $left, $width, $height }) => ({
    style: {
      top: $top,
      left: $left,
      width: $width,
      height: $height,
    },
  }),
)<PositionProps>`
  position: absolute;
  cursor: pointer;
  border: ${({ theme, $selected = false }) =>
    $selected ? `2px dashed ${theme.colors.darkPink}` : '0'};
  :hover {
    border: ${({ theme }) => `2px dashed ${theme.colors.darkPink}`};
  }
`;
