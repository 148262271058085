import { Select } from 'antd';
import { Label } from 'components/Label';
import styled from 'styled-components';
import { Size } from '.';

interface SelectProps {
  $inputSize?: Size;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.gray300};
  padding-bottom: 8px;
`;

const getSize = (size?: Size) => {
  switch (size) {
    case 'x_small':
      return '26px';
    case 'small':
      return '34px';
    case 'large':
    default:
      return '40px';
  }
};

export const StyledSelect = styled(Select)<SelectProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;

  ${({ $inputSize }) =>
    $inputSize &&
    `
    max-height: ${getSize($inputSize)};
  `}

  &.ant-select-multiple .ant-select-selector {
    ${({ $inputSize }) =>
      $inputSize &&
      `
    max-height: ${getSize($inputSize)};
  `}
    padding-top: 4px;
    padding-left: 4px;
  }

  .ant-select-selection-search-input {
    ${({ $inputSize }) =>
      $inputSize &&
      `
      height: ${getSize($inputSize)}!important;
    `}
  }

  &.ant-select-multiple .ant-select-selection-item {
    background-color: ${({ theme }) => theme.colors.blackPurple};
    border: 1px solid transparent;
    border-radius: 4px;
    height: 18px;
    line-height: 18px;
  }

  &.ant-select-multiple .ant-select-selection-item-content {
    color: white;
    font-family: ${({ theme }) => theme.fontFamily.semiBold};
    background-color: ${({ theme }) => theme.colors.blackPurple};
    height: 18px;
    font-size: 12px;
    line-height: 18px;
  }

  &.ant-select-multiple .ant-select-selection-item-remove {
    color: white;
    border-radius: 4px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    ${({ $inputSize }) =>
      $inputSize &&
      `
    max-height: ${getSize($inputSize)};
  `}
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: ${({ $inputSize }) => getSize($inputSize)}!important;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-search {
    line-height: ${({ $inputSize }) => getSize($inputSize)};
  }
`;
