import { Select } from 'components/Select';
import queryString from 'query-string';
import { FC, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';
import { orderOptions } from './Filters.helpers';
import { FilterPhrase, FiltersProps } from './Filters.interface';
import {
  StyledIconButton,
  StyledFilter,
  FiltersContainer,
  FilterTitle,
  Title,
  SortingContainer,
  FilterOptions,
  FilterContainer,
  FilterCount,
  ClearAllButton,
  InnerContainer,
  PhraseFieldValuesContainer,
  PhraseFieldTypeContainer,
  StyledAddButton,
  StyledIconTrash,
} from './Filters.styles';
import { isPhrasesEmpty } from '../ExtractedFields.helpers';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useLocation } from 'react-router-dom';
import { replaceAtIndex, stringifyParams } from 'utils/helpers';
import { Dictionary, isEmpty, omit } from 'lodash';
import { IconPlus } from 'components/Icons';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import { useFiltersStore } from './Filters.hooks';
import { useTyroHistory } from 'hooks';

export const statusFilters = (translation: TFunction) => [
  {
    id: '1',
    label: translation('missing'),
  },
  {
    id: '3',
    label: translation('invalid'),
  },
  {
    id: '2',
    label: translation('uncertain'),
  },
];

export const Filters: FC<FiltersProps> = () => {
  const {
    setFilterByFieldType,
    setFilterByStatus,
    setFilterByPhrases,
    setFilterByFieldDuplicates,
    setFieldArrow,
    setSortBy,
    filterByFieldDuplicates,
    filterByFieldTypes,
    filterByStatus,
    sortBy,
    filterByPhrases,
    commonTypes,
    duplicatedAnnotationTypes,
  } = useFiltersStore();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { addQueryParams, addWithQueryParams } = useTyroHistory();
  const { search } = useLocation();

  const updatePhraseQuery = (
    phrases: Array<FilterPhrase>,
    clearParams?: Dictionary<any>,
  ) => {
    const mapPhrases = phrases.reduce((acc, item) => {
      if (item.fieldType && item.phrases?.length) {
        acc[item.fieldType] = [...item.phrases];
      }

      return acc;
    }, {} as Dictionary<Array<string>>);

    const queryObj = queryString.parse(search);

    addWithQueryParams(
      `?${stringifyParams({
        ...omit({ ...queryObj, ...clearParams }, [
          ...commonTypes.annotationsTypes.map((item) => item.value),
        ]),
      })}`,
      mapPhrases,
    );
  };

  const onOpen = () => {
    setOpen(!open);
  };

  const onClearAll = () => {
    updatePhraseQuery([], { order: 'position', status: undefined, fields: [] });

    setSortBy('position');
    setFilterByStatus([]);
    setFilterByFieldType([]);
    setFilterByFieldDuplicates([]);
    setFilterByPhrases([{ phrases: [] }]);
    setOpen(false);
  };

  const onAddPhrase = () => {
    if (filterByPhrases.length < 5) {
      setFilterByPhrases([...filterByPhrases, { phrases: [] }]);
    }
  };

  const onDeletePhrase = (index: number) => {
    if (filterByPhrases.length > 1) {
      updatePhraseQuery(filterByPhrases.filter((_, i) => i !== index));
      setFilterByPhrases(filterByPhrases.filter((_, i) => i !== index));
    } else {
      updatePhraseQuery([]);
      setFilterByPhrases([{ phrases: [] }]);
    }
  };

  const countFilters = () => {
    let filters = 0;

    filters += filterByStatus?.length;
    filters += filterByFieldTypes?.length || 0;
    filters += filterByFieldDuplicates?.length || 0;
    filters += !isPhrasesEmpty(filterByPhrases) ? 0 : filterByPhrases?.length;

    return filters;
  };

  return (
    <Popover
      containerStyle={{ zIndex: '1000' }}
      padding={5}
      content={() => (
        <FiltersContainer>
          <FilterTitle>{t('sortingFiltering')}</FilterTitle>
          <SortingContainer>
            <Title>{t('sortFieldsBy')}</Title>
            <Select
              size="small"
              value={sortBy}
              options={orderOptions(t)}
              onChange={(value) => {
                addQueryParams({
                  order: value,
                });
                setSortBy(value || '');
              }}
            />
          </SortingContainer>
          <FilterContainer>
            <Title>{t('filterFieldsByType')}</Title>
            <Select
              size="small"
              mode="multiple"
              placeholder={t('filterFieldsByTypePlaceholder')}
              options={commonTypes.annotationsToAdd}
              allowClear
              value={filterByFieldTypes}
              onChange={(value) => {
                const fields = !value || isEmpty(value) ? [] : value.split(',');

                setFilterByFieldType(fields);

                addQueryParams({
                  fields: value,
                });
              }}
            />
          </FilterContainer>
          <FilterContainer>
            <Title>{t('removeDuplicatedFields')}</Title>
            <Select
              size="small"
              mode="multiple"
              placeholder={t('filterFieldsByTypePlaceholder')}
              options={duplicatedAnnotationTypes}
              allowClear
              value={filterByFieldDuplicates}
              onChange={(value) => {
                const fields = !value || isEmpty(value) ? [] : value.split(',');

                setFilterByFieldDuplicates(fields);

                addQueryParams({
                  duplicates: value,
                });
              }}
            />
          </FilterContainer>
          <FilterContainer>
            <Title>{t('filterByPhrase')}</Title>
            {filterByPhrases.map((item, index) => (
              <InnerContainer key={`phrase_${index}`}>
                <PhraseFieldTypeContainer>
                  <Select
                    placeholder={t('filterFieldsByTypePlaceholder')}
                    options={commonTypes.annotationsToAdd}
                    value={item.fieldType}
                    onChange={(value) => {
                      const phraseUpdate = { ...filterByPhrases[index] };
                      phraseUpdate.fieldType = value;

                      const newPhrases = replaceAtIndex(
                        [...filterByPhrases],
                        index,
                        phraseUpdate,
                      );

                      setFilterByPhrases(newPhrases);
                      updatePhraseQuery(newPhrases);
                    }}
                  />
                </PhraseFieldTypeContainer>
                <PhraseFieldValuesContainer>
                  <Select
                    size="small"
                    mode="tags"
                    placeholder={t('filterFieldsByTypePlaceholder')}
                    allowClear
                    value={item.phrases}
                    options={[]}
                    onChangeMultiple={(value) => {
                      const phraseUpdate = { ...filterByPhrases[index] };
                      phraseUpdate.phrases = value;

                      const newPhrases = replaceAtIndex(
                        [...filterByPhrases],
                        index,
                        phraseUpdate,
                      );

                      setFilterByPhrases(newPhrases);
                      updatePhraseQuery(newPhrases);
                    }}
                  />
                </PhraseFieldValuesContainer>
                <SecondaryAltButton
                  withBackground={false}
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeletePhrase(index);
                  }}
                  icon={<StyledIconTrash />}
                />
                {index === 0 && (
                  <StyledAddButton onClick={onAddPhrase} icon={<IconPlus />} />
                )}
              </InnerContainer>
            ))}
          </FilterContainer>
          <FilterContainer>
            <Title>{t('showFieldsWith')}</Title>
            <FilterOptions>
              {statusFilters(t).map((item) => (
                <Checkbox
                  key={item.label}
                  checked={filterByStatus.includes(item.label)}
                  onChange={() => {
                    const newStatus = filterByStatus.includes(item.label)
                      ? filterByStatus.filter((label) => item.label !== label)
                      : [...filterByStatus, item.label];
                    setFilterByStatus(newStatus);
                  }}
                >
                  {item.label}
                </Checkbox>
              ))}
            </FilterOptions>
          </FilterContainer>
          <ClearAllButton
            size="small"
            color="secondary"
            onClick={onClearAll}
            title={t('clearAll')}
          />
        </FiltersContainer>
      )}
      positions={['bottom']}
      align="end"
      onClickOutside={onOpen}
      isOpen={open}
    >
      <StyledIconButton
        enabled={open}
        onClick={() => {
          setFieldArrow(undefined);
          onOpen();
        }}
      >
        <StyledFilter />
        {countFilters() > 0 && <FilterCount>{countFilters()}</FilterCount>}
      </StyledIconButton>
    </Popover>
  );
};
