import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Select } from 'components/Select';
import { CreateFieldProps } from './CreateField.interface';
import {
  Container,
  StyledButton,
  CloseIcon,
  TitleLabel,
  HeaderContainer,
  ContentContainer,
  StyledLabel,
  TextArea,
} from './CreateField.styles';
import { api, CreateAnnotation, CreateComplexAnnotation } from 'api';
import { countBoundingBox } from 'utils/helpers';
import { useCreateFieldStore } from './CreateField.hooks';
import { useCollectionSync } from 'hooks';
import { find, sortBy } from 'lodash';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import autosize from 'autosize';

export const CreateField: FC<CreateFieldProps> = ({
  documentId,
  resultId,
  pageId,
  onClose,
  lookups,
  modalSize,
}) => {
  const {
    setFieldArrow,
    setZoom,
    addAnnotation,
    setAnnotationEditMode,
    createFields,
    commonTypes,
    complexAnnotationSchemas = [],
  } = useCreateFieldStore(pageId);
  const textAreaRef = useRef<TextAreaRef>(null);
  const [text, setText] = useState<string>();
  const [type, setType] = useState<string | undefined>(createFields?.fieldType);

  const { t } = useTranslation();
  const { syncCollection } = useCollectionSync();

  useEffect(() => {
    setText(lookups.map((item) => item.value).join(' '));

    const textArea = textAreaRef.current?.resizableTextArea?.textArea;

    if (textArea) {
      setTimeout(() => autosize.update(textArea));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookups]);

  useEffect(() => {
    const textArea = textAreaRef.current?.resizableTextArea?.textArea;

    if (textArea) {
      autosize(textArea);
    }
  }, [text]);

  const onCreate = () => {
    const complexAnnotationType = find(
      complexAnnotationSchemas,
      (item) => item.label === type,
    );

    if (complexAnnotationType && text) {
      const complexAnnotation: CreateComplexAnnotation = {
        pageId,
        lookups,
        type: 'complex',
        label: complexAnnotationType.label,
        boundingBox: countBoundingBox(lookups),
        annotations: complexAnnotationType.annotations.map((item) => ({
          ...item,
          value: '',
          lookups: [],
        })),
      };

      api.createAnnotation(resultId, complexAnnotation).then((data) => {
        addAnnotation({ pageId, annotation: { ...data, type: 'complex' } });
        setZoom({ scale: 1, isButtonAction: true });
        setFieldArrow({
          id: data.id,
          item: data,
          resultId,
          pageId,
          documentId,
        });

        setAnnotationEditMode({
          resultId,
          annotationId: data?.id,
          editMode: true,
        });

        syncCollection();
        onClose();
      });
    } else if (text && type) {
      const annotation: CreateAnnotation = {
        pageId,
        lookups,
        value: text,
        label: type,
        boundingBox: countBoundingBox(lookups),
        type: 'text',
      };

      api.createAnnotation(resultId, annotation).then((data) => {
        addAnnotation({ pageId, annotation: { ...data, type: 'text' } });
        setZoom({ scale: 1, isButtonAction: true });
        setFieldArrow({
          id: data.id,
          item: data,
          resultId,
          pageId,
          documentId,
        });
        syncCollection();
        onClose();
      });
    }
  };

  return (
    <>
      <Container $size={modalSize}>
        <div className="handle">
          <HeaderContainer>
            <TitleLabel size="regular" bold>
              {t('createField')}
            </TitleLabel>
            <CloseIcon onClick={onClose} />
          </HeaderContainer>
        </div>
        <ContentContainer>
          <Select
            size="small"
            label={t('fieldName')}
            onChange={setType}
            value={type}
            options={sortBy([...commonTypes?.annotationsToAdd], (item) => item.label)}
          />
          <StyledLabel size="small" bold>
            {t('value')}
          </StyledLabel>
          <TextArea
            ref={textAreaRef}
            value={text}
            onChange={(event) => setText(event.target.value)}
          />
          <StyledButton
            shadow
            disabled={!text || !type}
            title={t('create')}
            onClick={onCreate}
          />
        </ContentContainer>
      </Container>
    </>
  );
};
