import { Typography } from 'antd';
import {
  IconLanguage,
  IconDelete,
  IconExtractionSetup,
  IconSupervise,
  IconVersion,
} from 'components/Icons';
import styled from 'styled-components';

interface MenuProps {
  $selected: boolean;
  $hover?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.5);
  padding-left: 24px;
`;

export const IconCloseContainer = styled.div`
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray300};
  :hover {
    background-color: ${({ theme }) => theme.colors.lineDark};
  }
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 20px;
`;

export const StyledIconClose = styled(IconDelete)`
  height: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.white};
`;

export const HorizontalContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const MenuContainer = styled.div`
  width: 15%;
  height: 100%;
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
`;

export const MenuContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const MenuItemContainer = styled.div<MenuProps>`
  width: 100%;
  height: 5vh;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: ${({ theme }) => theme.padding.small};
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.colors.content : theme.colors.white};
  border-left: ${({ theme, $selected }) =>
    $selected ? `3px solid ${theme.colors.blackPurple}` : '3px solid white'};

  :hover {
    background-color: ${({ theme }) => theme.colors.content};
    border-left: ${({ theme }) => `3px solid ${theme.colors.blackPurple}`};
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const MenuItemLabel = styled(Typography)<MenuProps>`
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  color: ${({ theme, $selected, $hover }) =>
    $selected || $hover ? theme.colors.darkGray : theme.colors.gray300};
  font-size: 14px;
  user-select: none;
`;

export const StyledIconSupervise = styled(IconSupervise)<MenuProps>`
  height: 14px;
  width: 14px;
  margin-right: 8px;
  color: ${({ theme, $selected, $hover }) =>
    $selected || $hover ? theme.colors.blackPurple : theme.colors.gray300};
`;

export const StyledIconVersion = styled(IconVersion)<MenuProps>`
  height: 14px;
  width: 14px;
  margin-right: 8px;
  color: ${({ theme, $selected, $hover }) =>
    $selected || $hover ? theme.colors.blackPurple : theme.colors.gray300};
`;

export const StyledIconFlag = styled(IconLanguage)<MenuProps>`
  height: 14px;
  width: 14px;
  margin-right: 8px;
  color: ${({ theme, $selected, $hover }) =>
    $selected || $hover ? theme.colors.blackPurple : theme.colors.gray300};
`;

export const StyledIconExtractionSetup = styled(IconExtractionSetup)<MenuProps>`
  height: 14px;
  width: 14px;
  margin-right: 8px;
  color: ${({ theme, $selected, $hover }) =>
    $selected || $hover ? theme.colors.blackPurple : theme.colors.gray300};
`;

export const ContentContainer = styled.div`
  width: 85%;
  height: 100%;
  padding: ${({ theme }) => theme.padding.large};
  background-color: ${({ theme }) => theme.colors.content};
`;
