import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row } from './Row';
import { Columns } from './Columns';
import { UsersTableProps } from './UsersTable.interface';
import {
  Container,
  StyledPagination,
  RowsContainer,
  EmptyContainer,
  LoaderContainer,
} from './UsersTable.styles';
import { parseQueryParams } from 'utils/helpers';
import { Loader } from 'components/Loader';
import { useTranslation } from 'react-i18next';
import { useTyroHistory } from 'hooks';

export const UsersTable: FC<UsersTableProps> = ({ loading, totalSize, data }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { addQueryParams } = useTyroHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    const { offset, limit } = parseQueryParams(search);

    if (offset && limit) {
      setPage(parseInt(`${Number(offset) / Number(limit) + 1}`));
    }

    if (limit) {
      setSize(parseInt(`${limit}`));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Container>
        <Columns />
        <RowsContainer>
          {data && data.length > 0 ? (
            data.map((item) => <Row item={item} key={item.userId} />)
          ) : (
            <EmptyContainer>{t('emptyData')}</EmptyContainer>
          )}
          {loading && (
            <LoaderContainer>
              <Loader label={t('loading')} />
            </LoaderContainer>
          )}
        </RowsContainer>
        <StyledPagination
          locale={{ items_per_page: t('pagePagination') }}
          pageSizeOptions={['10', '20', '50', '100', '200']}
          current={page}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} ${t('of')} ${total} ${t('users')}`.toLowerCase()
          }
          pageSize={size}
          showSizeChanger
          showLessItems
          total={totalSize}
          onChange={(page, newSize) => {
            addQueryParams({
              offset: (page - 1) * (newSize || size),
              limit: newSize?.toString(),
            });
          }}
        />
      </Container>
    </>
  );
};
