import { Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { TableAnnotation } from 'api';
import { StyledSelect } from 'components/Select/Select.styles';
import { find, flatten } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import { selectDataObjectById } from 'store/reducers/collection';
import { selectCommonTypes } from 'store/reducers/common';
import {
  selectFieldArrow,
  selectTableEditMode,
  setCellEditMode,
} from 'store/reducers/supervision';
import { theme } from 'styles';
import { StyledHeaderCell, EmptyTypography } from './HeaderCell.styles';

export interface HeaderCellProps {
  label?: string;
  cellId: string;
}

export const HeaderCell = ({ label, cellId }: HeaderCellProps) => {
  const selectRef = useRef(null);
  const fieldArrow = useSelector(selectFieldArrow);
  const tableObject = useSelector((state: StoreProps) =>
    selectDataObjectById(state, fieldArrow?.pageId, fieldArrow?.id),
  ) as TableAnnotation;
  const commonTypes = useSelector(selectCommonTypes);
  const [editCell, setEditCell] = useState(false);
  const tableEditMode = useSelector((state: StoreProps) =>
    selectTableEditMode(state, tableObject?.id),
  );
  const dispatch = useDispatch();
  const headerTypes = commonTypes.tableAnnotationsTypes[tableObject?.label]
    ? commonTypes.tableAnnotationsTypes[tableObject?.label].headers
    : [];
  const item = find(flatten(tableObject?.headers), (item) => item.id === cellId);
  const selectedValue = tableEditMode?.[cellId]
    ? tableEditMode?.[cellId].text
    : item?.label;

  const handleEdit = () => {
    if (cellId) {
      setEditCell(true);

      //@ts-ignore
      setTimeout(() => selectRef?.current?.focus(), 100);
    }
  };

  const handleStopEdit = () => {
    setEditCell(false);
  };

  const onChange = (value: SelectValue) => {
    dispatch(
      setCellEditMode({
        tableId: tableObject?.id,
        id: cellId,
        text: value?.toString(),
        type: 'header',
      }),
    );
  };
  const renderTitle = find(headerTypes, (item) => item.value === selectedValue)?.label;

  return (
    <StyledHeaderCell
      edit={editCell}
      id={cellId}
      onClick={handleEdit}
      onBlur={handleStopEdit}
    >
      {editCell ? (
        <StyledSelect
          ref={selectRef}
          style={{ width: '100%' }}
          value={selectedValue}
          onChange={onChange}
          dropdownMatchSelectWidth={false}
          options={headerTypes}
          onBlur={handleStopEdit}
        />
      ) : renderTitle ? (
        <Tooltip
          title={<div>{renderTitle}</div>}
          placement="top"
          overlayStyle={{
            whiteSpace: 'pre-line',
            width: 'content-width',
            height: '100%',
            color: 'white',
            position: 'relative',
          }}
          color={theme.colors.blackPurple}
        >
          {renderTitle}
          {renderTitle && item?.status.status === 'Corrected' ? '*' : ''}
        </Tooltip>
      ) : (
        <EmptyTypography>&nbsp;</EmptyTypography>
      )}
    </StyledHeaderCell>
  );
};
