import { FC, useEffect, useState } from 'react';
import {
  Container,
  StyledAvatar,
  StyledUserName,
  StyledArrowDownIcon,
} from './User.styles';
import { DropdownMenu } from 'components/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { userMenu } from './User.menu';
import { SettingsModal } from 'modals/SettingsModal';
import { useHistory } from 'react-router';
import { UserAccountModal } from 'modals/UserAccountModal';
import avatar from 'assets/avatar.png';
import { useSelector } from 'react-redux';
import { selectAuthenticated, selectUser } from 'store/reducers/common';
import { keycloakInstance } from 'keycloak';

export const User: FC = () => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const authenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    history.listen(() => {
      setSettingsOpen(false);
    });
  }, [history]);

  const onUserMenu = (id: number) => {
    setMenuOpen(false);

    if (id === 1) {
      setSettingsOpen(true);
    } else if (id === 2) {
      setAccountOpen(true);
    } else if (id === 3) {
      const lang = localStorage.getItem('language');

      window.localStorage.clear();

      localStorage.setItem('language', lang || 'pl');

      keycloakInstance.logout();
    }
  };

  return (
    <>
      <DropdownMenu
        onVisibilityChange={setMenuOpen}
        trigger={['click']}
        onMenuItem={onUserMenu}
        items={userMenu(t, authenticated)}
      >
        <Container menuOpen={menuOpen} data-uid={'top-bar.user-menu'}>
          <StyledAvatar src={avatar} alt="avatar" />
          <StyledUserName size="regular">{user?.email}</StyledUserName>
          <StyledArrowDownIcon />
        </Container>
      </DropdownMenu>
      <SettingsModal onClose={() => setSettingsOpen(false)} open={settingsOpen} />
      <UserAccountModal onClose={() => setAccountOpen(false)} open={accountOpen} />
    </>
  );
};
