import { useSelector } from 'react-redux';
import { StoreProps } from 'store';
import { selectDocumentTagGroups } from 'store/reducers/collection';

export const useTagGroupsItemStore = (documentId: string) => {
  const tagGroups = useSelector((state: StoreProps) =>
    selectDocumentTagGroups(state, documentId),
  );

  return {
    tagGroups,
  };
};
