import { ALL_OPTION_ID } from 'components/DropdownSelect';
import { TFunction } from 'react-i18next';

export const menuStatus = (translation: TFunction) => [
  {
    id: ALL_OPTION_ID,
    label: translation('all'),
  },
  {
    id: '1',
    label: translation('waitingForFiles'),
  },
  {
    id: '2',
    label: translation('queued'),
  },
  {
    id: '3',
    label: translation('inProgress'),
  },
  {
    id: '4',
    label: translation('stopped'),
  },
  {
    id: '5',
    label: translation('needsAttention'),
  },
  {
    id: '6',
    label: translation('completed'),
  },
  {
    id: '7',
    label: translation('failed'),
  },
  {
    id: '8',
    label: translation('acceptedStatus'),
  },
];

export const menuPriority = (translation: TFunction) => [
  {
    id: ALL_OPTION_ID,
    label: translation('all'),
  },
  {
    id: '0',
    label: translation('notFlagged'),
  },
  {
    id: '1',
    label: translation('flagged'),
  },
];
