import { ModalSize } from 'components/CreateField/CreateField.interface';

interface Positions {
  left: number;
  top: number;
}

interface Props {
  positions: Positions;
  pageWidth: number;
  pageHeight: number;
  modalSize: ModalSize;
}

export const getBounds = ({ positions, pageWidth, pageHeight, modalSize }: Props) => {
  const top = positions.top * -1;
  const left = positions.left * -1;
  const right = pageWidth - positions.left - modalSize.width;
  const bottom = pageHeight - positions.top - modalSize.height;

  return {
    top,
    left,
    right,
    bottom,
  };
};
