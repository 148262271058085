import { FC, useState } from 'react';
import { CollapseButtonProps } from './CollapseButton.interface';
import {
  StyledIconButton,
  StyledCollapseIcon,
  StyledExpandIcon,
} from './CollapseButton.styles';

export const CollapseButton: FC<CollapseButtonProps> = ({ onClick }) => {
  const [expand, setExpand] = useState(false);

  const onExpand = () => {
    setExpand(!expand);
    onClick(!expand);
  };

  return (
    <StyledIconButton onClick={onExpand}>
      {expand ? <StyledExpandIcon /> : <StyledCollapseIcon />}
    </StyledIconButton>
  );
};
