import { Annotation } from 'api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import {
  selectDataObjectById,
  selectEditorId,
  updateDataObject,
} from 'store/reducers/collection';
import { selectCommonTypes, selectUser } from 'store/reducers/common';
import {
  selectFieldArrow,
  selectEditMode,
  updateFieldArrow,
  updateZoom,
  updateAnnotationEditMode,
  selectAnnotationEditMode,
} from 'store/reducers/supervision';

export const useFieldStore = (pageId: string, annotationId: string) => {
  const dispatch = useDispatch();
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const setZoom = useCallback((e) => dispatch(updateZoom(e)), [dispatch]);
  const setAnnotationEditMode = useCallback(
    (e) => dispatch(updateAnnotationEditMode(e)),
    [dispatch],
  );
  const updateAnnotationField = useCallback((e) => dispatch(updateDataObject(e)), [
    dispatch,
  ]);
  const annotationEditMode = useSelector((state: StoreProps) =>
    selectAnnotationEditMode(state, annotationId),
  );
  const documentEditMode = useSelector(selectEditMode);
  const fieldArrow = useSelector(selectFieldArrow);
  const annotation = useSelector((state: StoreProps) =>
    selectDataObjectById(state, pageId, annotationId),
  ) as Annotation;
  const commonTypes = useSelector((state: StoreProps) => selectCommonTypes(state));
  const editorId = useSelector(selectEditorId);
  const user = useSelector(selectUser);

  return {
    annotation,
    annotationEditMode,
    documentEditMode,
    fieldArrow,
    setFieldArrow,
    setAnnotationEditMode,
    updateAnnotationField,
    commonTypes,
    setZoom,
    editorId,
    user,
  };
};
