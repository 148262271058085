import { Switch } from 'antd';
import { IconChevronDown, IconTrash } from 'components/Icons';
import { Input } from 'components/Input';
import { Label } from 'components/Label';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import styled from 'styled-components';

interface ContainerProps {
  $error: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  border-radius: 8px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 16px;
  background-color: ${({ theme, $error }) =>
    $error ? theme.colors.blushPink : theme.colors.gray600};
  flex-direction: column;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lineDark}`};
  :hover {
    background-color: ${({ theme }) => theme.colors.content};
  }
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border-radius: 0.3em;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 16px;
  align-items: center;
  cursor: pointer;
`;

export const StyledArrowDown = styled(IconChevronDown)`
  color: ${({ theme }) => theme.colors.darkPurple};
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  aling-items: center;
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
`;

export const DeleteContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const StyledDeleteIcon = styled(IconTrash)`
  color: ${({ theme }) => theme.colors.darkPurple};
`;

export const StyledInput = styled(Input)`
  width: 250px;
`;

export const StyledIconButton = styled(SecondaryAltButton)`
  margin-left: 8px;
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-right: 32px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  padding: 16px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-left: 20px;
`;

export const SwitchLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.blackPurple};
  padding-left: 8px;
`;

export const StyledSwitch = styled(Switch)``;
