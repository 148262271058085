export const EXTRACTED_FIELD_ITEM_GAP = 8;

export const DEFAULT_ANNOTATION_ITEM_HEIGHT = 46;

export const COMPLEX_FIELD_HEADER_HEIGHT = 48;
export const COMPLEX_FIELD_ITEM_HEIGHT = 30;
export const COMPLEX_FIELD_ITEM_CONTAINER_VERTICAL_PADDING = 8;
export const COMPLEX_FIELD_EXPANDED_STATIC_HEIGHT =
  COMPLEX_FIELD_HEADER_HEIGHT +
  COMPLEX_FIELD_ITEM_CONTAINER_VERTICAL_PADDING * 2 +
  EXTRACTED_FIELD_ITEM_GAP;

export const GROUP_HEADER_HEIGHT = 56;

export const PAGE_HEADER_HEIGHT = 41;

export const TAG_GROUP_HEADER_HEIGHT = 28;

export const TAG_GROUP_ITEM_HEIGHT = 90;
