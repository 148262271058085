import styled from 'styled-components';
import { Headline } from 'components/Headline';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const HeaderContent = styled.div`
  width: 92%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 14px;
`;

export const Header = styled.div`
  height: 110px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const StyledTitle = styled(Headline)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  height: calc(100vh - 182px);
  width: 100%;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.gray600};
`;

export const TableContainer = styled.div`
  width: 92%;
`;
