import { store } from 'react-notifications-component';
import { useEffect, useState } from 'react';
import { FC } from 'react';
import { DatePicker, Tooltip } from 'antd';
import { DropdownMenu } from 'components/DropdownMenu';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FieldProps } from './Field.interface';
import { menuMore, menuMoreAttention } from './Field.menu';
import { AnnotationProcessStatus, api, PatchAnnotation } from 'api';
import { useTranslation } from 'react-i18next';
import { Notification } from 'components/Notification';
import { Select } from 'components/Select';
import {
  StyledField,
  Type,
  Text,
  StatusContainer,
  MenuContainer,
  StyledInput,
  SelectNameContainer,
  SelectInputContainer,
  StyledAcceptIcon,
  StyledAcceptContainer,
  StyledCancelIcon,
  StyledCancelContainer,
  StyledAcceptWithoutIcon,
  Dot,
  TextContainer,
  StyledCopy,
  StyledAcceptWithoutContainer,
  SelectEnumContainer,
} from './Field.styles';
import {
  hasVisibleStatus,
  mapAnnotation,
  requiresAttention,
  requiresEditAttention,
} from 'utils/helpers';
import { theme } from 'styles';
import { FieldStatus } from 'components/FieldStatus';
import { InputKey } from 'components/Input/Input.interface';
import { useFieldStore } from './Field.hooks';
import { useAnnotationDeleteModalStore } from 'store/reducers/modals/annotationDeleteModal.hooks';
import { fieldDataObjectId } from 'components/FieldArrow';
import { useUserScope, useCollectionSync } from 'hooks';
import { isBoolean } from 'lodash';
import { ENV_VARIABLE } from 'variables';
import { ExtractionEnumInput } from '../ExtractionEnumInput';
import { binaryOptions, getTooltipProps } from 'components/Field/Field.utils';
import { fieldTypesWithoutValidators } from 'pages/Supervision/ExtractedFieldListItem';
import moment from 'moment';

export const FIELD_INPUT = 'input_';

const addAsterisk = (text: string) => {
  return (
    <>
      <span style={{ color: theme.colors.darkPink }}>*</span>
      <span>{text}</span>
    </>
  );
};

export const Field: FC<FieldProps> = ({
  documentId,
  resultId,
  pageId,
  shouldFocus,
  annotationId,
  id,
}) => {
  const {
    documentEditMode,
    fieldArrow,
    setFieldArrow,
    annotation,
    updateAnnotationField,
    commonTypes,
    setZoom,
    editorId,
    user,
    annotationEditMode,
    setAnnotationEditMode,
  } = useFieldStore(pageId, annotationId);
  const { syncCollection } = useCollectionSync();
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const [editText, setEditText] = useState<string | undefined>(annotation?.value);
  const [editType, setEditType] = useState<string | undefined>(annotation?.label);
  const [hover, setHover] = useState(false);
  const { isAllowed } = useUserScope('RESULT_UPDATE');
  const editMode = annotation && annotationEditMode?.editMode;
  const { setAnnotationDeleteModal } = useAnnotationDeleteModalStore();

  useEffect(() => {
    if (
      annotation &&
      documentEditMode &&
      !isBoolean(annotationEditMode?.editMode) &&
      requiresEditAttention(annotation.status.status)
    ) {
      setAnnotationEditMode({
        resultId,
        annotationId: annotation?.id,
        editMode: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotation, editMode, documentEditMode]);

  const disabledAccept =
    (annotation?.value === editText && annotation?.label === editType) ||
    editText?.length === 0;

  const toggleEditMode = () => {
    setAnnotationEditMode({
      resultId,
      annotationId: annotation?.id,
      editMode: !editMode,
    });
  };

  const onRowAction = (actionId: number) => {
    switch (actionId) {
      case 1: {
        toggleEditMode();
        onFieldClick(false);
        break;
      }
      case 2: {
        onDelete();
        break;
      }
      case 3: {
        onAcceptWithoutClick();
        break;
      }
    }

    setShowMenu(false);
  };

  const onDelete = () => {
    setAnnotationDeleteModal({
      open: true,
      pageId,
      resultId,
      annotationId,
    });
  };

  const updateFieldArrow = (status: AnnotationProcessStatus) => {
    if (fieldDataObjectId(fieldArrow?.item?.id) === id) {
      setFieldArrow({
        id: fieldArrow?.id,
        item: { ...fieldArrow?.item, status: { status } },
        resultId,
        documentId,
      });
    }
  };

  const onAccept = () => {
    api.acceptAnnotation(resultId, annotationId).then((data) => {
      updateAnnotationField({
        ...data,
        pageId,
        resultId,
        annotationId,
      });
      updateFieldArrow('Accepted');

      syncCollection();

      if (editMode) {
        toggleEditMode();
        setEditText(annotation?.value);
        setEditType(annotation?.label);
      }
    });
  };

  const onEdit = (data: PatchAnnotation) => {
    if (editText !== annotation?.value) {
      data = { ...data, value: editText };
    }

    if (editType !== annotation?.label) {
      data = { ...data, label: editType };
    }

    if (annotation?.status.status === 'Manual') {
      data = { ...data };
    }

    api.patchAnnotation(data).then((result) => {
      updateAnnotationField({ ...data, ...result });
      updateFieldArrow('Completed');

      syncCollection();

      if (editMode) {
        toggleEditMode();
      }
    });
  };

  const copiedToClipboard = () => {
    const successNotification = () => <Notification content={t('clipboardInfo') || ''} />;

    store.addNotification({
      insert: 'bottom',
      content: successNotification,
      container: 'bottom-left',
      animationIn: ['animate__animated animate__zoomIn'],
      animationOut: ['animate__animated animate__zoomOut'],
      dismiss: {
        duration: 1300,
      },
    });
  };

  const onSave = () => {
    if (!disabledAccept) {
      if (resultId) {
        onEdit({
          pageId,
          resultId,
          annotationId,
        });
      }
    }
  };

  const onInputKey = (key: InputKey) => {
    if (key === 'shift' && requiresAttention(annotation?.status.status)) {
      onAcceptWithoutClick();
    } else if (key === 'enter') {
      onSave();
    } else if (key === 'esc') {
      toggleEditMode();
    }
  };

  const onSaveClick = () => {
    onSave();
  };

  const onCancelClick = () => {
    toggleEditMode();
    setEditText(annotation?.value);
    setEditType(annotation?.label);
  };

  const onAcceptWithoutClick = () => {
    if (resultId) {
      onAccept();
    }
  };

  const onFieldClick = (withRevert: boolean) => {
    if (!fieldArrow || fieldArrow.id !== annotationId.toString()) {
      setZoom({ scale: 1, isButtonAction: true });
      setFieldArrow({
        id: annotationId,
        item: annotation,
        pageId,
        resultId,
        documentId,
      });
    } else if (withRevert) {
      setFieldArrow(undefined);
    }
  };

  const onFieldDoubleClick = () => {
    setZoom({ scale: 1, isButtonAction: true });
    setFieldArrow({
      id: annotationId,
      item: annotation,
      pageId,
      resultId,
      documentId,
    });

    setAnnotationEditMode({
      resultId,
      annotationId: annotation?.id,
      editMode: true,
    });
  };

  const onEditText = (text?: string) => {
    setEditText(text);

    setAnnotationEditMode({ ...annotationEditMode, text, annotationId, resultId });
  };

  const onEditType = (type?: string) => {
    setEditType(type);

    setAnnotationEditMode({ ...annotationEditMode, type, annotationId, resultId });
  };

  const showEditMenu =
    !ENV_VARIABLE.KEY_CLOAK_URL ||
    (editorId && editorId === user?.sub && isAllowed) ||
    (ENV_VARIABLE.KEY_CLOAK_URL &&
      isAllowed &&
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED !== 'true');

  const annotationLabel = annotation?.labelRaw
    ? addAsterisk(mapAnnotation(commonTypes?.annotationsTypes, annotation?.label))
    : mapAnnotation(commonTypes?.annotationsTypes, annotation?.label);

  return annotation ? (
    <StyledField
      id={id}
      key={id}
      className={id}
      $selected={id === fieldDataObjectId(fieldArrow?.id)}
      $requiresAttention={requiresAttention(annotation?.status.status)}
      onMouseEnter={() => showEditMenu && setShowMenu(true)}
      onMouseLeave={() => showEditMenu && setShowMenu(false)}
      onClick={() => {
        if (!editMode) {
          onFieldClick(true);
        }
      }}
      onDoubleClick={onFieldDoubleClick}
    >
      {editMode ? (
        <>
          <SelectNameContainer>
            <Select
              size="small"
              onFocus={() => onFieldClick(false)}
              value={editType}
              options={commonTypes?.annotationsTypes}
              onChange={onEditType}
            />
          </SelectNameContainer>
          {annotation.type === 'enum' && (
            <SelectEnumContainer>
              <ExtractionEnumInput
                label={annotation?.label || ''}
                initialValue={editText}
                onChange={onEditText}
                size="small"
                metadata={annotation.metadata}
              />
            </SelectEnumContainer>
          )}
          {!fieldTypesWithoutValidators().includes('date') && annotation.type === 'date' && (
            <SelectEnumContainer>
              <DatePicker
                allowClear={false}
                onChange={(date) => onEditText(date?.format('YYYY-MM-DD'))}
                style={{ borderRadius: 6, height: 34, width: '100%' }}
                value={editText ? moment(new Date(editText)) : undefined}
              />
            </SelectEnumContainer>
          )}
          {annotation.type === 'binary' && (
            <SelectInputContainer>
              <Select
                onChange={onEditText}
                value={editText}
                options={binaryOptions(t)}
                size="small"
              />
            </SelectInputContainer>
          )}
          {fieldTypesWithoutValidators().includes(annotation.type) && (
            <SelectInputContainer>
              <StyledInput
                id={`${FIELD_INPUT}${annotation?.id}`}
                onKey={onInputKey}
                value={editText}
                onFocus={() => onFieldClick(false)}
                onChange={onEditText}
                shouldFocus={shouldFocus}
              />
            </SelectInputContainer>
          )}
          <StyledAcceptContainer $disabled={disabledAccept} onClick={onSaveClick}>
            <StyledAcceptIcon $disabled={disabledAccept} />
          </StyledAcceptContainer>
          <StyledCancelContainer onClick={onCancelClick}>
            <StyledCancelIcon />
          </StyledCancelContainer>
          {requiresAttention(annotation?.status.status) && (
            <StyledAcceptWithoutContainer onClick={onAcceptWithoutClick}>
              <StyledAcceptWithoutIcon />
            </StyledAcceptWithoutContainer>
          )}
        </>
      ) : (
        <>
          <Tooltip {...getTooltipProps(annotationLabel)}>
            <Type edited={!!annotation?.labelRaw}>{annotationLabel}</Type>
          </Tooltip>
          <Tooltip {...getTooltipProps(annotation?.value)}>
            <TextContainer
              $hover={showMenu}
              $hasVisibleStatus={hasVisibleStatus(annotation?.status.status)}
            >
              <CopyToClipboard onCopy={copiedToClipboard} text={annotation?.value || ''}>
                <Text
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onClick={(event) => event.stopPropagation()}
                  edited={!!annotation?.valueRaw}
                >
                  {annotation?.valueRaw
                    ? addAsterisk(annotation?.value || '')
                    : annotation?.value || ''}
                </Text>
              </CopyToClipboard>
              {hover && <StyledCopy />}
            </TextContainer>
          </Tooltip>
          {!showMenu && hasVisibleStatus(annotation?.status.status) && (
            <StatusContainer $hover={showMenu}>
              <FieldStatus
                status={annotation?.status.status}
                message={annotation?.status.msg}
              />
            </StatusContainer>
          )}
          {showMenu && (
            <MenuContainer
              $requiresAttention={requiresAttention(annotation?.status.status)}
            >
              <DropdownMenu
                onMenuItem={onRowAction}
                items={
                  requiresAttention(annotation?.status.status)
                    ? menuMoreAttention(t)
                    : menuMore(t)
                }
              />
            </MenuContainer>
          )}
        </>
      )}
      {id === fieldDataObjectId(fieldArrow?.id) && annotation?.boundingBox && (
        <Dot $requiresAttention={requiresAttention(annotation?.status.status)} />
      )}
    </StyledField>
  ) : null;
};
