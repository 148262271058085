import { FC } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { HeaderButton } from 'components/HeaderButton';
import { Container, Content, StyledLogo } from './Header.styles';
import { User } from './User';
import logo from 'assets/logo.png';
import { useTranslation } from 'react-i18next';
import { useTyroHistory, useUserScope } from 'hooks';

export const Header: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { openUsers } = useTyroHistory();
  let documentsMatch = useRouteMatch('/documents');
  let usersMatch = useRouteMatch('/users');
  const { isAllowed } = useUserScope('USERS_READ');

  return (
    <Container>
      <Content>
        <div>
          <StyledLogo src={logo} alt="logo" />
          <HeaderButton
            onClick={() => history.push('/documents')}
            title={t('queues')}
            active={!!documentsMatch}
          />
          {isAllowed && (
            <HeaderButton
              onClick={() => openUsers()}
              title={t('users')}
              active={!!usersMatch}
            />
          )}
        </div>
        <User />
      </Content>
    </Container>
  );
};
