import { FC } from 'react';
import { PropsWithChildren } from 'react';
import { LabelProps } from './Label.interface';
import { StyledLabel } from './Label.styles';

export const Label: FC<PropsWithChildren<LabelProps>> = ({
  children,
  size,
  bold = false,
  ...props
}) => {
  return (
    <StyledLabel bold={bold} {...props} size={size}>
      {children}
    </StyledLabel>
  );
};
