import {
  CollectionResult,
  Document,
  Extraction,
  ExtractionField,
  Extractions,
  Lookup,
  PageImage,
  DataObject,
  TagGroup,
  ThumbnailSection,
} from 'api';
import { Dictionary, keyBy } from 'lodash';
import { countCollectionAttentions } from './helpers';

const mapWords = (documents: Array<Document>) => {
  return documents.reduce((accumulator, current) => {
    for (const page of current.pages) {
      accumulator[page.id] = {};

      for (let i = 0; i < page.words.length; i += 1) {
        accumulator[page.id][page.words[i].id] = { ...page.words[i], order: i };
      }
    }

    return accumulator;
  }, {} as Dictionary<Dictionary<Lookup>>);
};

const mapDataObjects = (documents: Array<Document>) => {
  return documents.reduce((accumulator, current) => {
    for (const page of current.pages) {
      const { annotations, tableAnnotations = [] } = page;
      accumulator[page.id] = {};

      for (const annotation of annotations) {
        accumulator[page.id][annotation.id] = annotation;
      }

      for (const object of tableAnnotations) {
        accumulator[page.id][object.id] = object;
      }
    }

    return accumulator;
  }, {} as Dictionary<Dictionary<DataObject>>);
};

const mapTagGroups = (documents: Array<Document>) => {
  return documents.reduce((accumulator, current) => {
    if (current.tagGroups) {
      accumulator[current.id] = current.tagGroups;
    }

    return accumulator;
  }, {} as Dictionary<Array<TagGroup>>);
};

const mapPageImages = (documents: Array<Document>) => {
  return documents.reduce((accumulator, current) => {
    for (const page of current.pages) {
      accumulator[page.id] = page.image;
    }

    return accumulator;
  }, {} as Dictionary<PageImage>);
};

const mapThumbnailImages = (documents: Array<Document>) => {
  return documents.reduce((accumulator, current) => {
    accumulator[current.fileId] = {
      fileId: current.fileId,
      fileName: current.fileName,
      thumbnails: current.pages.map((page) => ({
        id: page.id,
        collectionPageNum: page.collectionPageNum,
        image: page.image,
      })),
    };

    return accumulator;
  }, {} as Dictionary<ThumbnailSection>);
};

export const mapCollectionResult = (data: CollectionResult) => {
  return {
    name: data.collectionName,
    collectionId: data.collectionId,
    totalPages: data.totalPages,
    totalFiles: data.totalFiles,
    editorId: data.editor?.editorId,
    documents: keyBy(data.documents, 'id'),
    annotationAttentions: countCollectionAttentions(data.documents),
    dataObjects: mapDataObjects(data.documents),
    words: mapWords(data.documents),
    images: mapPageImages(data.documents),
    tagGroups: mapTagGroups(data.documents),
    thumbnailSections: mapThumbnailImages(data.documents),
    tagGroupsOptions: data.availableValues,
    metadata: data.metadata,
    complexAnnotationSchemas: data.complexAnnotationSchemas,
    processedDocuments: data.documents.filter(
      (document) => document.status.status !== 'Failed',
    ).length,
    status: data.status,
  };
};

export const mapConfigurations = (data: Extractions) => {
  const extractions = data.extractions.reduce((acc, current) => {
    acc[current.id] = {
      ...current,
      fieldIds: current.processorConfig.map((item) => item.id),
    };

    return acc;
  }, {} as Dictionary<Extraction>);

  const extractionsFields = data.extractions.reduce((acc, current) => {
    acc[current.id] = current.processorConfig.reduce((accFields, field) => {
      accFields[field.id] = field;

      return accFields;
    }, {} as Dictionary<ExtractionField>);

    return acc;
  }, {} as Dictionary<Dictionary<ExtractionField>>);

  return { extractions, extractionsFields };
};
