import { FC, useEffect, useState } from 'react';
import { Annotation, api } from 'api';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  LocationParamsProps,
  LocationStateProps,
  AcceptNextProps,
} from './AcceptNext.interface';
import { Container, StyledNextButton } from './AcceptNext.styles';
import { useAcceptNextStore } from './AcceptNext.hooks';
import { useSupervisionStore } from '../Supervision.hooks';
import { keys, values } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCollectionId } from 'store/reducers/collection';
import { toast } from 'react-toastify';
import { QUEUES_PARAMS } from 'utils/helpers';
import { ConfirmationModal } from 'modals/ConfirmationModal';
import { ENV_VARIABLE } from 'variables';

export const AcceptNext: FC<AcceptNextProps> = () => {
  const { status, annotationsEditMode, groupTagsEditMode } = useAcceptNextStore();
  const { documents, fetchCollection } = useSupervisionStore();
  const [nextCollectionId, setNextCollectionId] = useState<string | undefined>();
  const history = useHistory();
  const { id } = useParams<LocationParamsProps>();
  const { state } = useLocation<LocationStateProps>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const collectionId = useSelector(selectCollectionId);
  const [confirmationModal, setConfirmationModal] = useState<{
    open: boolean;
    fieldId?: string;
    extractionId?: string;
  }>({
    open: false,
  });

  const checkNextCollection = () => {
    const localStorageParams = localStorage.getItem(QUEUES_PARAMS);
    const { search, priority, from, to, order } = JSON.parse(localStorageParams || '{}');

    api
      .nextCollection(collectionId, { priority, from, to, search, order })
      .then((data) => {
        if (data) {
          setNextCollectionId(data.collectionId);
        }
      });
  };

  useEffect(() => {
    checkNextCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAccept = async () => {
    const acceptFieldsRequest: Array<Promise<Annotation>> = [];
    const acceptGroupTagsRequest: Array<Promise<Annotation>> = [];
    const acceptRequest: Array<() => Promise<void>> = [];

    const acceptFieldIds = keys(annotationsEditMode).filter(
      (key) =>
        annotationsEditMode[key].editMode &&
        annotationsEditMode[key].resultId &&
        (annotationsEditMode[key].text || annotationsEditMode[key].type),
    );

    const acceptGroupTagIds = keys(groupTagsEditMode).filter(
      (key) => groupTagsEditMode[key].editMode && groupTagsEditMode[key].resultId,
    );

    acceptGroupTagIds.forEach((key) => {
      const { tags, resultId } = groupTagsEditMode[key];
      if (tags && resultId) {
        acceptGroupTagsRequest.push(api.updateGroupTags(resultId, key, tags));
      }
    });

    acceptFieldIds.forEach((key) => {
      const { text, type, resultId } = annotationsEditMode[key];
      if ((text || type) && resultId) {
        acceptFieldsRequest.push(
          api.patchAnnotation({
            value: text,
            label: type,
            resultId,
            annotationId: key,
            pageId: '', // refactor
          }),
        );
      }
    });

    values(documents).forEach((document) => {
      acceptRequest.push(() => api.acceptResult(document.resultId));
    });

    setLoading(true);

    await Promise.all(acceptGroupTagsRequest.map((p) => p.catch((e) => e)));
    await Promise.all(acceptFieldsRequest.map((p) => p.catch((e) => e)));

    const results = await Promise.all(
      acceptRequest.map((accept) => accept().catch((e) => e)),
    );

    const validResults = results.filter((result) => !(result instanceof Error));

    if (validResults.length > 0) {
      setTimeout(() => {
        setLoading(false);

        const localStorageParams = localStorage.getItem(QUEUES_PARAMS);
        const { search, priority, from, to, order } = JSON.parse(
          localStorageParams || '{}',
        );

        api
          .nextCollection(collectionId, { priority, from, to, search, order })
          .then((data) => {
            setLoading(false);

            if (data) {
              history.replace(`/supervision/${data.collectionId}`, {
                ...state,
              });
            }
          })
          .catch(() => {
            fetchCollection(id).catch(() => {
              /* no - op */
            });
          });
      }, 1000);
    } else {
      setLoading(false);
      toast.error(t('updateNeedsAttentionFields'), { position: 'bottom-left' });
    }
  };

  const onConfirmation = () => {
    if (ENV_VARIABLE.ACCEPT_CONFIRM_ENABLED === 'true') {
      setConfirmationModal({ open: true });
    } else {
      onAccept();
    }
  };

  const showAcceptButton =
    status === 'Needs attention' ||
    status === 'Edited' ||
    (ENV_VARIABLE.ACCEPT_COMPLETED_ENABLED === 'true' && status === 'Completed');

  return showAcceptButton ? (
    <Container>
      <StyledNextButton
        loading={loading}
        size="large"
        title={nextCollectionId ? t('acceptNext') : t('accept')}
        onClick={onConfirmation}
      />
      <ConfirmationModal
        onClose={() => setConfirmationModal({ open: false })}
        onConfirm={() => {
          setConfirmationModal({ open: false });
          onAccept();
        }}
        open={confirmationModal.open}
        title={`${t('confirmAcceptTitle')}`}
        content={t('confirmAcceptDescription')}
      />
    </Container>
  ) : null;
};
