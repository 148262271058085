import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import { UserAccountModalProps } from './UserAccountModal.interface';
import {
  Header,
  IconCloseContainer,
  StyledIconClose,
  StyledTitle,
  TopContainer,
  ContentContainer,
  Container,
  StyledAvatar,
  StyledUserName,
} from './UserAccountModal.styles';
import avatar from 'assets/avatar.png';

export const UserAccountModal: FC<UserAccountModalProps> = ({ open, onClose }) => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      // && history.location.pathname === "any specific path")
      if (history.action === 'POP') {
        history.replace(history.location.pathname /* the new state */);
      }
    };
  }, [history]);

  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'fullscreen-modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Header>
        <StyledTitle>{t('yourAccount')}</StyledTitle>
        <IconCloseContainer onClick={onClose}>
          <StyledIconClose />
        </IconCloseContainer>
      </Header>
      <TopContainer>
        <Container>
          <StyledAvatar src={avatar} alt="avatar" />
          <StyledUserName>{t('user')}</StyledUserName>
        </Container>
      </TopContainer>
      <ContentContainer />
    </Modal>
  );
};
