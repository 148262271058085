import { IconClose } from 'components/Icons';
import { FC } from 'react';
import Modal from 'react-modal';
import { ZoomModalProps } from './ZoomModal.interface';
import { CloseButton, Container, Image, ImageContainer } from './ZoomModal.styles';

export const ZoomModal: FC<ZoomModalProps> = ({ open, onClose, url }) => {
  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'fullscreen-modal-transparent',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <ImageContainer>
          <CloseButton onClick={() => onClose()}>
            <IconClose />
          </CloseButton>
          <Image src={url} alt="key" />
        </ImageContainer>
      </Container>
    </Modal>
  );
};
