import { BaseDispatch, CommonStore, StoreProps } from 'store/store.interface';

export interface LanguageType {
  language: string;
}

interface LanguageTypeDispatch extends BaseDispatch {
  payload: LanguageType;
}

export const SET_LANGUAGE = 'SET_LANGUAGE';

export const selectLanguage = (state: StoreProps) => state.common.language;

export const updateLanguageState = (state: CommonStore, data: LanguageType) => {
  return { ...state, language: data };
};

export const updateLanguage = (data: LanguageType) => (
  dispatch: (data: LanguageTypeDispatch) => void,
) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: data,
  });
};
