import { Typography } from 'antd';
import { FlagPoland, FlagUK } from 'components/Icons';
import styled from 'styled-components';

interface SelectedProps {
  selected: boolean;
}

export const Header = styled.div`
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.5);
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.line}`};
  padding-left: ${({ theme }) => theme.padding.large};
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 20px;
  font-weight: 600;
  padding-bottom: ${({ theme }) => theme.padding.medium};
`;

export const VerticalContainer = styled.div`
  display: flex;
  max-width: 10%;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding-bottom: ${({ theme }) => theme.padding.normal};
`;

export const Label = styled(Typography)<SelectedProps>`
  color: ${({ theme }) => theme.colors.gray};
  font-weight: ${({ selected }) => (selected ? 600 : 500)};
  user-select: none;
  padding-left: ${({ theme }) => theme.padding.tiny};
`;

export const StyledFlagPoland = styled(FlagPoland)<SelectedProps>`
  width: 35px;
  border: ${({ theme, selected }) =>
    selected
      ? `3px solid ${theme.colors.blackPurple}`
      : `1px solid ${theme.colors.gray400}`};
  border-radius: 20px;
`;

export const StyledFlagEngland = styled(FlagUK)<SelectedProps>`
  width: 35px;
  border: ${({ theme, selected }) =>
    selected
      ? `3px solid ${theme.colors.blackPurple}`
      : `1px solid ${theme.colors.gray400}`};
  border-radius: 20px;
`;
