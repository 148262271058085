import { InputSearch } from 'components/InputSearch';
import { ProcessStatus } from 'components/ProcessStatus';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentsListProps } from './DocumentsList.interface';
import {
  FieldColumns,
  FieldName,
  FieldStatus,
  StyledRow,
  RowName,
  RowStatus,
  StyledProgress,
  StyledPagination,
  SearchContainer,
} from './DocumentsList.styles';

export const DocumentsList: FC<DocumentsListProps> = ({ data }) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState(data);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    setDocuments(data);
  }, [data]);

  const onSearch = (value?: string) => {
    setSearch(value);
  };

  return (
    <div>
      <SearchContainer>
        <InputSearch value={search} onChange={onSearch} />
      </SearchContainer>
      <FieldColumns>
        <FieldName size="small" bold>
          {t('fileName')}
        </FieldName>
        <FieldStatus size="small" bold>
          {t('status')}
        </FieldStatus>
        <FieldStatus size="small" bold>
          {t('progress')}
        </FieldStatus>
      </FieldColumns>
      {documents
        .filter((item) =>
          search ? item.fileName.toLowerCase().includes(search.toLowerCase()) : true,
        )
        .slice((page - 1) * size, size * page)
        .map(({ fileName, fileId, status, progress }) => (
          <StyledRow key={fileId}>
            <RowName>{fileName}</RowName>
            <RowStatus>
              <ProcessStatus status={status} />
            </RowStatus>
            <RowStatus>
              <StyledProgress progress={progress} />
            </RowStatus>
          </StyledRow>
        ))}
      <StyledPagination
        locale={{ items_per_page: t('pagePagination') }}
        pageSizeOptions={['10', '20', '50', '100', '200']}
        current={page}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} ${t('of')} ${total} ${t('collections')}`
        }
        pageSize={size}
        showSizeChanger
        showLessItems
        total={documents.length}
        onChange={(page, size) => {
          setPage(page);
          setSize(size || 10);
        }}
      />
    </div>
  );
};
