import { Typography } from 'antd';
import styled, { css } from 'styled-components';

const getCellStyles = (edit: boolean) => {
  return edit
    ? css`
        padding: '0';
      `
    : css`
        padding: 0 17px;
      `;
};

export const StyledHeaderCell = styled.div<{ edit: boolean }>`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.gray300};
  cursor: pointer;
  width: 100%;
  margin: 4px;
  ${({ edit }) => getCellStyles(edit)};
`;

export const EmptyTypography = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
`;
