import { FC } from 'react';
import { CreateFieldContainer, LookUpWrapper } from './LookUpSelection.styles';
import { LookUpSelectionProps } from './LookUpSelection.interface';
import { useLookUpSelectionStore } from './LookUpSelection.hooks';
import { sortBy } from 'lodash';
import { countBoundingBox } from 'utils/helpers';
import { Word } from 'components/Word/Word';
import { CreateField } from 'components/CreateField';
import Draggable from 'react-draggable';
import { getBounds } from './LookupSelection.utils';
import { Portal } from 'react-portal';

export const PAGE_THUMB_ID = 'page_thumb_';
export const PAGE_THUMB_CONTAINER = 'page_thumb_container';

const MODAL_MARGIN = 24;
const MODAL_SIZE = {
  width: 288,
  height: 200,
};

export const LookUpSelection: FC<LookUpSelectionProps> = ({
  pageId,
  resultId,
  documentId,
  pageWidth,
  pageHeight,
  pageRef,
}) => {
  const { createFields, clearCreateFields } = useLookUpSelectionStore(pageId);
  const sortedCreateFields = sortBy(createFields?.lookups, (item) => item.order);
  const boundingBox = countBoundingBox(createFields?.lookups);

  const modalPositions = {
    left: boundingBox.x * pageWidth,
    top: boundingBox.y * pageHeight + MODAL_MARGIN,
  };

  const bounds = getBounds({
    positions: modalPositions,
    pageHeight,
    pageWidth,
    modalSize: MODAL_SIZE,
  });

  return (
    <>
      {/* ant-select-selection-search-input */}
      <Portal node={pageRef.current}>
        <Draggable handle=".handle" bounds={bounds}>
          <CreateFieldContainer positions={modalPositions}>
            <CreateField
              documentId={documentId}
              pageId={pageId}
              resultId={resultId}
              lookups={sortedCreateFields}
              onClose={() => {
                clearCreateFields();
              }}
              modalSize={MODAL_SIZE}
            />
          </CreateFieldContainer>
        </Draggable>
      </Portal>
      <LookUpWrapper
        $left={boundingBox.x * pageWidth}
        $top={boundingBox.y * pageHeight}
        $width={boundingBox.width * pageWidth}
        $height={boundingBox.height * pageHeight}
      >
        {sortBy(createFields?.lookups, (item) => item.order).map((item) => (
          <Word
            key={`create_field_${item.id}`}
            item={item}
            pageId={pageId}
            resultId={resultId}
            top={(item.boundingBox.y - boundingBox.y) * pageHeight}
            left={(item.boundingBox.x - boundingBox.x) * pageWidth}
            width={item.boundingBox.width * pageWidth}
            height={item.boundingBox.height * pageHeight}
            selected
          />
        ))}
      </LookUpWrapper>
    </>
  );
};
