import { Typography } from 'antd';
import styled from 'styled-components';

export const Section = styled.div`
  width: 80%;
  height: 30px;
  padding-top: 16px;
  padding-bottom: 8px;
  position: relative;
`;

export const Line = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray400};
`;

export const SectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray300};
  max-width: 70%;
  top: 6px;
  transform: translateX(-50%);
  left: 50%;
  font-size: 12px;
  padding-left: ${({ theme }) => theme.padding.xtiny};
  padding-right: ${({ theme }) => theme.padding.xtiny};
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  position: absolute;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;
