import { api } from 'api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollectionId, updateCollectionStatus } from 'store/reducers/collection';

export const useCollectionSync = () => {
  const dispatch = useDispatch();
  const collectionId = useSelector(selectCollectionId);
  const setCollectionStatus = useCallback((e) => dispatch(updateCollectionStatus(e)), [
    dispatch,
  ]);

  const syncCollection = useCallback(() => {
    api.fetchCollection(collectionId).then((data) => {
      setCollectionStatus(data.status);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  return { syncCollection };
};
