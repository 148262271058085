import { Button } from 'antd';
import styled from 'styled-components';

interface StyledButtonProps {
  $active: boolean;
}

export const Container = styled.div<StyledButtonProps>`
  display: inline-block;
  margin-right: 2rem;
  align-items: center;
  border-bottom: ${({ theme, $active }) =>
    `3px solid ${$active ? theme.colors.darkPurple : theme.colors.white}`};
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  font-weight: 500;
  padding-right: 0px;
  padding-left: 0px;
  height: 66px;
  margin-top: 3px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: ${({ theme }) => theme.fontSize.headlineh4};
  color: ${({ theme, $active }) =>
    $active ? theme.colors.blackPurple : theme.colors.blackPurple};
  :focus {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.blackPurple : theme.colors.blackPurple};
  }
  :hover {
    color: ${({ theme }) => theme.colors.blackPurple};
  }
`;
