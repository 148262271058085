import { IconFileText, IconFolder } from 'components/Icons';
import { TFunction } from 'react-i18next';

export const uploadMenu = (translate: TFunction) => [
  {
    id: 1,
    label: translate('files') || '',
    Icon: IconFileText,
  },
  {
    id: 2,
    label: translate('folders') || '',
    Icon: IconFolder,
  },
];
