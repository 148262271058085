import { FC, useCallback, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { Checkbox } from 'antd';
import { ConfigItemProps } from './ConfigItem.interface';
import {
  Container,
  Header,
  HorizontalContainer,
  StyledDeleteIcon,
  DeleteContainer,
  StyledSelect,
  StyledMultipleSelect,
  FieldContainer,
  StyledIconButton,
  StyledInput,
  PatternContainer,
  StyledButton,
  StyledSwitch,
  SwitchContainer,
  SwitchLabel,
  LineContainer,
  TresholdContainer,
  TresholdInput,
  SwitchIgnoreCaseContainer,
  SeparatorsContainer,
  StyledLabel,
} from './ConfigItem.styles';
import { useSelector } from 'react-redux';
import { selectExtractorPrefedinedFields } from 'store/reducers/common';
import { StoreProps } from 'store';
import { debounce, keys, uniq, uniqueId, without } from 'lodash';
import { ConfigType } from 'api';

const extractorType = [
  { value: ConfigType.KEYWORDS, label: 'Keywords' },
  { value: ConfigType.REGEX, label: 'Regex' },
  { value: ConfigType.PREDEFINED, label: 'Predefined' },
];

const separators = (t: TFunction) => [
  { value: ' ', label: t('space') },
  { value: '\n', label: t('newLine') },
  { value: '.', label: t('dot') },
  { value: ':', label: t('colon') },
  { value: ',', label: t('comma') },
];

interface Pattern {
  value?: string;
  id: string;
}

export const ConfigItem: FC<ConfigItemProps> = ({
  extractorConfig,
  extractionId,
  onDelete,
  onPredefinedField,
  onExtractorType,
  onKeywords,
  onThreshold,
  onSeparators,
  onIgnoreCase,
  onMatchGroup,
}) => {
  const { t } = useTranslation();
  const predefinedFields = useSelector((state: StoreProps) =>
    selectExtractorPrefedinedFields(state, extractionId),
  );
  const [patterns, setPatterns] = useState<Array<Pattern>>([]);

  useEffect(() => {
    setPatterns(
      extractorConfig.keywords?.map((item) => ({ value: item, id: uniqueId() })) || [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPatterns = (patterns: Array<string | undefined>) => {
    onKeywords(extractorConfig.id, patterns);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncePatterns = useCallback(debounce(onPatterns, 500), [extractorConfig]);

  const onPatternChange = (id: string, value?: string) => {
    const updatedPatterns = patterns.map((item) => {
      if (item.id === id) {
        item.value = value;
      }

      return item;
    });

    setPatterns(updatedPatterns);

    debouncePatterns(updatedPatterns.map((item) => item.value));
  };

  const onAddPattern = () => {
    setPatterns([...patterns, { id: uniqueId(), value: '' }]);
    debouncePatterns([...patterns.map((item) => item.value), '']);
  };

  const onDeletePattern = (id: string) => {
    const updated = patterns.filter((item) => item.id !== id);

    setPatterns(updated);
    debouncePatterns(updated.map((item) => item.value));
  };

  return (
    <Container>
      <Header>
        <HorizontalContainer>
          <StyledSelect
            size="large"
            label={t('extractorType')}
            value={extractorConfig.configType}
            onChange={(value) => {
              if (value === ConfigType.REGEX) {
                setPatterns([{ id: uniqueId(), value: '' }]);
              } else {
                setPatterns([]);
              }

              onExtractorType(extractorConfig.id, value as ConfigType);
            }}
            options={extractorType}
          />
          {extractorConfig.configType === ConfigType.PREDEFINED && (
            <FieldContainer>
              <StyledSelect
                size="large"
                label={t('predefinedField')}
                value={extractorConfig.predefinedExtractorType}
                onChange={(value) => onPredefinedField(extractorConfig.id, value)}
                options={keys(predefinedFields).map((item) => ({
                  value: item,
                  label: predefinedFields[item],
                }))}
              />
            </FieldContainer>
          )}
          {extractorConfig.configType === ConfigType.KEYWORDS && (
            <FieldContainer>
              <StyledMultipleSelect
                mode="tags"
                onChangeMultiple={(values) => onKeywords(extractorConfig.id, values)}
                label={t('listOfValues')}
                value={uniq(extractorConfig.keywords) || []}
                options={
                  uniq(extractorConfig.keywords)?.map((item) => ({
                    value: item,
                    key: `${extractorConfig}_item`,
                    label: item,
                  })) || []
                }
              />
              <Collapse isOpened>
                <FieldContainer>
                  <LineContainer>
                    <div>
                      <StyledLabel size="small" bold>
                        {t('separators')}
                      </StyledLabel>
                      <SeparatorsContainer>
                        {separators(t).map((item) => (
                          <Checkbox
                            key={item.label}
                            checked={extractorConfig.separators?.includes(item.value)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                onSeparators(extractorConfig.id, [
                                  ...(extractorConfig.separators || []),
                                  item.value,
                                ]);
                              } else {
                                onSeparators(
                                  extractorConfig.id,
                                  without(extractorConfig.separators, item.value),
                                );
                              }
                            }}
                          >
                            {item.label}
                          </Checkbox>
                        ))}
                      </SeparatorsContainer>
                    </div>
                  </LineContainer>
                  <LineContainer>
                    <TresholdContainer>
                      <TresholdInput
                        value={extractorConfig.threshold}
                        type="number"
                        label={t('threshold')}
                        onChange={(value) => onThreshold(extractorConfig.id, value)}
                      />
                    </TresholdContainer>
                    <SwitchIgnoreCaseContainer>
                      <StyledSwitch
                        size="small"
                        checked={extractorConfig.ignoreCase}
                        onChange={(value) => onIgnoreCase(extractorConfig.id, value)}
                      />
                      <SwitchLabel size="small" bold>
                        {t('ignoreCase')}
                      </SwitchLabel>
                    </SwitchIgnoreCaseContainer>
                  </LineContainer>
                </FieldContainer>
              </Collapse>
            </FieldContainer>
          )}
          {extractorConfig.configType === ConfigType.REGEX && (
            <>
              <LineContainer>
                <SwitchContainer>
                  <StyledSwitch
                    size="small"
                    checked={extractorConfig.ignoreCase}
                    onChange={(value) => onIgnoreCase(extractorConfig.id, value)}
                  />
                  <SwitchLabel size="small" bold>
                    {t('ignoreCase')}
                  </SwitchLabel>
                </SwitchContainer>
                <SwitchContainer>
                  <StyledSwitch
                    size="small"
                    checked={extractorConfig.matchGroup}
                    onChange={(value) => onMatchGroup(extractorConfig.id, value)}
                  />
                  <SwitchLabel size="small" bold>
                    {t('matchGroup')}
                  </SwitchLabel>
                </SwitchContainer>
              </LineContainer>
              {patterns.map((item, index) => (
                <PatternContainer key={item.id}>
                  <StyledInput
                    value={item.value}
                    label={index === 0 ? t('pattern') : undefined}
                    onChange={(value) => onPatternChange(item.id, value)}
                  />
                  {index > 0 && (
                    <StyledIconButton
                      withBackground={false}
                      onClick={() => onDeletePattern(item.id)}
                      icon={<StyledDeleteIcon />}
                    />
                  )}
                </PatternContainer>
              ))}
              <StyledButton
                color="light"
                title={t('addPattern')}
                onClick={onAddPattern}
              />
            </>
          )}
        </HorizontalContainer>

        <DeleteContainer>
          <StyledIconButton
            withBackground={false}
            onClick={() => onDelete(extractorConfig.id)}
            icon={<StyledDeleteIcon />}
          />
        </DeleteContainer>
      </Header>
    </Container>
  );
};
