import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Dictionary, keyBy, keys } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDocuments, updateCollection } from 'store/reducers/collection';
import { PagesSection } from './SplittingModal.interface';
import { Document, api } from 'api';
import { mapCollectionResult } from 'utils/mapping';

const prepareDocuments = (documents: Dictionary<Document>) =>
  keys(documents).reduce((acc, item) => {
    acc[item] = {
      documentId: item,
      items: documents[item].pages.map((item) => item.id),
      documentType: '-',
      documentName: documents[item].fileName,
      resultId: documents[item].resultId,
    };

    return acc;
  }, {} as Dictionary<PagesSection>);

export const useSplittingModal = (collectionId: string) => {
  const dispatch = useDispatch();
  const setCollection = useCallback((e) => dispatch(updateCollection(e)), [dispatch]);
  const [zoomModal, setZoomModal] = useState<string | undefined>();
  const documents = useSelector(selectDocuments);
  const [items, setItems] = useState<Dictionary<PagesSection>>({});
  const [activeResultId, setActiveResultId] = useState<string | undefined>();
  const [activePageId, setActivePageId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setItems(prepareDocuments(documents));
  }, [documents]);

  const onAdd = () => {
    const itemsKeys = keys(items);
    const nextId = Number(itemsKeys.sort()[itemsKeys.length - 1]) + 1;

    setItems((prev) => ({ ...prev, [nextId]: { items: [], documentId: `x${nextId}` } }));
  };

  const onZoomModal = (url?: string) => {
    setZoomModal(url);
  };

  const onDocumentType = (key: string, documentType?: string) => {
    setItems((prev) => {
      return { ...prev, [key]: { ...prev[key], documentType } };
    });
  };

  const onDocumentName = (key: string, documentName?: string) => {
    setItems((prev) => {
      return { ...prev, [key]: { ...prev[key], documentName } };
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 100, tolerance: 10 },
    }),
  );

  const findContainer = (id: string) => {
    if (id in items) {
      return id;
    }

    return (
      Object.keys(items).find((key) => items[key].items.includes(id)) ||
      Object.keys(items).find((key) => items[key].documentId === id)
    );
  };

  const handleDragStart = (event: any) => {
    const { active } = event;
    const { id } = active;

    setActivePageId(id);
  };

  const handleDragOver = (event: any) => {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);
    let activeResultId;
    let overResultId;

    if (activeContainer) {
      activeResultId = items[activeContainer].resultId;
    }

    if (overContainer) {
      overResultId = items[overContainer].resultId;
    }

    setActiveResultId(activeResultId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer ||
      activeResultId !== overResultId
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      const activeIndex = activeItems.items.indexOf(id);
      const overIndex = overItems.items.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        newIndex = overItems.items.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.items.length - 1 &&
          draggingRect?.offsetTop > over.rect?.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.items.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: {
          ...prev[activeContainer],
          items: [...prev[activeContainer].items.filter((item) => item !== active.id)],
        },
        [overContainer]: {
          ...prev[overContainer],
          items: [
            ...prev[overContainer].items.slice(0, newIndex),
            items[activeContainer].items[activeIndex],
            ...prev[overContainer].items.slice(
              newIndex,
              prev[overContainer].items.length,
            ),
          ],
        },
      };
    });
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer || activeContainer !== overContainer) {
      return;
    }

    const activeIndex = items[activeContainer].items.indexOf(active.id);
    const overIndex = items[overContainer].items.indexOf(overId);

    const pages = arrayMove(items[overContainer].items, activeIndex, overIndex);

    setItems((items) => ({
      ...items,
      [overContainer]: {
        ...items[overContainer],
        items: pages,
      },
    }));

    const resultId = items[overContainer].resultId;

    if (resultId) {
      setLoading(true);

      const response = await api.updatePageOrder(resultId, overContainer, pages);

      setItems(prepareDocuments(keyBy(response.documents, 'id')));

      setLoading(false);
    }

    setActivePageId(null);
    setActiveResultId(undefined);
  };

  const handleCancel = () => {
    setActiveResultId(undefined);
  };

  const onMoveDown = (key: string) => {
    setItems((prev) => {
      const itemsKeys = keys(prev);
      const movedItem = prev[key];
      const nextItemKeyIndex = itemsKeys.indexOf(key) + 1;
      const nextItem = prev[itemsKeys[nextItemKeyIndex]];
      const nextItemKey = itemsKeys[nextItemKeyIndex];

      return { ...prev, [key]: nextItem, [nextItemKey]: movedItem };
    });
  };

  const onMoveUp = (key: string) => {
    setItems((prev) => {
      const itemsKeys = keys(prev);
      const movedItem = prev[key];
      const previousItemKeyIndex = itemsKeys.indexOf(key) - 1;
      const previousItem = prev[itemsKeys[previousItemKeyIndex]];
      const previousItemKey = itemsKeys[previousItemKeyIndex];

      return { ...prev, [key]: previousItem, [previousItemKey]: movedItem };
    });
  };

  const onDelete = (key: string) => {
    setItems((prev) => {
      const itemsKeys = keys(prev);
      const deletedItem = prev[key];

      if (itemsKeys.length > itemsKeys.indexOf(key) + 1) {
        const nextItemKeyIndex = itemsKeys.indexOf(key) + 1;
        const nextItem = prev[itemsKeys[nextItemKeyIndex]];
        const nextItemKey = itemsKeys[nextItemKeyIndex];

        delete prev[key];

        return {
          ...prev,
          [nextItemKey]: {
            ...nextItem,
            items: [...nextItem.items, ...deletedItem.items],
          },
        };
      } else if (itemsKeys.length === itemsKeys.indexOf(key) + 1) {
        const previousItemKeyIndex = itemsKeys.indexOf(key) - 1;
        const previousItem = prev[itemsKeys[previousItemKeyIndex]];
        const previousItemKey = itemsKeys[previousItemKeyIndex];

        delete prev[key];

        return {
          ...prev,
          [previousItemKey]: {
            ...previousItem,
            items: [...previousItem.items, ...deletedItem.items],
          },
        };
      }

      return prev;
    });
  };

  const refreshCollection = () => {
    return api.fetchCollectionResult(collectionId).then((data) => {
      setCollection(mapCollectionResult(data));

      return data;
    });
  };

  const onSplit = async (
    pageId: string,
    documentId: string,
    resultId: string | undefined,
  ) => {
    const pageSection = items[documentId];

    const splitPageIndex = pageSection.items.indexOf(pageId) + 1;
    const pages = pageSection.items;

    const pageIds = pages.slice(splitPageIndex);

    if (resultId) {
      setLoading(true);

      const response = await api.splitDocument(resultId, pageIds);

      refreshCollection();

      setItems(prepareDocuments(keyBy(response.documents, 'id')));

      setLoading(false);
    }
  };

  return {
    activePageId,
    sensors,
    items,
    documents,
    zoomModal,
    activeResultId,
    loading,
    onAdd,
    onSplit,
    onZoomModal,
    onDocumentType,
    onDocumentName,
    onMoveUp,
    onMoveDown,
    onDelete,
    handleDragOver,
    handleDragStart,
    handleDragEnd,
    handleCancel,
  };
};
