import { FC } from 'react';
import {
  Header,
  StyledArrowDown,
  HorizontalContainer,
  HeaderContent,
  StyledName,
  StyledLabel,
  LabelsContainer,
  Issues,
  IconContainer,
} from './DocumentItem.styles';
import { DocumentItemProps } from './DocumentItem.interface';
import { Tooltip } from 'antd';
import { theme } from 'styles';
import { translateStatus } from 'components/FieldStatus';
import { useTranslation } from 'react-i18next';
import { mapAnnotation } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { selectCommonTypes } from 'store/reducers/common';
import { StoreProps } from 'store';

export const DocumentItem: FC<DocumentItemProps> = ({
  collapsed,
  onClick,
  label,
  labels,
  index,
  annotationAttentions,
}) => {
  const commonTypes = useSelector((state: StoreProps) => selectCommonTypes(state));
  const { t } = useTranslation();

  return (
    <Header $showBorder={index > 0} onClick={onClick}>
      <HeaderContent>
        <HorizontalContainer>
          <IconContainer>
            <StyledArrowDown
              $annotationAttentions={annotationAttentions.length > 0}
              className={collapsed ? 'rotate' : ''}
            />
          </IconContainer>
          <StyledName $annotationAttentions={annotationAttentions.length > 0}>
            {label}
          </StyledName>
          {annotationAttentions.length > 0 && (
            <Tooltip
              title={
                <>
                  {annotationAttentions.map((item, index) => (
                    <span
                      style={{ color: theme.colors.blackPurple }}
                      key={`${item.field}-${index}`}
                    >
                      <b>{mapAnnotation(commonTypes.annotationsTypes, item.field)}</b> -{' '}
                      {translateStatus(t, item.status)}
                      <br />
                    </span>
                  ))}
                </>
              }
              placement="topLeft"
              overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 500 }}
              color={theme.colors.blushPink}
            >
              <Issues>{annotationAttentions.length}</Issues>
            </Tooltip>
          )}
        </HorizontalContainer>
        <LabelsContainer>
          {labels?.map((label) => (
            <StyledLabel size="small" bold key={`${label}`}>
              {label}
            </StyledLabel>
          ))}
        </LabelsContainer>
      </HeaderContent>
    </Header>
  );
};
