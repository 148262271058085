import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addCreateFields, updateFieldArrow } from 'store/reducers/supervision';

export const useWordStore = () => {
  const dispatch = useDispatch();
  const addField = useCallback((e) => dispatch(addCreateFields(e)), [dispatch]);
  const addFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);

  return { addField, addFieldArrow };
};
