import { Document, Page } from 'api';
import { SelectOption } from 'components/Select';
import { Dictionary, flatten, isEmpty, keys, values } from 'lodash';
import { TFunction } from 'react-i18next';
import { FilterPhrase } from './ExtractedFields/Filters/Filters.interface';

export const flatToPages = (documents: Dictionary<Document>): Array<Page> => {
  return flatten(
    values(documents).map((doc) =>
      doc.pages.map((page, index) => ({
        ...page,
        resultId: doc.resultId,
        documentId: doc.id,
        tagGroup: doc.tagGroups && index === 0,
      })),
    ),
  );
};

export const flatToThumbnails = (documents: Dictionary<Document>) => {
  return flatten(
    values(documents).map((doc) => [
      { label: doc.fileName, id: doc.id, isGroup: true },
      ...doc.pages.map((page) => ({
        ...page,
        isGroup: false,
        documentName: doc.fileName,
        label: page.collectionPageNum?.toString(),
      })),
    ]),
  );
};

export const phrasesFromQuery = (
  query: Dictionary<Array<string> | string>,
  annotationTypes?: Array<SelectOption>,
) => {
  const queryKeys = keys(query);
  const phrases: Array<FilterPhrase> = [];
  const annotationTypesKeys = annotationTypes?.map((item) => item.value) || [];

  for (let i = 0; i < queryKeys.length; i += 1) {
    if (annotationTypesKeys.includes(queryKeys[i])) {
      if (Array.isArray(query[queryKeys[i]])) {
        phrases.push({
          fieldType: queryKeys[i],
          phrases: [...(query[queryKeys[i]] as Array<string>)],
        });
      } else {
        phrases.push({
          fieldType: queryKeys[i],
          phrases: [query[queryKeys[i]] as string],
        });
      }
    }
  }

  return isEmpty(phrases) ? undefined : phrases;
};

export const mapErrorStatus = (t: TFunction, errorStatus?: number) => {
  if (errorStatus === 404) {
    return t('collectionNotFound');
  }

  if (errorStatus === 403) {
    return t('readNotAllowed');
  }

  return t('collectionFailed');
};
