import { api } from 'api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePaneDragging,
  selectEditField,
  updateEditField,
  clearSupervisionState,
  updateSortBy,
  updateSearch,
  updateFilterByFieldTypes,
  updateFilterByPhrases,
  selectEditMode,
  updateEditMode,
  updateAnnotationsEditMode,
  updateGroupTagsEditMode,
} from 'store/reducers/supervision';
import { mapCollectionResult } from 'utils/mapping';
import {
  updateCollection,
  selectCollectionLoading,
  updateCollectionLoading,
  selectDocuments,
  updateCollectionError,
  selectCollectionError,
  selectCollectionStatus,
  selectProcessedDocuments,
  selectCollectionId,
  updateCollectionEditor,
  selectEditorId,
  selectAllDataObjects,
  selectAllTagGroups,
} from 'store/reducers/collection';
import { selectCommonTypes, selectUser } from 'store/reducers/common';
import { AxiosError } from 'axios';
import { ENV_VARIABLE } from 'variables';
import { useUserScope } from 'hooks';
import { updateFilterByStatus } from 'store/reducers/supervision/filterByStatus';
import { Dictionary, flatten, values } from 'lodash';
import { AnnotationEditMode, GroupTagEditMode, StoreProps } from 'store';
import { updatePaneSizes } from 'store/reducers/supervision/paneSizes';
import { selectAllImages } from 'store/reducers/supervision/images';
import { updateFilterByFieldDuplicates } from 'store/reducers/supervision/filterByFieldDuplicates';

export const useSupervisionStore = () => {
  const dispatch = useDispatch();
  const setEditMode = useCallback((e) => dispatch(updateEditMode(e)), [dispatch]);
  const setPaneDrag = useCallback((e) => dispatch(updatePaneDragging(e)), [dispatch]);
  const setPaneSizes = useCallback((e) => dispatch(updatePaneSizes(e)), [dispatch]);
  const setSearch = useCallback((e) => dispatch(updateSearch(e)), [dispatch]);
  const setSortBy = useCallback((e) => dispatch(updateSortBy(e)), [dispatch]);
  const setEditField = useCallback((e) => dispatch(updateEditField(e)), [dispatch]);
  const setCollection = useCallback((e) => dispatch(updateCollection(e)), [dispatch]);
  const setFilterByStatus = useCallback((e) => dispatch(updateFilterByStatus(e)), [
    dispatch,
  ]);
  const cachedImages = useSelector(selectAllImages);
  const clearSupervision = useCallback(() => {
    values(cachedImages).forEach((blobUrl) => URL.revokeObjectURL(blobUrl));
    dispatch(clearSupervisionState());
  }, [dispatch, cachedImages]);
  const setEditorId = useCallback(
    (editorId) => dispatch(updateCollectionEditor(editorId)),
    [dispatch],
  );
  const setCollectionLoading = useCallback((e) => dispatch(updateCollectionLoading(e)), [
    dispatch,
  ]);
  const setCollectionError = useCallback((e) => dispatch(updateCollectionError(e)), [
    dispatch,
  ]);
  const setFilterByFieldType = useCallback((e) => dispatch(updateFilterByFieldTypes(e)), [
    dispatch,
  ]);
  const setFilterByPhrases = useCallback((e) => dispatch(updateFilterByPhrases(e)), [
    dispatch,
  ]);
  const setFilterByFieldDuplicates = useCallback(
    (e) => dispatch(updateFilterByFieldDuplicates(e)),
    [dispatch],
  );
  const setAnnotationsEditMode = useCallback(
    (e) => dispatch(updateAnnotationsEditMode(e)),
    [dispatch],
  );

  const setGroupTagsEditMode = useCallback((e) => dispatch(updateGroupTagsEditMode(e)), [
    dispatch,
  ]);

  const collectionId = useSelector(selectCollectionId);
  const loading = useSelector(selectCollectionLoading);
  const error = useSelector(selectCollectionError);
  const documents = useSelector(selectDocuments);
  const editField = useSelector(selectEditField);
  const commonTypes = useSelector((state: StoreProps) => selectCommonTypes(state));
  const collectionStatus = useSelector(selectCollectionStatus);
  const processedDocuments = useSelector(selectProcessedDocuments);
  const editMode = useSelector(selectEditMode);
  const user = useSelector(selectUser);
  const editorId = useSelector(selectEditorId);
  const dataObjects = useSelector(selectAllDataObjects);
  const tagGroups = useSelector(selectAllTagGroups);

  const { isAllowed } = useUserScope('RESULT_UPDATE');

  const fetchCollection = (id: string) => {
    clearSupervision();
    setCollectionLoading({ loading: true });

    return api
      .fetchCollectionResult(id)
      .then((data) => {
        setCollection(mapCollectionResult(data));
        setCollectionLoading({ loading: false });

        return data;
      })
      .then((data) => {
        if (
          ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED?.toLowerCase() === 'true' &&
          user &&
          !data.editor?.editorId &&
          isAllowed
        ) {
          api.addEditor(data.collectionId, user.sub).then(() => setEditorId(user.sub));
        }
      })
      .catch((error: AxiosError) => {
        setCollectionLoading(false);
        setCollectionError(error.response?.status);
      });
  };

  const keepAliveEditor = () => {
    if (ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED === 'true' && user && isAllowed) {
      api.activeEditor(collectionId, user.sub);
    }
  };

  const addAnnotationsEditMode = () => {
    const list = flatten(values(dataObjects).map((item) => values(item)));

    const editModeAnnotations = list.reduce((acc, item) => {
      acc[item.id] = { editMode: true };
      return acc;
    }, {} as Dictionary<AnnotationEditMode>);

    setAnnotationsEditMode(editModeAnnotations);
  };

  const addGroupTagsEditMode = () => {
    const list = flatten(values(tagGroups).map((item) => values(item)));

    const editModeTagGroups = list.reduce((acc, item) => {
      acc[item.id] = { editMode: true };
      return acc;
    }, {} as Dictionary<GroupTagEditMode>);

    setGroupTagsEditMode(editModeTagGroups);
  };

  return {
    error,
    editorId,
    processedDocuments,
    documents,
    editField,
    loading,
    commonTypes,
    collectionStatus,
    editMode,
    user,
    dataObjects,
    tagGroups,
    fetchCollection,
    setPaneDrag,
    setPaneSizes,
    setFilterByStatus,
    setSearch,
    setSortBy,
    setEditField,
    clearSupervision,
    setFilterByFieldType,
    setFilterByPhrases,
    setFilterByFieldDuplicates,
    keepAliveEditor,
    setEditMode,
    addAnnotationsEditMode,
    addGroupTagsEditMode,
  };
};
