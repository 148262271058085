import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Notification } from 'components/Notification';
import { store } from 'react-notifications-component';
import {
  Container,
  StyledEditor,
  StyledDocumentType,
  StyledIconBack,
  StyledIconFile,
  StyledIconFolder,
  UsersContainer,
  NameContainer,
  StyledName,
  BackContainer,
  ActionContainer,
  StyledIconDelete,
  StyledPrimaryIconButton,
  PageAction,
  StyledIconNext,
  PageNumberContainer,
  StyledIconPrevious,
  CurrentPage,
  StyledPages,
  StyledZoomIn,
  StyledZoomOut,
  StyledSecondaryButton,
  StyledIconEditMode,
  StyledEditorContainer,
  StyledIconMarkToDelete,
} from './Header.styles';
import { useHeaderStore } from './Header.hooks';
import { ProcessStatus } from 'components/ProcessStatus';
import { IconSplitMode, IconUpload } from 'components/Icons';
import { flatten, values } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  countAttentions,
  countUncertainTagGroups,
  supervisionSettings,
} from 'utils/helpers';
import { useExportModalStore } from 'store/reducers/modals';
import { useTyroHistory, useUserScope } from 'hooks';
import { statusFilters } from '../ExtractedFields/Filters';
import { ENV_VARIABLE } from 'variables';
import { api } from 'api';
import { useInterval } from 'hooks/useInterval';
import { useDispatch } from 'react-redux';
import { clearTablesEditMode } from 'store/reducers/supervision';
import { HeaderProps } from './Header.interface';
import { StyledSecondaryAltButton } from './Header.styles';

const REFRESH_INTERVAL = 5000;

export const Header: FC<HeaderProps> = ({ onSplit }) => {
  const { t } = useTranslation();
  const {
    setEditMode,
    setCurrentPage,
    currentPageId,
    totalFiles,
    name,
    collectionId,
    metadata,
    status,
    editMode,
    totalPages,
    documents,
    dataObjects,
    tagGroups,
    zoom,
    setZoom,
    setMetadata,
    clearGroupTagEditMode,
    clearAnnotationEditMode,
    error,
    loading,
    collectionStatus,
    processedDocuments,
    setFilterByStatus,
    user,
    editorId,
    fetchCollection,
    addAnnotationsEditMode,
    addGroupTagsEditMode,
  } = useHeaderStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeNext, setActiveNext] = useState(false);
  const [activePrevious, setActivePrevious] = useState(false);
  const [zoomInActive, setZoomInActive] = useState(false);
  const [zoomOutActive, setZoomOutActive] = useState(false);
  const attentions =
    countAttentions(values(dataObjects)) + countUncertainTagGroups(tagGroups);
  const { setExportModal } = useExportModalStore();
  const { openDocuments } = useTyroHistory();
  const location = useLocation<{ from?: string }>();
  const { isAllowed: editPermission } = useUserScope('RESULT_UPDATE');
  const { isAllowed: exportPermission } = useUserScope('RESULT_EXPORT');

  useInterval(() => fetchCollection(), REFRESH_INTERVAL, true);

  const collectionFailed =
    (error || collectionStatus === 'Failed') && !loading && processedDocuments === 0;

  const onFinishEditing = () => {
    setEditMode(false);
    setFilterByStatus([]);

    clearGroupTagEditMode();
    clearAnnotationEditMode();
    dispatch(clearTablesEditMode());
  };

  const onEdit = () => {
    const settings = supervisionSettings();

    if (attentions > 0 && settings.uncertainFilter) {
      setFilterByStatus([statusFilters(t)[2].label]);
    }

    addAnnotationsEditMode();
    addGroupTagsEditMode();
    setEditMode(true);
  };

  const pages = flatten(values(documents).map((document) => document.pages));

  const onCopy = () => {
    const successNotification = () => <Notification content={t('clipboardInfo') || ''} />;

    store.addNotification({
      insert: 'bottom',
      content: successNotification,
      container: 'bottom-left',
      animationIn: ['animate__animated animate__zoomIn'],
      animationOut: ['animate__animated animate__zoomOut'],
      dismiss: {
        duration: 1300,
      },
    });
  };

  const onBack = () => {
    if (
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED?.toLowerCase() === 'true' &&
      user?.sub === editorId &&
      user &&
      editPermission
    ) {
      api.deleteEditor(collectionId, user.sub);
    }

    if (location.state?.from) {
      history.goBack();
    } else {
      openDocuments();
    }
  };

  const onMarkToDelete = () => {
    if (metadata?.tags?.mark_to_delete === 'true') {
      api.unmarkFromDelete(collectionId).then(() => {
        setMetadata({ tags: {} });
      });
    } else {
      api.markToDelete(collectionId).then(() => {
        setMetadata({ tags: { mark_to_delete: 'true' } });
      });
    }
  };

  const isEditEnabled =
    !ENV_VARIABLE.KEY_CLOAK_URL ||
    (editorId && editorId === user?.sub && editPermission) ||
    (ENV_VARIABLE.KEY_CLOAK_URL &&
      editPermission &&
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED !== 'true');

  const readOnly = loading ? false : !isEditEnabled;

  return (
    <Container $editMode={readOnly}>
      <BackContainer>
        <StyledIconBack $editMode={readOnly} onClick={onBack} />
        <NameContainer>
          {name && (
            <StyledDocumentType>
              {totalFiles > 1 ? <StyledIconFolder /> : <StyledIconFile />}
            </StyledDocumentType>
          )}
          <CopyToClipboard onCopy={onCopy} text={name || ''}>
            <StyledName $editMode={readOnly}>{name}</StyledName>
          </CopyToClipboard>

          {status && <ProcessStatus status={status} />}
        </NameContainer>
        {pages.length > 0 && (
          <PageNumberContainer $editMode={readOnly}>
            <PageAction
              onClick={() => {
                const currentPage =
                  pages.find((page) => page.id === currentPageId)?.collectionPageNum || 0;

                if (currentPage - 2 >= 0) {
                  setCurrentPage(pages[currentPage - 2].id);
                }
              }}
              onMouseEnter={() => setActivePrevious(true)}
              onMouseLeave={() => setActivePrevious(false)}
            >
              <StyledIconPrevious $active={activePrevious} />
            </PageAction>
            <CurrentPage>
              {pages.find((page) => page.id === currentPageId)?.collectionPageNum}
            </CurrentPage>
            <PageAction
              onClick={() => {
                const currentPage =
                  pages.find((page) => page.id === currentPageId)?.collectionPageNum || 0;

                if (currentPage < pages.length) {
                  setCurrentPage(pages[currentPage].id);
                }
              }}
              onMouseEnter={() => setActiveNext(true)}
              onMouseLeave={() => setActiveNext(false)}
            >
              <StyledIconNext $active={activeNext} />
            </PageAction>
            <StyledPages $editMode={readOnly}>{`${t('of')} ${totalPages}`}</StyledPages>
          </PageNumberContainer>
        )}
        {pages.length > 0 && (
          <PageNumberContainer $editMode={readOnly}>
            <PageAction
              onMouseEnter={() => setZoomOutActive(true)}
              onMouseLeave={() => setZoomOutActive(false)}
              onClick={() => {
                setZoom({
                  scale: zoom?.scale - 0.2 < 1 ? 1 : zoom?.scale - 0.2,
                  isButtonAction: true,
                });
              }}
            >
              <StyledZoomOut $active={zoomOutActive} />
            </PageAction>
            <CurrentPage>{(zoom?.scale * 100).toFixed(0)}%</CurrentPage>
            <PageAction
              onClick={() => {
                setZoom({
                  scale: zoom?.scale + 0.2 > 3 ? 3 : zoom?.scale + 0.2,
                  isButtonAction: true,
                });
              }}
              onMouseEnter={() => setZoomInActive(true)}
              onMouseLeave={() => setZoomInActive(false)}
            >
              <StyledZoomIn $active={zoomInActive} />
            </PageAction>
          </PageNumberContainer>
        )}
      </BackContainer>
      {!collectionFailed && !loading && (
        <ActionContainer>
          {readOnly && (
            <UsersContainer $editMode={readOnly}>
              <StyledEditorContainer>
                <StyledEditor>READ ONLY</StyledEditor>
              </StyledEditorContainer>
            </UsersContainer>
          )}

          {ENV_VARIABLE.SPLITTING_ENABLED === 'true' && (
            <StyledSecondaryButton onClick={onSplit} icon={<IconSplitMode />} />
          )}
          {exportPermission && (
            <StyledSecondaryButton
              onClick={() => {
                setExportModal({
                  open: true,
                  collectionIds: [collectionId],
                });
              }}
              icon={<IconUpload />}
            />
          )}
          {ENV_VARIABLE.MARK_TO_DELETE_ENABLED === 'true' && (
            <StyledSecondaryAltButton
              onClick={onMarkToDelete}
              disabled
              icon={
                <StyledIconMarkToDelete
                  $active={metadata?.tags?.mark_to_delete === 'true'}
                />
              }
              data-uid="supervision-page.mark-to-delete-button"
            />
          )}
          {!readOnly && (
            <>
              {!editMode ? (
                <StyledPrimaryIconButton
                  issues={attentions}
                  onClick={onEdit}
                  icon={<StyledIconEditMode />}
                  data-uid="supervision-page.edit-button"
                />
              ) : (
                <StyledPrimaryIconButton
                  withShadow={false}
                  onClick={onFinishEditing}
                  icon={<StyledIconDelete />}
                />
              )}
            </>
          )}
        </ActionContainer>
      )}
    </Container>
  );
};
