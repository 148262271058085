import { useCallback, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { api } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { selectImageBlob, setImage } from 'store/reducers/supervision';
import { StoreProps } from 'store';
import placeholder from 'assets/placeholder.png';

interface SupervisionImageProps {
  shouldLoad?: boolean;
  imageUrl: string | undefined;
  onImageLoad?: VoidFunction;
  preventLoading?: boolean;
}

export const useSupervisionImage = ({
  shouldLoad = true,
  imageUrl,
  preventLoading,
  onImageLoad,
}: SupervisionImageProps) => {
  const dispatch = useDispatch();
  const setImageBlobUrl = useCallback(
    (e) => dispatch(setImage({ url: imageUrl, blobUrl: e.blobUrl })),
    [dispatch, imageUrl],
  );
  const blobUrl = useSelector((state: StoreProps) => selectImageBlob(state, imageUrl));

  useEffect(() => {
    let cancelToken: CancelTokenSource;

    if (blobUrl) {
      onImageLoad?.();
    }

    if (imageUrl && !preventLoading && !blobUrl && shouldLoad) {
      cancelToken = axios.CancelToken.source();

      api
        .fetchImage(imageUrl, cancelToken.token)
        .then((data) => {
          const blob = window.URL.createObjectURL(data);
          setImageBlobUrl({
            blobUrl: blob,
          });
          onImageLoad?.();
        })
        .catch(() => {
          /* no-op */
        });
    }

    return () => {
      cancelToken?.cancel();
    };
  }, [imageUrl, shouldLoad, preventLoading, onImageLoad, blobUrl, setImageBlobUrl]);

  return {
    image: blobUrl || placeholder,
  };
};
