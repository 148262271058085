import { Checkbox, Typography } from 'antd';
import {
  IconDelete,
  IconEdit,
  IconFile,
  IconFlag,
  IconFolder,
  IconMarkToDelete,
  IconUpload,
  IconViewResults,
} from 'components/Icons';
import { Label } from 'components/Label';
import { ProcessProgress } from 'components/ProcessProgress';
import { Menu } from 'react-contexify';
import styled from 'styled-components';

interface ContainerProps {
  $selected?: boolean;
  $checked?: boolean;
  $highlighted?: boolean;
}

interface CellProps {
  size: number;
}

interface NameContainerProps {
  $showMenu: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  padding-left: ${({ theme }) => theme.padding.normal};
  border-radius: 0.3em;
  width: 100%;
  height: 56px;
  margin-bottom: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-left: ${({ theme, $selected }) =>
    `${$selected ? '4px' : 0} solid ${theme.colors.blackPurple}`};
  margin-left: ${({ $selected }) => ($selected ? '0.75rem' : 0)};
  background-color: ${({ theme, $checked, $highlighted }) =>
    $checked || $highlighted ? theme.colors.gray400 : theme.colors.white};
  :hover {
    background-color: ${({ theme }) => theme.colors.gray400};
  }
`;

export const Cell = styled.div<CellProps>`
  min-width: ${({ size }) => `${size}%`};
  width: ${({ size }) => `${size}%`};
  align-items: center;
  display: flex;
`;

export const NameContainer = styled.div<NameContainerProps>`
  display: flex;
  max-width: ${({ $showMenu }) =>
    $showMenu ? 'calc(100% - 48px)' : 'calc(100% - 18px)'};
  align-items: center;
`;

export const ColumnName = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  padding-right: ${({ theme }) => theme.padding.small};
  :hover {
    text-decoration: underline;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.padding.large};
`;

export const StyledFlag = styled(IconFlag)`
  width: 15px;
  margin-right: ${({ theme }) => theme.padding.large};
`;

export const EmptySpace = styled.div`
  width: 15px;
  margin-right: ${({ theme }) => theme.padding.large};
`;

export const MenuContainer = styled.div`
  display: flex;
  height: 48px;
  margin-top: 8px;
  margin-left: ${({ theme }) => theme.padding.tiny};
`;

export const StyledIconFile = styled(IconFile)`
  width: 15px;
`;

export const StyledIconFolder = styled(IconFolder)`
  width: 15px;
`;

export const StyledDocumentType = styled.div`
  min-width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lilac};
  margin-right: ${({ theme }) => theme.padding.normal};
`;

export const StyledName = styled(Label)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const StyledFiles = styled(Label)`
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const StyledDate = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const StyledPages = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  margin-left: 15px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const StyledTotalFiles = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const StyledDeleteIcon = styled(IconDelete)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledEditIcon = styled(IconEdit)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledIconViewResults = styled(IconViewResults)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledIconMarkToDelete = styled(IconMarkToDelete)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledIconMarkToDeleteIcon = styled(IconMarkToDelete)`
  color: ${({ theme }) => theme.colors.warningYellow};
  margin-left: 8px;
  width: 20px;
`;

export const StyledIconUpload = styled(IconUpload)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledMenu = styled(Menu).attrs({})`
  border: ${({ theme }) => `1px solid ${theme.colors.line}`};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.2);
  border-radius: 3px;
  min-width: 5vw;

  .react-contexify__item__content {
    color: ${({ theme }) => theme.colors.gray};
  }

  .react-contexify__item:not(.react-contexify__item--disabled):hover
    > .react-contexify__item__content,
  .react-contexify__item:not(.react-contexify__item--disabled):focus
    > .react-contexify__item__content {
    background-color: ${({ theme }) => theme.colors.menuItem};
    color: ${({ theme }) => theme.colors.gray};
    overflow: hidden;
    border-radius: 3px;
  }
`;

export const PriorityContainer = styled.div`
  width: 45px;
`;

export const StyledProgress = styled(ProcessProgress)`
  width: 80%;
`;
