import { Input } from 'antd';
import { Label } from 'components/Label';
import styled from 'styled-components';

interface StyledInputProps {
  $error?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.gray300};
  padding-bottom: 8px;
`;

export const StyledInput = styled(Input)<StyledInputProps>`
  height: 40px;
  border-color: ${({ theme, $error }) =>
    $error ? theme.colors.red : theme.colors.gray400};
  border-radius: 6px;
  .ant-input {
    color: ${({ theme }) => theme.colors.blackPurple};
    ::placeholder {
      color: ${({ theme }) => theme.colors.gray300};
    }
  }
`;
