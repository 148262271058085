import { Dictionary } from 'lodash';
import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface AnnotationEditModeProps {
  annotationId: string;
  editMode: boolean;
  type?: string;
  text?: string;
  resultId?: string;
}

interface TableEditModeProps {
  annotationId: string;
  type?: string;
  cellId: string;
  text?: string;
  resultId?: string;
}

interface AnnotationsEditModeProps {
  annotationIds: Dictionary<string>;
}

interface AnnotationEditModeDispatch extends BaseDispatch {
  payload: AnnotationEditModeProps;
}
interface TableEditModeDispatch extends BaseDispatch {
  payload: TableEditModeProps;
}

interface AnnotationsEditModeDispatch extends BaseDispatch {
  payload: AnnotationsEditModeProps;
}

export const SET_ANNOTATION_EDIT_MODE = 'SET_ANNOTATION_EDIT_MODE';
export const SET_ANNOTATIONS_EDIT_MODE = 'SET_ANNOTATIONS_EDIT_MODE';
export const CLEAR_ANNOTATION_EDIT_MODE = 'CLEAR_ANNOTATION_EDIT_MODE';
export const SET_TABLE_EDIT_MODE = 'SET_TABLE_EDIT_MODE';

export const selectAnnotationEditMode = (state: StoreProps, id?: string) =>
  id ? state.supervision.annotationEditMode[id] : undefined;

export const selectAnnotationsEditMode = (state: StoreProps) =>
  state.supervision.annotationEditMode;

export const clearAnnotationEditModeState = (state: SupervisionStore) => {
  return {
    ...state,
    annotationEditMode: {},
  };
};

export const updateAnnotationEditModeState = (
  state: SupervisionStore,
  data: AnnotationEditModeProps,
) => {
  return {
    ...state,
    annotationEditMode: {
      ...state.annotationEditMode,
      [data.annotationId]: {
        editMode: data.editMode,
        text: data.text,
        type: data.type,
        resultId: data.resultId,
      },
    },
  };
};

export const updateTableEditModeState = (
  state: SupervisionStore,
  data: TableEditModeProps,
) => {
  return {
    ...state,
    annotationEditMode: {
      ...state.annotationEditMode,
      [data.annotationId]: {
        ...state.annotationEditMode[data.annotationId],
        editedCells: {
          ...state.annotationEditMode[data.annotationId].editedCells,
          [data.cellId]: { text: data.text },
        },
      },
    },
  };
};

export const updateAnnotationsEditModeState = (
  state: SupervisionStore,
  data: AnnotationsEditModeProps,
) => {
  return {
    ...state,
    annotationEditMode: {
      ...data,
    },
  };
};

export const updateAnnotationEditMode = (data: AnnotationEditModeProps) => (
  dispatch: (data: AnnotationEditModeDispatch) => void,
) => {
  dispatch({
    type: SET_ANNOTATION_EDIT_MODE,
    payload: data,
  });
};

export const updateTableEditMode = (data: TableEditModeProps) => (
  dispatch: (data: TableEditModeDispatch) => void,
) => {
  dispatch({
    type: SET_TABLE_EDIT_MODE,
    payload: data,
  });
};

export const updateAnnotationsEditMode = (data: AnnotationsEditModeProps) => (
  dispatch: (data: AnnotationsEditModeDispatch) => void,
) => {
  dispatch({
    type: SET_ANNOTATIONS_EDIT_MODE,
    payload: data,
  });
};

export const clearAnnotationEditMode = () => (dispatch: (data: BaseDispatch) => void) => {
  dispatch({
    type: CLEAR_ANNOTATION_EDIT_MODE,
  });
};
