import styled from 'styled-components';

export const Image = styled.img<{ isActive: boolean }>`
  width: 100%;
  position: relative;
  width: 138px;
  max-height: 195px;
  height: 195px;
  border: ${({ theme, isActive }) =>
    `1px solid ${isActive ? theme.colors.darkPurple : theme.colors.gray400}`};
  box-shadow: 0px 0px 11px #dfdde6;
`;

export const Container = styled.div`
  margin-bottom: 36px;
  position: relative;
`;

export const EmptyPage = styled.div`
  :hover {
    border: ${({ theme }) => `1px solid ${theme.colors.darkPurple}`};
  }
  width: 100%;
  position: relative;
  width: 138px;
  height: 195px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  box-shadow: 0px 0px 11px #dfdde6;
`;

export const PageNumber = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: center;
`;
