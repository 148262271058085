import { SizeType } from 'antd/lib/config-provider/SizeContext';
import styled from 'styled-components';

interface ButtonProps {
  $withBackground: boolean;
  buttonSize: SizeType;
}

export const Container = styled.div`
  position: relative;
`;

export const size = (size: SizeType) => {
  switch (size) {
    case 'small':
      return '26px';
    default:
      return '40px';
  }
};

export const StyledButton = styled.div<ButtonProps>`
  :focus {
    border: ${({ theme }) => `1px solid ${theme.colors.blackPurple}`};
    border: 1px;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.lilacDark};
  }
  height: ${({ buttonSize }) => size(buttonSize)};
  width: ${({ buttonSize }) => size(buttonSize)};
  border-radius: 6px;
  background-color: ${({ theme, $withBackground }) =>
    $withBackground ? theme.colors.lilac : theme.colors.transparent};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0px;
  display: flex;
  cursor: pointer;
`;

export const Issues = styled.div`
  background-color: ${({ theme }) => theme.colors.darkPink};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: -4px;
  right: -6px;
  user-select: none;
`;
