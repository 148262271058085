import { FC } from 'react';
import { SecondaryButtonProps } from './SecondaryButton.interface';
import { StyledButton, Container } from './SecondaryButton.styles';

export const SecondaryButton: FC<SecondaryButtonProps> = ({
  onClick,
  icon,
  ...other
}) => {
  return (
    <Container {...other}>
      <StyledButton onClick={onClick}>{icon}</StyledButton>
    </Container>
  );
};
