import React, { FC } from 'react';
import { DocumentType } from 'api';
import { UploadFileProps } from './UploadFile.interface';
import {
  StyledDocumentType,
  StyledIconFile,
  StyledIconFolder,
  FileInfoContainer,
  FileNameContainer,
  StyledFileName,
  StyledFileSize,
  FileContainer,
  FileProgressContainer,
  StyledProgress,
  StyledProgressValue,
  UploadStatus,
  StyledLoader,
  StyledIconCheck,
  StyledIconRefresh,
} from './UploadFile.styles';
import { theme } from 'styles';

const documentTypeIcon = (type: DocumentType) => {
  if (type === 'file') {
    return <StyledIconFile />;
  }

  return <StyledIconFolder />;
};

export const UploadFile: FC<UploadFileProps> = ({
  name,
  size,
  uploading,
  failed,
  uploadProgress = 0,
  onRefresh,
}) => {
  return (
    <FileContainer>
      <StyledDocumentType>{documentTypeIcon('file')}</StyledDocumentType>
      <FileProgressContainer>
        <FileInfoContainer>
          <FileNameContainer>
            <StyledFileName>{`${name.slice(0, 15)}${
              name.length > 15 ? '...' : ''
            }`}</StyledFileName>
            <StyledFileSize>{(size * 0.000001).toFixed(2)} MB</StyledFileSize>
          </FileNameContainer>
          {uploadProgress > 0 && (
            <StyledProgressValue
              percent={uploadProgress}
            >{`${uploadProgress}%`}</StyledProgressValue>
          )}
        </FileInfoContainer>
        <StyledProgress
          trailColor={failed ? theme.colors.red : theme.colors.progressBg}
          strokeWidth={4}
          showInfo={false}
          percent={uploadProgress}
        />
      </FileProgressContainer>
      <UploadStatus>
        {failed && <StyledIconRefresh onClick={onRefresh} />}
        {uploadProgress < 100 ? (
          uploading && !failed && <StyledLoader />
        ) : (
          <StyledIconCheck />
        )}
      </UploadStatus>
    </FileContainer>
  );
};
