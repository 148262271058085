import { Typography } from 'antd';
import {
  IconArrowNext,
  IconArrowPrevious,
  IconBack,
  IconDelete,
  IconEditMode,
  IconFile,
  IconFolder,
  IconMarkToDelete,
  IconZoomIn,
  IconZoomOut,
} from 'components/Icons';
import { PrimaryIconButton } from 'components/PrimaryIconButton';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import { SecondaryButton } from 'components/SecondaryButton';
import styled from 'styled-components';

interface EditModeProps {
  $editMode: boolean;
}

interface IconProps {
  $active: boolean;
}

export const Container = styled.div<EditModeProps>`
  z-index: 1005;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme, $editMode }) =>
    $editMode ? theme.colors.darkPurple : theme.colors.white};
  box-shadow: ${({ $editMode }) =>
    $editMode ? 0 : '0 4px 8px 0 rgba(217, 222, 234, 0.5)'};
`;

export const NameContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.padding.normal};
`;

export const StyledIconFile = styled(IconFile)`
  width: 15px;
`;

export const StyledIconFolder = styled(IconFolder)`
  width: 15px;
`;

export const StyledIconEditMode = styled(IconEditMode)`
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledName = styled(Typography)<EditModeProps>`
  font-weight: bold;
  max-width: 18vw;
  font-size: ${({ theme }) => theme.fontSize.normal};
  padding-right: 8px;
  color: ${({ theme, $editMode }) =>
    $editMode ? theme.colors.white : theme.colors.darkGray};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  cursor: text;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledIconBack = styled(IconBack)<EditModeProps>`
  cursor: pointer;
  width: 24px;
  color: ${({ theme, $editMode }) =>
    $editMode ? theme.colors.white : theme.colors.blackPurple};
  margin-left: ${({ theme }) => theme.padding.medium};
`;

export const StyledDocumentType = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lilac};
  margin-right: ${({ theme }) => theme.padding.small};
`;

export const StyledEditor = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 14px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledEditorContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.padding.small};
`;

export const BackContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ActionContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export const StyledSecondaryAltButton = styled(SecondaryAltButton)`
  margin-right: 8px;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 8px;
`;

export const StyledPrimaryIconButton = styled(PrimaryIconButton)`
  margin-right: 8px;
`;

export const PageAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
`;

export const StyledIconNext = styled(IconArrowNext)<IconProps>`
  width: 20px;
  height: 20px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.darkPurple : theme.colors.gray300};
`;

export const PageNumberContainer = styled.div<EditModeProps>`
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-left: ${({ theme, $editMode }) =>
    `1px solid ${$editMode ? theme.colors.blackPurple : theme.colors.line}`};
  margin-left: 16px;
`;

export const UsersContainer = styled.div<EditModeProps>`
  height: 100%;
  display: flex;
  padding-left: 16px;
  border-right: ${({ theme, $editMode }) =>
    `1px solid ${$editMode ? theme.colors.blackPurple : theme.colors.line}`};
  margin-right: 16px;
`;

export const StyledIconPrevious = styled(IconArrowPrevious)<IconProps>`
  width: 20px;
  height: 20px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.darkPurple : theme.colors.gray300};
`;

export const StyledIconDelete = styled(IconDelete)`
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledZoomIn = styled(IconZoomIn)<IconProps>`
  width: 20px;
  height: 20px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.darkPurple : theme.colors.gray300};
`;

export const StyledZoomOut = styled(IconZoomOut)<IconProps>`
  width: 20px;
  height: 20px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.darkPurple : theme.colors.gray300};
`;

export const CurrentPage = styled(Typography)`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  border-radius: 4px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  color: ${({ theme }) => theme.colors.darkPurple};
  margin-right: 8px;
  margin-left: 8px;
  user-select: none;
`;

export const StyledPages = styled(Typography)<EditModeProps>`
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme, $editMode }) =>
    $editMode ? theme.colors.white : theme.colors.darkPurple};
  margin-left: 8px;
  user-select: none;
`;

export const StyledIconMarkToDelete = styled(IconMarkToDelete)<IconProps>`
  color: ${({ theme, $active }) =>
    $active ? theme.colors.warningYellow : theme.colors.blackPurple};
  width: 30px;
`;
