import { FC, memo, useEffect, useRef } from 'react';
import { areEqual, ListChildComponentProps, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoizeOne from 'memoize-one';
import {
  isDataObjectFullyVisible,
  isPagePartiallyVisible,
  boundingBoxByDataObjectId,
} from 'utils/helpers';
import { Page } from '../Page';
import { PageListProps } from './PageList.interface';
import { RowContainer } from './PageList.styles';
import { pageDataObjectId } from 'components/FieldArrow';
import { usePageListStore } from './PageList.hooks';

const RowItem = memo(({ data, index, style, isScrolling }: ListChildComponentProps) => {
  const { flattenedData } = data;
  const page = flattenedData[index];

  return (
    <RowContainer
      style={{
        ...style,
      }}
    >
      <Page
        documentId={page.documentId}
        isScrolling={isScrolling}
        key={page.id}
        resultId={page.resultId}
        page={page}
      />
    </RowContainer>
  );
}, areEqual);

const getItemData = memoizeOne((flattenedData) => ({
  flattenedData,
}));

export const PageList: FC<PageListProps> = ({ data }) => {
  const {
    setCurrentPage,
    currentPageId,
    fieldArrow,
    pageArrow,
    editField,
    setEditField,
    paneDragging,
  } = usePageListStore();
  const preventScroll = useRef(false);
  const resizeList = useRef<VariableSizeList>(null);
  const itemData = getItemData(data);

  useEffect(() => {
    if (fieldArrow?.id) {
      for (let i = 0; i < data.length; i++) {
        const boundingBox = boundingBoxByDataObjectId(data[i], fieldArrow.id);

        if (
          boundingBox &&
          !isDataObjectFullyVisible(pageDataObjectId(fieldArrow?.id), fieldArrow)
        ) {
          preventScroll.current = true;

          if (boundingBox && boundingBox.y > 0.5) {
            resizeList.current?.scrollToItem(i, 'end');
          } else {
            resizeList.current?.scrollToItem(i, 'start');
          }

          setCurrentPage(data[i].id);

          //Prevent from auto scrolling list
          setTimeout(() => {
            preventScroll.current = false;
          }, 200);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldArrow]);

  useEffect(() => {
    if (pageArrow?.pageId) {
      for (let i = 0; i < data.length; i++) {
        if (
          pageArrow?.pageId === data[i].id &&
          !isPagePartiallyVisible(pageArrow.pageId)
        ) {
          preventScroll.current = true;

          resizeList.current?.scrollToItem(i, 'start');

          setCurrentPage(data[i].id);

          //Prevent from auto scrolling list
          setTimeout(() => {
            preventScroll.current = false;
          }, 200);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, pageArrow]);

  useEffect(() => {
    if (!preventScroll.current) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].id === currentPageId) {
          preventScroll.current = true;
          resizeList.current?.scrollToItem(i, 'start');

          //Prevent from auto scrolling list
          setTimeout(() => {
            preventScroll.current = false;
          }, 200);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId]);

  return (
    <AutoSizer
      onResize={() => {
        resizeList.current?.resetAfterIndex(0);
      }}
    >
      {({ height, width }) => {
        return (
          <VariableSizeList
            ref={resizeList}
            height={height}
            overscanCount={1}
            itemCount={data.length}
            onScroll={() => {
              if (editField) {
                setEditField(undefined);
              }
            }}
            onItemsRendered={({ visibleStartIndex }) => {
              if (!preventScroll.current && !paneDragging) {
                preventScroll.current = true;

                setCurrentPage(data[visibleStartIndex].id);

                setTimeout(() => {
                  preventScroll.current = false;
                }, 200);
              }
            }}
            itemSize={(index) => {
              const page = data[index];
              const ratio = page.image.height / page.image.width;

              return (width - 34) * ratio + 24;
            }}
            width={width}
            itemKey={(index) => data[index].id}
            itemData={itemData}
            useIsScrolling
          >
            {RowItem}
          </VariableSizeList>
        );
      }}
    </AutoSizer>
  );
};
