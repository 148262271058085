import { Typography } from 'antd';
import { AnnotationProcessStatus } from 'api';
import styled from 'styled-components';
import { TyroTheme } from 'styles';

interface StyledTypographyProps {
  $status?: AnnotationProcessStatus;
}

const statusBackgroundColor = (theme: TyroTheme, status?: AnnotationProcessStatus) => {
  switch (status) {
    case 'Corrected':
      return theme.colors.warningYellow;
    case 'Needs Attention':
      return theme.colors.darkPink;
    case 'Accepted':
      return theme.colors.accepted;
    default:
      return theme.colors.gray;
  }
};

export const StyledTypography = styled(Typography)<StyledTypographyProps>`
  display: inline-block;
  background-color: ${({ theme, $status }) => statusBackgroundColor(theme, $status)};
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  user-select: none;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  line-height: 18px;
`;
