import { Extraction, ExtractionField } from 'api';
import { omit } from 'lodash';
import { ConfigurationStore } from 'store';
import { BaseDispatch, StoreProps } from 'store/store.interface';

interface ExtractionFieldProps {
  extractionId: string;
  field: ExtractionField;
}

interface ExtractionCollapseProps {
  collapsed: boolean;
}

interface ExtractionCollapseDispatch extends BaseDispatch {
  payload: ExtractionCollapseProps;
}

interface ExtractionsDispatch extends BaseDispatch {
  payload: Array<Extraction>;
}

interface ExtractionFieldDispatch extends BaseDispatch {
  payload: ExtractionFieldProps;
}

export const SET_EXTRACTIONS = 'SET_EXTRACTIONS';
export const SET_EDIT_EXTRACTION = 'SET_EDIT_EXTRACTION';
export const ADD_EXTRACTION_FIELD = 'ADD_EXTRACTION_FIELD';
export const DELETE_EXTRACTION_FIELD = 'DELETE_EXTRACTION_FIELD';
export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE';

export const selectExtractions = (state: StoreProps) => state.configuration.extractions;

export const selectExtractionById = (state: StoreProps, id: string) =>
  state.configuration.extractions[id];

export const selectExtractionFields = (state: StoreProps, id: string) =>
  state.configuration.extractionsFields[id];

export const selectExtractionField = (state: StoreProps, id: string, fieldId: string) =>
  state.configuration.extractionsFields[id][fieldId];

export const selectEditField = (state: StoreProps, fieldId: string) =>
  state.configuration.edited[fieldId];

export const selectEditExtractionFields = (state: StoreProps) =>
  state.configuration.edited;

export const selectCollapsedExtractionFields = (state: StoreProps) =>
  state.configuration.collapsed;

export const updateExtractionsState = (
  state: ConfigurationStore,
  data: Array<Extraction>,
) => {
  return { ...state, ...data };
};

export const updateEditExtractionFieldState = (
  state: ConfigurationStore,
  data: ExtractionFieldProps,
) => {
  return { ...state, edited: { ...state.edited, [data.field.id]: data.field } };
};

export const toggleAllExtracionFields = (
  state: ConfigurationStore,
  data: ConfigurationStore,
) => {
  return { ...state, collapsed: data.collapsed };
};

export const updateAddExtractionFieldState = (
  state: ConfigurationStore,
  data: ExtractionFieldProps,
) => {
  return {
    ...state,
    extractionsFields: {
      ...state.extractionsFields,
      [data.extractionId]: {
        ...state.extractionsFields[data.extractionId],
        [data.field.id]: { ...data.field },
      },
    },
  };
};

export const updateDeleteExtractionFieldState = (
  state: ConfigurationStore,
  data: ExtractionFieldProps,
) => {
  return {
    ...state,
    edited: {
      ...omit(state.edited, [data.field.id]),
    },
    extractionsFields: {
      ...state.extractionsFields,
      [data.extractionId]: {
        ...omit(state.extractionsFields[data.extractionId], [data.field.id]),
      },
    },
  };
};

export const toggle = (data: ExtractionFieldProps) => (
  dispatch: (data: ExtractionFieldDispatch) => void,
) => {
  dispatch({
    type: TOGGLE_COLLAPSE,
    payload: data,
  });
};

export const updateExtractions = (data: Array<Extraction>) => (
  dispatch: (data: ExtractionsDispatch) => void,
) => {
  dispatch({
    type: SET_EXTRACTIONS,
    payload: data,
  });
};

export const updateEditExtractionField = (data: ExtractionFieldProps) => (
  dispatch: (data: ExtractionFieldDispatch) => void,
) => {
  dispatch({
    type: SET_EDIT_EXTRACTION,
    payload: data,
  });
};

export const updateAddExtractionField = (data: ExtractionFieldProps) => (
  dispatch: (data: ExtractionFieldDispatch) => void,
) => {
  dispatch({
    type: ADD_EXTRACTION_FIELD,
    payload: data,
  });
};

export const toggleAllExtracion = (data: ExtractionCollapseProps) => (
  dispatch: (data: ExtractionCollapseDispatch) => void,
) => {
  dispatch({
    type: TOGGLE_COLLAPSE,
    payload: data,
  });
};

export const updateDeleteExtractionField = (data: ExtractionFieldProps) => (
  dispatch: (data: ExtractionFieldDispatch) => void,
) => {
  dispatch({
    type: DELETE_EXTRACTION_FIELD,
    payload: data,
  });
};
