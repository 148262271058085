import { Queues } from 'pages/Queues';
import { Supervision } from 'pages/Supervision';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Login } from 'pages/Login';
import { Splash } from 'pages/Splash';
import { PrivateRoute } from './PrivateRoute';
import { NavigationHeader } from './NavigationHeader';
import { ENV_VARIABLE } from 'variables';
import { useSelector } from 'react-redux';
import { selectAuthenticated, selectInitialized } from 'store/reducers/common';
import { Users } from 'pages/Users';
import { useUserScope } from 'hooks';

export const Router = () => {
  const initialized = useSelector(selectInitialized);
  const authenticated = useSelector(selectAuthenticated);
  const { isAllowed } = useUserScope('USERS_READ');

  return !ENV_VARIABLE.KEY_CLOAK_URL || initialized ? (
    <BrowserRouter>
      {(authenticated || !ENV_VARIABLE.KEY_CLOAK_URL) && <NavigationHeader />}
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute exact path="/documents" component={Queues} />
        <PrivateRoute exact path="/supervision/:id" component={Supervision} />
        {isAllowed && <PrivateRoute exact path="/users" component={Users} />}
        <Redirect to="/documents" />
      </Switch>
    </BrowserRouter>
  ) : (
    <Splash />
  );
};
