import { api } from 'api';
import { Dictionary, some, uniqBy, values } from 'lodash';
import { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';

interface FileProgress {
  progress: number;
  uploading: boolean;
  failed?: boolean;
}

export const useUploadFiles = () => {
  const [files, setFiles] = useState<Array<FileWithPath>>([]);
  const [filesProgress, setFilesProgress] = useState<Dictionary<FileProgress>>({});
  const [uploadingFiles, setUploadingFiles] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      uploadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    setUploadingFiles(
      values(filesProgress).filter((item) => item.progress !== 100).length > 0,
    );
  }, [filesProgress]);

  const addFiles = (newFiles: Array<FileWithPath>) => {
    const mappedFiles: Dictionary<FileProgress> = {};
    const filterFiles = newFiles.filter(
      (item) => !some(files, (file) => file.path === item.path),
    );

    filterFiles.forEach((file) => {
      mappedFiles[file.name] = { uploading: false, progress: 0 };
    });

    setFilesProgress((prev) => ({ ...prev, ...mappedFiles }));
    setFiles((prev) => uniqBy([...prev, ...filterFiles], 'path'));
  };

  const clearData = () => {
    setFilesProgress({});
    setFiles([]);
  };

  const updateFileProgress = (name: string, progress: number, failed = false) => {
    setFilesProgress((previous) => {
      return { ...previous, [name]: { ...previous[name], progress, failed } };
    });
  };

  const updateUploading = (name: string) => {
    setFilesProgress((previous) => {
      return {
        ...previous,
        [name]: { progress: 0, uploading: true, failed: false },
      };
    });
  };

  const createCollectionForFile = (fileName: string) => {
    return api.createCollection({
      fileNames: [fileName],
      collectionName: fileName,
      split: false,
      highPriority: false,
    });
  };

  const uploadFile = (
    collectionId: string,
    fileId: string,
    file: File,
    onUploadProgress: (event: ProgressEvent) => void,
  ) => {
    const data = new FormData();
    data.append('fileUpload', file);
    data.append('fileId', fileId);
    data.append('collectionId', collectionId);

    return api.uploadFile(data, onUploadProgress);
  };

  const uploadFiles = async () => {
    for (const file of files) {
      if (filesProgress[file.name].progress === 0) {
        updateUploading(file.name);

        try {
          const { collectionId, files } = await createCollectionForFile(file.name);

          await uploadFile(collectionId, files[0].fileId, file, (event) => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            updateFileProgress(file.name, percentCompleted);
          });
        } catch (e) {
          updateFileProgress(file.name, 0, true);
        }
      }
    }
  };

  const refreshFile = async (name: string) => {
    updateUploading(name);

    const file = files.find((item) => item.name === name);

    if (file) {
      try {
        const { collectionId, files } = await createCollectionForFile(name);

        await uploadFile(collectionId, files[0].fileId, file, (event) => {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          updateFileProgress(name, percentCompleted);
        });
      } catch (e) {
        updateFileProgress(name, 0, true);
      }
    }
  };

  return {
    addFiles,
    files,
    clearData,
    refreshFile,
    uploadFiles,
    filesProgress,
    uploadingFiles,
  };
};
