import { FC, useState } from 'react';
import { Button } from 'components/Button';
import Modal from 'react-modal';
import { ExportModalProps } from './ExportModal.interface';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  StyledTitle,
  StyledSelect,
  StyledCancel,
  Container,
  Divider,
} from './ExportModal.styles';

const options = () => {
  return [
    { value: 'csv', label: 'CSV' },
    { value: 'xlsx', label: 'XLSX' },
  ];
};

export const ExportModal: FC<ExportModalProps> = ({ open, onConfirm, onClose }) => {
  const { t } = useTranslation();
  const [selectedFormat, setSelectedFormat] = useState<string | undefined>('csv');

  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'modal-base-narrow',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <StyledTitle size="h3">{t('exportModalTitle')}</StyledTitle>
        <StyledSelect
          size="small"
          value={selectedFormat}
          onChange={setSelectedFormat}
          options={options()}
        />
        <Divider />
        <ButtonContainer>
          <StyledCancel
            onClick={onClose}
            color="light"
            size="large"
            title={t('cancel')}
          />

          <Button
            size="large"
            onClick={() => {
              if (selectedFormat) {
                onConfirm(selectedFormat);
              }
            }}
            disabled={!selectedFormat}
            title={t('export')}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
