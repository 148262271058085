import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface SetImageProps {
  url: string | undefined;
  blobUrl: string;
}

interface SetImageDispatch extends BaseDispatch {
  payload: SetImageProps;
}

export const SET_IMAGE = 'SET_IMAGE';

export const selectAllImages = (state: StoreProps) => state.supervision.images;

export const selectImageBlob = (state: StoreProps, imageUrl: string | undefined) =>
  imageUrl && state.supervision.images[imageUrl];

export const setImageState = (state: SupervisionStore, image: SetImageProps) => {
  if (!image.url) return state;
  return { ...state, images: { ...state.images, [image.url]: image.blobUrl } };
};

export const setImage = (data: SetImageProps) => (
  dispatch: (data: SetImageDispatch) => void,
) => {
  dispatch({
    type: SET_IMAGE,
    payload: data,
  });
};
