import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

export interface ZoomProps {
  scale: number;
  isButtonAction?: boolean;
}

interface ZoomDispatch extends BaseDispatch {
  payload: ZoomProps;
}

export const SET_ZOOM = 'SET_ZOOM';

export const selectZoom = (state: StoreProps) => state.supervision.zoom;

export const updateZoomState = (state: SupervisionStore, zoom: ZoomProps) => {
  return { ...state, zoom };
};

export const updateZoom = (data: ZoomProps) => (
  dispatch: (data: ZoomDispatch) => void,
) => {
  dispatch({
    type: SET_ZOOM,
    payload: data,
  });
};
