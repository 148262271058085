import { Typography } from 'antd';
import styled from 'styled-components';
import { TyroTheme } from 'styles';

interface ProgressProps {
  $progress: number;
}

// TODO: update colors based on progress

const backgroundColor = (theme: TyroTheme, progress: number) => {
  if (progress < 40) {
    return theme.colors.gray600;
  }

  if (progress < 80) {
    return theme.colors.lilac;
  }

  return theme.colors.lightGreen;
};

const textColor = (theme: TyroTheme, progress: number) => {
  return progress < 100 ? theme.colors.gray200 : theme.colors.successGreen;
};

export const Container = styled.div`
  position: relative;
  width: 50%;
`;

export const Background = styled.div<ProgressProps>`
  width: ${({ $progress }) => ($progress > 0 ? `${$progress}%` : '2%')};
  background-color: ${({ theme, $progress }) => backgroundColor(theme, $progress)};
  border-radius: 4px;
  user-select: none;
`;

export const StyledTypography = styled(Typography)<ProgressProps>`
  position: absolute;
  user-select: none;
  top: 50%;
  transform: translate(0, -50%);
  color: ${({ theme, $progress }) => textColor(theme, $progress)};
  right: 5px;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
`;

export const EmptyTypography = styled(Typography)`
  user-select: none;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
`;
