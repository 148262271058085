import { collectionReducer } from './reducers/collection';
import { supervisionReducer, tableAnnotationEditModeSlice } from './reducers/supervision';
import { commonReducer } from './reducers/common';
import { usersReducer } from './reducers/users';
import { configurationReducer } from './reducers/configuration';
import { configureStore } from '@reduxjs/toolkit';
import modalsSlice from './reducers/modals/modalsSlice';
import { queuesSlice } from './reducers/queues';

export const store = configureStore({
  reducer: {
    //@ts-ignore
    supervision: supervisionReducer,
    //@ts-ignore
    collection: collectionReducer,
    modals: modalsSlice,
    queues: queuesSlice.reducer,
    tableAnnotationEditMode: tableAnnotationEditModeSlice.reducer,
    //@ts-ignore
    common: commonReducer,
    //@ts-ignore
    users: usersReducer,
    //@ts-ignore
    configuration: configurationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
