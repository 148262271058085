import { Header } from 'components/Header';
import { useLanguage } from 'hooks';
import { useLocation } from 'react-router-dom';

export const NavigationHeader = () => {
  const location = useLocation();
  useLanguage();
  const isSupervision = location.pathname.includes('supervision');

  return !isSupervision ? <Header /> : null;
};
