import { Button } from 'components/Button';
import { Headline } from 'components/Headline';
import styled from 'styled-components';

export const StyledTitle = styled(Headline)`
  margin-left: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
  margin-bottom: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledSubTitle = styled.span`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  margin-left: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
  margin-bottom: ${({ theme }) => theme.padding.xmedium};
  max-height: 30vh;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.padding.xmedium};
  margin-bottom: ${({ theme }) => theme.padding.xtiny};
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
  display: flex;
  height: 50px;
  justify-content: flex-end;
`;

export const Divider = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.line}`};
  width: 100%;
`;

export const StyledCancel = styled(Button)`
  margin-right: ${({ theme }) => theme.padding.tiny};
`;
