import { IconMaximize, IconMinimize } from 'components/Icons';
import styled from 'styled-components';

export const StyledIconButton = styled.div`
  background-color: ${({ theme }) => theme.colors.lilac};
  border-radius: 6px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.lilacDark};
  }
`;

export const StyledExpandIcon = styled(IconMaximize)`
  width: 20px;
`;

export const StyledCollapseIcon = styled(IconMinimize)`
  width: 20px;
`;
