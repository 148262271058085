import { Typography } from 'antd';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import styled from 'styled-components';

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 24px;
`;

export const Container = styled.div`
  padding-bottom: 32px;
`;

export const Content = styled.div`
  overflow-y: scroll;
  height: 85vh;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
`;

export const StyledIconButton = styled(SecondaryAltButton)`
  margin-left: 8px;
`;
