import { fieldsEN, fieldsPL } from './fields';

export const translationsEN = {
  en: {
    translation: {
      documentSplitting: 'Document splitting',
      queues: 'Queues',
      reporting: 'Reporting',
      upload: 'Upload',
      files: 'Files',
      folders: 'Folders',
      status: 'Status',
      priority: 'Priority',
      all: 'All',
      waitingForFiles: 'Waiting for files',
      queued: 'Queued',
      inProgress: 'In progress',
      stopped: 'Stopped',
      needsAttention: 'Needs attention',
      completed: 'Completed',
      failed: 'Failed',
      notFlagged: 'Not flagged',
      flagged: 'Flagged',
      search: 'Search',
      date: 'Date',
      lastMonth: 'LAST MONTH',
      last7Days: 'LAST 7 DAYS',
      today: 'TODAY',
      startDate: 'Start date',
      endDate: 'End date',
      pagePagination: ' / page',
      loading: 'Loading...',
      emptyData: 'No data to display.',
      name: 'Name',
      progress: 'Progress',
      uploadDate: 'Date of upload',
      processingDate: 'Date of processing',
      those: 'those',
      this: 'this',
      deleteFiles: 'files',
      deleteFile: 'file',
      deleteQuestion: 'Are you sure you want to delete',
      deleteExtractorFieldQuestion: 'Are you sure you want to delete this field?',
      deleteExtractorFieldInfo: 'You will lose all data for this field.',
      deleteInfo: 'You will lose all your processed data for',
      deleteInfoSingleFile: 'You will lose all your processed data for this document.',
      deleteUserInfo: 'You will lose all data for this user.',
      userDeleted: 'User deleted.',
      deleteDocuments: 'documents',
      deleteDocument: 'document',
      cancel: 'Cancel',
      confirm: 'Confirm',
      unflag: 'Unflag',
      flag: 'Flag',
      viewResults: 'View results',
      markToDelete: 'Mark to delete',
      unmarkToDelete: 'Unmark from delete',
      delete: 'Delete',
      stop: 'Stop',
      start: 'Start',
      processingProgress: 'Processing progress',
      size: 'Size',
      numberOfDocuments: 'No. of documents',
      category: 'Category',
      documents: 'Documents',
      documentInfo: 'Document Info',
      fileName: 'File name',
      pages: 'Pages',
      resultOfProcessing: 'RESULT OF PROCESSING',
      of: 'of',
      acceptNext: 'Accept & next',
      page: 'Page',
      uncertain: 'Uncertain',
      missing: 'Missing',
      invalid: 'Invalid',
      missingField: 'Missing field',
      blankField: 'Blank field',
      cannotRead: 'Cannot read',
      field: 'Field',
      value: 'Value',
      edit: 'Edit',
      edited: 'Edited',
      acceptWithoutEditing: 'Accept without editing',
      acceptGroupWithoutEditing: 'Accept without editing',
      extractedFields: 'Extracted Fields',
      save: 'Save',
      fieldName: 'Field name',
      overview: 'Overview',
      extractions: 'EXTRACTIONS',
      splitAndClassify: 'SPLIT & CLASSIFY',
      firstIssue: 'Start with first issue',
      welcomeBack: 'Welcome back!',
      welcomeBackNote: 'Check out what’s going on in your tasks.',
      finishEditing: 'Finish editing',
      fixIssues: 'Fix issues',
      fixIssuesEdit: 'You are now editing all extractions.',
      corrected: 'Corrected',
      deleteFieldQuestion: 'Are you sure you want to delete this field?',
      deleteFieldInfo: "All it's data will be removed permanently.",
      tasksOverview: 'Tasks overview',
      interventionType: 'Intervention type',
      split: 'Splitting',
      extract: 'Extraction',
      classify: 'Classifcation',
      startTask: 'Start',
      interventions: 'Interventions',
      interventionsType: 'Interventions type',
      completedTasks: 'Accepted tasks',
      lastModified: 'Last modified',
      close: 'Close',
      chooseFromComputer: 'Choose from computer',
      or: 'or',
      dragAndDropFiles: 'Drag & drop your files here...',
      dragAndDropFolders: 'Drag & drop your folders here...',
      uploadedFiles: 'UPLOADED FILES',
      uploadYourFiles: 'Upload your files',
      uploadYourFilesInfo: 'Upload files you want to process',
      uploadedFolders: 'UPLOADED FOLDERS',
      uploadYourFolders: 'Upload your folders',
      uploadYourFoldersInfo: 'Upload folders you want to process',
      clipboardInfo: 'Copied to clipboard.',
      accepted: 'Accepted',
      acceptedStatus: 'Accepted',
      accept: 'Accept',
      addNewField: 'Add new field',
      addNewFieldInfo: 'Define type and value of new field.',
      add: 'Add',
      settings: 'Settings',
      yourAccount: 'Your account',
      logOut: 'Logout',
      language: 'Languages',
      selectLanguage: 'Select language',
      createField: 'Create field',
      manual: 'Manual',
      create: 'Create',
      export: 'Export',
      exportModalTitle: 'Choose format to export',
      inviteUserTitle: 'INVITE USER',
      editUserTitle: 'EDIT USER',
      exportSingleFile: 'Single file for each collection',
      sortingFiltering: 'SORTING & FILTERING',
      sortFieldsBy: 'Sort fields by',
      filterFieldsByType: 'Filter by field type',
      filterFieldsByTypePlaceholder: 'Select field types',
      filterByPhrase: 'Filter by phrase',
      removeDuplicatedFields: 'Remove duplicated fields',
      showFieldsWith: 'Show rows with status',
      apply: 'Apply',
      clearAll: 'Clear All',
      byPosition: 'By position',
      alphabetically: 'Alphabetically',
      user: 'User',
      collections: 'collections',
      totalPages: 'Pages',
      totalFiles: 'Files',
      numberOfPages: 'Number of pages',
      preparingFile: 'Preparing {{format}} file...',
      preparingFiles: 'Preparing {{format}} files...',
      fileDownload: 'File is downloaded.',
      filePreparingError: 'Error while preparing {{format}} file.',
      filesPreparingError: 'Error while preparing {{format}} files.',
      inviteUser: 'Invite user',
      users: 'Users',
      created: 'Created',
      editUser: 'Edit user',
      deleteUser: 'Delete user',
      role: 'Role',
      viewer: 'Viewer',
      editor: 'Editor',
      active: 'Active',
      invited: 'Invited',
      inactive: 'Inactive',
      disabled: 'Disabled',
      invite: 'Invite',
      emailAddress: 'E-mail address',
      invitationSend: 'User invitation send.',
      readNotAllowed: "You don't have permission to view result.",
      oneTagError: 'Add at least one tag.',
      onlyOneTagError: 'Add only one tag.',
      collectionNotFound: 'Collection not found.',
      collectionFailed: 'Collection process failed.',
      versionAIA: 'AIA version:',
      versionAPI: 'Rest API version:',
      build: 'Build number: ',
      viewDetails: 'View details',
      updateNeedsAttentionFields: 'Correct all needs attention fields.',
      extractionsSetup: 'Extractions setup',
      labelName: 'Label name',
      extractionType: 'Extraction Type',
      addExtractor: 'Add extractor',
      addExtractionField: 'Add extraction field',
      extractorType: 'Extractor type',
      documentType: 'Document Type',
      addDocument: 'Add new document',
      predefinedField: 'Predefined field',
      listOfValues: 'List of values',
      pattern: 'Patterns',
      threshold: 'Threshold',
      extractorFieldError: 'Error while saving extractor field.',
      addPattern: 'Add pattern',
      newLine: 'New line',
      comma: 'Comma',
      colon: 'Colon',
      dot: 'Dot',
      space: 'Space',
      separators: 'Separators',
      mandatory: 'Mandatory',
      matchGroup: 'Match groups',
      ignoreCase: 'Ignore case',
      selectTags: 'Select tags',
      supervisionSettings: 'Supervision settings',
      defaultEditMode: 'Open supervision with edit mode',
      defaultUncertain: 'Filter uncertain fields in edit mode',
      defaultSorting: 'Sort fields by',
      version: 'Version',
      table: 'Table',
      selection: 'Selection',
      true: 'True',
      false: 'False',
      emptyDocumentName: 'Add document name...',
      confirmAcceptTitle: 'Are you sure you want to accept this collection?',
      confirmAcceptDescription: "This action can't be reverted.",
      confirmAction: 'Confirm',
      ...fieldsEN,
    },
  },
};

export const translationsPL = {
  pl: {
    translation: {
      documentSplitting: 'Podział na dokumenty',
      queues: 'Kolejki',
      reporting: 'Raporty',
      upload: 'Prześlij',
      files: 'Plik',
      folders: 'Folder',
      status: 'Status',
      priority: 'Priorytet',
      all: 'Wszystko',
      waitingForFiles: 'Oczekuje na pliki',
      queued: 'W kolejce',
      inProgress: 'Procesowane',
      stopped: 'Zatrzymane',
      needsAttention: 'Wymaga uwagi',
      completed: 'Zakończone',
      failed: 'Nieudane',
      notFlagged: 'Normalny',
      flagged: 'Priorytet',
      search: 'Szukaj',
      date: 'Data',
      lastMonth: 'OSTATNI MIESIĄC',
      last7Days: 'OSTATNIE 7 DNI',
      today: 'DZISIAJ',
      startDate: 'Od',
      endDate: 'Do',
      pagePagination: ' / strona',
      loading: 'Ładowanie...',
      emptyData: 'Brak danych.',
      name: 'Kolekcja',
      progress: 'Postęp',
      uploadDate: 'Data dodania',
      processingDate: 'Data przetworzenia',
      processingProgress: 'Postęp przetwarzania',
      deleteFiles: 'pliki',
      deleteFile: 'plik',
      deleteQuestion: 'Czy napewno chcesz usunąć',
      deleteInfo: 'Stracisz wszystkie dane dla',
      deleteInfoSingleFile: 'Stracisz wszystkie dane dla tego dokumentu.',
      deleteUserInfo: 'Stracisz wszystkie dane tego użytkownika.',
      userDeleted: 'Użytkownik usunięty.',
      those: 'tych',
      this: 'tego',
      deleteDocuments: 'dokumentów',
      deleteDocument: 'dokumentu',
      cancel: 'Anuluj',
      confirm: 'Usuń',
      unflag: 'Zwiększ priorytet',
      flag: 'Ustaw priorytet',
      viewResults: 'Zobacz wynik',
      markToDelete: 'Oznacz do usunięcia',
      unmarkToDelete: 'Usuń oznaczenie',
      delete: 'Usuń',
      stop: 'Zatrzymaj',
      start: 'Start',
      size: 'Rozmiar',
      numberOfDocuments: 'Ilość dokumentów',
      category: 'Kategoria',
      documents: 'Dokumenty',
      documentInfo: 'Informacje o dokumencie',
      fileName: 'Plik',
      pages: 'Ilość stron',
      resultOfProcessing: 'WYNIK ANALIZY',
      of: 'z',
      acceptNext: 'Zaakceptuj i przejdź',
      page: 'Strona',
      uncertain: 'Niepewne',
      missing: 'Brak',
      invalid: 'Niepoprawne',
      missingField: 'Brakujące pole',
      blankField: 'Puste pole',
      cannotRead: 'Błąd odczytu',
      field: 'Pole',
      value: 'Wartość',
      edit: 'Edytuj',
      edited: 'Edytowany',
      acceptWithoutEditing: 'Zatwierdź pole',
      acceptGroupWithoutEditing: 'Zatwierdź grupę',
      extractedFields: 'Znalezione pola',
      save: 'Zapisz',
      fieldName: 'Typ pola',
      overview: 'Zadania',
      extractions: 'EKSTRAKCJA',
      splitAndClassify: 'KLASYFIKACJA',
      firstIssue: 'Przejdź do pierwszego zadania',
      welcomeBack: 'Witaj ponownie!',
      welcomeBackNote: 'Sprawdź swoje nowe zadania.',
      finishEditing: 'Zakończ edycje',
      fixIssues: 'Napraw błędy',
      fixIssuesEdit: 'Tryb edycji ekstrakcji danych.',
      corrected: 'Poprawione',
      deleteFieldQuestion: 'Czy napewno chcesz usunąć to pole?',
      deleteFieldInfo: 'Pole zostanie usunięte całkowicie.',
      tasksOverview: 'Nowe zadania',
      interventionType: 'Typ interwencji',
      interventions: 'Ilość interwencji',
      split: 'Podział',
      extract: 'Ekstrakcja',
      classify: 'Klasyfikacja',
      startTask: 'Rozpocznij',
      interventionsType: 'Typy interwencji',
      completedTasks: 'Zaakceptowane zadania',
      lastModified: 'Ostatnia modyfikacja',
      close: 'Zamknij',
      chooseFromComputer: 'Wybierz z komputera',
      or: 'lub',
      dragAndDropFiles: 'Przeciągnij i upuść pliki tutaj...',
      dragAndDropFolders: 'Przeciągnij i upuść foldery tutaj...',
      uploadedFiles: 'DODANE PLIKI',
      uploadYourFiles: 'Dodaj pliki',
      uploadYourFilesInfo: 'Dodaj pliki do procesowania',
      uploadedFolders: 'DODANE FOLDERY',
      uploadYourFolders: 'Dodaj foldery',
      uploadYourFoldersInfo: 'Dodaj foldery do procesowania',
      clipboardInfo: 'Skopiowano do schowka.',
      accepted: 'Zatwierdzone',
      acceptedStatus: 'Zatwierdzony',
      accept: 'Zaakceptuj',
      addNewField: 'Dodaj nowe pole',
      addNewFieldInfo: 'Zdefiniuj typ oraz podaj wartość dla nowego pola.',
      add: 'Dodaj',
      settings: 'Ustawienia',
      yourAccount: 'Twoje konto',
      logOut: 'Wyloguj',
      language: 'Język',
      selectLanguage: 'Wybierz język',
      createField: 'Dodaj pole',
      manual: 'Dodane',
      create: 'Dodaj',
      export: 'Eksportuj',
      exportModalTitle: 'Wybierz format eksportu',
      exportSingleFile: 'Osobny plik dla kolekcji',
      sortingFiltering: 'SORTOWANIE & FILTROWANIE',
      sortFieldsBy: 'Sortuj pola wg.',
      showFieldsWith: 'Pokaż pola ze statusem',
      filterFieldsByType: 'Filtruj pola według typu',
      filterFieldsByTypePlaceholder: 'Wybierz pola',
      filterByPhrase: 'Filtruj po frazie',
      removeDuplicatedFields: 'Usuń zduplikowane pola',
      apply: 'Zatwierdź',
      clearAll: 'Resetuj',
      byPosition: 'Według pozycji',
      alphabetically: 'Alfabetycznie',
      user: 'Użytkownik',
      collections: 'kolekcji',
      totalPages: 'Strony',
      totalFiles: 'Pliki',
      numberOfPages: 'Ilość stron',
      preparingFile: 'Przygotowywanie pliku {{format}}...',
      preparingFiles: 'Przygotowywanie plików {{format}}...',
      fileDownload: 'Plik został pobrany.',
      filePreparingError: 'Błąd podczas przygotowywania pliku.',
      filesPreparingError: 'Błąd podczas przygotowywania plików.',
      inviteUser: 'Dodaj użytkownika',
      users: 'Użytkownicy',
      created: 'Data dodania',
      editUser: 'Edytuj użytkownika',
      deleteUser: 'Usuń użytkownika',
      role: 'Rola',
      viewer: 'Viewer',
      editor: 'Editor',
      active: 'Aktywny',
      invited: 'Zaproszenie wysłane',
      disabled: 'Nieaktywny',
      inviteUserTitle: 'DODAJ UŻYTKOWNIKA',
      editUserTitle: 'EDYTUJ UŻYTKOWNIKA',
      invite: 'Dodaj',
      emailAddress: 'E-mail',
      invitationSend: 'Zaproszenie wysłane.',
      readNotAllowed: 'Nie masz uprawnień do przeglądania wyników.',
      oneTagError: 'Pole obowiązkowe musi zawierać przynajmniej jeden tag.',
      onlyOneTagError: 'Pole obowiązkowe musi zawierać jeden tag.',
      collectionNotFound: 'Kolekcja nie istnieje.',
      collectionFailed: 'Błąd procesowania kolekcji.',
      version: 'Wersja',
      viewDetails: 'Zobacz szczegóły',
      updateNeedsAttentionFields: 'Popraw wszystkie pola wymagające uwagi.',
      extractionsSetup: 'Ustawienia ekstrakcji',
      labelName: 'Nazwa pola',
      addExtractor: 'Dodaj ekstraktor',
      extractorType: 'Typ ekstraktora',
      documentType: 'Typ dokumentu',
      addDocument: 'Nowy dokument',
      addExtractionField: 'Dodaj pole',
      predefinedField: 'Predefiniowane pole',
      listOfValues: 'Lista wartości',
      pattern: 'Wzór',
      threshold: 'Zakres',
      extractorFieldError: 'Błąd podczas zapisu pola.',
      addPattern: 'Dodaj wzór',
      newLine: 'Nowa linia',
      comma: 'Przecinek',
      colon: 'Dwukropek',
      dot: 'Kropka',
      space: 'Spacja',
      separators: 'Separatory',
      mandatory: 'Obowiązkowy',
      matchGroup: 'Dopasuj grupy',
      ignoreCase: 'Ignoruj wielkość liter',
      selectTags: 'Wybierz',
      versionAIA: 'Wersja AIA:',
      versionAPI: 'Wersja API:',
      build: 'Numer kompilacji: ',
      supervisionSettings: 'Ustawienia supervision',
      defaultEditMode: 'Otwórz supervision w trybie edycji',
      defaultUncertain: 'Filtruj niepewne pola w trybie edycji',
      defaultSorting: 'Sortuj pola wg.',
      table: 'Tabela',
      selection: 'Obszar',
      extractionType: 'Typ zaznaczenia',
      true: 'Prawda',
      false: 'Fałsz',
      emptyDocumentName: 'Dodaj nazwę dokumentu...',
      confirmAcceptTitle: 'Czy napewno chcesz zatwierdzić tę kolekcję?',
      confirmAcceptDescription: 'Nie mozna cofnąć tej akcji.',
      confirmAction: 'Potwierdź',
      ...fieldsPL,
    },
  },
};
