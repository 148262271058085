import { InputSearch } from 'components/InputSearch';
import styled from 'styled-components';

interface EditModeProps {
  editMode: boolean;
}

export const Container = styled.div<EditModeProps>`
  width: 100%;
  min-height: calc(100vh - 56px);
  max-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: 1000;
  margin-top: 24px;
  margin-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const AutocompleteContainer = styled.div`
  width: 100%;
  padding-right: 8px;
`;

export const StyledSearch = styled(InputSearch)`
  width: 45%;
`;

export const CollectionsContainer = styled.div`
  height: 89vh;
  width: 100%;
`;
