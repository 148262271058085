import { BaseDispatch, CommonStore, StoreProps } from 'store/store.interface';

export interface InitializedType {
  initialized: boolean;
}

interface InitializedTypeDispatch extends BaseDispatch {
  payload: InitializedType;
}

export const SET_INITIALIZED = 'SET_INITIALIZED';

export const selectInitialized = (state: StoreProps) => state.common.initialized;

export const updateInitializedState = (state: CommonStore, data: boolean) => {
  return { ...state, initialized: data };
};

export const updateInitialized = (data: InitializedType) => (
  dispatch: (data: InitializedTypeDispatch) => void,
) => {
  dispatch({
    type: SET_INITIALIZED,
    payload: data,
  });
};
