import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const SuggestionContainer = styled.ul`
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.blackPurple};
  list-style: none;
  max-height: 150px;
  overflow-y: auto;
  padding: 8px;
  width: calc(300px + 1rem);
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(223, 221, 230, 0.5);
  top: 44px;

  li {
    padding-left: 16px;
    padding-top: 10px;
    height: 40px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 2px;
    text-overflow: ellipsis;
  }

  li:hover,
  .active {
    background-color: ${({ theme }) => theme.colors.gray400};
    border-radius: 6px;
    cursor: pointer;
  }
`;
