import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    lightBlue: '#E1E9F8',
    iconBlue: '#CEDEF7',
    line: '#d9deea',
    lineDark: '#C3CAD9',
    gray: '#69758C',
    green: '#7EAD15',
    lightGreen: '#D6EBA7',
    red: '#E85720',
    redDark: '#D04A17',
    menu: '#E2E4EC',
    menuItem: '#EDEFF2',
    filter: '#979FB6',
    accepted: '#21BBBF',
    progressBg: '#D4D6D8',
    btnDisabledBg: '#F5F5F5',
    iconDisabled: '#DADADA',

    // v2
    transparent: 'transparent',
    content: '#F5F6FA',
    lightYellow: '#FFF7DE',
    white: '#ffffff',
    darkPurple: '#462D8C',
    blackPurple: '#261A59',
    darkGray: '#26334D',
    gray200: '#596075',
    gray300: '#B2AEC1',
    gray400: '#DFDDE6',
    gray500: '#EDEFF2',
    gray600: '#F5F5FB',
    gray700: '#F2F2F2',
    darkPink: '#B61467',
    lilac: '#E8E8FC',
    lilacDark: '#DCDCEF',
    blushPink: '#FFD7EB',
    darkBlushPink: '#EDC3D9',
    successGreen: '#7EAD15',
    warningYellow: '#F6B81A',
    neonPurple: '#1C1CE8',
    notificatioInfo: '#9081BA',
  },
  fontSize: {
    tiny: '0.8em',
    small: '0.85em',
    normal: '1em',
    medium: '1.15em',
    large: '2em',
    headlineh4: '1.15em',
    headlineh3: '1.45em',
    headlineh2: '1.7em',
    headlineh1: '2.15em',
    labelSmall: '0.85em',
    labelRegular: '1em',
  },
  fontFamily: {
    regular: 'PoppinsRegular',
    semiBold: 'PoppinsSemiBold',
  },
  padding: {
    xtiny: '0.25rem',
    tiny: '0.5rem',
    small: '0.75rem',
    normal: '1rem',
    xmedium: '1.25rem',
    medium: '1.5rem',
    large: '1.75rem',
  },
};
