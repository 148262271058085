import {
  ADD_EXTRACTION_FIELD,
  DELETE_EXTRACTION_FIELD,
  SET_EDIT_EXTRACTION,
  SET_EXTRACTIONS,
  toggleAllExtracionFields,
  TOGGLE_COLLAPSE,
  updateAddExtractionFieldState,
  updateDeleteExtractionFieldState,
  updateEditExtractionFieldState,
  updateExtractionsState,
} from '.';

const INITIAL_STATE = {
  extractions: {},
  extractionsFields: {},
  edited: {},
  collapsed: false,
};

export const configurationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case TOGGLE_COLLAPSE:
      return toggleAllExtracionFields(state, action.payload);
    case SET_EXTRACTIONS:
      return updateExtractionsState(state, action.payload);
    case SET_EDIT_EXTRACTION:
      return updateEditExtractionFieldState(state, action.payload);
    case ADD_EXTRACTION_FIELD:
      return updateAddExtractionFieldState(state, action.payload);
    case DELETE_EXTRACTION_FIELD:
      return updateDeleteExtractionFieldState(state, action.payload);
    default:
      return state;
  }
};
