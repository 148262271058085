let _ENV_VARIABLE;

if (process.env.REACT_APP_LOCAL) {
  _ENV_VARIABLE = {
    API_URL: process.env.REACT_APP_API_URL,
    EXPORT: process.env.REACT_APP_EXPORT,
    MAX_FILE_SIZE: process.env.REACT_APP_MAX_FILE_SIZE,
    KEY_CLOAK_CLIENT_ID: process.env.REACT_APP_KEY_CLOAK_CLIENT_ID,
    KEY_CLOAK_REALM: process.env.REACT_APP_KEY_CLOAK_REALM,
    KEY_CLOAK_URL: process.env.REACT_APP_KEY_CLOAK_URL,
    DISABLE_UPLOAD: process.env.REACT_APP_DISABLE_UPLOAD,
    SINGLE_COLLECTION_EDITOR_ENABLED:
      process.env.REACT_APP_SINGLE_COLLECTION_EDITOR_ENABLED,
    CONFIGURATION_ENABLED: process.env.REACT_APP_CONFIGURATION_ENABLED,
    USER_MANAGEMENT_ENABLED: process.env.REACT_APP_USER_MANAGEMENT_ENABLED,
    SHOW_RESULT_ENABLED: process.env.REACT_APP_SHOW_RESULT_ENABLED,
    MARK_TO_DELETE_ENABLED: process.env.REACT_APP_MARK_TO_DELETE_ENABLED,
    DISABLE_BB_SELECTION: process.env.REACT_APP_DISABLE_BB_SELECTION,
    ACCEPT_CONFIRM_ENABLED: process.env.REACT_APP_ACCEPT_CONFIRM_ENABLED,
    ACCEPT_COMPLETED_ENABLED: process.env.REACT_APP_ACCEPT_COMPLETED_ENABLED,
    SPLITTING_ENABLED: process.env.REACT_APP_SPLITTING_ENABLED,
    DATE_VALIDATOR_ENABLED: process.env.REACT_APP_DATE_VALIDATOR_ENABLED,
  };
} else {
  _ENV_VARIABLE = {
    API_URL: 'https://nvtrd101.aia.alphamoon.ai/api/internal',
    EXPORT: 'true',
    MAX_FILE_SIZE: 1000,
    KEY_CLOAK_CLIENT_ID: 'aia-frontend',
    KEY_CLOAK_REALM: 'nvtrd101',
    KEY_CLOAK_URL: 'https://login.aia.alphamoon.ai/',
    DISABLE_UPLOAD: '',
    SINGLE_COLLECTION_EDITOR_ENABLED: '',
    CONFIGURATION_ENABLED: '',
    USER_MANAGEMENT_ENABLED: '',
    SHOW_RESULT_ENABLED: 'true',
    MARK_TO_DELETE_ENABLED: '',
    DISABLE_BB_SELECTION: '',
    ACCEPT_CONFIRM_ENABLED: '',
    ACCEPT_COMPLETED_ENABLED: 'true',
    SPLITTING_ENABLED: '',
    DATE_VALIDATOR_ENABLED: 'false',
  };
}

export const ENV_VARIABLE = _ENV_VARIABLE;
