import { Typography } from 'antd';
import { Button } from 'components/Button';
import { IconDelete, IconUploadFolder } from 'components/Icons';
import styled from 'styled-components';

const { Title } = Typography;

export const CloseIcon = styled(IconDelete)`
  position: absolute;
  top: ${({ theme }) => theme.padding.xmedium};
  right: ${({ theme }) => theme.padding.xmedium};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray300};
`;

export const StyledTitle = styled(Title)`
  align-self: center;
  margin-top: ${({ theme }) => theme.padding.large};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  color: ${({ theme }) => theme.colors.blackPurple};
  user-select: none;
`;

export const StyledDropInfo = styled(Typography)`
  text-align: center;
  line-height: 18px;
  margin-top: ${({ theme }) => theme.padding.tiny};
  margin-bottom: ${({ theme }) => theme.padding.xtiny};
  color: ${({ theme }) => theme.colors.gray300};
  user-select: none;
`;

export const StyledSubTitle = styled(Typography)`
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.blackPurple};
  user-select: none;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.padding.medium};
  margin-bottom: ${({ theme }) => theme.padding.normal};
  margin-left: ${({ theme }) => theme.padding.small};
  margin-right: ${({ theme }) => theme.padding.small};
`;

export const DropZoneContainer = styled.div`
  border: ${({ theme }) => `1px dashed ${theme.colors.gray300}`};
  border-radius: 6px;
  margin-top: ${({ theme }) => theme.padding.medium};
  padding: 3%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledIconUploadFolder = styled(IconUploadFolder)`
  margin-bottom: ${({ theme }) => theme.padding.small};
  margin-top: ${({ theme }) => theme.padding.normal};
  height: 5vh;
`;

export const FoldersList = styled.div`
  margin-left: ${({ theme }) => theme.padding.small};
  margin-right: ${({ theme }) => theme.padding.small};
  overflow-y: scroll;
  max-height: 20vh;
  width: 98%;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.line};
    width: 6px;
    padding-right: 10px;
    border-radius: 6px;
  }
`;

export const StyledUploadFolders = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 600;
  margin-left: ${({ theme }) => theme.padding.small};
  margin-top: ${({ theme }) => theme.padding.small};
  user-select: none;
`;

export const UploadButton = styled(Button)`
  align-self: flex-end;
  margin-top: ${({ theme }) => theme.padding.normal};
  position: right;
`;

export const FoldersContainer = styled.div`
  max-height: 25vh;
  overflow: hidden;
`;
