import { FC } from 'react';
import { Header, StyledName } from './PageItem.styles';
import { PageItemProps } from './PageItem.interface';

export const PageItem: FC<PageItemProps> = ({ label }) => {
  return (
    <Header>
      <StyledName>{label}</StyledName>
    </Header>
  );
};
