import { Dictionary } from 'lodash';
import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface GroupTagEditModeProps {
  groupTagId: string;
  editMode: boolean;
  resultId?: string;
  tags?: Array<string>;
}

interface GroupTagEditModeDispatch extends BaseDispatch {
  payload: GroupTagEditModeProps;
}

interface GroupTagsEditModeProps {
  annotationIds: Dictionary<string>;
}

interface GroupTagsEditModeDispatch extends BaseDispatch {
  payload: GroupTagsEditModeProps;
}

export const SET_GROUP_TAG_EDIT_MODE = 'SET_GROUP_TAG_EDIT_MODE';
export const SET_GROUP_TAGS_EDIT_MODE = 'SET_GROUP_TAGS_EDIT_MODE';
export const CLEAR_GROUP_TAG_EDIT_MODE = 'CLEAR_GROUP_TAG_EDIT_MODE';

export const selectGroupTagsEditMode = (state: StoreProps) =>
  state.supervision.groupTagEditMode;

export const selectGroupTagEditMode = (state: StoreProps, groupTagId: string) =>
  state.supervision.groupTagEditMode[groupTagId];

export const clearGroupTagEditModeState = (state: SupervisionStore) => {
  return {
    ...state,
    groupTagEditMode: {},
  };
};

export const updateGroupTagEditModeState = (
  state: SupervisionStore,
  data: GroupTagEditModeProps,
) => {
  return {
    ...state,
    groupTagEditMode: { ...state.groupTagEditMode, [data.groupTagId]: data },
  };
};

export const updateGroupTagsEditModeState = (
  state: SupervisionStore,
  data: GroupTagsEditModeProps,
) => {
  return {
    ...state,
    groupTagEditMode: {
      ...data,
    },
  };
};

export const updateGroupTagEditMode = (data: GroupTagEditModeProps) => (
  dispatch: (data: GroupTagEditModeDispatch) => void,
) => {
  dispatch({
    type: SET_GROUP_TAG_EDIT_MODE,
    payload: data,
  });
};

export const updateGroupTagsEditMode = (data: GroupTagsEditModeProps) => (
  dispatch: (data: GroupTagsEditModeDispatch) => void,
) => {
  dispatch({
    type: SET_GROUP_TAGS_EDIT_MODE,
    payload: data,
  });
};

export const clearGroupTagEditMode = () => (dispatch: (data: BaseDispatch) => void) => {
  dispatch({
    type: CLEAR_GROUP_TAG_EDIT_MODE,
  });
};
