import { IconChevronDown } from 'components/Icons';
import { Label } from 'components/Label';
import styled from 'styled-components';

interface ContainerProps {
  menuOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ theme, menuOpen }) =>
    menuOpen
      ? `3px solid ${theme.colors.darkPurple}`
      : `3px solid ${theme.colors.white}`};
  :hover {
    border-bottom: ${({ theme }) => `3px solid ${theme.colors.darkPurple}`};
  }
`;

export const StyledUserName = styled(Label)`
  white-space: nowrap;
  user-select: none;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledAvatar = styled.img`
  margin-right: ${({ theme }) => theme.padding.normal};
  margin-bottom: 2px;
  height: 40px;
  width: 40px;
  border-radius: 6px;
`;

export const StyledArrowDownIcon = styled(IconChevronDown)`
  margin-left: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.blackPurple};
`;
