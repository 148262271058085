import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface PaneSizesProps {
  sizes: Array<string>;
}

interface PaneSizesDispatch extends BaseDispatch {
  payload: PaneSizesProps;
}

export const PANE_SIZES = 'PANE_SIZES';

export const selectPaneSizes = (state: StoreProps) => state.supervision.paneSizes;

export const updatePaneSizesState = (state: SupervisionStore, data: PaneSizesProps) => {
  return { ...state, paneSizes: data.sizes };
};

export const updatePaneSizes = (sizes: Array<string>) => (
  dispatch: (data: PaneSizesDispatch) => void,
) => {
  dispatch({
    type: PANE_SIZES,
    payload: { sizes },
  });
};
