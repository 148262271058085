import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface DragProps {
  dragging: boolean;
}

interface DragDispatch extends BaseDispatch {
  payload: DragProps;
}

export const PANE_DRAGGING = 'PANE_DRAGGING';

export const selectPaneDragging = (state: StoreProps) => state.supervision.paneDragging;

export const updatePaneDraggingState = (state: SupervisionStore, data: DragProps) => {
  return { ...state, paneDragging: data.dragging };
};

export const updatePaneDragging = (dragging: boolean) => (
  dispatch: (data: DragDispatch) => void,
) => {
  dispatch({
    type: PANE_DRAGGING,
    payload: { dragging },
  });
};
