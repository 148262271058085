import { Lookup } from 'api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateField, StoreProps } from 'store';
import {
  selectAllTagGroups,
  selectDataObjects,
  selectWords,
} from 'store/reducers/collection';
import {
  addCreateFields,
  clearCreatedFields,
  selectCreateFields,
  selectFieldArrow,
  selectZoom,
  updateFieldArrow,
  updateZoom,
  updatePageArrow,
  selectEditMode,
} from 'store/reducers/supervision';
import { selectPageArrow } from 'store/reducers/supervision/pageArrow';

export const usePageStore = (pageId: string) => {
  const dispatch = useDispatch();
  const addSelectedField = useCallback((e) => dispatch(addCreateFields(e)), [dispatch]);
  const clearCreateFields = useCallback(() => dispatch(clearCreatedFields()), [dispatch]);
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const setZoom = useCallback((e) => dispatch(updateZoom(e)), [dispatch]);
  const setPageArrow = useCallback((e) => dispatch(updatePageArrow(e)), [dispatch]);
  const pageDataObjects = useSelector((state: StoreProps) =>
    selectDataObjects(state, pageId),
  );
  const editMode = useSelector(selectEditMode);
  const fieldArrow = useSelector(selectFieldArrow);
  const pageArrow = useSelector(selectPageArrow);
  const createFields = useSelector<StoreProps, CreateField>((state) =>
    selectCreateFields(state, pageId),
  );
  const words = useSelector<StoreProps, Array<Lookup>>((state) =>
    selectWords(state, pageId),
  );
  const paneDragging = useSelector((state: StoreProps) => state.supervision.paneDragging);
  const zoom = useSelector(selectZoom);
  const tagGroups = useSelector(selectAllTagGroups);

  return {
    clearCreateFields,
    pageDataObjects,
    paneDragging,
    fieldArrow,
    words,
    editMode,
    addSelectedField,
    createFields,
    zoom,
    pageArrow,
    setZoom,
    setFieldArrow,
    setPageArrow,
    tagGroups,
  };
};
