import styled from 'styled-components';

export const Content = styled.div`
  width: 92%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Container = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  z-index: 1001;
  position: relative;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 5px 9px 0 rgba(217, 222, 234, 0.4);
`;

export const StyledLogo = styled.img`
  height: 20px;
  margin-right: 7vw;
`;
