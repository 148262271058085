import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppVersion, Label, Container, StyledTitle } from './Version.styles';
import { api, Version as VersionApi } from 'api';

export const Version: FC = () => {
  const { t } = useTranslation();
  const [version, setVersion] = useState<VersionApi>({
    aiaVersion: '2022.1.1',
    apiVersion: '1.0',
    build: 'build-220111150709-381384d7',
  });

  useEffect(() => {
    api.fetchVersion().then(setVersion);
  }, []);

  return (
    <div>
      <StyledTitle>{t('version')}</StyledTitle>
      <Container>
        <div>
          <Label>{t('versionAIA')}</Label>
          <AppVersion>{version?.aiaVersion}</AppVersion>
        </div>
        <div>
          <Label>{t('build')}</Label>
          <AppVersion>{version?.build}</AppVersion>
        </div>
        <div>
          <Label>{t('versionAPI')}</Label>
          <AppVersion>{version?.apiVersion}</AppVersion>
        </div>
      </Container>
    </div>
  );
};
