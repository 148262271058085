import { FC } from 'react';
import { Container } from './TagGroupsItem.styles';
import { TagGroupsItemProps } from './TagGroupsItem.interface';
import { GroupTagItem } from '../GroupTagItem';
import { useTagGroupsItemStore } from './TagGroupsItem.hooks';

export const TagGroupsItem: FC<TagGroupsItemProps> = ({ resultId, documentId }) => {
  const { tagGroups = [] } = useTagGroupsItemStore(documentId);

  return (
    <Container>
      {tagGroups?.map((item, index) => (
        <GroupTagItem
          documentId={documentId}
          key={item.id}
          tagGroup={item}
          resultId={resultId}
        />
      ))}
    </Container>
  );
};
