import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentsList } from './DocumentsList';
import { ProcessStatus } from 'components/ProcessStatus';
import { SidePanelProps } from './SidePanel.interface';
import {
  VerticalContainer,
  HorizontalContainer,
  HorizontalBottomContainer,
  Container,
  TopContainer,
  StyledIconFlag,
  StyledIconEdit,
  StyledIconClose,
  StyledDocumentType,
  StyledCollectionName,
  StyledIconFile,
  StyledIconFolder,
  GroupCollapse,
  StyledPanel,
  StyledInfoLabel,
  StyledInfo,
  StyledProcessProgress,
  ProcessProgressContainer,
  CollapseContainer,
  StyledIconViewResults,
  StyledPrimaryIconButton,
  StyledIconUpload,
  StyledSecondaryAltButton,
} from './SidePanel.styles';
import { DocumentType } from 'api';
import moment from 'moment';
import { useQueueCollections } from 'providers';
import { ViewResultsModal } from 'modals/ViewResultsModal';
import { collectionProcessed } from 'utils/helpers';
import { IconButton } from 'components/IconButton';
import { useTyroHistory, useUserScope } from 'hooks';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import { useExportModalStore } from 'store/reducers/modals';

const documentTypeIcon = (type: DocumentType) => {
  if (type === 'file') {
    return <StyledIconFile />;
  }

  return <StyledIconFolder />;
};

export const SidePanel: FC<SidePanelProps> = ({ id, collection, open }) => {
  const { setSelectedRowId } = useQueueCollections();
  const [openViewResults, setOpenViewResults] = useState(false);
  const [retainedCollection, setRetaindCollection] = useState(collection);
  const topRef = useRef(null);
  const { t } = useTranslation();
  const { openSupervision } = useTyroHistory();
  const { isAllowed } = useUserScope('RESULT_EXPORT');
  const { setExportModal } = useExportModalStore();

  useEffect(() => {
    if (collection) {
      setRetaindCollection(collection);
    }
  }, [collection]);

  const onSupervision = () => {
    if (id) {
      openSupervision(id);
    }
  };

  const onExport = () => {
    setExportModal({ open: true, collectionIds: [collection.collectionId] });
  };

  return (
    <>
      <Container $open={open}>
        <div ref={topRef}>
          <TopContainer>
            <HorizontalContainer>
              {retainedCollection?.highPriority && <StyledIconFlag />}
              {<ProcessStatus status={retainedCollection?.status} />}
            </HorizontalContainer>
            <HorizontalContainer>
              {collectionProcessed(retainedCollection) && isAllowed && (
                <>
                  {isAllowed && (
                    <SecondaryAltButton onClick={onExport} icon={<StyledIconUpload />} />
                  )}
                  <StyledSecondaryAltButton
                    onClick={() => setOpenViewResults(true)}
                    icon={<StyledIconViewResults />}
                  />
                  <StyledPrimaryIconButton
                    onClick={onSupervision}
                    icon={<StyledIconEdit />}
                  />
                </>
              )}
              <IconButton
                onClick={() => setSelectedRowId(undefined)}
                icon={<StyledIconClose />}
              />
            </HorizontalContainer>
          </TopContainer>
          <HorizontalBottomContainer>
            <StyledDocumentType>
              {retainedCollection?.files && retainedCollection.files.length > 1
                ? documentTypeIcon('folder')
                : documentTypeIcon('file')}
            </StyledDocumentType>
            <StyledCollectionName size="h3">
              {retainedCollection?.collectionName}
            </StyledCollectionName>
          </HorizontalBottomContainer>
        </div>
        <CollapseContainer
          topContainerSize={
            topRef.current
              ? ((topRef.current as unknown) as HTMLDivElement).clientHeight
              : 0
          }
        >
          <GroupCollapse defaultActiveKey={['info']} ghost expandIconPosition="right">
            <StyledPanel
              style={{ border: '0' }}
              header={<div>{t('documentInfo')?.toUpperCase()}</div>}
              key="info"
            >
              <VerticalContainer>
                <StyledInfoLabel size="small" bold>
                  {t('uploadDate')}
                </StyledInfoLabel>
                <StyledInfo>
                  {retainedCollection?.createdAt
                    ? moment(new Date(retainedCollection?.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )
                    : '-'}
                </StyledInfo>
              </VerticalContainer>
              <VerticalContainer>
                <StyledInfoLabel size="small" bold>
                  {t('processingDate')}
                </StyledInfoLabel>
                <StyledInfo>
                  {retainedCollection?.createdAt
                    ? moment(new Date(retainedCollection?.createdAt)).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )
                    : '-'}
                </StyledInfo>
              </VerticalContainer>
              <VerticalContainer>
                <StyledInfoLabel size="small" bold>
                  {t('processingProgress')}
                </StyledInfoLabel>
                <ProcessProgressContainer>
                  <StyledProcessProgress progress={retainedCollection?.progress} />
                </ProcessProgressContainer>
              </VerticalContainer>
              <VerticalContainer>
                <StyledInfoLabel size="small" bold>
                  {t('numberOfDocuments')}
                </StyledInfoLabel>
                <StyledInfo>{retainedCollection?.totalFiles}</StyledInfo>
              </VerticalContainer>
              <VerticalContainer>
                <StyledInfoLabel size="small" bold>
                  {t('numberOfPages')}
                </StyledInfoLabel>
                <StyledInfo>{retainedCollection?.totalPages || '-'}</StyledInfo>
              </VerticalContainer>
            </StyledPanel>
          </GroupCollapse>
          {retainedCollection && retainedCollection?.files.length > 1 && (
            <GroupCollapse defaultActiveKey="documents" ghost expandIconPosition="right">
              <StyledPanel
                style={{ border: '0' }}
                header={
                  <div>
                    {t('documents')} ({retainedCollection?.files.length})
                  </div>
                }
                key="documents"
              >
                <DocumentsList
                  key={retainedCollection?.collectionId}
                  data={retainedCollection?.files}
                />
              </StyledPanel>
            </GroupCollapse>
          )}
        </CollapseContainer>
      </Container>
      {openViewResults && (
        <ViewResultsModal
          fileName={retainedCollection?.collectionName}
          collectionId={retainedCollection?.collectionId}
          open={openViewResults}
          onClose={() => setOpenViewResults(false)}
        />
      )}
    </>
  );
};
