import { FC, useState } from 'react';
import { Item, useContextMenu, TriggerEvent } from 'react-contexify';
import { ConfirmationModal } from 'modals/ConfirmationModal';
import { DropdownMenu } from 'components/DropdownMenu';
import { RowAction, RowProps } from './Row.interface';
import { rowAction, userMenu } from './Row.menu';
import {
  Cell,
  Container,
  StyledName,
  StyledDate,
  MenuContainer,
  StyledMenu,
} from './Row.styles';
import { useRefreshList, useUserScope } from 'hooks';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UserStatus } from 'components/UserStatus';
import { toast } from 'react-toastify';
import { theme } from 'styles';
import { IconCheckCircle } from 'components/Icons';
import { api } from 'api';
import { useEditUserModalStore } from 'store/reducers/modals';

export const Row: FC<RowProps> = ({ item }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const { refresh } = useRefreshList();
  const { show } = useContextMenu({
    id: item.userId,
  });
  const { t } = useTranslation();
  const { setEditUserModal } = useEditUserModalStore();
  const { isAllowed } = useUserScope('USERS_MANAGE');

  const toggleContextMenu = (event: TriggerEvent) => {
    event.preventDefault();
    show(event);
  };

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const onRowAction = (action: RowAction) => {
    if (action === 'delete') {
      toggleDeleteModal();
    }

    if (action === 'edit') {
      setEditUserModal({ open: true, user: item });
    }
  };

  const onDelete = () => {
    toggleDeleteModal();

    api.deleteUser(item.userId).then(() => {
      refresh();

      toast.success(t('userDeleted'), {
        position: 'bottom-left',
        icon: <IconCheckCircle />,
        progressStyle: { backgroundColor: theme.colors.darkPurple },
      });
    });
  };

  return (
    <>
      <Container
        $highlighted={showContextMenu}
        onMouseEnter={() => isAllowed && setShowMenu(true)}
        onMouseLeave={() => isAllowed && setShowMenu(false)}
        onContextMenu={toggleContextMenu}
      >
        <Cell size={40}>
          <StyledName size="regular" bold>
            {`${item.givenName} ${item.familyName}`}
          </StyledName>
        </Cell>
        <Cell size={30}>
          <StyledDate>{item.emails.length > 0 ? item.emails[0] : ''}</StyledDate>
        </Cell>
        <Cell size={15}>
          <StyledDate>
            {item.joinedAt
              ? moment(new Date(item.joinedAt)).format('DD/MM/YYYY HH:mm:ss')
              : ''}
          </StyledDate>
        </Cell>
        <Cell size={15}>
          <UserStatus status={item.status} />
        </Cell>
        {showMenu && isAllowed && (
          <MenuContainer>
            <DropdownMenu
              onMenuItem={(id) => onRowAction(rowAction(id))}
              items={userMenu(t)}
            />
          </MenuContainer>
        )}
      </Container>
      <ConfirmationModal
        onClose={toggleDeleteModal}
        onConfirm={onDelete}
        open={openDeleteModal}
        title={`${t('deleteQuestion')} ${item.givenName} ${item.familyName}?`}
        content={t('deleteUserInfo')}
      />
      {isAllowed && (
        <StyledMenu
          onHidden={() => setShowContextMenu(false)}
          onShown={() => setShowContextMenu(true)}
          id={item.userId}
        >
          {userMenu(t).map(({ id, label, Icon }) => (
            <Item
              key={id}
              style={{
                marginLeft: 5,
                marginRight: 5,
                borderRadius: '3px',
              }}
              onClick={() => onRowAction(rowAction(id))}
            >
              <Icon style={{ width: '15px', height: '15px', marginRight: '8px' }} />
              {label}
            </Item>
          ))}
        </StyledMenu>
      )}
    </>
  );
};
