import styled from 'styled-components';

interface ImageProps {
  withCursor: boolean;
}

export const StyledImage = styled.img<ImageProps>`
  height: auto;
  width: 100%;
  objectfit: contain;
  objectposition: 0 0;
  user-select: none;
  cursor: ${({ withCursor }) => (withCursor ? 'pointer' : 'auto')};
`;
