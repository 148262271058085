import type { AlignType } from 'rc-trigger/lib/interface';
export interface UseTextAreaProps {
  minWidth?: number;
  value?: string;
}
export const useTextAreaCell = ({ minWidth, value }: UseTextAreaProps) => {
  const MAX_WIDTH = 200;

  if (!minWidth) {
    minWidth = MAX_WIDTH;
  }

  const WIDTH_TO_NUMBER_OF_CHARS_RATIO = 13;
  const ratio = minWidth / (value ? value.length : 0) > WIDTH_TO_NUMBER_OF_CHARS_RATIO;
  const width = ratio ? minWidth : MAX_WIDTH;

  const alignConfig: AlignType = {
    points: ['tl', 'tl'],
    ignoreShake: true,
    offset: [0, 0],
  };

  const autoSizeConfig = { minRows: 1, maxRows: 5 };

  return {
    width,
    alignConfig,
    autoSizeConfig,
  };
};
