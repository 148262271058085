import { Loader } from 'components/Loader';
import { Container, Logo } from './Splash.styles';
import logo from 'assets/logo.png';

export const Splash = () => {
  return (
    <Container>
      <Logo src={logo} />
      <Loader />
    </Container>
  );
};
