import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.transparent};
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Image = styled.img`
  width: 30vw;
  height: auto;
  padding-top: 8px;
  user-select: none;
`;

export const CloseButton = styled.div`
  :focus {
    border: ${({ theme }) => `1px solid ${theme.colors.lilac}`};
    border: 1px;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.lilac};
  }
  height: 40px;
  width: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0px;
  color: ${({ theme }) => theme.colors.darkPurple};
  cursor: pointer;
  align-self: flex-end;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
