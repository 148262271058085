import { BaseDispatch, CommonStore, StoreProps } from 'store/store.interface';

export interface ExtractorPrefedinedFieldsProps {
  id: string;
  data: Array<string>;
}

interface ExtractorPrefedinedFieldsDispatch extends BaseDispatch {
  payload: ExtractorPrefedinedFieldsProps;
}

export const SET_EXTRACTOR_PREDEFINED_FIELDS = 'SET_EXTRACTOR_PREDEFINED_FIELDS';

export const selectExtractorPrefedinedFields = (state: StoreProps, id: string) =>
  state.common.extractorPrefedinedFields[id];

export const updateExtractorPrefedinedFieldsState = (
  state: CommonStore,
  data: ExtractorPrefedinedFieldsProps,
) => {
  return {
    ...state,
    extractorPrefedinedFields: {
      ...state.extractorPrefedinedFields,
      [data.id]: data.data,
    },
  };
};

export const updateExtractorPrefedinedFields = (data: ExtractorPrefedinedFieldsProps) => (
  dispatch: (data: ExtractorPrefedinedFieldsDispatch) => void,
) => {
  dispatch({
    type: SET_EXTRACTOR_PREDEFINED_FIELDS,
    payload: data,
  });
};
