import { FC, useEffect } from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import { UploadFoldersModalProps } from './UploadFoldersModal.interface';
import { useUploadFolders } from 'hooks';
import {
  StyledTitle,
  StyledSubTitle,
  StyledDropInfo,
  CloseIcon,
  Container,
  DropZoneContainer,
  FoldersList,
  StyledUploadFolders,
  StyledIconUploadFolder,
  FoldersContainer,
} from './UploadFoldersModal.styles';
import { UploadFolder } from './UploadFolder';
import { keys, values } from 'lodash';
import { ACCEPTED_FILES, FILE_1_MB, getDirectory } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { ENV_VARIABLE } from 'variables';

export const UploadFoldersModal: FC<UploadFoldersModalProps> = ({
  open,
  onClose,
  onUploadFinish,
}) => {
  const {
    addFolders,
    folders,
    folderProgress,
    uploadingFolders,
    clearData,
    refreshFolder,
  } = useUploadFolders();
  const { t } = useTranslation();

  useEffect(() => {
    if (keys(folderProgress).length > 0) {
      const filesUploaded = values(folderProgress).filter(
        (item) => item.uploadedFiles !== item.allFiles,
      ).length;

      if (filesUploaded === 0) {
        onUploadFinish();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderProgress]);

  const onDrop = (acceptedFolders: string | any[]) => {
    const folders = [];

    for (var i = 0; i < acceptedFolders.length; i = i + 1) {
      if (getDirectory(acceptedFolders[i].path)) {
        folders.push(acceptedFolders[i]);
      }
    }

    addFolders(folders);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
    maxSize: ENV_VARIABLE.MAX_FILE_SIZE
      ? FILE_1_MB * ENV_VARIABLE.MAX_FILE_SIZE
      : undefined,
    useFsAccessApi: false,
  });

  const onCloseCallback = () => {
    if (!uploadingFolders) {
      clearData();
      onClose();
    }
  };

  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
      onRequestClose={onCloseCallback}
    >
      <CloseIcon onClick={onCloseCallback} />
      <Container>
        <StyledTitle level={3}>{t('uploadYourFolders')}</StyledTitle>
        <StyledSubTitle>{t('uploadYourFoldersInfo')}</StyledSubTitle>
        <DropZoneContainer {...getRootProps()}>
          <input {...getInputProps()} directory="" webkitdirectory="" type="file" />
          <>
            <StyledIconUploadFolder />
            <StyledDropInfo>
              {t('dragAndDropFolders')}
              <br />
              {t('or')}
            </StyledDropInfo>
          </>
          <Button size="large" title={t('chooseFromComputer')} color="light" />
        </DropZoneContainer>
        {keys(folders).length > 0 && keys(folderProgress).length > 0 && (
          <FoldersContainer>
            <StyledUploadFolders>{t('uploadedFolders')}</StyledUploadFolders>
            <FoldersList>
              {keys(folders)
                .filter(
                  (item) =>
                    folderProgress[item].uploadedFiles !== folderProgress[item].allFiles,
                )
                .map((folder) => (
                  <UploadFolder
                    key={folder}
                    name={folder}
                    filesNumber={
                      folderProgress[folder].uploading
                        ? `${folderProgress[folder]?.uploadedFiles}/${folderProgress[folder]?.allFiles}`
                        : `${folders[folder].length}`
                    }
                    uploading={folderProgress[folder].uploading}
                    uploadProgress={Number(
                      (
                        (folderProgress[folder]?.uploadedFiles /
                          folderProgress[folder]?.allFiles) *
                        100
                      ).toFixed(0),
                    )}
                    failed={folderProgress[folder].failed}
                    onRefresh={() => refreshFolder(folder)}
                  />
                ))}
              {keys(folders)
                .filter(
                  (item) =>
                    folderProgress[item].uploadedFiles === folderProgress[item].allFiles,
                )
                .map((folder) => (
                  <UploadFolder
                    key={folder}
                    name={folder}
                    filesNumber={
                      folderProgress[folder].uploading
                        ? `${folderProgress[folder]?.uploadedFiles}/${folderProgress[folder]?.allFiles}`
                        : `${folders[folder].length}`
                    }
                    uploading={folderProgress[folder].uploading}
                    uploadProgress={Number(
                      (
                        (folderProgress[folder]?.uploadedFiles /
                          folderProgress[folder]?.allFiles) *
                        100
                      ).toFixed(0),
                    )}
                  />
                ))}
            </FoldersList>
          </FoldersContainer>
        )}
      </Container>
    </Modal>
  );
};

// Disable warning for TS
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    directory?: string;
    webkitdirectory?: string;
  }
}
