import {
  Document,
  PatchAnnotation,
  PageImage,
  Lookup,
  Annotation,
  ThumbnailSection,
  ProcessStatus,
  PatchTagGroup,
  TagGroup,
  DataObject,
  MetaData,
} from 'api';
import { Dictionary, omit, values } from 'lodash';
import { BaseDispatch, CollectionStore, StoreProps } from 'store/store.interface';

interface CollectionProps {
  documents: Dictionary<Document>;
  images: Dictionary<PageImage>;
  dataObjects: Dictionary<PageImage>;
  words: Dictionary<Dictionary<Lookup>>;
  name: string;
  status: ProcessStatus;
  totalPages: number;
  totalFiles: number;
  annotationAttentions: number;
  thumbnailSections: Dictionary<ThumbnailSection>;
}

interface DeleteAnnotationProps {
  pageId: string;
  annotationId: string;
}

interface CollectionLoadingProps {
  loading: boolean;
}
interface CollectionEditorProps {
  editorId?: string;
}

interface CollectionMetaDataProps {
  data: MetaData;
}
interface CollectionStatusProps {
  status: string;
}
interface CollectionErrorProps {
  errorStatus: number;
}

interface UpdateAnnotationProps {
  annotation: PatchAnnotation;
}

interface CreateAnnotationProps {
  pageId: string;
  annotation: Annotation;
}

interface CollectionDispatch extends BaseDispatch {
  payload: CollectionProps;
}

interface CollectionLoadingDispatch extends BaseDispatch {
  payload: CollectionLoadingProps;
}

interface CollectionEditorDispatch extends BaseDispatch {
  payload: CollectionEditorProps;
}

interface CollectionStatusDispatch extends BaseDispatch {
  payload: CollectionStatusProps;
}

interface CollectionMetadataDispatch extends BaseDispatch {
  payload: CollectionMetaDataProps;
}

interface CollectionErrorDispatch extends BaseDispatch {
  payload: CollectionErrorProps;
}

interface UpdateDataObjectDispatch extends BaseDispatch {
  payload: UpdateAnnotationProps;
}

interface UpdateTagGroupDispatch extends BaseDispatch {
  payload: PatchTagGroup;
}

interface DeleteDataObjectDispatch extends BaseDispatch {
  payload: DeleteAnnotationProps;
}

interface CreateDataObjectDispatch extends BaseDispatch {
  payload: CreateAnnotationProps;
}

export const COLLECTION = 'COLLECTION';
export const COLLECTION_LOADING = 'COLLECTION_LOADING';
export const COLLECTION_EDITOR = 'COLLECTION_EDITOR';
export const COLLECTION_STATUS = 'COLLECTION_STATUS';
export const COLLECTION_ERROR = 'COLLECTION_ERROR';
export const CREATE_ANNOTATION = 'CREATE_ANNOTATION';
export const UPDATE_ANNOTATION = 'UPDATE_ANNOTATION';
export const UPDATE_METADATA = 'UPDATE_METADATA';
export const UPDATE_TAG_GROUP = 'UPDATE_TAG_GROUP';
export const DELETE_ANNOTATION = 'DELETE_ANNOTATION';

export const selectCollectionName = (state: StoreProps) => state.collection.name;

export const selectCollectionTotalPages = (state: StoreProps) =>
  state.collection.totalPages;

export const selectCollectionStatus = (state: StoreProps) => state.collection.status;

export const selectCollectionTotalFiles = (state: StoreProps) =>
  state.collection.totalFiles;

export const selectCollectionDocuments = (state: StoreProps) =>
  state.collection.documents;

export const selectCollectionLoading = (state: StoreProps) => state.collection.loading;

export const selectCollectionError = (state: StoreProps) => state.collection.errorStatus;

export const selectProcessedDocuments = (state: StoreProps) =>
  state.collection.processedDocuments;

export const selectPages = (state: StoreProps) => state.collection.pages;

export const selectDocumentByResultId = (state: StoreProps, id: string) =>
  state.collection.documents[id];

export const selectDocuments = (state: StoreProps) => state.collection.documents;

export const selectCollectionId = (state: StoreProps) => state.collection.collectionId;

export const selectCollectionMetadata = (state: StoreProps) => state.collection.metadata;

export const selectEditorId = (state: StoreProps) => state.collection.editorId;

export const selectAllDataObjects = (state: StoreProps) => state.collection.dataObjects;

export const selectAllTagGroups = (state: StoreProps) => state.collection.tagGroups;

export const selectTagGroupOptions = (state: StoreProps) =>
  state.collection.tagGroupsOptions;

export const selectDataObjects = (state: StoreProps, pageId: string) =>
  values(state.collection.dataObjects[pageId]);

export const selectDataObjectById = (
  state: StoreProps,
  pageId?: string,
  id?: string,
): DataObject | undefined =>
  id && pageId
    ? state.collection.dataObjects[pageId] && state.collection.dataObjects[pageId][id]
    : undefined;

export const selectDocumentTagGroups = (
  state: StoreProps,
  documentId: string,
): Array<TagGroup> => {
  return state.collection.tagGroups[documentId];
};

export const selectWords = (state: StoreProps, pageId: string) =>
  values(state.collection.words[pageId]);

export const selectImage = (state: StoreProps, pageId: string) =>
  state.collection.images[pageId];

export const selectThumbnailSections = (state: StoreProps) =>
  state.collection.thumbnailSections;

export const selectComplexAnnotationSchemas = (state: StoreProps) =>
  state.collection.complexAnnotationSchemas;

export const createDataObjectState = (
  state: CollectionStore,
  data: CreateAnnotationProps,
) => {
  return {
    ...state,
    dataObjects: {
      ...state.dataObjects,
      [data.pageId]: {
        ...state.dataObjects[data.pageId],
        [data.annotation.id]: {
          ...data.annotation,
        },
      },
    },
  };
};

export const updateDataObjectsState = (state: CollectionStore, data: PatchAnnotation) => {
  return {
    ...state,
    dataObjects: {
      ...state.dataObjects,
      [data.pageId]: {
        ...state.dataObjects[data.pageId],
        [data.annotationId]: {
          ...state.dataObjects[data.pageId][data.annotationId],
          ...data,
        },
      },
    },
  };
};

export const updateTagGroupState = (state: CollectionStore, data: PatchTagGroup) => {
  return {
    ...state,
    tagGroups: {
      ...state.tagGroups,
      [data.documentId]: [
        ...state.tagGroups[data.documentId].map((item) => {
          if (item.id === data.tagGroup.id) {
            return data.tagGroup;
          }

          return item;
        }),
      ],
    },
  };
};

export const deleteDataObjectState = (
  state: CollectionStore,
  data: DeleteAnnotationProps,
) => {
  return {
    ...state,
    dataObjects: {
      ...state.dataObjects,
      [data.pageId]: omit(state.dataObjects[data.pageId], [data.annotationId]),
    },
  };
};

export const updateCollectionState = (
  state: CollectionStore,
  collection: CollectionProps,
) => {
  return { ...state, ...collection };
};

export const updateCollectionLoadingState = (
  state: CollectionStore,
  data: CollectionLoadingProps,
) => {
  return { ...state, loading: data.loading };
};

export const updateCollectionEditorState = (
  state: CollectionStore,
  data: CollectionEditorProps,
) => {
  return { ...state, editorId: data.editorId };
};

export const updateCollectionMetadataState = (
  state: CollectionStore,
  data: CollectionMetaDataProps,
) => {
  console.log(data);
  return { ...state, metadata: { ...data } };
};

export const updateCollectionStatusState = (
  state: CollectionStore,
  data: CollectionStatusProps,
) => {
  return { ...state, status: data.status };
};

export const updateCollectionErrorState = (
  state: CollectionStore,
  data: CollectionErrorProps,
) => {
  return { ...state, errorStatus: data.errorStatus };
};

export const updateCollection = (collection: CollectionProps) => (
  dispatch: (data: CollectionDispatch) => void,
) => {
  dispatch({
    type: COLLECTION,
    payload: { ...collection },
  });
};

export const updateCollectionLoading = (loading: boolean) => (
  dispatch: (data: CollectionLoadingDispatch) => void,
) => {
  dispatch({
    type: COLLECTION_LOADING,
    payload: { loading },
  });
};

export const updateCollectionEditor = (editorId?: string) => (
  dispatch: (data: CollectionEditorDispatch) => void,
) => {
  dispatch({
    type: COLLECTION_EDITOR,
    payload: { editorId },
  });
};

export const updateCollectionStatus = (status: string) => (
  dispatch: (data: CollectionStatusDispatch) => void,
) => {
  dispatch({
    type: COLLECTION_STATUS,
    payload: { status },
  });
};

export const updateCollectionMetadata = (data: MetaData) => (
  dispatch: (data: CollectionMetadataDispatch) => void,
) => {
  dispatch({
    type: UPDATE_METADATA,
    payload: { data },
  });
};

export const updateCollectionError = (errorStatus: number) => (
  dispatch: (data: CollectionErrorDispatch) => void,
) => {
  dispatch({
    type: COLLECTION_ERROR,
    payload: { errorStatus },
  });
};

export const updateDataObject = (annotation: PatchAnnotation) => (
  dispatch: (data: UpdateDataObjectDispatch) => void,
) => {
  dispatch({
    type: UPDATE_ANNOTATION,
    payload: { annotation },
  });
};

export const updateTagGroup = (data: PatchTagGroup) => (
  dispatch: (data: UpdateTagGroupDispatch) => void,
) => {
  dispatch({
    type: UPDATE_TAG_GROUP,
    payload: { ...data },
  });
};

export const deleteDataObject = (data: DeleteAnnotationProps) => (
  dispatch: (data: DeleteDataObjectDispatch) => void,
) => {
  dispatch({
    type: DELETE_ANNOTATION,
    payload: { ...data },
  });
};

export const createDataObject = (data: CreateAnnotationProps) => (
  dispatch: (data: CreateDataObjectDispatch) => void,
) => {
  dispatch({
    type: CREATE_ANNOTATION,
    payload: { ...data },
  });
};
