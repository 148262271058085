import { Button } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import styled from 'styled-components';
import { TyroTheme } from 'styles';
import { ColorType } from './Button.interface';

interface ButtonProps {
  color: ColorType;
  size: SizeType;
  $shadow: boolean;
  $withIcon: boolean;
}

const backgroundColor = (theme: TyroTheme, color: ColorType) => {
  switch (color) {
    case 'light':
      return theme.colors.white;
    case 'secondary':
      return theme.colors.white;
    case 'warning':
      return theme.colors.red;
    case 'transparent':
      return theme.colors.transparent;
    default:
    case 'primary':
      return theme.colors.darkPurple;
  }
};

const backgroundColorHover = (theme: TyroTheme, color: ColorType) => {
  switch (color) {
    case 'secondary':
    case 'light':
      return theme.colors.lilac;
    case 'warning':
      return theme.colors.redDark;
    default:
    case 'primary':
      return theme.colors.blackPurple;
  }
};

const borderColorHover = (theme: TyroTheme, color: ColorType) => {
  switch (color) {
    case 'light':
      return theme.colors.lineDark;
    case 'secondary':
      return theme.colors.white;
    case 'warning':
      return theme.colors.red;
    default:
    case 'primary':
      return theme.colors.blackPurple;
  }
};

const borderColor = (theme: TyroTheme, color: ColorType) => {
  switch (color) {
    case 'light':
      return theme.colors.darkPurple;
    case 'secondary':
      return theme.colors.white;
    case 'warning':
      return theme.colors.red;
    default:
    case 'primary':
      return theme.colors.darkPurple;
  }
};

const textColor = (theme: TyroTheme, color: ColorType) => {
  switch (color) {
    case 'light':
    case 'secondary':
      return theme.colors.darkGray;
    case 'transparent':
      return theme.colors.blackPurple;
    default:
    case 'warning':
    case 'primary':
      return theme.colors.white;
  }
};

const textColorHover = (theme: TyroTheme, color: ColorType) => {
  switch (color) {
    case 'transparent':
      return theme.colors.white;
    case 'light':
    case 'secondary':
      return theme.colors.darkGray;
    default:
    case 'warning':
    case 'primary':
      return theme.colors.white;
  }
};

const buttonSize = (size: SizeType) => {
  switch (size) {
    case 'large':
      return '40px';
    case 'middle':
      return '36px';
    default:
    case 'small':
      return '32px';
  }
};

const fontSize = (theme: TyroTheme, size: SizeType) => {
  switch (size) {
    case 'large':
      return theme.fontSize.normal;
    case 'middle':
      return '14px';
    default:
    case 'small':
      return theme.fontSize.small;
  }
};

const shadow = (color: ColorType) => {
  switch (color) {
    case 'transparent':
      return 'none';
    case 'light':
    case 'secondary':
      return '0 2px 6px 0 rgba(151,159,182,0.3)';
    default:
      return '0 5px 6px 0 rgba(70,45,140,0.1)';
  }
};

const shadowHover = (color: ColorType) => {
  switch (color) {
    case 'transparent':
    case 'light':
    case 'secondary':
      return '0 2px 6px 0 rgba(151,159,182,0.3)';
    default:
      return '0 5px 6px 0 rgba(70,45,140,0.1)';
  }
};

export const StyledButton = styled(Button)<ButtonProps>`
  :focus {
    background-color: ${({ theme, color }) => backgroundColor(theme, color)};
    border-color: ${({ theme, color }) => borderColor(theme, color)};
    color: ${({ theme, color }) => textColor(theme, color)};
    border-radius: 6px;
    border: 1px;
  }
  :hover {
    box-shadow: ${({ $shadow, color }) => ($shadow ? shadowHover(color) : 'none')};
    background-color: ${({ theme, color }) => backgroundColorHover(theme, color)};
    border-color: ${({ theme, color }) => borderColorHover(theme, color)};
    color: ${({ theme, color }) => textColorHover(theme, color)};
    border-radius: 6px;
  }
  box-shadow: ${({ $shadow, color }) => ($shadow ? shadow(color) : 'none')};
  height: ${({ size }) => buttonSize(size)};
  border-radius: 6px;
  background-color: ${({ theme, color }) => backgroundColor(theme, color)};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  color: ${({ theme, color }) => textColor(theme, color)};
  font-size: ${({ theme, size }) => fontSize(theme, size)};
  border-color: ${({ theme, color }) => borderColor(theme, color)};
  align-items: center;
  border: 0px;
  display: flex;
  padding-right: ${({ $withIcon, size }) =>
    $withIcon ? '8px' : size === 'small' ? '8px' : '16px'};
  .ant-btn-primary {
    padding-right: 0;
    text-shadow: 0;
  }
`;
