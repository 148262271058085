import { Progress, Typography } from 'antd';
import {
  IconFile,
  IconFolder,
  IconLoader,
  IconCheck,
  IconRefresh,
} from 'components/Icons';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }`;

interface ProgressProps {
  percent: number;
}

export const StyledDocumentType = styled.div`
  width: 3.5vh;
  height: 3.5vh;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.iconBlue};
  margin-right: ${({ theme }) => theme.padding.normal};
`;

export const StyledIconFile = styled(IconFile)`
  width: 15px;
`;

export const StyledIconCheck = styled(IconCheck)`
  color: ${({ theme }) => theme.colors.green};
`;

export const StyledIconFolder = styled(IconFolder)`
  width: 15px;
`;

export const FileInfoContainer = styled.div`
  display: flex;
  aling-items: center;
  justify-content: space-between;
`;

export const FileNameContainer = styled.div`
  display: flex;
  aling-items: center;
  justify-content: center;
`;

export const StyledFolderName = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
`;

export const StyledFileNumber = styled(Typography)`
  color: ${({ theme }) => theme.colors.filter};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding-left: ${({ theme }) => theme.padding.tiny};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  user-select: none;
`;

export const FileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.padding.normal};
`;

export const FileProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 86%;
  padding-right: ${({ theme }) => theme.padding.tiny};
`;

export const StyledProgress = styled(Progress)<ProgressProps>`
  margin-top: -0.5vh;
  .ant-progress-bg {
    background-color: ${({ theme, percent }) =>
      percent < 100 ? theme.colors.blackPurple : theme.colors.green};
  }
`;

export const StyledProgressValue = styled(Typography)<ProgressProps>`
  color: ${({ theme, percent }) =>
    percent < 100 ? theme.colors.blackPurple : theme.colors.green};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: 500;
  user-select: none;
  align-self: end;
`;

export const UploadStatus = styled.div`
  align-self: flex-end;
`;

export const StyledLoader = styled(IconLoader)`
  align-self: flex-end;
  animation: ${rotate} 1.5s linear infinite;
`;

export const StyledIconRefresh = styled(IconRefresh)`
  height: 19px;
  width: 19px;
  cursor: pointer;
  margin-left: 4px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.darkPurple};
`;
