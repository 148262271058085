import { useAppDispatch, useAppSelector } from 'store/store.hooks';
import { AnnotationDeleteModalProps } from './modals.interface';

import { setAnnotationDelete } from './modalsSlice';

export const useAnnotationDeleteModalStore = () => {
  const dispatch = useAppDispatch();

  const setAnnotationDeleteModal = (e: AnnotationDeleteModalProps) =>
    dispatch(setAnnotationDelete(e));

  const annotationDeleteModal = useAppSelector(
    (state) => state.modals.annotationDeleteModal,
  );

  return { annotationDeleteModal, setAnnotationDeleteModal };
};
