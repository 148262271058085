import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const StyledButton = styled.div`
  :focus {
    border: ${({ theme }) => `1px solid ${theme.colors.blackPurple}`};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.lilac};
  }
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0px;
  display: flex;
  cursor: pointer;
`;
