import { TableAnnotation } from 'api';
import { TableStyleSettings } from 'components/ExtractionTable/ExtractionTable.interface';
import { find, flatten } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import { selectDataObjectById } from 'store/reducers/collection';
import {
  selectFieldArrow,
  setCellEditMode,
  selectTableEditMode,
} from 'store/reducers/supervision';
import { TextAreaCell } from './components/TextAreaCell/TextAreaCell';

export interface CellProps {
  id: string;
  styleSettings?: TableStyleSettings;
  cellId: string;
  width: number | undefined;
}

export const Cell = ({ id, cellId, width }: CellProps) => {
  const fieldArrow = useSelector(selectFieldArrow);
  const tableObject = useSelector((state: StoreProps) =>
    selectDataObjectById(state, fieldArrow?.pageId, fieldArrow?.id),
  ) as TableAnnotation;
  const dispatch = useDispatch();
  const tableEditMode = useSelector((state: StoreProps) =>
    selectTableEditMode(state, tableObject?.id),
  );
  const item = find(flatten(tableObject?.rows), (item) => item.id === cellId);

  const handleValueChange = (text?: string) => {
    dispatch(
      setCellEditMode({
        tableId: tableObject?.id,
        id: cellId,
        type: 'cell',
        text,
      }),
    );
  };

  return (
    <TextAreaCell
      value={tableEditMode?.[cellId] ? tableEditMode?.[cellId].text : item?.text}
      id={id}
      corrected={item?.status.status === 'Corrected'}
      onChange={handleValueChange}
      width={width}
    />
  );
};
