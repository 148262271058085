import { FC, useState } from 'react';
import { store } from 'react-notifications-component';
import { Item, useContextMenu, TriggerEvent } from 'react-contexify';
import { api, DocumentType } from 'api';
import moment from 'moment';
import { ConfirmationModal } from 'modals/ConfirmationModal';
import { DropdownMenu } from 'components/DropdownMenu';
import { ProcessStatus } from 'components/ProcessStatus';
import { RowAction, RowProps } from './Row.interface';
import { createMenu, rowAction } from './Row.menu';
import {
  Cell,
  Container,
  StyledCheckbox,
  StyledFlag,
  StyledIconFile,
  StyledIconFolder,
  StyledDocumentType,
  StyledName,
  StyledDate,
  MenuContainer,
  EmptySpace,
  StyledMenu,
  StyledFiles,
  PriorityContainer,
  StyledProgress,
  StyledPages,
  StyledTotalFiles,
  NameContainer,
  ColumnName,
  StyledIconMarkToDeleteIcon,
} from './Row.styles';
import { useRefreshList, useUserScope } from 'hooks';
import { Notification } from 'components/Notification';
import { useQueueCollections } from 'providers';
import { useTranslation } from 'react-i18next';
import { collectionProcessed } from 'utils/helpers';
import { useExportModalStore } from 'store/reducers/modals';
import { ENV_VARIABLE } from 'variables';

const documentTypeIcon = (type: DocumentType) => {
  if (type === 'file') {
    return <StyledIconFile />;
  }

  return <StyledIconFolder />;
};

export const Row: FC<RowProps> = ({
  onRowClick,
  onSidePanel,
  onRowChecked,
  onViewResults,
  selected,
  checked,
  item,
}) => {
  const { setSelectedRowId } = useQueueCollections();
  const [showMenu, setShowMenu] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const { refresh } = useRefreshList();
  const { show } = useContextMenu({
    id: item.collectionId,
  });
  const { setExportModal } = useExportModalStore();
  const { t } = useTranslation();
  const { isAllowed: deletePermission } = useUserScope('COLLECTION_DELETE');
  const { isAllowed: startStopPermission } = useUserScope('COLLECTION_START_STOP');
  const { isAllowed: readPermission } = useUserScope('RESULT_READ');
  const { isAllowed: exportPermission } = useUserScope('RESULT_EXPORT');

  const toggleContextMenu = (event: TriggerEvent) => {
    setSelectedRowId(undefined);
    event.preventDefault();
    show(event);
  };

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const onExport = () => {
    setExportModal({ open: true, collectionIds: [item.collectionId] });
  };

  const onRowAction = (action: RowAction) => {
    if (action === 'delete') {
      toggleDeleteModal();
    }

    if (action === 'flag') {
      api.updatePriority(item.collectionId, !item.highPriority).then(() => {
        refresh();
      });
    }

    if (action === 'start') {
      api.collectionAction(item.collectionId, 'start').then(() => {
        refresh();
      });
    }

    if (action === 'stop') {
      api.collectionAction(item.collectionId, 'stop').then(() => {
        refresh();
      });
    }

    if (action === 'results') {
      onViewResults(item.collectionId, item.collectionName);
    }

    if (action === 'export') {
      onExport();
    }

    if (action === 'details') {
      onSidePanel();
    }

    if (action === 'mark') {
      api.markToDelete(item.collectionId).then(() => {
        refresh();
      });
    }

    if (action === 'unmark') {
      api.unmarkFromDelete(item.collectionId).then(() => {
        refresh();
      });
    }
  };

  const onDelete = () => {
    toggleDeleteModal();

    api.deleteCollection(item.collectionId).then(() => {
      refresh();

      const successNotification = () => (
        <Notification content={`${item.collectionName} has been successfully deleted.`} />
      );
      store.addNotification({
        insert: 'top',
        content: successNotification,
        container: 'top-center',
        animationIn: ['animate__animated animate__zoomIn'],
        animationOut: ['animate__animated animate__zoomOut'],
        dismiss: {
          duration: 2000,
        },
      });
    });
  };

  const menuActions = createMenu(
    item.highPriority,
    item.status,
    collectionProcessed(item),
    readPermission,
    deletePermission,
    startStopPermission,
    exportPermission,
    t,
    item.metadata?.tags?.mark_to_delete,
  );

  const onRow = () => {
    if (collectionProcessed(item) && readPermission) {
      onRowClick();
    }
  };

  return (
    <>
      <Container
        $checked={checked}
        $selected={selected}
        $highlighted={showContextMenu}
        onClick={onSidePanel}
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
        onContextMenu={toggleContextMenu}
      >
        <Cell size={5}>
          <StyledCheckbox
            checked={checked}
            onClick={(event) => {
              event.stopPropagation();
              onRowChecked();
            }}
          />
          <PriorityContainer>
            {item.highPriority ? <StyledFlag /> : <EmptySpace />}
          </PriorityContainer>
        </Cell>
        <Cell size={44}>
          <ColumnName
            onClick={(e) => {
              e.stopPropagation();
              onRow();
            }}
          >
            <NameContainer $showMenu={showMenu}>
              <StyledDocumentType>
                {documentTypeIcon(item.files.length > 1 ? 'folder' : 'file')}
              </StyledDocumentType>
              <StyledName size="regular" bold>
                {item.collectionName}
              </StyledName>
              {item.files.length > 1 && (
                <StyledFiles size="regular" bold>
                  &nbsp;{`(${item.files.length})`}
                </StyledFiles>
              )}
              {ENV_VARIABLE.MARK_TO_DELETE_ENABLED === 'true' &&
                item.metadata?.tags?.mark_to_delete === 'true' && (
                  <StyledIconMarkToDeleteIcon />
                )}
            </NameContainer>
            {menuActions.length > 0 && showMenu && (
              <MenuContainer>
                <DropdownMenu
                  onMenuItem={(id) => onRowAction(rowAction(id))}
                  items={menuActions}
                />
              </MenuContainer>
            )}
          </ColumnName>
        </Cell>
        <Cell size={14}>
          <ProcessStatus status={item.status} />
        </Cell>
        <Cell size={12}>
          <StyledProgress progress={item.progress} />
        </Cell>
        <Cell size={13}>
          <StyledDate>
            {moment(new Date(item.createdAt)).format('DD/MM/YYYY HH:mm:ss')}
          </StyledDate>
        </Cell>
        <Cell size={5}>
          <StyledTotalFiles>{item.totalFiles}</StyledTotalFiles>
        </Cell>
        <Cell size={7}>
          <StyledPages>{item.totalPages || '-'}</StyledPages>
        </Cell>
      </Container>
      <ConfirmationModal
        onClose={toggleDeleteModal}
        onConfirm={onDelete}
        open={openDeleteModal}
        title={`${t('deleteQuestion')} ${item.collectionName}?`}
        content={t('deleteInfoSingleFile')}
      />
      {menuActions.length > 0 && (
        <StyledMenu
          onHidden={() => setShowContextMenu(false)}
          onShown={() => setShowContextMenu(true)}
          id={item.collectionId}
        >
          {menuActions.map(({ id, label, Icon }) => (
            <Item
              key={id}
              style={{
                marginLeft: 5,
                marginRight: 5,
                borderRadius: '3px',
              }}
              onClick={() => onRowAction(rowAction(id))}
            >
              <Icon style={{ width: '15px', height: '15px', marginRight: '8px' }} />
              {label}
            </Item>
          ))}
        </StyledMenu>
      )}
    </>
  );
};
