import { FC, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { DateRange, DropdownMenuProps } from './FilterDatePicker.interface';
import {
  Container,
  MenuContainer,
  StyledFilterLabel,
  StyledArrowDownIcon,
  StyledRangePicker,
  StyledRange,
  StyledIconDeleteWhite,
  StyledSelectedFilter,
  StyledRangeFilter,
} from './FilterDatePicker.styles';
import { formatDate } from 'utils/helpers';
import moment from 'moment';

const dateRanges: Array<DateRange> = ['today', 'last_seven_days', 'last_month'];

const dateRange = (type: DateRange, translation: TFunction) => {
  switch (type) {
    case 'last_month':
      return translation('lastMonth');
    case 'last_seven_days':
      return translation('last7Days');
    case 'today':
    default:
      return translation('today');
  }
};

const dateRangeDates = (
  type: DateRange,
): [moment.Moment | null, moment.Moment | null] => {
  switch (type) {
    case 'last_month':
      return [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ];
    case 'last_seven_days':
      return [moment().subtract(7, 'day').startOf('day'), moment().endOf('day')];
    case 'today':
    default:
      return [moment().startOf('day'), moment().endOf('day')];
  }
};

export const FilterDatePicker: FC<DropdownMenuProps> = ({
  dateFrom,
  dateTo,
  onDates,
}) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [range, setRange] = useState<DateRange>();
  const [dates, setDates] = useState<[moment.Moment | null, moment.Moment | null] | null>(
    null,
  );
  const pickerRef = useRef<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (dateFrom && dateTo) {
      setDates([moment(dateFrom), moment(dateTo)]);
    }
  }, [dateFrom, dateTo]);

  const onClear = () => {
    setDates(null);
    setRange(undefined);
    onDates(undefined, undefined);
  };

  const onDateRange = (item: DateRange) => {
    setDates(null);
    setOpenPicker(false);
    setRange(item);
    onDates(formatDate(dateRangeDates(item)[0]), formatDate(dateRangeDates(item)[1]));
  };

  return (
    <Container>
      <MenuContainer $hovered={openPicker}>
        <StyledFilterLabel bold size="regular">
          {t('date')}:
          {range ? (
            <span
              onClick={() => {
                setRange(undefined);
                setOpenPicker(true);
              }}
            >
              <StyledRangeFilter size="small" bold>
                {dateRange(range, t)}
              </StyledRangeFilter>
            </span>
          ) : (
            <StyledSelectedFilter
              $selected={(dates !== null && dates.length > 0) || !!range}
            >
              <StyledRangePicker
                separator="-"
                placeholder={[t('startDate') || '', t('endDate') || '']}
                ref={pickerRef}
                $selected={dates !== null && dates.length > 0}
                style={{
                  boxShadow: '0 0 0 0 transparent',
                }}
                value={dates}
                open={openPicker}
                onCalendarChange={(props) => {
                  const from =
                    props !== null && props[0] && props[0] !== null
                      ? formatDate(props[0].startOf('day'))
                      : undefined;
                  const to =
                    props !== null && props[1] && props[1] !== null
                      ? formatDate(props[1].endOf('day'))
                      : undefined;

                  onDates(from, to);

                  if (props !== null && props[0] !== null && props[1] != null) {
                    setDates([props[0], props[1]]);
                    setOpenPicker(false);
                    pickerRef.current?.blur();
                  } else {
                    setDates(null);
                  }
                }}
                onOpenChange={setOpenPicker}
                suffixIcon={null}
                clearIcon={null}
                renderExtraFooter={() => (
                  <div
                    style={{
                      height: '4vh',
                      lineHeight: '2vh',
                      paddingTop: '1vh',
                    }}
                  >
                    {dateRanges.map((item) => (
                      <span key={item} onClick={() => onDateRange(item)}>
                        <StyledRange size="small" bold $selected={item === range}>
                          {dateRange(item, t)}
                        </StyledRange>
                      </span>
                    ))}
                  </div>
                )}
              />
            </StyledSelectedFilter>
          )}
        </StyledFilterLabel>
        {(dates && dates.length > 0) || range ? (
          <StyledIconDeleteWhite onClick={onClear} />
        ) : (
          <StyledArrowDownIcon
            onClick={() => {
              if (!range) {
                setOpenPicker(true);
              }
              pickerRef.current?.focus();
            }}
          />
        )}
      </MenuContainer>
    </Container>
  );
};
