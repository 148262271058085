import {
  Annotation,
  AnnotationStatus,
  DataObject,
  Document,
  isAnnotationType,
  StatusMsg,
  TagGroup,
} from 'api';
import { TFunction } from 'react-i18next';
import {
  chain,
  Dictionary,
  find,
  flatten,
  get,
  isEmpty,
  uniq,
  uniqBy,
  values,
} from 'lodash';
import { extractUncertainItems, mapAnnotation } from 'utils/helpers';
import { FilterPhrase } from './Filters/Filters.interface';
import { SelectOption } from 'components/Select';
import { basicAnnotationTypes, extendedAnnotationTypes } from '../ExtractedFieldListItem';

export const mapMsg = (status?: StatusMsg) => {
  switch (status) {
    case 'Missing value':
      return 'Missing';
    case 'Invalid Format':
      return 'Invalid';
    case 'Uncertain Annotation':
    default:
      return 'Uncertain';
  }
};

export const filterPages = (data?: Array<Document>) => {
  return data
    ? data.filter(
        (document) =>
          document.pages.filter((page) => (page.dataObjects || []).length > 0).length >
            0 ||
          (document.tagGroups && document.tagGroups.length > 0),
      )
    : [];
};

const mapItems = (document: Document, t: TFunction) => {
  let items: any[] = [];

  if (document.tagGroups) {
    const tagGroup = {
      id: `group_${document.pages.length > 0 && document.pages[0].id}`,
      isTagGroup: true,
      pageId: document.pages.length > 0 && document.pages[0].id,
      tags: document.tagGroups,
      resultId: document.resultId,
      documentId: document.id,
    };

    items = [tagGroup];
  }

  return [
    ...items,
    ...flatten(
      document.pages.map((page, index) => {
        const { dataObjects = [] } = page;

        const fields = [
          ...dataObjects.map((dataObject) => ({
            id: dataObject.id,
            pageId: page.id,
            annotationId: dataObject.id,
            type: dataObject.type,
            resultId: document.resultId,
            documentId: document.id,
            annotations: get(dataObject, 'annotations'),
          })),
        ];

        if (fields.length > 0) {
          return [
            {
              id: page.id,
              label: page.label || `${t('page')} ${index + 1}/${document.pages.length}`,
              isPage: true,
            },
            ...fields,
          ];
        }

        return fields;
      }),
    ),
  ];
};

export const mapDocuments = (documents: Array<Document>, t: TFunction) =>
  documents.map((docItem) => ({
    annotationAttentions: extractUncertainItems(docItem),
    id: docItem.id,
    label: docItem.fileName,
    labels: docItem.labels,
    collapsed: false,
    isGroup: true,
    items: flatten(mapItems(docItem, t)),
  }));

const phrasesFromQuery = (query?: string): Array<string> =>
  query
    ?.match(/\\?.|^$/g)
    ?.reduce(
      (p, c) => {
        if (c === '"') {
          p.quote ^= 1;
        } else if (!p.quote && c === ' ') {
          p.a.push('');
        } else {
          p.a[p.a.length - 1] += c.replace(/\\(.)/, '$1').toLowerCase();
        }
        return p;
      },
      { a: [''], quote: 0 },
    )
    .a.filter((substring) => substring.length > 0) || [];

const containsStatus = (annotationStatus: AnnotationStatus, status: Array<string>) => {
  return (
    annotationStatus.status === 'Needs Attention' &&
    status.includes(mapMsg(annotationStatus.msg))
  );
};

const complexContainsStatus = (annotations: Array<Annotation>, status: Array<string>) => {
  return find(
    annotations,
    (item) =>
      item.status.status === 'Needs Attention' &&
      status.includes(mapMsg(item.status.msg)),
  );
};

export const containsSearch = (
  annotationTypes: Array<SelectOption>,
  annotation: Annotation,
  search?: string,
) => {
  const annotations = [...(annotation.annotations || []), annotation];
  const splitSearch = phrasesFromQuery(search);

  for (let i = 0; i < annotations.length; i += 1) {
    if (
      splitSearch.some((substring) =>
        annotations[i].value?.toLowerCase().includes(substring),
      ) ||
      splitSearch.some((substring) =>
        mapAnnotation(annotationTypes, annotations[i].label)
          ?.toLowerCase()
          .includes(substring),
      ) ||
      isEmpty(search)
    ) {
      return true;
    }
  }

  return false;
};

export const containsPhrase = (annotation: Annotation, phrases: Array<FilterPhrase>) => {
  for (let i = 0; i < phrases.length; i++) {
    if (phrases[i].fieldType === annotation.label && phrases[i].phrases.length > 0) {
      return (
        phrases[i].fieldType === annotation.label &&
        phrases[i].phrases.some(
          (item) => annotation.value.toLowerCase() === item.toLowerCase(),
        )
      );
    }
  }

  return false;
};

export const isFieldType = (annotationType: string, fieldTypes?: Array<string>) => {
  return fieldTypes?.includes(annotationType);
};

export const applyFilters = (
  filterAnnotations: Array<DataObject>,
  annotationTypes: Array<SelectOption>,
  status: Array<string>,
  phrases: Array<FilterPhrase>,
  fieldTypes: Array<string>,
  fieldTypeOccurrencesId: Dictionary<Array<string>>,
  fieldTypesDuplicates: Array<string>,
  search?: string,
) => {
  let filterByStatus: Array<DataObject> = [];
  let filterByFieldType: Array<DataObject> = [];
  let filterByPhrases: Array<DataObject> = [];

  if (status?.length > 0) {
    filterByStatus = filterAnnotations.filter(
      (item) =>
        (basicAnnotationTypes.includes(item.type) &&
          containsStatus(item.status, status)) ||
        (item.type === 'complex' &&
          complexContainsStatus((item as Annotation).annotations, status)),
    );
  }

  if (fieldTypes && fieldTypes.length > 0) {
    filterByFieldType = filterAnnotations.filter(
      (item) =>
        extendedAnnotationTypes.includes(item.type) &&
        isFieldType((item as Annotation).label, fieldTypes),
    );
  }

  if (isPhrasesEmpty(phrases)) {
    filterByPhrases = filterAnnotations.filter(
      (item) =>
        extendedAnnotationTypes.includes(item.type) &&
        containsPhrase(item as Annotation, phrases),
    );
  }

  let selectedAnnotations = uniqBy(
    [...filterByStatus, ...filterByFieldType, ...filterByPhrases],
    (item) => item.id,
  );

  if (status?.length === 0 && fieldTypes?.length === 0 && !isPhrasesEmpty(phrases)) {
    selectedAnnotations = [...filterAnnotations];
  }

  selectedAnnotations = selectedAnnotations.filter((item) =>
    containsSearch(annotationTypes, item as Annotation, search),
  );

  if (fieldTypesDuplicates.length > 0) {
    selectedAnnotations = selectedAnnotations.filter((item) => {
      if (
        fieldTypesDuplicates.includes(item.label) &&
        fieldTypeOccurrencesId[item.label]?.includes(item.id)
      ) {
        return false;
      }

      return true;
    });
  }

  return selectedAnnotations;
};

const isTagGroupUncertain = (tagGroups?: Array<TagGroup>) => {
  return (
    tagGroups &&
    tagGroups.filter(
      (item) =>
        item.status.status === 'Needs Attention' ||
        item.tags.filter((tag) => tag.status.status === 'Needs Attention').length > 0,
    ).length > 0
  );
};

export const filterAnnotations = (
  t: TFunction,
  annotationTypes: Array<SelectOption>,
  documents: Dictionary<Document>,
  annotations: Dictionary<Dictionary<DataObject>>,
  tagGroups: Dictionary<Array<TagGroup>>,
  status: Array<string>,
  phrases: Array<FilterPhrase>,
  fieldTypes: Array<string>,
  fieldTypesDuplicates: Array<string>,
  search?: string,
) => {
  return values(documents).reduce((acc: Array<Document>, value) => {
    const pages = [];

    for (let j = 0; j < value.pages.length; j += 1) {
      const filterAnnotations = values(annotations[value.pages[j].id]);

      const selectedAnnotations = applyFilters(
        filterAnnotations,
        annotationTypes,
        status,
        phrases,
        fieldTypes,
        findDuplicatedFieldsIdByType(annotations, fieldTypesDuplicates),
        fieldTypesDuplicates,
        search,
      );

      if (selectedAnnotations.length > 0) {
        pages.push({
          ...value.pages[j],
          dataObjects: selectedAnnotations,
          label: `${t('page')} ${j + 1}/${value.pages.length}`,
        });
      }
    }

    if (pages.length > 0) {
      acc.push({ ...value, pages: pages });
    }

    if (tagGroups[value.id] && isTagGroupUncertain(tagGroups[value.id])) {
      acc.push({ ...value, tagGroups: tagGroups[value.id] });
    }

    return acc;
  }, []);
};

export const findDuplicatedFieldsIdByType = (
  annotations: Dictionary<Dictionary<DataObject>>,
  fieldTypesDuplicates: Array<string>,
) => {
  const uniqueValuesByFieldType: Dictionary<Array<string>> = {};

  const data = chain(annotations)
    .values()
    .map((page) => values(page))
    .flatten()
    .flatten()
    .reduce((acc: Dictionary<Array<string>>, item) => {
      const isAnnotation = isAnnotationType(item);

      if (isAnnotation) {
        const annotation = item as Annotation;

        if (
          fieldTypesDuplicates.includes(annotation.label) &&
          uniqueValuesByFieldType[annotation.label]?.includes(annotation.value)
        ) {
          acc[annotation.label] = [...(acc[annotation.label] || []), annotation.id];
        } else {
          uniqueValuesByFieldType[annotation.label] = [
            ...(uniqueValuesByFieldType[annotation.label] || []),
            annotation.value,
          ];
        }
      }

      return acc;
    }, {})
    .value();

  return data;
};

export const isPhrasesEmpty = (phrases: Array<FilterPhrase>) => {
  return phrases?.some((item) => item.fieldType && item.phrases?.length);
};

export const searchSuggestions = (
  dataObjects: Dictionary<Dictionary<DataObject>>,
  annotationTypes: Array<SelectOption> = [],
) => {
  const list = flatten(values(dataObjects).map((item) => values(item)));

  return uniq([
    ...annotationTypes.map((item) => item.label),
    ...list
      .map((dataObject) =>
        basicAnnotationTypes.includes(dataObject.type)
          ? (dataObject as Annotation).value
          : '',
      )
      .filter((text) => text && text.length > 0),
    ...flatten(
      list.map((dataObject) =>
        dataObject.type === 'complex'
          ? (dataObject as Annotation).annotations.map((item) => item.value || '')
          : [],
      ),
    ).filter((text) => text && text.length > 0),
  ]).sort((a, b) => a.localeCompare(b));
};
