import { BaseDispatch, CollectionStore } from 'store/store.interface';
import {
  COLLECTION_EDITOR,
  COLLECTION_ERROR,
  COLLECTION_STATUS,
  updateCollectionEditorState,
  updateCollectionErrorState,
  updateCollectionStatusState,
  updateTagGroupState,
  UPDATE_TAG_GROUP,
} from '.';
import {
  COLLECTION,
  COLLECTION_LOADING,
  UPDATE_ANNOTATION,
  DELETE_ANNOTATION,
  CREATE_ANNOTATION,
  updateDataObjectsState,
  updateCollectionLoadingState,
  updateCollectionState,
  deleteDataObjectState,
  createDataObjectState,
  UPDATE_METADATA,
  updateCollectionMetadataState,
} from './collection';

const INITIAL_STATE = {
  loading: true,
  name: undefined,
  collectionId: '',
  annotationAttentions: 0,
  totalPages: 0,
  totalFiles: 0,
  documents: {},
  dataObjects: {},
  images: {},
  words: {},
  thumbnailSections: {},
  pages: {},
  tagGroups: {},
  tagGroupsOptions: {},
  processedDocuments: 0,
  complexAnnotationSchemas: [],
  metadata: {},
};

const CLEAR_COLLECTION_STATE = 'CLEAR_COLLECTION_STATE';

export const clearCollection = () => (dispatch: (data: BaseDispatch) => void) => {
  dispatch({
    type: CLEAR_COLLECTION_STATE,
  });
};

export const collectionReducer = (
  state: CollectionStore = INITIAL_STATE,
  action: any,
) => {
  switch (action.type) {
    case CREATE_ANNOTATION:
      return createDataObjectState(state, action.payload);
    case DELETE_ANNOTATION:
      return deleteDataObjectState(state, action.payload);
    case UPDATE_ANNOTATION:
      return updateDataObjectsState(state, action.payload.annotation);
    case UPDATE_METADATA:
      return updateCollectionMetadataState(state, action.payload.data);
    case UPDATE_TAG_GROUP:
      return updateTagGroupState(state, action.payload);
    case COLLECTION_LOADING:
      return updateCollectionLoadingState(state, action.payload.loading);
    case COLLECTION_ERROR:
      return updateCollectionErrorState(state, action.payload);
    case COLLECTION:
      return updateCollectionState(state, action.payload);
    case COLLECTION_EDITOR:
      return updateCollectionEditorState(state, action.payload);
    case COLLECTION_STATUS:
      return updateCollectionStatusState(state, action.payload);
    case CLEAR_COLLECTION_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
