import { FC, useEffect, useMemo, useState } from 'react';
import { PageThumbnailsList } from './PageThumbnailsList';
import { ExtractedFields } from './ExtractedFields';
import { useTranslation } from 'react-i18next';
import { Header } from './Header';
import { SupervisionProps } from './Supervision.interface';
import {
  Container,
  SupervisionContent,
  InfoSectionContainer,
  PagesContainer,
  InfoContainer,
  StyledInfo,
} from './Supervision.styles';
import { FieldArrow } from 'components/FieldArrow';
import SplitPane from 'components/SplitPane/SplitPane';
import Pane from 'components/SplitPane/Pane';
import { useSupervisionStore } from './Supervision.hooks';
import { Loader } from 'components/Loader';
import { PageList } from './PageList';
import {
  countAttentions,
  countUncertainTagGroups,
  parseQueryParams,
  supervisionSettings,
} from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import {
  flatToPages,
  flatToThumbnails,
  mapErrorStatus,
  phrasesFromQuery,
} from './Supervision.helpers';
import { Dictionary, values } from 'lodash';
import { useTyroHistory, useUserScope } from 'hooks';
import { ExportModal } from 'modals/ExportModal';
import { useExportModalStore } from 'store/reducers/modals';
import { useInterval } from 'hooks/useInterval';
import { ENV_VARIABLE } from 'variables';
import { useBrowserClosed } from 'hooks/useBrowserClosed';
import { statusFilters } from './ExtractedFields/Filters';
import { ExtractionTable } from 'components/ExtractionTable';
import { useSelector } from 'react-redux';
import { selectFieldArrow } from 'store/reducers/supervision';
import { Transition } from 'react-transition-group';
import { SplittingModal } from './SplittingModal';

const REFRESH_INTERVAL = 5 * 60 * 1000;
export const PAGES_CONTAINER_ID = 'pagesContainer';

export const Supervision: FC<SupervisionProps> = ({
  match: {
    params: { id },
  },
}) => {
  const {
    error,
    loading,
    documents,
    commonTypes,
    collectionStatus,
    processedDocuments,
    editorId,
    user,
    dataObjects,
    tagGroups,
    setPaneSizes,
    setPaneDrag,
    setSearch,
    setSortBy,
    fetchCollection,
    setFilterByPhrases,
    setFilterByFieldDuplicates,
    setFilterByFieldType,
    keepAliveEditor,
    setEditMode,
    setFilterByStatus,
    addAnnotationsEditMode,
    addGroupTagsEditMode,
  } = useSupervisionStore();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [splittingModal, setSplittingModal] = useState(false);
  const { addQueryParams } = useTyroHistory();
  const { isAllowed } = useUserScope('RESULT_READ');
  const { isAllowed: updatePermission } = useUserScope('RESULT_UPDATE');
  const attentions =
    countAttentions(values(dataObjects)) + countUncertainTagGroups(tagGroups);
  useBrowserClosed();

  useInterval(
    () => keepAliveEditor(),
    REFRESH_INTERVAL,
    updatePermission &&
      editorId === user?.sub &&
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED === 'true',
  );
  const fieldArrow = useSelector(selectFieldArrow);
  const isTableSelected = fieldArrow?.item?.type === 'table';
  const collectionFailed =
    (error || collectionStatus === 'Failed') && !loading && processedDocuments === 0;

  const onSplittingModalClose = () => {
    setSplittingModal(false);

    fetchCollection(id);
  };

  const onSplit = () => {
    setSplittingModal(true);
  };

  const updateUrlParams = () => {
    const settings = supervisionSettings();
    const params = parseQueryParams(search);

    if (settings.editMode) {
      addAnnotationsEditMode();
      addGroupTagsEditMode();

      setEditMode(settings.editMode);

      if (settings.uncertainFilter && attentions > 0) {
        setFilterByStatus([statusFilters(t)[0].label]);
      }
    }

    if (params.search && typeof params.search === 'string') {
      setSearch(params.search as string);
    }

    if (!params.order) {
      setSortBy('position');

      addQueryParams({ order: 'position' });
    } else {
      setSortBy(settings.sortFieldsBy);
    }

    if (params.fields) {
      setFilterByFieldType(
        Array.isArray(params.fields) ? params.fields : [params.fields as string],
      );
    }

    if (params.duplicates) {
      setFilterByFieldDuplicates(
        Array.isArray(params.duplicates)
          ? params.duplicates
          : [params.duplicates as string],
      );
    }

    if (
      phrasesFromQuery(
        params as Dictionary<string | Array<string>>,
        commonTypes.annotationsTypes,
      )
    ) {
      setFilterByPhrases(
        phrasesFromQuery(
          params as Dictionary<string | Array<string>>,
          commonTypes.annotationsTypes,
        ),
      );
    }
  };

  useEffect(() => {
    if (documents && commonTypes?.annotationsTypes.length > 0) {
      updateUrlParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, commonTypes]);

  useEffect(() => {
    // if (
    //   id &&
    //   commonTypes?.annotationsTypes.length > 0 &&
    //   (user || !ENV_VARIABLE.KEY_CLOAK_URL)
    // ) {
    fetchCollection(id);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const pageThumbnails = useMemo(() => flatToThumbnails(documents), [documents]);
  const pages = useMemo(() => flatToPages(documents), [documents]);
  const { exportModal, setExportModal, onExport } = useExportModalStore();

  return (
    <>
      <Container>
        <Header onSplit={onSplit} />
        {!collectionFailed && !loading && (
          <SupervisionContent>
            <SplitPane
              size="100%"
              split="vertical"
              onChange={(sizes) => {
                setPaneSizes(sizes);
              }}
              onResizeStart={() => {
                setPaneDrag(true);
              }}
              onResizeEnd={(sizes) => {
                localStorage.setItem('pane', JSON.stringify(sizes));
                setPaneDrag(false);
              }}
            >
              <Pane minSize="0%" maxSize="40%">
                <PageThumbnailsList data={pageThumbnails} />
              </Pane>
              <PagesContainer id={PAGES_CONTAINER_ID}>
                <PageList data={pages} />

                <Transition in={Boolean(isTableSelected)} timeout={0}>
                  {(state) => <ExtractionTable transition={state} />}
                </Transition>
              </PagesContainer>
              <Pane minSize="25%" maxSize="40%">
                <InfoSectionContainer>
                  <ExtractedFields />
                </InfoSectionContainer>
              </Pane>
            </SplitPane>
          </SupervisionContent>
        )}
        {(collectionFailed || loading || !isAllowed) && (
          <InfoContainer>
            {collectionFailed && <StyledInfo>{mapErrorStatus(t, error)}</StyledInfo>}
            {loading && <Loader label={t('loading')} />}
            {!isAllowed && <StyledInfo>{t('readNotAllowed')}</StyledInfo>}
          </InfoContainer>
        )}
      </Container>
      <FieldArrow />
      <ExportModal
        open={exportModal.open}
        onClose={() => setExportModal({ open: false })}
        onConfirm={onExport}
      />
      <SplittingModal
        collectionId={id}
        onClose={onSplittingModalClose}
        open={splittingModal}
      />
    </>
  );
};
