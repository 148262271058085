import { Input } from 'antd';
import { Button } from 'components/Button';
import { IconDelete } from 'components/Icons';
import { Label } from 'components/Label';
import styled from 'styled-components';
import { ModalSize } from './CreateField.interface';

export const Container = styled.div<{ $size: ModalSize }>`
  ${({ $size }) => ({ width: $size.width, minHeight: $size.height })};
  z-index: 10000;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.colors.gray400};
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(38, 26, 89, 0.3);
  display: flex;
  flex-direction: column;
  > :not(:first-child) {
    margin-top: 16px;
  }
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  margin-top: 16px;
`;

export const CloseIcon = styled(IconDelete)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray300};
`;

export const TitleLabel = styled(Label)`
  left: ${({ theme }) => theme.padding.normal};
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const HeaderContainer = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  :hover {
    cursor: grab;
  }
  :active {
    cursor: grabbing;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px 16px;
`;

export const TextArea = styled(Input.TextArea)`
  max-height: 250px;
  border-radius: 6px;
`;

export const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.gray300};
  padding-top: 8px;
`;
