import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentPageId,
  selectEditField,
  selectFieldArrow,
  selectPaneDragging,
  updateCurrentPage,
  updateEditField,
} from 'store/reducers/supervision';
import { selectPageArrow } from 'store/reducers/supervision/pageArrow';

export const usePageListStore = () => {
  const dispatch = useDispatch();
  const setCurrentPage = useCallback((e) => dispatch(updateCurrentPage(e)), [dispatch]);
  const setEditField = useCallback((e) => dispatch(updateEditField(e)), [dispatch]);
  const fieldArrow = useSelector(selectFieldArrow);
  const pageArrow = useSelector(selectPageArrow);
  const editField = useSelector(selectEditField);
  const currentPageId = useSelector(selectCurrentPageId);
  const paneDragging = useSelector(selectPaneDragging);
  const currentPage = 1;

  return {
    setCurrentPage,
    fieldArrow,
    pageArrow,
    currentPage,
    editField,
    setEditField,
    currentPageId,
    paneDragging,
  };
};
