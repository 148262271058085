import styled from 'styled-components';

interface LookUpWrapperProps {
  $left: number;
  $top: number;
  $height: number;
  $width: number;
  $visible: boolean;
  $selected: boolean;
}

export const LookUpWrapper = styled.div<LookUpWrapperProps>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => $left}px;
  height: ${({ $height }) => $height}px;
  width: ${({ $width }) => $width}px;
  border: ${({ $visible, theme }) =>
    $visible ? `1px solid ${theme.colors.darkPurple}` : 0};
  background-color: ${({ $visible, $selected, theme }) =>
    $visible
      ? $selected
        ? 'rgba(70,45,140,0.6)'
        : 'rgba(70, 45, 140, 0.2)'
      : theme.colors.transparent};
  cursor: pointer;
  pointer-events: none;
`;
