import { ProcessStatus } from 'api';
import { MenuItem } from 'components/DropdownMenu/DropdownMenu.interface';
import { IconPlay, IconStop, IconPurpleFlag, IconInfo } from 'components/Icons';
import { TFunction } from 'react-i18next';
import { ENV_VARIABLE } from 'variables';
import { RowAction } from './Row.interface';
import {
  StyledDeleteIcon,
  StyledIconViewResults,
  StyledIconUpload,
  StyledIconMarkToDelete,
} from './Row.styles';

export const rowAction = (id: number): RowAction => {
  switch (id) {
    case 1:
      return 'start';
    case 2:
      return 'stop';
    case 3:
      return 'delete';
    case 4:
      return 'flag';
    case 6:
      return 'results';
    case 7:
      return 'export';
    case 9:
      return 'mark';
    case 10:
      return 'unmark';
    case 8:
    default:
      return 'details';
  }
};

const optionFlag = {
  id: 4,
  label: 'Flag',
  Icon: IconPurpleFlag,
};

export const createMenu = (
  highPriority: boolean,
  status: ProcessStatus,
  collectionProcessed: boolean,
  readPermision: boolean,
  deletePermission: boolean,
  startStopPermission: boolean,
  exportPermission: boolean,
  translation: TFunction,
  markToDelete?: string,
): Array<MenuItem> => {
  const menu: Array<MenuItem> = [];
  const flagItem = {
    ...optionFlag,
    label: highPriority ? translation('unflag') : translation('flag'),
  };

  menu.push({
    id: 8,
    label: translation('viewDetails'),
    Icon: IconInfo,
  });

  if (collectionProcessed && exportPermission) {
    menu.push({
      id: 7,
      label: translation('export'),
      Icon: StyledIconUpload,
    });
  }

  if (status === 'Queued' && startStopPermission) {
    menu.push({
      id: 2,
      label: translation('stop'),
      Icon: IconStop,
    });
  }

  if (status === 'Stopped' && startStopPermission) {
    menu.push({
      id: 1,
      label: translation('start'),
      Icon: IconPlay,
    });
  }

  if (deletePermission) {
    menu.push({
      id: 3,
      label: translation('delete'),
      Icon: StyledDeleteIcon,
    });
  }

  if ((status === 'Queued' || status === 'Stopped') && startStopPermission) {
    menu.push(flagItem);
  }

  if (
    collectionProcessed &&
    readPermision &&
    ENV_VARIABLE.SHOW_RESULT_ENABLED === 'true'
  ) {
    menu.push({
      id: 6,
      label: translation('viewResults'),
      Icon: StyledIconViewResults,
    });
  }

  if (
    collectionProcessed &&
    markToDelete !== 'true' &&
    ENV_VARIABLE.MARK_TO_DELETE_ENABLED === 'true'
  ) {
    menu.push({
      id: 9,
      label: translation('markToDelete'),
      Icon: StyledIconMarkToDelete,
    });
  }

  if (
    collectionProcessed &&
    markToDelete === 'true' &&
    ENV_VARIABLE.MARK_TO_DELETE_ENABLED === 'true'
  ) {
    menu.push({
      id: 10,
      label: translation('unmarkToDelete'),
      Icon: StyledIconMarkToDelete,
    });
  }

  return menu;
};
