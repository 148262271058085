import { Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Select';
import {
  StyledTitle,
  SwitchContainer,
  SwitchLabel,
  SelectContainer,
} from './Supervision.styles';
import { orderOptions } from 'pages/Supervision/ExtractedFields/Filters';
import { supervisionSettings, SUPERVISION_SETTINGS } from 'utils/helpers';

export const Supervision: FC = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [uncertainFilter, setUncertainFilter] = useState(false);
  const [sortFieldsBy, setSortFieldsBy] = useState('position');

  useEffect(() => {
    const settings = supervisionSettings();

    setEditMode(settings.editMode);
    setUncertainFilter(settings.uncertainFilter);
    setSortFieldsBy(settings.sortFieldsBy);
  }, []);

  const onEditMode = (value: boolean) => {
    setEditMode(value);

    const settings = { editMode: value, uncertainFilter, sortFieldsBy };

    window.localStorage.setItem(SUPERVISION_SETTINGS, JSON.stringify(settings));
  };

  const onUncertainFilter = (value: boolean) => {
    setUncertainFilter(value);

    const settings = { editMode, uncertainFilter: value, sortFieldsBy };

    window.localStorage.setItem(SUPERVISION_SETTINGS, JSON.stringify(settings));
  };

  const onSortFieldsBy = (value?: string) => {
    if (value) {
      setSortFieldsBy(value);

      const settings = { editMode, uncertainFilter, sortFieldsBy: value };

      window.localStorage.setItem(SUPERVISION_SETTINGS, JSON.stringify(settings));
    }
  };

  return (
    <>
      <StyledTitle>{t('supervisionSettings')}</StyledTitle>
      <SwitchContainer>
        <SwitchLabel size="small">{t('defaultEditMode')}:</SwitchLabel>
        <div>
          <Switch size="small" checked={editMode} onChange={onEditMode} />
        </div>
      </SwitchContainer>
      <SwitchContainer>
        <SwitchLabel size="small">{t('defaultUncertain')}:</SwitchLabel>
        <div>
          <Switch size="small" checked={uncertainFilter} onChange={onUncertainFilter} />
        </div>
      </SwitchContainer>
      <SelectContainer>
        <SwitchLabel size="small">{t('sortFieldsBy')}:</SwitchLabel>
        <Select
          size="small"
          value={sortFieldsBy}
          options={orderOptions(t)}
          onChange={onSortFieldsBy}
        />
      </SelectContainer>
    </>
  );
};
