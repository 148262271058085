import { useAppDispatch, useAppSelector } from 'store/store.hooks';
import { InviteUserModalProps } from './modals.interface';
import { setInviteUser } from './modalsSlice';

export const useInviteUserModalStore = () => {
  const dispatch = useAppDispatch();
  const setInviteUserModal = (e: InviteUserModalProps) => dispatch(setInviteUser(e));
  const inviteUserModal = useAppSelector((state) => state.modals.inviteUserModal);

  return { open: inviteUserModal.open, setInviteUserModal };
};
