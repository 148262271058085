import { useTyroHistory } from 'hooks';
import { useEffect } from 'react';
import { useAppSelector } from 'store/store.hooks';
import { QUEUES_PARAMS } from 'utils/helpers';

const DEFAULT_ORDER = 'dd';

export const useQueues = () => {
  const search = useAppSelector((state) => state.queues.search);
  const from = useAppSelector((state) => state.queues.from);
  const to = useAppSelector((state) => state.queues.to);
  const status = useAppSelector((state) => state.queues.status);
  const priority = useAppSelector((state) => state.queues.priority);
  const order = useAppSelector((state) => state.queues.order);
  const page = useAppSelector((state) => state.queues.page);
  const pageSize = useAppSelector((state) => state.queues.pageSize);
  const markToDelete = useAppSelector((state) => state.queues.markToDelete);
  const { addQueryParams } = useTyroHistory();

  useEffect(() => {
    addQueryParams({
      search,
      from,
      to,
      status,
      priority,
      order: order || DEFAULT_ORDER,
      page,
      size: pageSize,
      mark_to_delete: markToDelete || undefined,
    });

    localStorage.setItem(
      QUEUES_PARAMS,
      JSON.stringify({
        search,
        from,
        to,
        status,
        priority,
        order: order || DEFAULT_ORDER,
        page,
        pageSize,
        mark_to_delete: markToDelete || undefined,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, from, to, status, priority, order, page, pageSize, markToDelete]);
};
