import ReactNotification from 'react-notifications-component';
import { ThemeProvider } from 'styled-components';
import { store } from 'store';
import { theme } from 'styles';
import { Provider } from 'react-redux';
import './App.css';
import { CollectionsProvider, QueueCollectionsProvider } from 'providers';
import { Router } from 'router';
import { CommonData } from './CommonData';
import { ToastContainer } from 'react-toastify';
import { ENV_VARIABLE } from 'variables';
import { Keycloak } from './Keycloak';

const TyroApp = () => (
  <ThemeProvider theme={theme}>
    <CommonData>
      <QueueCollectionsProvider>
        <CollectionsProvider>
          <ReactNotification />
          <Router />
        </CollectionsProvider>
      </QueueCollectionsProvider>
    </CommonData>
    <ToastContainer />
  </ThemeProvider>
);

export default function App() {
  return (
    <Provider store={store}>
      {ENV_VARIABLE.KEY_CLOAK_URL ? (
        <Keycloak>
          <TyroApp />
        </Keycloak>
      ) : (
        <TyroApp />
      )}
    </Provider>
  );
}
