import { IconChevronDown, IconLoader } from 'components/Icons';
import { FC } from 'react';
import { SelectProps } from './Select.interface';
import { StyledSelect, Container, StyledLabel } from './Select.styles';
import { useTranslation } from 'react-i18next';

export const Select: FC<SelectProps> = ({
  onChange,
  onFocus,
  onChangeMultiple,
  value,
  label,
  options,
  mode,
  placeholder,
  disabled = false,
  allowClear = false,
  size,
  loading,
  listHeight,
  ...other
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      {label && (
        <StyledLabel size="small" bold>
          {label}
        </StyledLabel>
      )}
      <StyledSelect
        getPopupContainer={(trigger) => trigger.parentNode}
        value={value}
        disabled={disabled}
        showSearch
        options={options}
        mode={mode}
        $inputSize={size}
        suffixIcon={<IconChevronDown />}
        onFocus={() => onFocus && onFocus()}
        optionFilterProp="label"
        allowClear={allowClear}
        placeholder={placeholder}
        onChange={(value) => {
          if (Array.isArray(value) && onChangeMultiple) {
            onChangeMultiple(value as Array<string>);
          } else if (onChange) {
            onChange(value?.toString());
          }
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        listHeight={listHeight}
        notFoundContent={loading ? <IconLoader /> : t('emptyData')}
        {...other}
      />
    </Container>
  );
};
