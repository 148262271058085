import { FC, useState } from 'react';
import { Button } from 'components/Button';
import Modal from 'react-modal';
import { InviteUserModalProps } from './InviteUserModal.interface';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  StyledTitle,
  StyledInput,
  StyledSelect,
  StyledCancel,
  Container,
  Divider,
  FieldsContainer,
  FieldContainer,
} from './InviteUserModal.styles';
import { isEmpty } from 'lodash';

const options = () => {
  return [
    { value: 'editor', label: 'Editor' },
    { value: 'reader', label: 'Reader' },
  ];
};

export const InviteUserModal: FC<InviteUserModalProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();

  const onCreate = () => {
    if (email && selectedRole) {
      onConfirm(email, selectedRole);

      setEmail(undefined);
      setSelectedRole(undefined);

      onClose();
    }
  };

  const onCancel = () => {
    setEmail(undefined);
    setSelectedRole(undefined);

    onClose();
  };

  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'modal-base-narrow',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <StyledTitle size="h3">{t('inviteUserTitle')}</StyledTitle>
        <FieldsContainer>
          <FieldContainer>
            <StyledInput
              label={t('emailAddress')}
              value={email}
              onChange={setEmail}
              shouldFocus
            />
          </FieldContainer>
          <FieldContainer>
            <StyledSelect
              size="small"
              label={t('role')}
              value={selectedRole}
              onChange={setSelectedRole}
              options={options()}
            />
          </FieldContainer>
        </FieldsContainer>
        <Divider />
        <ButtonContainer>
          <StyledCancel
            onClick={onClose}
            color="light"
            size="large"
            title={t('cancel')}
          />

          <Button
            size="large"
            onClick={onCreate}
            disabled={!selectedRole || isEmpty(email)}
            title={t('invite')}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
