import { Ref, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useUserScope } from 'hooks';
import {
  ExtractedFieldsListProps,
  FieldItem,
  GroupItem,
  instanceOfTagGroupsItem,
  TagGroupsItem,
} from './ExtractedFieldsList.interface';
import { isFieldFullyVisible, isGroupFullyVisible } from 'utils/helpers';
import { fieldDataObjectId } from 'components/FieldArrow';
import { ENV_VARIABLE } from 'variables';
import { useSelector } from 'react-redux';
import { selectFieldArrow, selectPageArrow } from 'store/reducers/supervision';
import { selectCollectionStatus, selectEditorId } from 'store/reducers/collection';
import { selectUser } from 'store/reducers/common';
import memoizeOne from 'memoize-one';
import { ProcessStatus } from 'api';

const getItemData = memoizeOne(
  (onOpenGroup, flattenedData, setIsComplexFieldOpen, collapsedComplexFields) => ({
    onOpenGroup,
    flattenedData,
    setIsComplexFieldOpen,
    collapsedComplexFields,
  }),
);

interface ExtractedFieldsListData {
  status?: ProcessStatus;
  resizeListRef: Ref<VariableSizeList>;
  flattenedData: Array<GroupItem | FieldItem | TagGroupsItem>;
  itemData: {
    onOpenGroup: (node: { id: string }) => void;
    flattenedData: ExtractedFieldsListData['flattenedData'];
    setIsComplexFieldOpen: (id: string, isOpen: boolean) => void;
    collapsedComplexFields: string[];
  };
  showAcceptNext: boolean;
  collapsedComplexFields: string[];
}

export const useExtractedFieldsList = ({
  data,
  onGroupCollapse,
  collapsedGroups,
}: ExtractedFieldsListProps): ExtractedFieldsListData => {
  const pageArrow = useSelector(selectPageArrow);
  const fieldArrow = useSelector(selectFieldArrow);
  const status = useSelector(selectCollectionStatus);
  const editorId = useSelector(selectEditorId);
  const user = useSelector(selectUser);

  const resizeListRef = useRef<VariableSizeList>(null);
  const { isAllowed } = useUserScope('RESULT_UPDATE');

  const flattenOpened = () => {
    let result: Array<GroupItem | FieldItem | TagGroupsItem> = [];

    for (let groupItem of data) {
      result = flattenItems(groupItem, result);
    }

    return result;
  };

  const flattenItems = (
    groupItem: GroupItem,
    result: Array<GroupItem | FieldItem | TagGroupsItem>,
  ) => {
    const { id, label, items, ...other } = groupItem;

    let collapsed = !collapsedGroups?.includes(id);

    result.push({
      id,
      label,
      ...other,
      collapsed,
    });

    if (!collapsed && items) {
      return result.concat(items);
    }

    return result;
  };

  const flattenedData = flattenOpened();

  useEffect(() => {
    if (!isFieldFullyVisible(fieldDataObjectId(fieldArrow?.id)) && fieldArrow?.id) {
      for (let i = 0; i < flattenedData.length; i++) {
        if (flattenedData[i].id === fieldArrow.id) {
          resizeListRef.current?.scrollToItem(i, 'start');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldArrow, flattenedData]);

  useEffect(() => {
    if (pageArrow && !isGroupFullyVisible(pageArrow.pageId) && pageArrow?.pageId) {
      for (let i = 0; i < flattenedData.length; i++) {
        if (instanceOfTagGroupsItem(flattenedData[i])) {
          const tagGroup = flattenedData[i] as TagGroupsItem;

          if (tagGroup.pageId === pageArrow.pageId) {
            resizeListRef.current?.scrollToItem(i, 'start');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageArrow, flattenedData]);

  useEffect(() => {
    resizeListRef.current?.resetAfterIndex(0);
  }, [collapsedGroups, flattenedData]);

  const onOpenGroup = (node: { id: string }) => {
    onGroupCollapse(node.id);
  };

  const showAcceptNext = Boolean(
    !ENV_VARIABLE.KEY_CLOAK_URL ||
      (editorId && editorId === user?.sub && isAllowed) ||
      (ENV_VARIABLE.KEY_CLOAK_URL &&
        isAllowed &&
        ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED !== 'true'),
  );

  const [collapsedComplexFields, setCollapsedComplexFields] = useState<Array<string>>([]);
  const setIsComplexFieldOpen = (fieldId: string, isOpen: boolean) => {
    if (isOpen) {
      setCollapsedComplexFields((prev) => [...prev, fieldId]);
    } else {
      setCollapsedComplexFields(collapsedComplexFields.filter((id) => id !== fieldId));
    }
  };

  const itemData = getItemData(
    onOpenGroup,
    flattenedData,
    setIsComplexFieldOpen,
    collapsedComplexFields,
  );

  return {
    status,
    resizeListRef,
    flattenedData,
    itemData,
    showAcceptNext,
    collapsedComplexFields,
  };
};
