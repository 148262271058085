import { FC, useEffect } from 'react';
import Modal from 'react-modal';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { UploadFilesModalProps } from './UploadFilesModal.interface';
import { useUploadFiles } from 'hooks';
import {
  StyledTitle,
  StyledSubTitle,
  StyledDropInfo,
  CloseIcon,
  Container,
  DropZoneContainer,
  FileList,
  StyledUploadFiles,
  StyledIconUploadFolder,
  FilesContainer,
} from './UploadFilesModal.styles';
import { UploadFile } from './UploadFile';
import { keys, values } from 'lodash';
import { ACCEPTED_FILES, FILE_1_MB } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { ENV_VARIABLE } from 'variables';

export const UploadFilesModal: FC<UploadFilesModalProps> = ({
  open,
  onClose,
  onUploadFinish,
}) => {
  const {
    addFiles,
    files,
    clearData,
    filesProgress,
    uploadingFiles,
    refreshFile,
  } = useUploadFiles();
  const { t } = useTranslation();

  useEffect(() => {
    if (keys(filesProgress).length > 0) {
      const filesUploaded = values(filesProgress).filter((item) => item.progress !== 100)
        .length;

      if (filesUploaded === 0) {
        onUploadFinish();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesProgress]);

  const onDrop = (acceptedFiles: FileWithPath[]) => {
    addFiles(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
    maxSize: ENV_VARIABLE.MAX_FILE_SIZE
      ? FILE_1_MB * ENV_VARIABLE.MAX_FILE_SIZE
      : undefined,
    useFsAccessApi: false,
  });

  const onCloseCallback = () => {
    if (!uploadingFiles) {
      clearData();
      onClose();
    }
  };

  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
      onRequestClose={onCloseCallback}
    >
      <CloseIcon onClick={onCloseCallback} />
      <Container>
        <StyledTitle level={3}>{t('uploadYourFiles')}</StyledTitle>
        <StyledSubTitle>{t('uploadYourFilesInfo')}</StyledSubTitle>
        <DropZoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <>
            <StyledIconUploadFolder />
            <StyledDropInfo>
              {t('dragAndDropFiles')}
              <br />
              {t('or')}
            </StyledDropInfo>
          </>
          <Button size="large" title={t('chooseFromComputer')} color="light" />
        </DropZoneContainer>
        {files.length > 0 && keys(filesProgress).length > 0 && (
          <FilesContainer>
            <StyledUploadFiles>{t('uploadedFiles')}</StyledUploadFiles>
            <FileList>
              {files
                .filter((item) => filesProgress[item.name]?.progress < 100)
                .map((file) => (
                  <UploadFile
                    key={file.size}
                    name={file.name}
                    size={file.size}
                    failed={filesProgress[file.name]?.failed}
                    uploading={filesProgress[file.name]?.uploading}
                    uploadProgress={filesProgress[file.name]?.progress}
                    onRefresh={() => refreshFile(file.name)}
                  />
                ))}
              {files
                .filter((item) => filesProgress[item.name]?.progress === 100)
                .map((file) => (
                  <UploadFile
                    key={file.size}
                    name={file.name}
                    size={file.size}
                    uploading={filesProgress[file.name]?.uploading}
                    uploadProgress={filesProgress[file.name]?.progress}
                  />
                ))}
            </FileList>
          </FilesContainer>
        )}
      </Container>
    </Modal>
  );
};
