import styled from 'styled-components';

interface SizeProps {
  height?: number;
  $selected?: boolean;
}

interface DotProps {
  $top: number;
}

export const Container = styled.div<SizeProps>`
  width: 100%;
  height: ${({ height }) => height}px;
  display: flex;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  box-shadow: ${({ theme, $selected }) =>
    `0 0 11px 0 ${$selected ? theme.colors.gray200 : theme.colors.gray400}`};
  background-color: ${({ theme }) => theme.colors.white};
  flex-shrink: 0;
`;

export const PageContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  scrollbar-width: none;
  padding-top: 8px;
  padding-bottom: 8px;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

export const Dot = styled.div<DotProps>`
  position: absolute;
  top: ${({ $top }) => $top - 6}px;
  right: 0;
  z-index: 1001;
  margin-right: -6px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.darkPurple};
  border: ${({ theme }) => `3px solid ${theme.colors.white}`};
  box-shadow: 0 2px 4px 0 rgba(182, 20, 103, 0.3);
`;
