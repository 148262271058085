import { useEffect, useState } from 'react';
import { api } from 'api';
import { useLocation } from 'react-router-dom';
import { parseQueryParams, stringifyParams } from 'utils/helpers';
import { useQueueCollections } from 'providers';
import { useQueuesFilters } from 'store/reducers/queues';

export const useCollectionsList = () => {
  const {
    collections,
    addCollections,
    setTotalSize,
    totalSize,
    selectedRowId,
    setSelectedRowId,
  } = useQueueCollections();
  const [loading, setLoading] = useState(false);
  const {
    search,
    from,
    to,
    priority,
    status,
    order,
    page,
    pageSize,
    markToDelete,
    setPageFilter,
  } = useQueuesFilters();

  useEffect(() => {
    let queryParams = {
      page,
      size: pageSize,
      order,
      status,
      priority,
      search,
      from,
      to,
      mark_to_delete: markToDelete || undefined,
    };

    setLoading(true);

    api
      .fetchCollections(stringifyParams(queryParams))
      .then(({ collections, totalNumCollections }) => {
        addCollections(collections);
        setTotalSize(totalNumCollections);

        if (totalNumCollections < Number(pageSize) && Number(page) !== 1) {
          setPageFilter(1);
        }
      })
      .catch(() => {
        /* no-op */
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, status, priority, search, from, to, page, pageSize, markToDelete]);

  return { collections, totalSize, loading, selectedRowId, setSelectedRowId };
};

export const useRefreshList = () => {
  const { addCollections, setTotalSize } = useQueueCollections();
  const { setPageFilter } = useQueuesFilters();
  const { search: locationSearch } = useLocation();

  const { page, size } = parseQueryParams(locationSearch);

  const refresh = () => {
    api
      .fetchCollections(stringifyParams(parseQueryParams(locationSearch)))
      .then(({ collections, totalNumCollections }) => {
        addCollections(collections);
        setTotalSize(totalNumCollections);

        if (totalNumCollections < Number(size) && Number(page) !== 1) {
          setPageFilter(1);
        }
      })
      .catch(() => {
        /* no-op */
      });
  };

  return { refresh };
};
