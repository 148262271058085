import { Input } from 'components/Input';
import styled from 'styled-components';

interface StyledCellProps {
  corrected?: boolean;
}

export const StyledCell = styled.div<StyledCellProps>`
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  padding: 0 17px;
  font-family: ${({ theme, corrected }) =>
    corrected ? theme.fontFamily.semiBold : theme.fontFamily.regular};
`;

export const StyledInput = styled(Input)`
  padding-left: 16px;
`;

export const overlayStyle: React.CSSProperties = {
  whiteSpace: 'pre-line',
  width: 'content-width',
  height: '100%',
  color: 'white',
  position: 'relative',
};
