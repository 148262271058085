import { useTyroHistory } from 'hooks';
import i18n from 'i18n';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { parseQueryParams, updateUrlParam } from 'utils/helpers';

export const useLanguage = () => {
  const { addQueryParams } = useTyroHistory();
  const { search } = useLocation();

  useEffect(() => {
    const localLanguage = localStorage.getItem('language');
    const { lang } = parseQueryParams(search);

    if (!lang) {
      if (localLanguage) {
        addQueryParams({
          lang: localLanguage,
        });

        i18n?.changeLanguage(localLanguage);
      } else if (lang !== i18n?.language) {
        addQueryParams({
          lang: 'en',
        });

        i18n?.changeLanguage('en');
      }
    } else if (lang) {
      if (lang !== i18n?.language) {
        i18n?.changeLanguage(lang as string);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, search]);

  const changeLanguage = () => {
    const language = i18n?.language === 'pl' ? 'en' : 'pl';
    localStorage.setItem('language', language);

    i18n?.changeLanguage(language);

    updateUrlParam('lang', language);
  };

  return { language: i18n?.language, changeLanguage };
};
