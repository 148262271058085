import { FC, useState } from 'react';
import { Button } from 'components/Button';
import { QueuesFilters } from './QueueFilters';
import { QueueTable } from './QueueTable';
import { SidePanel } from './SidePanel';
import { UploadFilesModal } from 'modals/UploadFilesModal';
import { UploadFoldersModal } from 'modals/UploadFoldersModal';
import { DropdownMenu } from 'components/DropdownMenu';
import { useTranslation } from 'react-i18next';
import {
  StyledTitle,
  Content,
  Container,
  Header,
  HeaderContent,
  TableContainer,
  StyledIconChevronDown,
} from './Queues.styles';
import { useCollectionsList, useRefreshList, useTyroHistory, useUserScope } from 'hooks';
import { useInterval } from 'hooks/useInterval';
import { uploadMenu } from './Queues.menu';
import { ExportModal } from 'modals/ExportModal';
import { useExportModalStore } from 'store/reducers/modals';
import { ENV_VARIABLE } from 'variables';
import { useQueues } from './Queues.hooks';

const REFRESH_INTERVAL = 5000;

export const Queues: FC = () => {
  const [openUploadFilesModal, setOpenUploadFilesModal] = useState(false);
  const [openUploadFoldersModal, setOpenUploadFoldersModal] = useState(false);
  const {
    collections,
    loading,
    totalSize,
    selectedRowId,
    setSelectedRowId,
  } = useCollectionsList();
  const { refresh } = useRefreshList();
  const { setExportModal, exportModal, onExport } = useExportModalStore();
  const { isAllowed } = useUserScope('COLLECTION_CREATE');
  const { t } = useTranslation();
  const { openSupervision } = useTyroHistory();
  useQueues();

  const openSidePanel = (id: string) => {
    setSelectedRowId(id === selectedRowId ? undefined : id);
  };

  const onRow = (id: string) => {
    openSupervision(id);
  };

  useInterval(() => refresh(), REFRESH_INTERVAL);

  const onUploadMenu = (id: number) => {
    if (id === 1) {
      setOpenUploadFilesModal(true);
    } else {
      setOpenUploadFoldersModal(true);
    }
  };

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <StyledTitle size="h2">{t('queues')}</StyledTitle>
            {ENV_VARIABLE.DISABLE_UPLOAD?.toLowerCase() !== 'true'
              ? isAllowed && (
                  <DropdownMenu
                    trigger={['click']}
                    onMenuItem={(id) => onUploadMenu(id)}
                    items={uploadMenu(t)}
                  >
                    <Button
                      shadow={false}
                      size="large"
                      title={t('upload')}
                      icon={<StyledIconChevronDown />}
                      data-uid="queues-page.upload-button"
                    />
                  </DropdownMenu>
                )
              : null}
          </HeaderContent>
        </Header>
        <Content>
          <QueuesFilters />
          <TableContainer>
            <QueueTable
              loading={loading}
              data={collections}
              totalSize={totalSize}
              selectedRowId={selectedRowId}
              onSidePanel={openSidePanel}
              onRowClick={onRow}
            />
          </TableContainer>
        </Content>
        <SidePanel
          collection={
            collections.filter((item) => item.collectionId === selectedRowId)[0]
          }
          id={selectedRowId}
          open={!!selectedRowId}
        />
      </Container>
      <UploadFilesModal
        open={openUploadFilesModal}
        onClose={() => setOpenUploadFilesModal(false)}
        onUploadFinish={() => refresh()}
      />
      <UploadFoldersModal
        open={openUploadFoldersModal}
        onClose={() => setOpenUploadFoldersModal(false)}
        onUploadFinish={() => refresh()}
      />
      <ExportModal
        open={exportModal.open}
        onClose={() => setExportModal({ open: false })}
        onConfirm={onExport}
      />
    </>
  );
};
