import { useLanguage } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledFlagEngland,
  StyledFlagPoland,
  StyledTitle,
  VerticalContainer,
  Label,
} from './Language.styles';

export const Language: FC = () => {
  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();

  return (
    <>
      <StyledTitle>{t('selectLanguage')}</StyledTitle>
      <VerticalContainer onClick={changeLanguage}>
        <StyledFlagPoland selected={language === 'pl'} />
        <Label selected={language === 'pl'}>Polski</Label>
      </VerticalContainer>
      <VerticalContainer onClick={changeLanguage}>
        <StyledFlagEngland selected={language === 'en'} />
        <Label selected={language === 'en'}>English</Label>
      </VerticalContainer>
    </>
  );
};
