import { TFunction } from 'react-i18next';
import { StyledDeleteIcon, StyledIconCheck, StyledIconEdit } from './ComplexField.styles';

export const menuMore = (translation: TFunction) => [
  {
    id: 1,
    label: translation('edit'),
    Icon: StyledIconEdit,
  },
  {
    id: 2,
    label: translation('delete'),
    Icon: StyledDeleteIcon,
  },
];

export const menuMoreAttention = (translation: TFunction) => [
  ...menuMore(translation),
  {
    id: 3,
    label: translation('acceptWithoutEditing'),
    Icon: StyledIconCheck,
  },
];
