import React, { useEffect } from 'react';
import { Route, RouteComponentProps, useLocation, useHistory } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { ENV_VARIABLE } from 'variables';
import { useSelector } from 'react-redux';
import { selectAuthenticated } from 'store/reducers/common';

interface PrivateRouteParams extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export function PrivateRoute({ component: Component, ...rest }: PrivateRouteParams) {
  const authenticated = useSelector(selectAuthenticated);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!authenticated && ENV_VARIABLE.KEY_CLOAK_URL) {
      history.replace('/login', { redirect: location.pathname });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
