import { TFunction } from 'react-i18next';
import { StyledIconEdit } from './TableField.styles';

export const menuMore = (translation: TFunction) => [
  {
    id: 1,
    label: translation('edit'),
    Icon: StyledIconEdit,
  },
];
