import { Button } from 'components/Button';
import { Headline } from 'components/Headline';
import { Input } from 'components/Input';
import { Select } from 'components/Select';
import styled from 'styled-components';

export const StyledTitle = styled(Headline)`
  user-select: none;
  margin-left: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
  margin-bottom: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.blackPurple};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledSelect = styled(Select)`
  color: ${({ theme }) => theme.colors.blackPurple};
  user-select: none;
  line-height: 1em;
`;

export const StyledInput = styled(Input)`
  height: 40px;
  border-radius: 6px;
  line-height: 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.padding.xmedium};
  margin-bottom: ${({ theme }) => theme.padding.xtiny};
`;

export const FieldContainer = styled.div`
  margin-top: ${({ theme }) => theme.padding.small};
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
  display: flex;
  height: 50px;
  justify-content: flex-end;
`;

export const Divider = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.line}`};
  margin-top: ${({ theme }) => theme.padding.xmedium};
  width: 100%;
`;

export const StyledCancel = styled(Button)`
  margin-right: ${({ theme }) => theme.padding.tiny};
`;
