import styled from 'styled-components';

export const StyledName = styled.div`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  user-select: none;
  color: ${({ theme }) => theme.colors.gray300};
  padding-left: 32px;
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.transparent};
  height: 35px;
  border-radius: 6px;
  width: 98%;
  align-items: center;
  display: flex;
`;
