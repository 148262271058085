import { Dropdown, Menu } from 'antd';
import { FC, useState } from 'react';
import { theme } from 'styles';
import { DropdownMenuProps, MenuItem } from './DropdownMenu.interface';
import {
  MenuContainer,
  StyledMenuItem,
  StyledMenuLabel,
  StyledMenuIcon,
} from './DropdownMenu.styles';

const buildMenu = (items: Array<MenuItem>, onClick: (id: number) => void) => (
  <Menu data-uid={'user-dropdown-menu'}>
    {items.map(({ id, label, Icon, uid }) => (
      <StyledMenuItem
        key={id}
        onClick={(event) => {
          event.domEvent.stopPropagation();
          onClick(id);
        }}
        data-uid={uid}
      >
        <Icon style={{ width: '15px', height: '15px', marginRight: '8px' }} />
        <StyledMenuLabel>{label}</StyledMenuLabel>
      </StyledMenuItem>
    ))}
  </Menu>
);

export const DropdownMenu: FC<DropdownMenuProps> = ({
  items,
  onMenuItem,
  children,
  onVisibilityChange,
  trigger = ['hover'],
}) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const onVisibleChange = (visible: boolean) => {
    onVisibilityChange && onVisibilityChange(visible);
    setMenuVisible(visible);
  };

  return (
    <Dropdown
      trigger={trigger}
      overlay={buildMenu(items, onMenuItem)}
      onVisibleChange={onVisibleChange}
      placement="bottomRight"
      overlayStyle={{ border: `1px solid ${theme.colors.gray400}`, borderRadius: '6px' }}
    >
      {children ? (
        children
      ) : (
        <MenuContainer
          onClick={(event) => event.stopPropagation()}
          $hovered={menuVisible}
        >
          <StyledMenuIcon />
        </MenuContainer>
      )}
    </Dropdown>
  );
};
