import { api } from 'api';
import { useUserScope } from 'hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCollectionId, selectEditorId } from 'store/reducers/collection';
import { selectUser } from 'store/reducers/common';
import { ENV_VARIABLE } from 'variables';

export const useBrowserClosed = () => {
  const collectionId = useSelector(selectCollectionId);
  const editorId = useSelector(selectEditorId);
  const user = useSelector(selectUser);
  const { isAllowed } = useUserScope('RESULT_UPDATE');

  useEffect(() => {
    if (
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED === 'true' &&
      user?.sub === editorId
    ) {
      window.addEventListener('beforeunload', deleteEditor);

      return () => {
        window.removeEventListener('beforeunload', deleteEditor);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId, user, editorId]);

  const deleteEditor = () => {
    if (
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED === 'true' &&
      editorId &&
      user?.sub === editorId &&
      isAllowed
    ) {
      api.deleteEditor(collectionId, editorId);
    }
  };
};
