import { Button } from 'components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  height: 72px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: rgba(178, 174, 193, 0.3);
`;

export const StyledNextButton = styled(Button)`
  pointer-events: auto;
`;
