import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

export interface PageArrow {
  id: string;
  pageId: string;
  documentId: string;
  resultId: string;
  tagGroupId?: string;
}

interface PageArrowProps {
  pageArrow?: PageArrow;
}

interface ArrowDispatch extends BaseDispatch {
  payload: PageArrowProps;
}

export const PAGE_ARROW = 'PAGE_ARROW';

export const selectPageArrow = (state: StoreProps) => state.supervision.pageArrow;

export const updatePageArrowState = (state: SupervisionStore, data: PageArrowProps) => {
  return { ...state, pageArrow: { ...data.pageArrow }, fieldArrow: undefined };
};

export const updatePageArrow = (pageArrow?: PageArrow) => (
  dispatch: (data: ArrowDispatch) => void,
) => {
  dispatch({
    type: PAGE_ARROW,
    payload: { pageArrow },
  });
};
