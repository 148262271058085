import {
  SET_AUTHENTICATED,
  SET_INITIALIZED,
  SET_USER,
  SET_COMMON_TYPES,
  SET_LANGUAGE,
  SET_EXTRACTOR_PREDEFINED_FIELDS,
  updateLanguageState,
  updateCommonTypesState,
  updateAuthenticatedState,
  updateInitializedState,
  updateUserState,
  updateExtractorPrefedinedFieldsState,
} from '.';

const INITIAL_STATE = {
  language: 'en',
  authenticated: false,
  initialized: false,
  extractorPrefedinedFields: {},
  commonTypes: {
    annotationsToAdd: [],
    annotationsTypes: [],
    tableAnnotationsTypes: {},
  },
};

export const commonReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_COMMON_TYPES:
      return updateCommonTypesState(state, action.payload);
    case SET_LANGUAGE:
      return updateLanguageState(state, action.payload);
    case SET_USER:
      return updateUserState(state, action.payload);
    case SET_AUTHENTICATED:
      return updateAuthenticatedState(state, action.payload);
    case SET_INITIALIZED:
      return updateInitializedState(state, action.payload);
    case SET_EXTRACTOR_PREDEFINED_FIELDS:
      return updateExtractorPrefedinedFieldsState(state, action.payload);
    default:
      return state;
  }
};
