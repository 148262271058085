import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 14px;
  padding-bottom: 8px;
  margin-left: 16px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  user-select: none;
  color: ${({ theme }) => theme.colors.gray200};
`;
