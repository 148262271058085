import ReactDOM from 'react-dom';
import App from './app/App';
import './index.css';
import 'react-notifications-component/dist/theme.css';
import 'react-contexify/dist/ReactContexify.css';
import 'animate.css/animate.min.css';
import 'react-virtualized/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import 'i18n';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
