import styled from 'styled-components';
import { Typography } from 'antd';
import { Button } from 'components/Button';

const { Title } = Typography;

export const Container = styled.div`
  width: 100%;
  display: flex;
`;

export const LoginContainer = styled.div`
  height: 100vh;
  width: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10%;
`;

export const ImageContainer = styled.div`
  width: 50%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.content};
`;

export const Logo = styled.img`
  width: 10vw;
  margin-bottom: 10vh;
`;

export const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${({ theme }) => theme.colors.darkPurple};
  }
`;

export const StyledSubTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 1.4em;
  margin-bottom: 4vh;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.darkPurple};
  height: 42px;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  border: ${({ theme }) => `1px ${theme.colors.darkPurple} solid`};
  :hover {
    background-color: ${({ theme }) => theme.colors.darkPurple};
    border: ${({ theme }) => `1px ${theme.colors.darkPurple} solid`};
  }
  :focus {
    background-color: ${({ theme }) => theme.colors.darkPurple};
    border: ${({ theme }) => `1px ${theme.colors.darkPurple} solid`};
  }
  justify-content: center;
`;

export const VersionContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

export const AppVersion = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 12px;
  padding-left: 8px;
  align-self: center;
`;
