import { Page } from 'api';

export interface DocumentSection {
  id: string;
  isGroup: boolean;
  label?: string;
}

export interface PageSection extends DocumentSection, Page {}

export interface PageThumbnailsListProps {
  data: Array<PageSection | DocumentSection>;
}

export const isPageSection = (
  item: DocumentSection | PageSection,
): item is PageSection => {
  return (item as PageSection).image !== undefined;
};
