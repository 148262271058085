import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
`;

export const SupervisionContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
`;

export const PagesContainer = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

export const InfoSectionContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.content};
`;

export const InfoContainer = styled.div`
  display: flex;
  height: 95vh;
  align-items: center;
  justify-content: center;
`;

export const StyledInfo = styled.span`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  margin-left: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
  margin-bottom: ${({ theme }) => theme.padding.xmedium};
  user-select: none;
`;
