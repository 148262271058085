import { Annotation } from 'api';
import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

export interface FieldArrow {
  id: string;
  item: Annotation;
  pageId: string;
  documentId: string;
  resultId: string;
}

interface FieldArrowProps {
  fieldArrow?: FieldArrow;
}

interface ArrowDispatch extends BaseDispatch {
  payload: FieldArrowProps;
}

export const FIELD_ARROW = 'FIELD_ARROW';

export const selectFieldArrow = (state: StoreProps) => state.supervision.fieldArrow;

export const updateFieldArrowState = (state: SupervisionStore, data: FieldArrowProps) => {
  return { ...state, fieldArrow: { ...data.fieldArrow }, pageArrow: undefined };
};

export const updateFieldArrow = (fieldArrow?: FieldArrow) => (
  dispatch: (data: ArrowDispatch) => void,
) => {
  dispatch({
    type: FIELD_ARROW,
    payload: { fieldArrow },
  });
};
