import { BaseDispatch, CommonStore, StoreProps } from 'store/store.interface';

export interface AuthenticatedType {
  authenticated: boolean;
}

interface AuthenticatedTypeDispatch extends BaseDispatch {
  payload: AuthenticatedType;
}

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';

export const selectAuthenticated = (state: StoreProps) => state.common.authenticated;

export const updateAuthenticatedState = (state: CommonStore, data: boolean) => {
  return { ...state, authenticated: data };
};

export const updateAuthenticated = (data: AuthenticatedType) => (
  dispatch: (data: AuthenticatedTypeDispatch) => void,
) => {
  dispatch({
    type: SET_AUTHENTICATED,
    payload: data,
  });
};
