export const fieldsEN = {
  buyer_nip: 'Buyer NIP',
  seller_nip: 'Seller NIP',
  payment: 'Payment amount',
  account_number: 'Account number',
  sell_date: 'Sell date',
  payment_date: 'Payment date',
  bank: 'Bank Name',
  buyer_address: 'Buyer Address',
  buyer_name: 'Buyer Name',
  invoice_item: 'Invoice Item',
  invoice_number: 'Invoice Number',
  issue_date: 'Issue Date',
  seller_address: 'Seller Address',
  seller_name: 'Seller Name',
  reciever_nip: 'Reciever NIP',
  reciever_phone: 'Reciever Phone',
  buyer_phone: 'Buyer Phone',
  reciever_address: 'Reciever Address',
  reciever_name: 'Reciever Name',
  seller_phone: 'Seller Phone',
  order_date: 'Order Date',
  payment_method: 'Payment Method',
  vat_amount: 'VAT Amount',
  netto_amount: 'Net Amount',
  invoice_items: 'Invoice Items',
  address: 'Address',
  execution_title: 'Execution title',
  execution_remission: 'Execution remission',
  pesel: 'Pesel',
  handwriting: 'Handwriting',
  names: 'Names',
  date_of_birth: 'Date of birth',
  nationality: 'Nationality',
  sex: 'Sex',
  expiry_date: 'Expiry date',
  date_of_issue: 'Issue date',
  place_of_birth: 'Place of birth',
  issuing_authority: 'Issuing authority',
  parents_names: 'Parents names',
  surname: 'Surname',
  family_name: 'Family name',
  account: 'Account',
  currentYear_turnover: 'Current turnover',
  previousYear_turnover: 'Previous turnover',
  currentYear_year: 'Current year',
  previousYear_year: 'Previous year',
  currentYear_months: 'Current months',
  previousYear_months: 'Previous months',
  currentYear_income: 'Current income',
  previousYear_income: 'Previous income',
  client: 'Client',
  objection: 'Objection',
  estate: 'Estate',
  contract: 'Contract',
  termination: 'Termination',
  death: 'Death',
  bankruptcy: 'Bankruptcy',
  enablement: 'Enablement',
  auction: 'Auction',
  appraisal: 'Appraisal',
  phone_number: 'Phone number',
  id_number: 'ID Number',
  nip: 'NIP',
  krs: 'KRS',
  regon: 'REGON',
  fraud: 'Fraud',
  proceedings_remission: 'Proceesings remission',
  execution: 'Execution',
  birth_date: 'Date of birth',
  clause: 'Clause',
  email: 'E-mail',
  mortgage_register: 'Mortgage register number',
  invalid_data: 'Invalid data',
  bank_enforcement_order: 'Bank enforcement order',
  electronic_writ_of_payment: 'Electronic writ of payment',
};

export const fieldsPL = {
  buyer_nip: 'NIP Kupującego',
  seller_nip: 'NIP Sprzedającego',
  payment: 'Kwota sprzedaży',
  account_number: 'Numer konta',
  sell_date: 'Data sprzedaży',
  payment_date: 'Data płatności',
  bank: 'Nazwa Banku',
  buyer_address: 'Adres Nabywcy',
  buyer_name: 'Adres Nabywcy',
  invoice_item: 'Pozycja na fakturze',
  invoice_number: 'Numer faktury',
  issue_date: 'Data wystawienia',
  seller_address: 'Adres Sprzedającego',
  seller_name: 'Nazwa Sprzedającego',
  address: 'Adres',
  execution_title: 'Tytuł wykonawczy',
  execution_remission: 'Umorzenie egzekucji',
  pesel: 'Pesel',
  handwriting: 'Pismo ręczne',
  names: 'Imiona',
  date_of_birth: 'Data urodzenia',
  nationality: 'Obywatelstwo',
  sex: 'Płeć',
  expiry_date: 'Data ważności dowodu osobistego',
  date_of_issue: 'Data wydania dowodu osobistego',
  place_of_birth: 'Miejsce urodzenia',
  issuing_authority: 'Organ wydający',
  parents_names: 'Imiona rodziców',
  surname: 'Nazwisko',
  family_name: 'Nazwisko rodowe',
  reciever_nip: 'NIP Odbierającego',
  reciever_phone: 'Telefon Odbierającego',
  buyer_phone: 'Telefon Kupującego',
  reciever_address: 'Adres Odbierającego',
  reciever_name: 'Nazwa Odbierającego',
  seller_phone: 'Numer Sprzedającego',
  order_date: 'Data Zamówienia',
  payment_method: 'Sposób Płatności',
  vat_amount: 'Wartość VAT',
  netto_amount: 'Wartość Netto',
  invoice_items: 'Pozycje na Fakturze',
  account: 'Konto',
  currentYear_turnover: 'Obrót bieżący',
  previousYear_turnover: 'Obrót poprzedni',
  currentYear_year: 'Rok bieżący',
  previousYear_year: 'Rok poprzedni',
  currentYear_months: 'Liczba miesięcy bieżąca',
  previousYear_months: 'Liczba miesięcy poprzednia',
  currentYear_income: 'Dochód brutto bieżący',
  previousYear_income: 'Dochód brutto poprzedni',
  client: 'Klient',
  objection: 'Sprzeciw',
  estate: 'Nieruchomość',
  contract: 'Umowa',
  termination: 'Wypowiedzenie umowy',
  death: 'Zgon',
  bankruptcy: 'Upadłość',
  enablement: 'Pełnomocnictwo',
  auction: 'Licytacja',
  appraisal: 'Operat szacunkowy',
  phone_number: 'Telefon',
  id_number: 'Dowód osobisty',
  nip: 'NIP',
  krs: 'KRS',
  regon: 'REGON',
  fraud: 'Fraud',
  proceedings_remission: 'Umorzenie postępowania sąd',
  execution: 'Egzekucja',
  birth_date: 'Data urodzenia',
  clause: 'Klauzula',
  email: 'Mail',
  mortgage_register: 'Numer księgi wieczystej',
  invalid_data: 'Błędne dane',
  bank_enforcement_order: 'Bankowy Tytuł Egzekucyjny',
  electronic_writ_of_payment: 'Elektroniczne postępowanie upominawcze',
};
