import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface EditFieldProps {
  id?: string;
}

interface EditFieldDispatch extends BaseDispatch {
  payload: EditFieldProps;
}

export const EDIT_FIELD = 'EDIT_FIELD';

export const selectEditField = (state: StoreProps) => state.supervision.editField;

export const updateEditFieldState = (state: SupervisionStore, data: EditFieldProps) => {
  return { ...state, editField: data.id };
};

export const updateEditField = (id?: string) => (
  dispatch: (data: EditFieldDispatch) => void,
) => {
  dispatch({
    type: EDIT_FIELD,
    payload: { id },
  });
};
