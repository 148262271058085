import React, { FC } from 'react';
import { HeaderButtonProps } from './HeaderButton.interface';
import { Container, StyledButton } from './HeaderButton.styles';

export const HeaderButton: FC<HeaderButtonProps> = ({
  title,
  onClick,
  active = false,
}) => {
  return (
    <Container $active={active}>
      <StyledButton onClick={() => onClick && onClick()} $active={active} type="link">
        {title}
      </StyledButton>
    </Container>
  );
};
