import { useAppDispatch, useAppSelector } from 'store/store.hooks';
import { EditUserModalProps } from './modals.interface';
import { setEditUser } from './modalsSlice';

export const useEditUserModalStore = () => {
  const dispatch = useAppDispatch();
  const setEditUserModal = (e: EditUserModalProps) => dispatch(setEditUser(e));
  const editUserModal = useAppSelector((state) => state.modals.editUserModal);

  return { open: editUserModal.open, setEditUserModal, user: editUserModal.user };
};
