import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Label } from 'components/Label';
import { IconFlag, IconTrash, IconUpload } from 'components/Icons';
import styled from 'styled-components';
import { SortType } from './Columns.interface';

interface ColumnProps {
  size?: number;
}

interface SortIconProps {
  sortType?: SortType;
}

export const Container = styled.div`
  padding-left: 1rem;
  height: 52px;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ColumnContainer = styled.div<ColumnProps>`
  min-width: ${({ size }) => `${size}%`};
  display: flex;
  align-items: center;
`;

export const StyledTypography = styled(Label)`
  color: ${({ theme }) => theme.colors.gray300};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-right: 3px;
  user-select: none;
  text-align: left;
`;

export const PriorityContainer = styled.div`
  width: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.padding.large};
`;

export const BulkActions = styled.div`
  display: flex;
`;

export const BulkAction = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-right: ${({ theme }) => theme.padding.tiny};
  height: 32px;
  width: 32px;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  :hover {
    background-color: ${({ theme }) => theme.colors.lilac};
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 2vh;
  margin-top: 1vh;
  margin-right: ${({ theme }) => theme.padding.tiny};
  background-color: ${({ theme }) => theme.colors.gray300};
`;

export const StyledIconDelete = styled(IconTrash)`
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledRedFlag = styled(IconFlag)`
  width: 15px;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledIconUpload = styled(IconUpload)`
  width: 15px;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const SortIcon = styled.img<SortIconProps>`
  height: 16px;
  user-select: none;
  transform: ${({ sortType }) => (sortType === 'd' ? 'rotate(180deg)' : 0)};
`;

export const StyledDeleteInfo = styled.div`
  margin-left: ${({ theme }) => theme.padding.xmedium};
  margin-right: ${({ theme }) => theme.padding.xmedium};
  margin-bottom: ${({ theme }) => theme.padding.xmedium};
`;

export const StyledFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.padding.xmedium};
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  max-height: 30vh;
  overflow: auto;
`;
