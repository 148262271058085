import { Popover as AntPopover } from 'antd';
import type { AlignType } from 'rc-trigger/lib/interface';
import { ReactNode, useState } from 'react';
interface AntPopoverProps {
  children: ReactNode;
  content: ReactNode;
  align?: AlignType;
}
export const Popover = ({ children, content, align }: AntPopoverProps) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  return (
    <AntPopover
      content={content}
      title=""
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      getPopupContainer={(trigger) =>
        trigger.parentElement!.parentElement!.parentElement!.parentElement!.parentElement!
      }
      zIndex={1000}
      align={align}
    >
      {children}
    </AntPopover>
  );
};
