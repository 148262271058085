import React, { FC } from 'react';
import { DocumentType } from 'api';
import { UploadFolderProps } from './UploadFolder.interface';
import {
  StyledDocumentType,
  StyledIconFile,
  StyledIconFolder,
  FileInfoContainer,
  FileNameContainer,
  StyledFolderName,
  StyledFileNumber,
  FileContainer,
  FileProgressContainer,
  StyledProgress,
  StyledProgressValue,
  UploadStatus,
  StyledLoader,
  StyledIconCheck,
  StyledIconRefresh,
} from './UploadFolder.styles';
import { theme } from 'styles';

const documentTypeIcon = (type: DocumentType) => {
  if (type === 'file') {
    return <StyledIconFile />;
  }

  return <StyledIconFolder />;
};

export const UploadFolder: FC<UploadFolderProps> = ({
  name,
  filesNumber,
  uploading,
  onRefresh,
  failed,
  uploadProgress = 0,
}) => {
  return (
    <FileContainer>
      <StyledDocumentType>{documentTypeIcon('folder')}</StyledDocumentType>
      <FileProgressContainer>
        <FileInfoContainer>
          <FileNameContainer>
            <StyledFolderName>{name}</StyledFolderName>
            <StyledFileNumber>{`(${filesNumber})`}</StyledFileNumber>
          </FileNameContainer>
          {uploadProgress > 0 && (
            <StyledProgressValue
              percent={uploadProgress}
            >{`${uploadProgress}%`}</StyledProgressValue>
          )}
        </FileInfoContainer>
        <StyledProgress
          trailColor={failed ? theme.colors.red : theme.colors.progressBg}
          strokeWidth={4}
          showInfo={false}
          percent={uploadProgress}
        />
      </FileProgressContainer>
      <UploadStatus>
        {failed && <StyledIconRefresh onClick={onRefresh} />}
        {uploadProgress < 100 ? (
          uploading && !failed && <StyledLoader />
        ) : (
          <StyledIconCheck />
        )}
      </UploadStatus>
    </FileContainer>
  );
};
