import { FC, useEffect, useState } from 'react';
import { ColumnsProps, ColumnType, SortType } from './Columns.interface';
import { IconPlay, IconStop } from 'components/Icons';
import {
  Container,
  PriorityContainer,
  StyledTypography,
  StyledCheckbox,
  ColumnContainer,
  BulkActions,
  BulkAction,
  StyledIconDelete,
  Separator,
  StyledRedFlag,
  StyledIconUpload,
  SortIcon,
  StyledDeleteInfo,
  StyledFileContainer,
} from './Columns.styles';
import { api, QueueCollection } from 'api';
import { useRefreshList, useCollectionsList } from 'hooks';
import { ConfirmationModal } from 'modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { ColumnHeader } from 'components/ColumnHeader';
import { useExportModalStore } from 'store/reducers/modals';
import iconSortEnabled from 'assets/icon_sort_enabled.png';
import iconSort from 'assets/icon_sort.png';
import { useUserScope } from 'hooks/useUserScope';
import { useQueuesFilters } from 'store/reducers/queues';

const actions = (
  startStopPermission: boolean,
  deletePermisssion: boolean,
  exportPermission: boolean,
  updatePermission: boolean,
) => {
  const actions = [];

  if (startStopPermission) {
    actions.push({ id: 1, Icon: IconPlay });
    actions.push({ id: 2, Icon: IconStop });
  }

  if (deletePermisssion) {
    actions.push({ id: 3, Icon: StyledIconDelete });
  }

  if (updatePermission) {
    actions.push({ id: 4, Icon: StyledRedFlag });
  }

  if (exportPermission) {
    actions.splice(3, 0, { id: 5, Icon: StyledIconUpload });
  }

  return actions;
};

const getCollection = (collections: Array<QueueCollection>, collectionId: string) => {
  for (var i = 0; i < collections.length; i = i + 1) {
    if (collections[i].collectionId === collectionId) {
      return collections[i];
    }
  }

  return undefined;
};

export const sortIcon = (enabled: boolean, sort?: SortType) => {
  return enabled ? (
    <SortIcon alt="sortEnabled" sortType={sort} src={iconSortEnabled} />
  ) : (
    <SortIcon alt="sort" src={iconSort} />
  );
};

export const Columns: FC<ColumnsProps> = ({ onSelectAll, checkedRows, onRowRemoved }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { collections, setSelectedRowId } = useCollectionsList();
  const [selectAll, setSelectAll] = useState(false);
  const [sortColumn, setSortColumn] = useState<{ column: ColumnType; sort: SortType }>();
  const { refresh } = useRefreshList();
  const { setExportModal } = useExportModalStore();
  const { t } = useTranslation();
  const { setOrderFilter, order } = useQueuesFilters();
  const { isAllowed: exportPermission } = useUserScope('RESULT_EXPORT');
  const { isAllowed: updatePermission } = useUserScope('RESULT_UPDATE');
  const { isAllowed: deletePermission } = useUserScope('COLLECTION_DELETE');
  const { isAllowed: startStopPermission } = useUserScope('COLLECTION_START_STOP');

  useEffect(() => {
    setSelectAll(checkedRows.length > 0);
  }, [checkedRows]);

  useEffect(() => {
    if (sortColumn) {
      let order = sortColumn?.column;

      if (sortColumn.sort === 'd') {
        order += 'd';
      }

      setOrderFilter(order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn]);

  useEffect(() => {
    if (order) {
      setSortColumn({
        column: order[0] as ColumnType,
        sort: order.length > 1 ? 'd' : 'a',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDeleteModal = () => setOpenDeleteModal(!openDeleteModal);

  const onDelete = async () => {
    toggleDeleteModal();

    checkedRows.forEach(async (id) => {
      await api.deleteCollection(id);
      onRowRemoved(id);
      refresh();
    });

    if (checkedRows.length === 0) {
      setSelectAll(false);
    }
  };

  const onFlag = async () => {
    checkedRows.forEach(async (id) => {
      const collection = getCollection(collections, id);

      if (
        collection &&
        (collection.status === 'Queued' || collection?.status === 'Stopped')
      ) {
        await api.updatePriority(id, !collection.highPriority);
        onRowRemoved(id);
        refresh();
      }
    });

    if (checkedRows.length === 0) {
      setSelectAll(false);
    }
  };

  const onStart = async () => {
    checkedRows.forEach(async (id) => {
      const collection = getCollection(collections, id);

      if (collection?.status === 'Stopped') {
        await api.collectionAction(id, 'start');
        onRowRemoved(id);
        refresh();
      }
    });

    if (checkedRows.length === 0) {
      setSelectAll(false);
    }
  };

  const onStop = async () => {
    checkedRows.forEach(async (id) => {
      const collection = getCollection(collections, id);

      if (collection?.status === 'Queued') {
        await api.collectionAction(id, 'stop');
        onRowRemoved(id);
        refresh();
      }
    });

    if (checkedRows.length === 0) {
      setSelectAll(false);
    }
  };

  const onExport = () => {
    setExportModal({ open: true, collectionIds: [...checkedRows] });
  };

  const onBulkAction = (id: number) => {
    setSelectedRowId(undefined);

    if (id === 1) {
      onStart();
    }

    if (id === 2) {
      onStop();
    }

    if (id === 3) {
      toggleDeleteModal();
    }

    if (id === 4) {
      onFlag();
    }

    if (id === 5) {
      onExport();
    }
  };

  const fileNames = () => {
    const files: Array<string> = [];

    collections.forEach((collection) => {
      if (checkedRows.includes(collection.collectionId)) {
        files.push(collection.collectionName);
      }
    });

    return files;
  };

  const bulkActions = actions(
    startStopPermission,
    deletePermission,
    exportPermission,
    updatePermission,
  );

  return (
    <>
      <Container>
        <ColumnContainer size={5}>
          <StyledCheckbox
            indeterminate={
              checkedRows.length > 0 && checkedRows.length < collections.length
            }
            checked={selectAll}
            onChange={(event) => {
              setSelectAll(event.target.checked);
              onSelectAll(event.target.checked);
            }}
          />
          {checkedRows.length === 0 ? (
            <PriorityContainer
              onClick={() =>
                setSortColumn({
                  column: 'p',
                  sort:
                    sortColumn?.column !== 'p'
                      ? 'd'
                      : sortColumn?.sort === 'd'
                      ? 'a'
                      : 'd',
                })
              }
            >
              <StyledRedFlag />
              {sortIcon(sortColumn?.column === 'p', sortColumn?.sort)}
            </PriorityContainer>
          ) : (
            <BulkActions>
              {bulkActions.map(({ id, Icon }) => (
                <BulkActions key={id}>
                  {id === 4 && <Separator />}
                  <BulkAction onClick={() => onBulkAction(id)}>
                    <Icon style={{ width: '16px', height: '16px' }} />
                  </BulkAction>
                </BulkActions>
              ))}
            </BulkActions>
          )}
        </ColumnContainer>
        <ColumnContainer size={44}>
          {checkedRows.length === 0 && (
            <ColumnHeader
              name={t('name')}
              sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
              sortEnabled={sortColumn?.column === 'n'}
              onClick={() =>
                setSortColumn({
                  column: 'n',
                  sort:
                    sortColumn?.column !== 'n'
                      ? 'd'
                      : sortColumn?.sort === 'd'
                      ? 'a'
                      : 'd',
                })
              }
            />
          )}
        </ColumnContainer>
        <ColumnContainer size={14}>
          <ColumnHeader
            name={t('status')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 's'}
            onClick={() =>
              setSortColumn({
                column: 's',
                sort:
                  sortColumn?.column !== 's' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
        <ColumnContainer size={12}>
          <StyledTypography size="small" bold>
            {t('progress')}
          </StyledTypography>
        </ColumnContainer>
        <ColumnContainer size={13}>
          <ColumnHeader
            name={t('uploadDate')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 'd'}
            onClick={() =>
              setSortColumn({
                column: 'd',
                sort:
                  sortColumn?.column !== 'd' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
        <ColumnContainer size={5}>
          <ColumnHeader
            name={t('totalFiles')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 'f'}
            onClick={() =>
              setSortColumn({
                column: 'f',
                sort:
                  sortColumn?.column !== 'f' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
        <ColumnContainer size={7}>
          <ColumnHeader
            name={t('totalPages')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 'c'}
            onClick={() =>
              setSortColumn({
                column: 'c',
                sort:
                  sortColumn?.column !== 'c' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
      </Container>
      <ConfirmationModal
        onClose={toggleDeleteModal}
        onConfirm={onDelete}
        open={openDeleteModal}
        title={`${t('deleteQuestion')} ${checkedRows.length} ${
          checkedRows.length > 1 ? t('deleteFiles') : t('deleteFile')
        }?`}
        contentComponent={
          <StyledDeleteInfo>
            <div>
              {t('deleteInfo')} {checkedRows.length > 1 ? t('those') : t('this')}{' '}
              {checkedRows.length > 1 ? t('deleteDocuments') : t('deleteDocument')}:
            </div>
            <StyledFileContainer>
              {fileNames().map((item) => (
                <span>{item}</span>
              ))}
            </StyledFileContainer>
          </StyledDeleteInfo>
        }
      />
    </>
  );
};
