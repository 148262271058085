import { DataObject } from 'api';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import { selectDataObjects } from 'store/reducers/collection';
import { selectCommonTypes } from 'store/reducers/common';
import {
  clearCreatedFields,
  selectSearch,
  selectFilterByFieldTypes,
  selectFilterByPhrases,
  selectAnnotationsEditMode,
} from 'store/reducers/supervision';
import { selectFilterByFieldDuplicates } from 'store/reducers/supervision/filterByFieldDuplicates';
import { selectFilterByStatus } from 'store/reducers/supervision/filterByStatus';

export const useAnnotationsStore = (pageId: string) => {
  const dispatch = useDispatch();
  const clearCreateFields = useCallback(() => dispatch(clearCreatedFields()), [dispatch]);
  const dataObjects = useSelector<StoreProps, Array<DataObject>>((state) =>
    selectDataObjects(state, pageId),
  );
  const search = useSelector(selectSearch);
  const fieldTypes = useSelector(selectFilterByFieldTypes);
  const filterFieldsByDuplicates = useSelector(selectFilterByFieldDuplicates);
  const phrases = useSelector(selectFilterByPhrases);
  const statuses = useSelector(selectFilterByStatus);
  const dataObjectsEditMode = useSelector(selectAnnotationsEditMode);
  const commonTypes = useSelector(selectCommonTypes);

  return {
    commonTypes,
    clearCreateFields,
    dataObjectsEditMode,
    dataObjects,
    search,
    fieldTypes,
    filterFieldsByDuplicates,
    statuses,
    phrases,
  };
};
