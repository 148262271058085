import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFieldArrow,
  selectZoom,
  updateFieldArrow,
} from 'store/reducers/supervision';
import { selectPageArrow } from 'store/reducers/supervision/pageArrow';

export const useFieldArrowStore = () => {
  const dispatch = useDispatch();
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const fieldArrow = useSelector(selectFieldArrow);
  const pageArrow = useSelector(selectPageArrow);
  const zoom = useSelector(selectZoom);

  return { zoom, fieldArrow, pageArrow, setFieldArrow };
};
