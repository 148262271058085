import { BoundingBox } from 'api';
import { SelectionRegion } from 'components/TableMask';

export const isWordSelected = (
  region: SelectionRegion,
  wordBoundingBox: BoundingBox,
  width: number,
  height: number,
) => {
  return (
    wordBoundingBox.x * width >= (width * region.x) / 100 &&
    wordBoundingBox.y * height >= (height * region.y) / 100 &&
    wordBoundingBox.x * width <=
      (width * region.x) / 100 + (width * region.width) / 100 &&
    wordBoundingBox.y * height <=
      (height * region.y) / 100 + (height * region.height) / 100
  );
};
