import { FC, useCallback } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloakInstance } from '../keycloak';
import { SCOPES } from 'hooks/useUserScope';
import { API_ACCESS_TOKEN, API_REFRESH_TOKEN } from 'api';
import { useDispatch } from 'react-redux';
import { updateAuthenticated, updateInitialized } from 'store/reducers/common';

const READY_STATUS = 'onReady';

export const Keycloak: FC = ({ children }) => {
  const dispatch = useDispatch();
  const setAuthenticated = useCallback((e) => dispatch(updateAuthenticated(e)), [
    dispatch,
  ]);

  const setInitialized = useCallback((e) => dispatch(updateInitialized(e)), [dispatch]);

  return (
    <ReactKeycloakProvider
      onEvent={(event) => {
        const { token, refreshToken, authenticated } = keycloakInstance;

        if (token) {
          window.localStorage.setItem(API_ACCESS_TOKEN, token);
        }

        if (refreshToken) {
          window.localStorage.setItem(API_REFRESH_TOKEN, refreshToken);
        }

        if (event === READY_STATUS) {
          setInitialized(true);
        }

        setAuthenticated(authenticated);
      }}
      initOptions={{
        checkLoginIframe: false,
        scope: SCOPES,
      }}
      authClient={keycloakInstance}
    >
      {children}
    </ReactKeycloakProvider>
  );
};
