import { MouseEvent, useEffect, useState } from 'react';
import { FC } from 'react';
import { FieldProps } from './TableField.interface';
import { useTranslation } from 'react-i18next';
import {
  StyledField,
  StatusContainer,
  MenuContainer,
  Dot,
  Name,
  StyledTableIcon,
  TableIconContainer,
  StyledAcceptContainer,
  StyledCancelContainer,
  StyledAcceptIcon,
  StyledCancelIcon,
  ActionContainer,
  StyledSpin,
} from './TableField.styles';
import {
  hasVisibleStatus,
  requiresAttention,
  requiresEditAttention,
} from 'utils/helpers';
import { FieldStatus } from 'components/FieldStatus';
import { useFieldStore } from './TableField.hooks';
import { fieldDataObjectId } from 'components/FieldArrow';
import { useUserScope } from 'hooks';
import { flatten, isBoolean, keys, values } from 'lodash';
import { ENV_VARIABLE } from 'variables';
import { clearTableEditMode } from 'store/reducers/supervision';
import { useDispatch } from 'react-redux';
import { DropdownMenu } from 'components/DropdownMenu';
import { menuMore } from './TableField.menu';
import { api } from 'api';
import { getTooltipProps } from 'components/Field/Field.utils';
import { Tooltip } from 'antd';

export const FIELD_INPUT = 'input_';

export const TableField: FC<FieldProps> = ({
  documentId,
  resultId,
  pageId,
  pageObjectId,
  id,
}) => {
  const {
    documentEditMode,
    fieldArrow,
    table,
    editorId,
    user,
    annotationEditMode,
    commonTypes,
    tableEditMode,
    setFieldArrow,
    setZoom,
    setAnnotationEditMode,
    updateAnnotationField,
  } = useFieldStore(pageId, pageObjectId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const { isAllowed } = useUserScope('RESULT_UPDATE');
  const editMode = annotationEditMode?.editMode;
  const editedCells = keys(tableEditMode);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      table &&
      documentEditMode &&
      !isBoolean(annotationEditMode?.editMode) &&
      requiresEditAttention(table.status.status)
    ) {
      setAnnotationEditMode({
        resultId,
        annotationId: table?.id,
        editMode: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, editMode, documentEditMode, editedCells]);

  useEffect(() => {
    if (!editMode && editedCells?.length > 0) {
      setAnnotationEditMode({
        resultId,
        annotationId: table?.id,
        editMode: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedCells, editMode]);

  const toggleEditMode = () => {
    setAnnotationEditMode({
      resultId,
      annotationId: pageObjectId,
      editMode: !editMode,
    });
  };

  const onCancelClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    toggleEditMode();
    dispatch(clearTableEditMode({ id: pageObjectId }));
  };

  const onSaveClick = (event: MouseEvent<HTMLElement>) => {
    if (editedCells.length === 0) {
      return;
    }

    event.stopPropagation();

    const cells = values(tableEditMode)
      .filter((item) => item.type === 'cell')
      .map((item) => ({ id: item.id, text: item.text }));

    const headers = values(tableEditMode)
      .filter((item) => item.type === 'header')
      .map((item) => ({ id: item.id, label: item.text }));

    setLoading(true);

    api.updateTableAnnotation(resultId, pageObjectId, { cells, headers }).then((data) => {
      setLoading(false);
      updateAnnotationField({
        ...data,
        pageId,
        resultId,
        annotationId: pageObjectId,
        type: 'table',
      });
      dispatch(clearTableEditMode({ id: pageObjectId }));
      setAnnotationEditMode({
        resultId,
        annotationId: pageObjectId,
        editMode: false,
      });
    });
  };

  const onFieldClick = (withRevert: boolean) => {
    if (!fieldArrow || fieldArrow.id !== pageObjectId.toString()) {
      setZoom({ scale: 1, isButtonAction: true });
      setFieldArrow({
        id: pageObjectId,
        item: table,
        type: 'table',
        pageId,
        resultId,
        documentId,
      });
    } else if (withRevert) {
      setFieldArrow(undefined);
    }
  };

  const onRowAction = (actionId: number) => {
    switch (actionId) {
      case 1: {
        toggleEditMode();
        onFieldClick(false);
        break;
      }
    }
  };

  const showEditMenu =
    !ENV_VARIABLE.KEY_CLOAK_URL ||
    (editorId && editorId === user?.sub && isAllowed) ||
    (ENV_VARIABLE.KEY_CLOAK_URL &&
      isAllowed &&
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED !== 'true');

  const status =
    !!table.headers.filter((item) => item.status.status === 'Corrected').length ||
    !!flatten(table.rows).filter((item) => item.status.status === 'Corrected').length
      ? 'Corrected'
      : table?.status.status;

  const annotationLabel = commonTypes.tableAnnotationsTypes[table.label]
    ? commonTypes.tableAnnotationsTypes[table.label].label
    : table.label;

  return table ? (
    <StyledField
      id={id}
      key={id}
      className={id}
      $selected={id === fieldDataObjectId(fieldArrow?.id)}
      $requiresAttention={requiresAttention(table?.status.status)}
      onMouseEnter={() => showEditMenu && setShowMenu(true)}
      onMouseLeave={() => showEditMenu && setShowMenu(false)}
      onClick={() => {
        onFieldClick(true);
      }}
    >
      <>
        <Tooltip {...getTooltipProps(annotationLabel)}>
          <Name edited={false}>{annotationLabel}</Name>
        </Tooltip>
        <TableIconContainer>
          <StyledTableIcon />
        </TableIconContainer>
        {!showMenu && !editMode && hasVisibleStatus(status) && (
          <StatusContainer>
            <FieldStatus status={status} message={table?.status.msg} />
          </StatusContainer>
        )}
        {editMode && (
          <ActionContainer>
            <StyledAcceptContainer
              $disabled={editedCells.length === 0}
              onClick={onSaveClick}
            >
              {loading ? (
                <StyledSpin size="small" spinning />
              ) : (
                <StyledAcceptIcon $disabled={editedCells.length === 0} />
              )}
            </StyledAcceptContainer>
            <StyledCancelContainer onClick={onCancelClick}>
              <StyledCancelIcon />
            </StyledCancelContainer>
          </ActionContainer>
        )}
        {showMenu && !editMode && (
          <MenuContainer $requiresAttention={requiresAttention(table?.status.status)}>
            <DropdownMenu onMenuItem={onRowAction} items={menuMore(t)} />
          </MenuContainer>
        )}
      </>
      {id === fieldDataObjectId(fieldArrow?.id) && table?.boundingBox && (
        <Dot $requiresAttention={requiresAttention(table?.status.status)} />
      )}
    </StyledField>
  ) : null;
};
