import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.padding.small};
  background-color: ${({ theme }) => theme.colors.notificatioInfo};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 14px;
`;
