import { Typography } from 'antd';
import styled from 'styled-components';

export const AppVersion = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 14px;
  padding-top: 4px;
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray300};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 14px;
  padding-top: 20px;
`;

export const Container = styled.div`
  width: 100%;
  padding-left: 24px;
  padding-top: 4px;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 20px;
  font-weight: 600;
  padding-bottom: ${({ theme }) => theme.padding.medium};
`;
