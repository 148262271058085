import { IconSplit } from 'components/Icons';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentSection } from './DocumentSection.hooks';
import { DocumentSectionProps } from './DocumentSection.interface';
import {
  Container,
  TitleLine,
  DocumentTitle,
  DocumentTitleWraper,
  DocumentTypeSection,
  InputContainer,
  StyledInput,
  SplitLine,
  StyledDivider,
  StyledIconButton,
} from './DocumentSection.styles';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { PageItem } from '../PageItem';
import { useDroppable } from '@dnd-kit/core';
import { theme } from 'styles';
import { TagGroupsItem } from '../TagGroupsItem';

const DividerComponent = (props: { onSplit: () => void }) => {
  const [style, setStyle] = useState({ display: 'none' });

  return (
    <StyledDivider
      onMouseEnter={(e) => {
        setStyle({ display: 'block' });
      }}
      onMouseLeave={(e) => {
        setStyle({ display: 'none' });
      }}
    >
      <SplitLine />
      <div style={style}>
        <StyledIconButton onClick={props.onSplit} icon={<IconSplit />} />
      </div>
      <SplitLine />
    </StyledDivider>
  );
};

export const DocumentSection: FC<DocumentSectionProps> = ({
  id,
  pages,
  resultId,
  documentId,
  documentName,
  activePageId,
  activeResultId,
  documentIndex,
  allDocuments,
  onDocumentName,
  onZoom,
  onSplit,
}) => {
  const { inputRef, rename, onRename } = useDocumentSection();
  const { setNodeRef } = useDroppable({
    id,
  });
  const { t } = useTranslation();

  return (
    <Container>
      <TitleLine />
      <DocumentTitleWraper>
        {rename ? (
          <InputContainer>
            <StyledInput
              ref={inputRef}
              onBlur={onRename}
              value={documentName}
              onChange={(event) => onDocumentName(event.target.value)}
            />
          </InputContainer>
        ) : (
          <DocumentTitle
            style={{
              color:
                activeResultId === undefined
                  ? theme.colors.blackPurple
                  : activeResultId === resultId
                  ? theme.colors.successGreen
                  : theme.colors.red,
            }}
          >
            {documentName || t('emptyDocumentName')} - [{documentIndex + 1}/{allDocuments}
            ]
          </DocumentTitle>
        )}
      </DocumentTitleWraper>
      <div>
        <SortableContext id={id} items={pages} strategy={rectSortingStrategy}>
          <div
            ref={setNodeRef}
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              minHeight: 280,
              width: pages.length > 0 ? '70%' : 500,
            }}
          >
            {pages.map((id, index) => (
              <>
                <PageItem activeId={activePageId} onZoom={onZoom} key={id} id={id} />
                {index !== pages.length - 1 && (
                  <DividerComponent onSplit={() => onSplit(id)} />
                )}
              </>
            ))}
          </div>
        </SortableContext>

        <DocumentTypeSection>
          {resultId && (
            <TagGroupsItem
              documentId={documentId}
              resultId={resultId}
              pageId={''}
              tagGroups={[]}
            />
          )}
          {/* <ActionContainer>
            {!isFirstItem && (
              <ActionButton>
                <IconArrowMoveUp onClick={onMoveUp} />
              </ActionButton>
            )}
            {!isLastItem && (
              <ActionButton>
                <IconArrowMoveDown onClick={onMoveDown} />
              </ActionButton>
            )}
            {showDeleteButton && (
              <ActionButton>
                <IconTrash onClick={onDelete} />
              </ActionButton>
            )}
          </ActionContainer> */}
        </DocumentTypeSection>
      </div>
    </Container>
  );
};
