import { Dictionary } from '@reduxjs/toolkit';
import { api, CommonTypes, CommonTypesApi, Language, TableAnnotationType } from 'api';
import { find, sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthenticated,
  updateCommonTypes,
  updateExtractorPrefedinedFields,
  updateUser,
} from 'store/reducers/common';
import { updateExtractions } from 'store/reducers/configuration';
import { mapConfigurations } from 'utils/mapping';
import { ENV_VARIABLE } from 'variables';

export const useCommonData = () => {
  const { t, i18n } = useTranslation();
  const authenticated = useSelector(selectAuthenticated);
  const dispatch = useDispatch();
  const [commonTypes, setCommonTypes] = useState<CommonTypesApi>();
  const setCommonTypesR = useCallback((e) => dispatch(updateCommonTypes(e)), [dispatch]);
  const setExtractions = useCallback((e) => dispatch(updateExtractions(e)), [dispatch]);
  const setExtractorPredefinedFields = useCallback(
    (e) => dispatch(updateExtractorPrefedinedFields(e)),
    [dispatch],
  );
  const setUser = useCallback((e) => dispatch(updateUser(e)), [dispatch]);

  useEffect(() => {
    if (authenticated || !ENV_VARIABLE.KEY_CLOAK_CLIENT_ID) {
      api
        .fetchFieldTypes()
        .then(setCommonTypes)
        .catch(() => {
          setCommonTypes({
            annotationsToAdd: [],
            annotationsTypes: { en: [], pl: [] },
            tablesAnnotationsTypes: [],
          });
        });

      if (ENV_VARIABLE.CONFIGURATION_ENABLED === 'true') {
        api.fetchExtractions().then((data) => {
          setExtractions(mapConfigurations(data));

          data.extractions.forEach((item) => {
            api.fetchExtractorPredefinedFields(item.id).then((data) =>
              setExtractorPredefinedFields({
                id: item.id,
                data: data.predefinedExtractors,
              }),
            );
          });
        });
      }

      api.fetchUser().then(setUser);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (commonTypes) {
      const language = i18n.language as Language;

      const types = ({
        annotationsTypes: [],
        annotationsToAdd: [],
        tableAnnotationsTypes: {},
      } as unknown) as CommonTypes;

      types.annotationsTypes = sortBy(
        commonTypes.annotationsTypes[language].map((item) => ({
          label: item.value,
          value: item.type,
        })),
        (item) => item.label,
      );

      types.annotationsToAdd = sortBy(
        commonTypes.annotationsToAdd.map((item) => {
          const annotationTranslation = find(
            types.annotationsTypes,
            (annotationType) => annotationType.value === item,
          );

          if (annotationTranslation) {
            return annotationTranslation;
          }

          return { label: item, value: item };
        }),
        (item) => item.label,
      );

      const tableTypes = {} as Dictionary<TableAnnotationType>;

      commonTypes.tablesAnnotationsTypes.forEach((item) => {
        tableTypes[item.tableType] = {
          headers: sortBy(
            item.headers[language].map((item) => ({
              label: item.value,
              value: item.type,
            })),
            (item) => item.label,
          ),
          type: { label: item[language][0].value, value: item[language][0].type },
          label: item[language][0].value,
        };
      });

      setCommonTypesR({ ...types, tableAnnotationsTypes: tableTypes });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonTypes, i18n, t]);
};
