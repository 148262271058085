import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QUEUES_PARAMS } from 'utils/helpers';

interface QueuesSliceProps {
  search?: string;
  from?: string;
  to?: string;
  status: Array<string>;
  priority: Array<string>;
  order: string;
  page: number;
  pageSize: number;
  markToDelete?: boolean;
}

export const queuesSlice = createSlice({
  name: 'QUEUES',
  initialState: () => {
    const localStorageParams = localStorage.getItem(QUEUES_PARAMS);
    const {
      search,
      from,
      to,
      order,
      page = 1,
      pageSize = 10,
      priority = [],
      status = [],
      markToDelete,
    } = JSON.parse(localStorageParams || '{}') as QueuesSliceProps;

    return { search, priority, from, to, order, page, pageSize, markToDelete, status };
  },
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    setFrom: (state, action: PayloadAction<string | undefined>) => {
      state.from = action.payload;
    },

    setTo: (state, action: PayloadAction<string | undefined>) => {
      state.to = action.payload;
    },

    setOrder: (state, action: PayloadAction<string>) => {
      state.order = action.payload;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },

    setMarkToDelete: (state, action: PayloadAction<boolean>) => {
      state.markToDelete = action.payload;
    },

    setStatus: (state, action: PayloadAction<Array<string>>) => {
      state.status = action.payload;
    },

    setPriority: (state, action: PayloadAction<Array<string>>) => {
      state.priority = action.payload;
    },
  },
});

export const {
  setSearch,
  setFrom,
  setTo,
  setStatus,
  setPriority,
  setOrder,
  setPage,
  setPageSize,
  setMarkToDelete,
} = queuesSlice.actions;

export default queuesSlice.reducer;
