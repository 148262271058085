import { useSupervisionImage } from 'hooks';
import { flatten, values } from 'lodash';
import { useSelector } from 'react-redux';
import { selectDocuments } from 'store/reducers/collection';

export const usePageItem = ({ id, inView }: { inView: boolean; id: string }) => {
  const documents = useSelector(selectDocuments);
  const page = flatten(values(documents).map((item) => item.pages)).find(
    (item) => item.id === id,
  );
  const { image } = useSupervisionImage({
    imageUrl: page?.image.imageUrl,
    shouldLoad: inView,
  });

  return { page, image };
};
