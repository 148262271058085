import { Menu, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { IconChevronDown, IconMore } from 'components/Icons';
import { Label } from 'components/Label';
import styled from 'styled-components';

interface StyledMenuContainerProps {
  $hovered: boolean;
}

interface StyledSelectedFilterProps {
  $allFilter: boolean;
}

export const MenuContainer = styled.div<StyledMenuContainerProps>`
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-right: ${({ theme }) => theme.padding.tiny};
  background-color: ${({ $hovered, theme }) =>
    $hovered ? theme.colors.white : 'transparent'};
  box-shadow: ${({ $hovered }) =>
    $hovered ? '0 2px 6px 0 rgba(151, 159, 182, 0.3)' : ''};
  :hover {
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  }
`;

export const StyledMenuIcon = styled(IconMore)`
  color: ${({ theme }) => theme.colors.darkGray};
  width: 20px;
`;

export const StyledArrowDownIcon = styled(IconChevronDown)`
  margin-left: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.gray200};
`;

export const StyledFilterLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.gray300};
  padding-left: ${({ theme }) => theme.padding.tiny};
  padding-right: ${({ theme }) => theme.padding.tiny};
  user-select: none;
`;

export const StyledSelectedFilter = styled(Label)<StyledSelectedFilterProps>`
  padding-left: ${({ theme }) => theme.padding.tiny};
  padding-right: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme, $allFilter }) =>
    $allFilter ? theme.colors.blackPurple : theme.colors.white};
  background-color: ${({ theme, $allFilter }) =>
    $allFilter ? theme.colors.lilac : theme.colors.blackPurple};
  height: 16px;
  text-transform: uppercase;
  border-radius: 4px;
  user-select: none;
  align-items: center;
  display: flex;
`;

export const StlyedCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.padding.tiny};
`;

export const StyledMenuLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  user-select: none;
`;

export const StyledCounterLabel = styled.span`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blackPurple};
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  border-radius: 4px;
  line-height: 1.4vh;
  height: 1.8vh;
  font-size: ${({ theme }) => theme.fontSize.tiny};
  user-select: none;
`;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 6px;
  height: 40px;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray400};
  }
`;
