import { FC } from 'react';
import { PropsWithChildren } from 'react';
import { HeadlineProps } from './Headline.interface';
import { StyledHeadline } from './Headline.styles';

export const Headline: FC<PropsWithChildren<HeadlineProps>> = ({
  children,
  size,
  ...props
}) => {
  return (
    <StyledHeadline {...props} size={size}>
      {children}
    </StyledHeadline>
  );
};
