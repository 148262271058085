import React, { FC } from 'react';
import { ButtonProps } from './Button.interface';
import { StyledButton } from './Button.styles';

export const Button: FC<ButtonProps> = ({
  title,
  onClick,
  icon,
  loading,
  disabled = false,
  size = 'middle',
  color = 'primary',
  shadow = true,
  ...other
}) => {
  return (
    <StyledButton
      loading={loading}
      size={size}
      $shadow={shadow}
      disabled={disabled}
      onClick={onClick}
      type="primary"
      $withIcon={!!icon}
      color={color}
      {...other}
    >
      {title}
      {icon}
    </StyledButton>
  );
};
