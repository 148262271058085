import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCreatedFields,
  selectEditField,
  selectEditMode,
  selectFieldArrow,
  updateEditField,
  updateFieldArrow,
  updateZoom,
} from 'store/reducers/supervision';

export const useAnnotationStore = () => {
  const dispatch = useDispatch();
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const setEditField = useCallback((e) => dispatch(updateEditField(e)), [dispatch]);
  const clearCreateFields = useCallback(() => dispatch(clearCreatedFields()), [dispatch]);
  const setZoom = useCallback((e) => dispatch(updateZoom(e)), [dispatch]);
  const editField = useSelector(selectEditField);
  const editMode = useSelector(selectEditMode);
  const fieldArrow = useSelector(selectFieldArrow);

  return {
    setFieldArrow,
    setEditField,
    clearCreateFields,
    setZoom,
    editField,
    editMode,
    fieldArrow,
  };
};
