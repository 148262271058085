import { useCallback, useEffect, useState } from 'react';
import { api } from 'api';
import { useLocation } from 'react-router-dom';
import { parseQueryParams, stringifyParams } from 'utils/helpers';
import { useTyroHistory } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsers, selectUsersTotal, updateUsers } from 'store/reducers/users';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

export const useUsersList = () => {
  const dispatch = useDispatch();
  const setUsers = useCallback((e) => dispatch(updateUsers(e)), [dispatch]);
  const users = useSelector(selectUsers);
  const totalSize = useSelector(selectUsersTotal);
  const { search: locationSearch } = useLocation();
  const [loading, setLoading] = useState(false);
  const { addQueryParams } = useTyroHistory();
  const { offset, limit, status, priority, from, to, search, order } = parseQueryParams(
    locationSearch,
  );

  useEffect(() => {
    let queryParams = {};

    if (!offset || !limit || !order) {
      queryParams = {
        offset: offset || DEFAULT_PAGE,
        limit: limit || DEFAULT_PAGE_SIZE,
        order: order || 'pd',
        status,
        priority,
        from,
        to,
        search,
      };

      addQueryParams(queryParams);
    } else {
      queryParams = parseQueryParams(locationSearch);
    }

    setLoading(true);

    api
      .fetchUsers(stringifyParams(queryParams))
      .then(({ users, pagination }) => {
        setUsers({ users, pagination });

        if (pagination.total < Number(limit) && Number(offset) * Number(limit) !== 1) {
          addQueryParams({ ...queryParams, offset: 0 });
        }
      })
      .catch(() => {
        /* no-op */
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, status, priority, from, to, search, order]);

  return { users, totalSize, loading };
};

export const useRefreshList = () => {
  const dispatch = useDispatch();
  const setUsers = useCallback((e) => dispatch(updateUsers(e)), [dispatch]);
  const { addQueryParams } = useTyroHistory();
  const { search: locationSearch } = useLocation();

  const { offset, limit } = parseQueryParams(locationSearch);

  const refresh = () => {
    api
      .fetchUsers(stringifyParams(parseQueryParams(locationSearch)))
      .then(({ users, pagination }) => {
        setUsers({ users, pagination });

        if (pagination.total < Number(limit) && Number(offset) * Number(limit) !== 1) {
          addQueryParams({ offset: 0 });
        }
      })
      .catch(() => {
        /* no-op */
      });
  };

  return { refresh };
};
