import { FC } from 'react';
import { PrimaryIconButtonProps } from './PrimaryIconButton.interface';
import { StyledButton, Issues, Container } from './PrimaryIconButton.styles';

export const PrimaryIconButton: FC<PrimaryIconButtonProps> = ({
  onClick,
  icon,
  withShadow = true,
  disabled = false,
  issues = 0,
  ...other
}) => {
  return (
    <Container {...other}>
      <StyledButton $disabled={disabled} $withShadow={withShadow} onClick={onClick}>
        {icon}
      </StyledButton>
      {issues > 0 && <Issues>{issues}</Issues>}
    </Container>
  );
};
