import { FC, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { uniq, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';
import { DropdownMenuProps, MenuItem } from './DropdownSelect.interface';
import {
  MenuContainer,
  StyledFilterLabel,
  StyledMenuItem,
  StyledMenuLabel,
  StlyedCheckbox,
  StyledSelectedFilter,
  StyledArrowDownIcon,
  StyledCounterLabel,
} from './DropdownSelect.styles';

export const ALL_OPTION_ID = '100';

const buildMenu = (
  items: Array<MenuItem>,
  checkedItems: Array<string>,
  onClick: (id: string) => void,
) => (
  <Menu>
    {items.map(({ id, label, counter }) => (
      <StyledMenuItem
        key={id}
        onClick={(event) => {
          event.domEvent.stopPropagation();
          onClick(id);
        }}
      >
        <div style={{ display: 'flex', marginRight: '10px' }}>
          <StlyedCheckbox
            checked={
              checkedItems.includes(id) ||
              (checkedItems.length === 0 && id === ALL_OPTION_ID)
            }
            onClick={(event) => {
              event.stopPropagation();
              onClick(id);
            }}
          />
          <StyledMenuLabel>{label}</StyledMenuLabel>
        </div>
        {counter && <StyledCounterLabel>{counter}</StyledCounterLabel>}
      </StyledMenuItem>
    ))}
  </Menu>
);

export const DropdownSelect: FC<DropdownMenuProps> = ({
  items,
  checkedItems,
  title,
  onMenuItems,
  singleSelect,
  ...props
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { t } = useTranslation();

  const onMenuItemSelect = (id: string) => {
    if (singleSelect) {
      if (id === ALL_OPTION_ID || checkedItems.includes(id)) {
        onMenuItems([]);
      } else {
        onMenuItems([id]);
      }

      return;
    }

    if (checkedItems.includes(id)) {
      if (checkedItems.filter((itemId) => itemId !== id).length === 0) {
        onMenuItems([]);
      } else {
        onMenuItems(checkedItems.filter((itemId) => itemId !== id));
      }
    } else if (id === ALL_OPTION_ID) {
      onMenuItems([]);
    } else {
      onMenuItems(uniq([...checkedItems, id].filter((item) => item !== ALL_OPTION_ID)));
    }
  };

  const selectedLabel = () => {
    if (checkedItems.includes(ALL_OPTION_ID) || checkedItems.length === 0) {
      return t('all');
    } else {
      const firstItemId = checkedItems[0];

      const firstFilter = find(items, (item) => item.id === firstItemId);

      return `${firstFilter?.label} ${
        checkedItems.length > 1 ? `+${checkedItems.length - 1}` : ''
      }`;
    }
  };

  return (
    <Dropdown
      overlay={buildMenu(items, checkedItems, onMenuItemSelect)}
      onVisibleChange={(visible) => {
        if (!visible) {
          setMenuVisible(visible);
        }
      }}
      visible={menuVisible}
      placement="bottomLeft"
      mouseLeaveDelay={0.3}
      overlayStyle={{ border: `1px solid ${theme.colors.gray400}`, borderRadius: '6px' }}
      {...props}
    >
      <MenuContainer
        onClick={(event) => {
          setMenuVisible(true);
          event.stopPropagation();
        }}
        $hovered={menuVisible}
      >
        <StyledFilterLabel size="regular" bold>
          {title}:
        </StyledFilterLabel>
        <StyledSelectedFilter
          size="small"
          bold
          $allFilter={checkedItems.includes(ALL_OPTION_ID) || checkedItems.length === 0}
        >
          {selectedLabel()}
        </StyledSelectedFilter>
        <StyledArrowDownIcon />
      </MenuContainer>
    </Dropdown>
  );
};
