import styled from 'styled-components';
import { TyroTheme } from 'styles';
import { LabelProps, LabelSize } from './Label.interface';

const fontSize = (theme: TyroTheme, size: LabelSize) => {
  switch (size) {
    case 'small':
      return theme.fontSize.labelSmall;
    case 'regular':
    default:
      return theme.fontSize.labelRegular;
  }
};

const lineHeight = (size: LabelSize) => {
  switch (size) {
    case 'regular':
      return '21px';
    case 'small':
    default:
      return '16px';
  }
};

export const StyledLabel = styled.span<LabelProps>`
  font-size: ${({ theme, size }) => fontSize(theme, size)};
  font-family: ${({ theme, bold }) =>
    bold ? theme.fontFamily.semiBold : theme.fontFamily.regular};
  line-height: ${({ size }) => lineHeight(size)};
`;
