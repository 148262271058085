import { Checkbox } from 'antd';
import { DropdownSelect } from 'components/DropdownSelect';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  width: 92%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.padding.medium};
  padding-bottom: ${({ theme }) => theme.padding.tiny};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
`;

export const SearchContainer = styled.div`
  width: 14%;
`;

export const Divider = styled.div`
  width: 1px;
  margin-left: ${({ theme }) => theme.padding.tiny};
  margin-right: ${({ theme }) => theme.padding.small};
  height: 20px;
  background-color: ${({ theme }) => theme.colors.gray400};
`;

export const StyledDropdownSelect = styled(DropdownSelect)`
  margin-left: ${({ theme }) => theme.padding.xtiny};
`;

export const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  padding-top: 8px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  height: 40px;
  color: ${({ theme }) => theme.colors.gray300};
  padding-right: 3px;
  padding-left: ${({ theme }) => theme.padding.tiny};
  background-color: transparent;
  :hover {
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 6px 0 rgba(151, 159, 182, 0.3);
  }
`;
