import { AnnotationField } from 'components/ComplexField';
import { SelectOption } from 'components/Select';
import { Dictionary } from 'lodash';

export type UserStatus = 'INVITED' | 'DISABLED' | 'ACTIVE';

export type ProcessStatus =
  | 'Paused'
  | 'Completed'
  | 'Needs attention'
  | 'Failed'
  | 'In progress'
  | 'Waiting for files'
  | 'Queued'
  | 'Stopped'
  | 'Edited'
  | 'Accepted';

export type InterventionType = 'split' | 'extraction' | 'classify';

export type DocumentType = 'file' | 'folder';

export type CollectionAction = 'start' | 'stop';

export type AnnotationProcessStatus =
  | 'Needs Attention'
  | 'Completed'
  | 'Accepted'
  | 'Corrected'
  | 'Manual';

export type StatusMsg = 'Missing value' | 'Uncertain Annotation' | 'Invalid Format';

export type TagMode = 'one' | 'one_or_more' | 'zero_or_more' | 'zero_or_one';

export type SortBy = 'position' | 'az';

export type Language = 'pl' | 'en';

export type PageObjectType =
  | 'table'
  | 'text'
  | 'complex'
  | 'number'
  | 'enum'
  | 'date'
  | 'binary'
  | 'anonymized';

export interface Status {
  code?: string;
  msg?: string;
  status: ProcessStatus;
}

export interface CreateCollection {
  collectionName: string;
  highPriority: boolean;
  split: boolean;
  fileNames: Array<string>;
}

export interface CreateCollectionResponse {
  collectionId: string;
  files: Array<CreateFile>;
}

export interface CreateFile {
  fileId: string;
  fileName: string;
  status: ProcessStatus;
}

export interface CollectionsResponse {
  collections: Array<QueueCollection>;
  totalNumCollections: number;
}

export interface Users {
  pagination: Pagination;
  users: Array<ListUser>;
  count: number;
}

export interface Pagination {
  limit: number;
  offset: number;
  total: number;
}

export interface ListUser {
  emails: Array<string>;
  familyName: string;
  givenName: string;
  roles: Array<string>;
  status: UserStatus;
  userId: string;
  joinedAt?: string;
}

export interface QueueCollection {
  collectionName: string;
  highPriority: boolean;
  status: ProcessStatus;
  progress: number;
  interventions: Array<InterventionType>;
  totalFiles: number;
  interventionsType: Array<InterventionType>;
  files: Array<CollectionFile>;
  collectionId: string;
  totalPages: number;
  createdAt: string;
  split: boolean;
  metadata?: MetaData;
}

export interface Collection {
  collectionName: string;
  highPriority: boolean;
  status: ProcessStatus;
  progress: number;
  interventions: number;
  totalFiles: number;
  interventionsType: Array<string>;
  files: Array<CollectionFile>;
  collectionId: string;
  createdAt: string;
  split: boolean;
  category: string;
  size: number;
  editor?: Editor;
  metadata?: MetaData;
}

export interface MetaData {
  tags?: {
    mark_to_delete?: string;
  };
}

export interface Editor {
  editorId?: string;
}
export interface CollectionFile {
  fileId: string;
  fileName: string;
  resultId: string;
  status: ProcessStatus;
  progress: number;
}

export interface CollectionResult {
  collectionId: string;
  documents: Array<Document>;
  collectionName: string;
  totalPages: number;
  totalFiles: number;
  status: ProcessStatus;
  editor?: Editor;
  availableValues?: Dictionary<Array<string>>;
  metadata: MetaData;
  complexAnnotationSchemas?: Array<AnnotationSchema>;
}

export interface AnnotationSchema {
  label: string;
  annotations: Array<SubAnnotationSchema>;
}

export interface SubAnnotationSchema {
  label: string;
  type: string;
}

export interface Document {
  id: string;
  collectionId: string;
  fileId: string;
  fileName: string;
  resultId: string;
  createdAt: string;
  pages: Array<Page>;
  status: Status;
  annotationsAttention: boolean;
  labelsAttention: boolean;
  labels: Array<string>;
  tagGroups?: Array<TagGroup>;
}

export interface TagGroup {
  id: string;
  tags: Array<Tag>;
  name: string;
  tagMode: TagMode;
  status: AnnotationStatus;
}

export interface Tag {
  id: string;
  tag: string;
  status: AnnotationStatus;
}

export interface Page {
  id: string;
  words: Array<Lookup>;
  annotations: Array<Annotation>;
  image: PageImage;
  collectionPageNum: number;
  resultId?: string;
  documentId?: string;
  tagGroup?: boolean;
  label?: string;
  tableAnnotations?: Array<TableAnnotation>;
  dataObjects?: Array<DataObject>;
}

export interface DataObject {
  id: string;
  status: AnnotationStatus;
  type: PageObjectType;
  label: string;
}

export interface TableAnnotation extends DataObject {
  boundingBox: BoundingBox;
  headers: Array<TableHeader>;
  rows: Array<Array<TableRow>>;
  columnGrid: Array<number>;
  rowGrid: Array<number>;
}

export interface TableHeader {
  id: string;
  label?: string;
  status: AnnotationStatus;
  text: string;
}

export interface TableRow {
  id: string;
  headerId?: string;
  label?: string;
  status: AnnotationStatus;
  text: string;
}

export interface Annotation extends DataObject {
  lookups: Array<Lookup>;
  boundingBox?: BoundingBox;
  keywords: Array<Lookup>;
  label: string;
  value: string;
  valueRaw?: string;
  labelRaw?: string;
  annotations: Array<Annotation>;
  metadata?: {
    best_matches?: Array<string>;
  };
}

export interface AnnotationStatus {
  code?: string;
  msg?: StatusMsg;
  status: AnnotationProcessStatus;
}

export interface Lookup {
  id: string;
  value: string;
  boundingBox: BoundingBox;
  order?: number;
}

export interface BoundingBox {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface PageImage {
  width: number;
  height: number;
  imageUrl: string;
}

export interface ThumbnailSection {
  fileId: string;
  fileName: string;
  thumbnails: Array<Thumbnail>;
}

export interface Thumbnail {
  id: string;
  collectionPageNum: number;
  image: PageImage;
}

export interface PatchAnnotation {
  value?: string;
  label?: string;
  labelRaw?: string;
  valueRaw?: string;
  pageId: string;
  resultId: string;
  annotationId: string;
  status?: AnnotationStatus;
}

export interface PatchComplexField {
  resultId: string;
  annotationId: string;
  annotations: Array<AnnotationField>;
}

export interface PatchTagGroup {
  documentId: string;
  tagGroup: TagGroup;
}

export interface CreateAnnotation {
  pageId: string;
  label: string;
  value: string;
  lookups: Array<Lookup>;
  boundingBox: BoundingBox;
  type: string;
}

export interface ComplexAnnotation {
  label: string;
  type: string;
  value?: string;
  lookups?: Array<Lookup>;
  boundingBox?: BoundingBox;
}

export interface CreateComplexAnnotation {
  pageId: string;
  label: string;
  type: string;
  lookups: Array<Lookup>;
  boundingBox: BoundingBox;
  annotations: Array<SubAnnotationSchema>;
}

export interface ExtractionEnumValues {
  enumLabel: string;
  search?: string;
  limit?: number;
}

export interface FieldType {
  type: string;
  value: string;
}

export interface TableAnnotationType {
  headers: Array<SelectOption>;
  type: SelectOption;
  label: string;
}

export interface CommonTypes {
  annotationsToAdd: Array<SelectOption>;
  annotationsTypes: Array<SelectOption>;
  tableAnnotationsTypes: Dictionary<TableAnnotationType>;
}

export interface CommonTypesApi {
  annotationsToAdd: Array<string>;
  annotationsTypes: CommonFieldTypes;
  tablesAnnotationsTypes: Array<CommonTableTypeApi>;
}

export interface CommonTableTypeApi extends CommonFieldTypes {
  tableType: string;
  headers: CommonFieldTypes;
}
export interface CommonFieldTypes {
  en: Array<FieldType>;
  pl: Array<FieldType>;
}

export interface FileExport {
  location: string;
}

export interface User {
  email: string;
  name: string;
  sub: string;
  scope: Array<string>;
}

export interface UncertainItem {
  field: string;
  status: AnnotationProcessStatus;
}

export interface ExtractorPredefinedFields {
  predefinedExtractors: Dictionary<string>;
}

export interface Extractions {
  extractions: Array<Extraction>;
}

export interface Extraction {
  id: string;
  processorConfig: Array<ExtractionField>;
  fieldIds?: Array<string>;
  visibleName: string;
}

export interface ExtractionField {
  id: string;
  configType: string;
  fieldNameText?: string;
  mandatory?: boolean;
  configs: Array<ExtractorConfig>;
  extractionId?: string;
  collapsed?: boolean;
  error?: boolean;
  saved?: boolean;
}

export interface BaseExtractionField {
  configType: string;
  fieldNameText?: string;
  mandatory?: boolean;
  configs: Array<ExtractorConfig>;
}

export interface ExtractorConfig {
  id: string;
  configType?: ConfigType;
  predefinedExtractorType?: string;
  keywords?: Array<string>;
  threshold?: number;
  separators?: Array<string>;
  ignoreCase?: boolean;
  matchGroup?: boolean;
}

export enum ConfigType {
  KEYWORDS = 'levenshtein_keyword_indices_extractor_configuration',
  REGEX = 'regex_keyword_indices_extractor_configuration',
  PREDEFINED = 'predefined_extractor_configuration',
}
export interface Version {
  aiaVersion: string;
  apiVersion: string;
  build: string;
}

export interface EditTableAnnotationProps {
  headers: Array<{ id: string; label?: string }>;
  cells: Array<{ id: string; text?: string }>;
}

export const isAnnotationType = (object: any): object is Annotation => {
  return 'value' in object;
};
