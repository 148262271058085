import { ChangeEvent } from 'react';
import { StyledTextArea } from './TextArea.styles';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
export interface TextAreaProps {
  placeholder?: string;
  onChange: (value: string) => void;
  value?: string;
  id?: string;
  width: number;
  autoSize: AutoSizeType;
}

export const TextArea = ({
  placeholder,
  value,
  onChange,
  id,
  width,
  autoSize,
}: TextAreaProps) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <StyledTextArea
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      autoSize={autoSize}
      width={width}
    />
  );
};
