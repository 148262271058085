import { FC, useReducer } from 'react';
import LineTo from 'react-lineto';
import { theme } from 'styles';
import {
  isDataObjectFullyVisible,
  isFieldFullyVisible,
  isGroupFullyVisible,
  isPagePartiallyVisible,
  requiresAttention,
} from 'utils/helpers';
import { useInterval } from 'hooks/useInterval';
import { useFieldArrowStore } from './FieldArrow.hooks';
import { useRef } from 'react';
import { useEffect } from 'react';
import { fieldDataObjectId, pageDataObjectId } from './FieldArrow.helpers';

export const FieldArrow: FC = () => {
  const { zoom, fieldArrow, pageArrow } = useFieldArrowStore();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const currentId = useRef<string>();
  const requireAttention = requiresAttention(fieldArrow?.item?.status.status);

  useInterval(() => {
    forceUpdate();
  }, 1);

  useEffect(() => {
    const id = fieldArrow?.id;
    if (id && currentId.current !== id) {
      currentId.current = id;
    }
  }, [fieldArrow]);

  return (
    <>
      {isDataObjectFullyVisible(pageDataObjectId(fieldArrow?.id), fieldArrow) &&
      isFieldFullyVisible(fieldDataObjectId(fieldArrow?.id)) &&
      currentId.current === fieldArrow?.id &&
      fieldArrow?.id &&
      zoom?.scale === 1 ? (
        <LineTo
          borderColor={requireAttention ? theme.colors.darkPink : theme.colors.darkPurple}
          borderWidth={2}
          fromAnchor="center right"
          toAnchor="center left"
          from={`field_${fieldArrow?.id}`}
          to={fieldDataObjectId(fieldArrow?.id)}
        />
      ) : null}
      {pageArrow?.pageId &&
        isPagePartiallyVisible(pageArrow?.pageId) &&
        isGroupFullyVisible(pageArrow?.pageId) && (
          <LineTo
            borderColor={theme.colors.darkPurple}
            borderWidth={2}
            fromAnchor="center right"
            toAnchor="center left"
            from={`line_${pageArrow.pageId}`}
            to={
              pageArrow?.tagGroupId
                ? `group_${pageArrow.pageId}_${pageArrow.tagGroupId}`
                : `group_${pageArrow.pageId}`
            }
          />
        )}
    </>
  );
};
