import { PositionProps } from 'components/TableMask/Region';
import styled from 'styled-components';

interface ContainerProps {
  $left: number;
  $top: number;
  $height: number;
  $width: number;
  $selected: boolean;
}

interface DotProps {
  $top: number;
  $requiresAttention: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => $left}px;
  height: ${({ $height }) => $height}px;
  width: ${({ $width }) => $width}px;
  border: ${({ theme, $selected }) =>
    $selected ? `1px solid ${theme.colors.darkPurple}` : '0'};
  background-color: ${({ $selected }) =>
    $selected ? 'rgba(70,45,140,0.4)' : 'rgba(70,45,140,0.2)'};
  cursor: pointer;
`;

export const ColumnLine = styled.div<PositionProps>`
  position: absolute;
  left: ${({ position }) => position}%;
  top: 0;
  z-index: 10;
  border: none;
  border-left: ${({ theme }) => `1px dashed ${theme.colors.blackPurple}`};
  height: 100%;
  width: 2px;
`;

export const RowLine = styled.div<PositionProps>`
  position: absolute;
  top: ${({ position }) => position}%;
  left: 0;
  z-index: 10;
  border: none;
  border-top: ${({ theme }) => `1px dashed ${theme.colors.blackPurple}`};
  width: 100%;
  height: 2px;
`;

export const Dot = styled.div<DotProps>`
  position: absolute;
  top: ${({ $top }) => $top - 6}px;
  right: 0;
  z-index: 1001;
  margin-right: -10px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: ${({ theme, $requiresAttention }) =>
    $requiresAttention ? theme.colors.darkPink : theme.colors.darkPurple};
  border: ${({ theme }) => `3px solid ${theme.colors.white}`};
  box-shadow: 0 2px 4px 0 rgba(182, 20, 103, 0.3);
`;
