import { FC, useEffect, useState } from 'react';
import { Button } from 'components/Button';
import Modal from 'react-modal';
import { EditUserModalProps } from './EditUserModal.interface';
import { TFunction, useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  StyledTitle,
  StyledInput,
  StyledSelect,
  StyledCancel,
  Container,
  Divider,
  FieldsContainer,
  FieldContainer,
} from './EditUserModal.styles';
import { api, UserStatus } from 'api';

const options = () => {
  return [
    { value: 'editor', label: 'Editor' },
    { value: 'reader', label: 'Reader' },
  ];
};

const statusOptions = (t: TFunction) => {
  return [
    { value: 'disabled', label: t('disabled') },
    { value: 'active', label: t('active') },
  ];
};

export const EditUserModal: FC<EditUserModalProps> = ({
  user,
  open,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | undefined>();
  const [role, setRole] = useState<string | undefined>();
  const [status, setStatus] = useState<string | undefined>();

  useEffect(() => {
    if (user) {
      if (user.roles.length > 0) {
        setRole(user.roles[0].toLowerCase());
      }

      if (user.emails.length > 0) {
        setEmail(user.emails[0]);
      }

      setStatus(t(user.status.toLowerCase()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onEdit = () => {
    if (user && status && role) {
      api.updateUser(user?.userId, status.toUpperCase() as UserStatus, [role]);

      setEmail(undefined);
      setRole(undefined);

      onConfirm();
    }
  };

  const onCancel = () => {
    setEmail(undefined);
    setRole(undefined);

    onClose();
  };

  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick
      onRequestClose={onCancel}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'modal-base-narrow',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <StyledTitle size="h3">{t('editUserTitle')}</StyledTitle>
        <FieldsContainer>
          <FieldContainer>
            <StyledInput
              label={t('emailAddress')}
              value={email}
              disabled
              onChange={setEmail}
              shouldFocus
            />
          </FieldContainer>
          <FieldContainer>
            <StyledSelect
              label={t('role')}
              value={role}
              size="small"
              onChange={setRole}
              options={options()}
            />
          </FieldContainer>
          <FieldContainer>
            <StyledSelect
              label={t('status')}
              value={status}
              size="small"
              onChange={setStatus}
              options={statusOptions(t)}
            />
          </FieldContainer>
        </FieldsContainer>
        <Divider />
        <ButtonContainer>
          <StyledCancel
            onClick={onClose}
            color="light"
            size="large"
            title={t('cancel')}
          />

          <Button
            size="large"
            onClick={onEdit}
            disabled={!role || !status}
            title={t('edit')}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
