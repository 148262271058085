import { FC } from 'react';
import { ProcessStatus as Status } from 'api';
import { useTranslation, TFunction } from 'react-i18next';
import { ProcessStatusProps } from './ProcessStatus.interface';
import { StyledTypography } from './ProcessStatus.styles';

const translateStatus = (translation: TFunction, status?: Status) => {
  switch (status) {
    case 'Completed':
      return translation('completed');
    case 'Failed':
      return translation('failed');
    case 'In progress':
      return translation('inProgress');
    case 'Needs attention':
      return translation('needsAttention');
    case 'Stopped':
      return translation('stopped');
    case 'Waiting for files':
      return translation('waitingForFiles');
    case 'Accepted':
      return translation('acceptedStatus');
    case 'Edited':
      return translation('edited');
    default:
    case 'Queued':
      return translation('queued');
  }
};

export const ProcessStatus: FC<ProcessStatusProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <StyledTypography size="small" bold $status={status}>
      {translateStatus(t, status).toUpperCase()}
    </StyledTypography>
  );
};
