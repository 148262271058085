import { Collection } from 'api';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { createContextWith } from './createContext';
import { CollectionsContext } from './collections.interface';
import { Dictionary } from 'lodash';

const [useCollections, ContextProvider] = createContextWith<CollectionsContext>();

const CollectionsProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [collections, setCollections] = useState<Dictionary<Collection>>({});

  const getCollection = (id?: string) => {
    return id ? collections[id] : undefined;
  };

  const addCollection = (id: string, data: Collection) => {
    setCollections((prev) => {
      prev[id] = data;

      return prev;
    });
  };

  return (
    <ContextProvider
      value={{
        addCollection,
        getCollection,
      }}
    >
      {children}
    </ContextProvider>
  );
};

export { useCollections, CollectionsProvider };
