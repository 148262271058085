import { SET_USERS, updateUsersState } from '.';

const INITIAL_STATE = {
  users: [],
  total: 0,
  limit: 0,
  offset: 0,
};

export const usersReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_USERS:
      return updateUsersState(state, action.payload);
    default:
      return state;
  }
};
