import { Label } from 'components/Label';
import { SortType } from 'pages/Queues/QueueTable/Columns/Columns.interface';
import styled from 'styled-components';

interface SortIconProps {
  sortType?: SortType;
}

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

export const StyledName = styled(Label)`
  color: ${({ theme }) => theme.colors.gray300};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-right: 3px;
  user-select: none;
  text-align: left;
`;

export const SortIcon = styled.img<SortIconProps>`
  height: 16px;
  user-select: none;
  transform: ${({ sortType }) => (sortType === 'a' ? 'rotate(180deg)' : 0)};
`;
