import { SortBy } from 'api';
import { cloneDeep } from 'lodash';
import { BaseDispatch } from 'store/store.interface';
import {
  clearAnnotationEditModeState,
  clearGroupTagEditModeState,
  CLEAR_ANNOTATION_EDIT_MODE,
  CLEAR_GROUP_TAG_EDIT_MODE,
  SET_ANNOTATIONS_EDIT_MODE,
  SET_ANNOTATION_EDIT_MODE,
  SET_GROUP_TAGS_EDIT_MODE,
  SET_GROUP_TAG_EDIT_MODE,
  SET_TABLE_EDIT_MODE,
  updateAnnotationEditModeState,
  updateTableEditModeState,
  updateAnnotationsEditModeState,
  updateGroupTagEditModeState,
  updateGroupTagsEditModeState,
} from '.';
import { FIELD_ARROW, updateFieldArrowState } from './arrow';
import {
  ADD_CREATED_FIELDS,
  CLEAR_CREATED_FIELDS,
  clearCreatedFieldsState,
  updateCreateFieldsState,
} from './createField';
import { SET_CURRENT_PAGE, updateCurrentPageState } from './currentPage';
import { EDIT_FIELD, updateEditFieldState } from './editField';
import { EDIT_MODE, updateEditModeState } from './editMode';
import {
  SET_FILTER_BY_FIELD_TYPE,
  updateFilterByFieldTypesState,
} from './filterByFieldType';
import { SET_FILTER_BY_PHRASES, updateFilterByPhrasesState } from './filterByPhrases';
import { SET_FILTER_BY_STATUS, updateFilterByStatusState } from './filterByStatus';
import { PAGE_ARROW, updatePageArrowState } from './pageArrow';
import { PANE_DRAGGING, updatePaneDraggingState } from './paneDragging';
import { PANE_SIZES, updatePaneSizesState } from './paneSizes';
import { SET_SEARCH, updateSearchState } from './search';
import { SET_SORT_BY, updateSortByState } from './sortBy';
import { SET_ZOOM, updateZoomState } from './zoom';
import { SET_IMAGE, setImageState } from './images';
import {
  SET_FILTER_BY_FIELD_DUPLICATES,
  updateFilterByFieldDuplicatesState,
} from './filterByFieldDuplicates';

const CLEAR_SUPERVISION_STATE = 'CLEAR_SUPERVISION_STATE';

const INITIAL_STATE = {
  zoom: { scale: 1 },
  currentPage: undefined,
  paneDragging: false,
  paneSizes: [],
  editMode: false,
  createFields: {},
  editField: undefined,
  search: undefined,
  sortBy: 'position' as SortBy,
  fieldArrow: undefined,
  filterByStatus: [],
  filterByPhrases: [{ phrases: [] }],
  filterByFieldTypes: [],
  filterByFieldDuplicates: [],
  groupTagEditMode: {},
  annotationEditMode: {},
  images: {},
};

export const clearSupervisionState = () => (dispatch: (data: BaseDispatch) => void) => {
  dispatch({
    type: CLEAR_SUPERVISION_STATE,
  });
};

export const supervisionReducer = (state = cloneDeep(INITIAL_STATE), action: any) => {
  switch (action.type) {
    case SET_TABLE_EDIT_MODE:
      return updateTableEditModeState(state, action.payload);
    case PANE_DRAGGING:
      return updatePaneDraggingState(state, action.payload);
    case PANE_SIZES:
      return updatePaneSizesState(state, action.payload);
    case EDIT_MODE:
      return updateEditModeState(state, action.payload);
    case FIELD_ARROW:
      return updateFieldArrowState(state, action.payload);
    case PAGE_ARROW:
      return updatePageArrowState(state, action.payload);
    case EDIT_FIELD:
      return updateEditFieldState(state, action.payload);
    case CLEAR_CREATED_FIELDS:
      return clearCreatedFieldsState(state);
    case ADD_CREATED_FIELDS:
      return updateCreateFieldsState(state, action.payload);
    case SET_SEARCH:
      return updateSearchState(state, action.payload);
    case SET_CURRENT_PAGE:
      return updateCurrentPageState(state, action.payload);
    case SET_ZOOM:
      return updateZoomState(state, action.payload);
    case SET_SORT_BY:
      return updateSortByState(state, action.payload);
    case SET_FILTER_BY_FIELD_TYPE:
      return updateFilterByFieldTypesState(state, action.payload);
    case SET_FILTER_BY_STATUS:
      return updateFilterByStatusState(state, action.payload);
    case SET_FILTER_BY_PHRASES:
      return updateFilterByPhrasesState(state, action.payload);
    case SET_GROUP_TAG_EDIT_MODE:
      return updateGroupTagEditModeState(state, action.payload);
    case SET_ANNOTATION_EDIT_MODE:
      return updateAnnotationEditModeState(state, action.payload);
    case SET_ANNOTATIONS_EDIT_MODE:
      return updateAnnotationsEditModeState(state, action.payload);
    case SET_GROUP_TAGS_EDIT_MODE:
      return updateGroupTagsEditModeState(state, action.payload);
    case CLEAR_SUPERVISION_STATE:
      return cloneDeep(INITIAL_STATE);
    case CLEAR_GROUP_TAG_EDIT_MODE:
      return clearGroupTagEditModeState(state);
    case CLEAR_ANNOTATION_EDIT_MODE:
      return clearAnnotationEditModeState(state);
    case SET_IMAGE:
      return setImageState(state, action.payload);
    case SET_FILTER_BY_FIELD_DUPLICATES:
      return updateFilterByFieldDuplicatesState(state, action.payload);
    default:
      return state;
  }
};
