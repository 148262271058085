import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import { updateDataObject } from 'store/reducers/collection';
import { selectCommonTypes } from 'store/reducers/common';
import {
  selectFieldArrow,
  updateAnnotationEditMode,
  updateFieldArrow,
} from 'store/reducers/supervision';

export const useEditFieldStore = () => {
  const dispatch = useDispatch();
  const fieldArrow = useSelector(selectFieldArrow);
  const commonTypes = useSelector((state: StoreProps) => selectCommonTypes(state));
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const updateAnnotationField = useCallback((e) => dispatch(updateDataObject(e)), [
    dispatch,
  ]);

  const setAnnotationEditMode = useCallback(
    (e) => dispatch(updateAnnotationEditMode(e)),
    [dispatch],
  );

  return {
    commonTypes,
    fieldArrow,
    setFieldArrow,
    updateAnnotationField,
    setAnnotationEditMode,
  };
};
