import { FC } from 'react';
import { AuthImageProps } from './AuthImage.interface';
import { StyledImage } from './AuthImage.styles';
import { ENV_VARIABLE } from 'variables';
import { useSupervisionImage } from 'hooks';

export const AuthImage: FC<AuthImageProps> = ({
  url,
  preventLoading,
  onImageLoad,
  onImage,
}) => {
  const { image } = useSupervisionImage({ imageUrl: url, preventLoading, onImageLoad });

  return (
    <StyledImage
      withCursor={!!onImage}
      onLoad={() => {
        if (!ENV_VARIABLE.KEY_CLOAK_CLIENT_ID) {
          onImageLoad();
        }
      }}
      onClick={() => onImage && onImage()}
      draggable={false}
      alt=""
      src={image}
    />
  );
};
