import { FC } from 'react';
import Modal from 'react-modal';
import { Container } from './ZoomModal.styles';
import { Loader } from 'components/Loader';
import { LoadingModalProps } from './LoadingModal.interface';

export const LoadingModal: FC<LoadingModalProps> = ({ open }) => {
  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'fullscreen-modal-transparent',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <Loader />
      </Container>
    </Modal>
  );
};
