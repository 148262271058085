import { FC } from 'react';
import { IconButtonProps } from './IconButton.interface';
import { StyledButton } from './IconButton.styles';

export const IconButton: FC<IconButtonProps> = ({ onClick, icon, ...other }) => {
  return (
    <StyledButton onClick={onClick} {...other}>
      {icon}
    </StyledButton>
  );
};
