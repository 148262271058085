// @ts-nocheck
import { IconMore } from 'components/Icons';
import { Component } from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  position: absolute;
  left: ${({ index }) => (index !== 1 ? '-7px' : '-8px')};
  top: 48%;
  width: 14px;
  height: 45px;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 10px;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s background-color;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray400};
    transition: 0.3s background-color;
  }
  z-index: 2;

  ::before {
    content: '';
    width: 11px;
    height: 100vh;
    display: block;
    position: relative;
    z-index: 1;
    left: 2px;
  }
`;

const StyledIconMore = styled(IconMore)`
  color: ${({ theme }) => theme.colors.gray300};
  width: 30px;
  height: 45px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: col-resize;
  position: absolute;
  transform: rotate(90deg);
`;

const Wrapper = styled.div`
  background: ${({ theme, index, value }) =>
    index !== 1 || (index === 1 && value === '0%')
      ? theme.colors.gray400
      : theme.colors.transparent};
  z-index: 0;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  :hover {
    transition: all 2s ease;
  }
`;

const HorizontalWrapper = styled(Wrapper)`
  height: 11px;
  margin: -6px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
  :hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
  .disabled {
    cursor: not-allowed;
  }
  .disabled:hover {
    border-color: transparent;
  }
`;

const VerticalWrapper = styled(Wrapper)`
  width: 1px;
  z-index: 1;
  border-left: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  cursor: col-resize;
  .disabled {
    cursor: not-allowed;
  }
  .disabled:hover {
    border-color: transparent;
  }
`;

class Resizer extends Component {
  render() {
    const {
      index,
      value,
      split = 'vertical',
      onClick = () => {},
      onDoubleClick = () => {},
      onMouseDown = () => {},
      onTouchEnd = () => {},
      onTouchStart = () => {},
    } = this.props;

    const props = {
      ref: (_) => (this.resizer = _),
      'data-attribute': split,
      'data-type': 'Resizer',
      index,
      value,
      onMouseDown: (event) => onMouseDown(event, index),
      onTouchStart: (event) => {
        event.preventDefault();
        onTouchStart(event, index);
      },
      onTouchEnd: (event) => {
        event.preventDefault();
        onTouchEnd(event, index);
      },
      onClick: (event) => {
        if (onClick) {
          event.preventDefault();
          onClick(event, index);
        }
      },
      onDoubleClick: (event) => {
        if (onDoubleClick) {
          event.preventDefault();
          onDoubleClick(event, index);
        }
      },
    };

    return split === 'vertical' ? (
      <VerticalWrapper {...props}>
        <IconWrapper>
          <StyledIconMore />
        </IconWrapper>
      </VerticalWrapper>
    ) : (
      <HorizontalWrapper {...props} />
    );
  }
}

export default Resizer;
