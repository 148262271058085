import { FilterPhrase } from 'pages/Supervision/ExtractedFields/Filters/Filters.interface';
import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface FilterByPhrasesProps {
  phrases: Array<FilterPhrase>;
}

interface FilterByPhrasesDispatch extends BaseDispatch {
  payload: FilterByPhrasesProps;
}

export const SET_FILTER_BY_PHRASES = 'SET_FILTER_BY_PHRASES';

export const selectFilterByPhrases = (state: StoreProps) =>
  state.supervision.filterByPhrases;

export const updateFilterByPhrasesState = (
  state: SupervisionStore,
  data: FilterByPhrasesProps,
) => {
  return { ...state, filterByPhrases: [...data.phrases] };
};

export const updateFilterByPhrases = (phrases: Array<FilterPhrase>) => (
  dispatch: (data: FilterByPhrasesDispatch) => void,
) => {
  dispatch({
    type: SET_FILTER_BY_PHRASES,
    payload: { phrases },
  });
};
