import { useSelector } from 'react-redux';
import { selectCollectionStatus } from 'store/reducers/collection';
import {
  selectAnnotationsEditMode,
  selectGroupTagsEditMode,
} from 'store/reducers/supervision';

export const useAcceptNextStore = () => {
  const status = useSelector(selectCollectionStatus);
  const annotationsEditMode = useSelector(selectAnnotationsEditMode);
  const groupTagsEditMode = useSelector(selectGroupTagsEditMode);

  return {
    groupTagsEditMode,
    annotationsEditMode,
    status,
  };
};
