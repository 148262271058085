import { ConfigType, ExtractorConfig } from 'api';
import { Button } from 'components/Button';
import { debounce, uniqueId } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import {
  selectCollapsedExtractionFields,
  selectEditField,
  selectExtractionField,
  updateEditExtractionField,
} from 'store/reducers/configuration';
import { ConfigItem } from '../ConfigItem';
import { ExtractionFieldItemProps } from './ExtractionFieldItem.interface';
import {
  Container,
  Header,
  HorizontalContainer,
  IconContainer,
  StyledArrowDown,
  StyledDeleteIcon,
  DeleteContainer,
  StyledInput,
  StyledIconButton,
  StyledButtonContainer,
  StyledSwitch,
  SwitchLabel,
  SwitchContainer,
} from './ExtractionFieldItem.styles';

export const ExtractionFieldItem: FC<ExtractionFieldItemProps> = ({
  fieldId,
  extractionId,
  onDeleteField,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allCollapsed = useSelector(selectCollapsedExtractionFields);
  const [open, setOpen] = useState(true);
  const baseField = useSelector((state: StoreProps) =>
    selectExtractionField(state, extractionId, fieldId),
  );
  const editField = useSelector((state: StoreProps) => selectEditField(state, fieldId));
  const addEditExtractionField = useCallback(
    (e) => dispatch(updateEditExtractionField(e)),
    [dispatch],
  );
  const [name, setName] = useState(baseField.fieldNameText);

  const field = editField || baseField;

  const onCollapse = () => {
    setOpen(!open);
  };

  const onName = (value?: string) => {
    addEditExtractionField({
      field: { ...field, fieldNameText: value, extractionId, saved: false, error: false },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceName = useCallback(debounce(onName, 500), [field]);

  const onFieldName = (value?: string) => {
    setName(value);
    debounceName(value);
  };

  useEffect(() => {
    setOpen(!allCollapsed);
  }, [allCollapsed]);

  const onExtractorType = (extractorId: string, configType?: ConfigType) => {
    let config: ExtractorConfig = { id: extractorId, configType };

    if (configType === ConfigType.REGEX) {
      config = { ...config, keywords: [''], ignoreCase: true, matchGroup: false };
    }

    if (configType === ConfigType.KEYWORDS) {
      config = {
        ...config,
        keywords: [],
        threshold: 0,
        ignoreCase: true,
        separators: [' ', '\n'],
      };
    }

    if (configType === ConfigType.PREDEFINED) {
      config = { ...config };
    }

    addEditExtractionField({
      field: {
        ...field,
        saved: false,
        error: false,
        extractionId,
        configs: field.configs.map((item) => {
          if (item.id === extractorId) {
            return { ...config };
          }

          return item;
        }),
      },
    });
  };

  const onThreshold = (extractorId: string, threshold?: string) => {
    addEditExtractionField({
      field: {
        ...field,
        saved: false,
        error: false,
        extractionId,
        configs: field.configs.map((item) => {
          if (item.id === extractorId) {
            return { ...item, threshold };
          }

          return item;
        }),
      },
    });
  };

  const onIgnoreCase = (extractorId: string, ignoreCase?: boolean) => {
    addEditExtractionField({
      field: {
        ...field,
        saved: false,
        error: false,
        extractionId,
        configs: field.configs.map((item) => {
          if (item.id === extractorId) {
            return { ...item, ignoreCase };
          }

          return item;
        }),
      },
    });
  };

  const onMatchGroup = (extractorId: string, matchGroup?: boolean) => {
    addEditExtractionField({
      field: {
        ...field,
        saved: false,
        error: false,
        extractionId,
        configs: field.configs.map((item) => {
          if (item.id === extractorId) {
            return { ...item, matchGroup };
          }

          return item;
        }),
      },
    });
  };

  const onKeywords = (extractorId: string, keywords?: Array<string | undefined>) => {
    addEditExtractionField({
      field: {
        ...field,
        extractionId,
        saved: false,
        error: false,
        configs: field.configs.map((item) => {
          if (item.id === extractorId) {
            return { ...item, keywords };
          }

          return item;
        }),
      },
    });
  };

  const onSeparators = (extractorId: string, separators?: Array<string | undefined>) => {
    addEditExtractionField({
      field: {
        ...field,
        extractionId,
        saved: false,
        error: false,
        configs: field.configs.map((item) => {
          if (item.id === extractorId) {
            return { ...item, separators };
          }

          return item;
        }),
      },
    });
  };

  const onDeleteExtractor = (extractorId: string) => {
    addEditExtractionField({
      field: {
        ...field,
        extractionId,
        saved: false,
        error: false,
        configs: field.configs.filter((item) => item.id !== extractorId),
      },
    });
  };

  const onExtractorPredefinedField = (extractorId: string, fieldType?: string) => {
    addEditExtractionField({
      field: {
        ...field,
        extractionId,
        saved: false,
        error: false,
        configs: field.configs.map((item) => {
          if (item.id === extractorId) {
            return { ...item, predefinedExtractorType: fieldType };
          }

          return item;
        }),
      },
    });
  };

  const onMandatory = (mandatory: boolean) => {
    addEditExtractionField({
      field: {
        ...field,
        extractionId,
        mandatory,
        saved: false,
        error: false,
      },
    });
  };

  const onAddConfig = () => {
    addEditExtractionField({
      field: {
        ...field,
        extractionId,
        saved: false,
        error: false,
        configs: [
          ...field.configs,
          { id: uniqueId(), configType: 'predefined_extractor_configuration' },
        ],
      },
    });
  };

  return (
    <Container $error={!!field.error}>
      <Header>
        <HorizontalContainer>
          <IconContainer onClick={onCollapse}>
            <StyledArrowDown className={open ? 'rotate' : ''} />
          </IconContainer>
          <StyledInput value={name} label={t('labelName')} onChange={onFieldName} />
          <SwitchContainer>
            <StyledSwitch
              size="small"
              checked={field.mandatory}
              onChange={(value) => onMandatory(value)}
            />
            <SwitchLabel size="small" bold>
              {t('mandatory')}
            </SwitchLabel>
          </SwitchContainer>
        </HorizontalContainer>
        <DeleteContainer>
          <StyledIconButton
            withBackground={false}
            onClick={() => onDeleteField(field.id)}
            icon={<StyledDeleteIcon />}
          />
        </DeleteContainer>
      </Header>
      <Collapse isOpened={open}>
        {field.configs &&
          field.configs.map((item) => (
            <ConfigItem
              key={item.id}
              extractionId={extractionId}
              onExtractorType={(id, value) => onExtractorType(id, value)}
              extractorConfig={item}
              onDelete={(id) => onDeleteExtractor(id)}
              onKeywords={(id, values) => onKeywords(id, values)}
              onSeparators={(id, values) => onSeparators(id, values)}
              onThreshold={(id, value) => onThreshold(id, value)}
              onPredefinedField={(id, value) => onExtractorPredefinedField(id, value)}
              onIgnoreCase={(id, value) => onIgnoreCase(id, value)}
              onMatchGroup={(id, value) => onMatchGroup(id, value)}
            />
          ))}
        <StyledButtonContainer>
          <Button onClick={onAddConfig} color="light" title={t('addExtractor')} />
        </StyledButtonContainer>
      </Collapse>
    </Container>
  );
};
