import axios, { AxiosError, AxiosResponse, CancelToken } from 'axios';
import {
  CollectionsResponse,
  CreateCollectionResponse,
  CreateCollection,
  FileExport,
  Users,
  UserStatus,
  User,
  Extractions,
  ExtractionField,
  ExtractorPredefinedFields,
  BaseExtractionField,
  Version,
  ExtractionEnumValues,
} from 'api';
import {
  Annotation,
  Collection,
  CollectionAction,
  CollectionResult,
  CommonTypesApi,
  CreateAnnotation,
  PatchAnnotation,
  EditTableAnnotationProps,
  TableAnnotation,
  PatchComplexField,
  CreateComplexAnnotation,
  PageObjectType,
} from './api.interface';
import queryString from 'query-string';
import { API_ACCESS_TOKEN, API_REFRESH_TOKEN } from './api.hooks';
import { ENV_VARIABLE } from 'variables';
import { Dictionary } from '@reduxjs/toolkit';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem(API_ACCESS_TOKEN);

  if (accessToken) {
    config.headers['Authorization'] =
      'Bearer ' + window.localStorage.getItem(API_ACCESS_TOKEN);
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const { response } = error;
    const refreshToken = window.localStorage.getItem(API_REFRESH_TOKEN);

    if (response?.status === 401 && refreshToken) {
      return axiosInstance
        .post(
          `${ENV_VARIABLE.KEY_CLOAK_URL}realms/${ENV_VARIABLE.KEY_CLOAK_REALM}/protocol/openid-connect/token`,
          queryString.stringify({
            client_id: ENV_VARIABLE.KEY_CLOAK_CLIENT_ID,
            refresh_token: refreshToken,
            grant_type: API_REFRESH_TOKEN,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
        .then((response) => {
          error.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;

          window.localStorage.setItem(API_ACCESS_TOKEN, response.data.access_token);
          window.localStorage.setItem(API_REFRESH_TOKEN, response.data.refresh_token);

          return axiosInstance.request(error.config);
        });
    }

    return Promise.reject(error);
  },
);

export const api = {
  async addEditor(collectionId: string, editorId: string): Promise<void> {
    return post(`/collections/${collectionId}/editor`, { editor_id: editorId });
  },

  async deleteEditor(collectionId: string, editorId: string): Promise<void> {
    return deleteRequest(`/collections/${collectionId}/editor/${editorId}`, {});
  },

  async activeEditor(collectionId: string, editorId: string): Promise<void> {
    return post(`/collections/${collectionId}/editor/active`, {
      editor_id: editorId,
    });
  },

  async fetchExtractions(): Promise<Extractions> {
    return get(`/extractions`, {});
  },

  async fetchExtractorPredefinedFields(id: string): Promise<ExtractorPredefinedFields> {
    return get(`/extractions/${id}/predefined_extractors`, {});
  },

  async fetchFieldTypes(): Promise<CommonTypesApi> {
    return get(`/annotation_types`, {});
  },

  async exportCollections(
    collectionIds: Array<string>,
    format: string,
    language: string,
  ): Promise<FileExport> {
    return get(
      `/export/collections`,
      { collection_ids: collectionIds, format, language },
      (params) => queryString.stringify(params),
    );
  },

  async fetchUser(): Promise<User> {
    return get('/user', {});
  },

  async fetchVersion(): Promise<Version> {
    return get('/version', {});
  },

  async fetchExportedFile(fileId: string): Promise<AxiosResponse<any>> {
    return getResponseBlob(`/export/${fileId}`);
  },

  async fetchUsers(params: string): Promise<Users> {
    return get(`/admin/users?${params}`, {});
  },

  async nextCollection(
    collectionId: string,
    params: Dictionary<string>,
  ): Promise<Collection> {
    return get(`/collections/next?current_collection_id=${collectionId}`, { ...params });
  },

  async fetchCollections(params: string): Promise<CollectionsResponse> {
    return get(`/collections?${params}`, {});
  },

  async fetchImage(url: string, cancelToken?: CancelToken): Promise<Blob> {
    return getBlob(`/${url}`, cancelToken);
  },

  async createCollection(data: CreateCollection): Promise<CreateCollectionResponse> {
    return post(`/collections`, data);
  },

  async uploadFile(data: FormData, onUploadProgress?: (event: ProgressEvent) => void) {
    return upload('/files', data, onUploadProgress);
  },

  async deleteExtractionField(extractionId: string, fieldId: string): Promise<void> {
    return deleteRequest(`/extractions/${extractionId}/fields/${fieldId}`, {});
  },

  async deleteUser(id: string): Promise<void> {
    return deleteRequest(`/admin/users/${id}`, {});
  },

  async deleteCollection(id: string): Promise<void> {
    return deleteRequest(`/collections/${id}`, {});
  },

  async deleteAnnotation(
    resultId: string,
    annotationId: string,
    type?: string,
  ): Promise<void> {
    return deleteRequest(`/results/${resultId}/annotations/${annotationId}`, { type });
  },

  async fetchCollection(id: string): Promise<Collection> {
    return get(`/collections/${id}`, {});
  },

  async fetchCollectionResult(id: string): Promise<CollectionResult> {
    return get(`/collections/${id}/results`, {});
  },

  async updateExtractorField(id: string, field: ExtractionField): Promise<void> {
    return put(`/extractions/${id}/fields/${field.id}`, { ...field });
  },

  async updateTableAnnotation(
    resultId: string,
    tableId: string,
    tableEdits: EditTableAnnotationProps,
  ): Promise<TableAnnotation> {
    return put(`/results/${resultId}/table_annotations/${tableId}`, {
      ...tableEdits,
    });
  },

  async updateUser(id: string, status: UserStatus, roles: Array<string>): Promise<void> {
    return patch(`/admin/users/${id}`, { status, roles });
  },

  async updatePriority(id: string, highPriority: boolean): Promise<void> {
    return patch(`/collections/${id}`, { highPriority });
  },

  async splitDocument(
    resultId: string,
    pageIds: Array<string>,
  ): Promise<CollectionResult> {
    return post(`/results/${resultId}/documents/create_from_pages`, { pageIds });
  },

  async updatePageOrder(
    resultId: string,
    documentId: string,
    pageIds: Array<string>,
  ): Promise<CollectionResult> {
    return patch(`/results/${resultId}/documents/${documentId}/edit_pages`, { pageIds });
  },

  async markToDelete(id: string): Promise<void> {
    return post(`/collections/${id}/tags`, { tag: 'mark_to_delete', value: 'true' });
  },

  async unmarkFromDelete(id: string): Promise<void> {
    return post(`/collections/${id}/tags`, { tag: 'mark_to_delete', value: null });
  },

  async inviteUser(email: string, role: string): Promise<void> {
    return post('/admin/invitations', { email, roles: [role] });
  },

  async collectionAction(id: string, action: CollectionAction): Promise<void> {
    return post(`/collections/${id}/processing`, { action });
  },

  async acceptResult(resultId: string): Promise<void> {
    return post(`/results/${resultId}/processing`, { action: 'accept' });
  },

  async acceptAnnotation(
    resultId: string,
    annotationId: string,
    type?: PageObjectType,
  ): Promise<Annotation> {
    return post(`/results/${resultId}/annotations/${annotationId}/processing`, {
      action: 'accept',
      type,
    });
  },

  async acceptTagGroup(resultId: string, tagGroupId: string): Promise<Annotation> {
    return post(`/results/${resultId}/tag_groups/${tagGroupId}/processing`, {
      action: 'accept',
    });
  },

  async updateGroupTags(resultId: string, tagGroupId: string, tags: Array<string>) {
    return post(`/results/${resultId}/tag_groups/${tagGroupId}`, {
      tags,
    });
  },

  async createExtractorField(
    extractionId: string,
    data: BaseExtractionField,
  ): Promise<Annotation> {
    return post(`/extractions/${extractionId}/fields`, data);
  },

  async createAnnotation(
    resultId: string,
    data: CreateAnnotation | CreateComplexAnnotation,
  ): Promise<Annotation> {
    return post(`/results/${resultId}/annotations`, { ...data });
  },

  async patchAnnotation({
    resultId,
    annotationId,
    label,
    value,
  }: PatchAnnotation): Promise<Annotation> {
    return patch(`/results/${resultId}/annotations/${annotationId}`, {
      label,
      value,
    });
  },

  async patchComplexField({
    resultId,
    annotationId,
    annotations,
  }: PatchComplexField): Promise<Annotation> {
    return patch(`/results/${resultId}/annotations/${annotationId}`, {
      annotationsData: annotations,
    });
  },

  async fetchExtractionEnumValues({
    enumLabel,
    search,
    limit = 15,
  }: ExtractionEnumValues): Promise<{ values: string[] }> {
    return get(`/extraction_enum_values/${enumLabel}`, {
      search: (search?.length || 0) >= 1 ? search : undefined,
      limit,
    });
  },
};

const get = (
  uri: string,
  params: Object,
  paramsSerializer?: (params: object) => string,
) => {
  const options = {
    params,
  };

  return axiosInstance
    .get(`${ENV_VARIABLE.API_URL}${uri}`, { ...options, paramsSerializer })
    .then((response) => response.data);
};

const getBlob = (uri: string, cancelToken?: CancelToken) => {
  return axiosInstance
    .get(`${ENV_VARIABLE.API_URL}${uri}`, { responseType: 'blob', cancelToken })
    .then((response) => response.data);
};

const getResponseBlob = (uri: string) => {
  return axiosInstance
    .get(`${ENV_VARIABLE.API_URL}${uri}`, { responseType: 'blob' })
    .then((response) => response);
};

const post = (uri: string, data: Object) => {
  return axiosInstance
    .post(`${ENV_VARIABLE.API_URL}${uri}`, data)
    .then((response) => response.data);
};

const patch = (uri: string, data: Object) => {
  return axiosInstance
    .patch(`${ENV_VARIABLE.API_URL}${uri}`, data)
    .then((response) => response.data);
};

const put = (uri: string, data: Object) => {
  return axiosInstance
    .put(`${ENV_VARIABLE.API_URL}${uri}`, data)
    .then((response) => response.data);
};

const deleteRequest = (uri: string, data: Object) => {
  return axiosInstance
    .delete(`${ENV_VARIABLE.API_URL}${uri}`, { data })
    .then((response) => response.data);
};

const upload = (uri: string, data: FormData, onUploadProgress?: (event: any) => void) => {
  return axiosInstance
    .post(`${ENV_VARIABLE.API_URL}${uri}`, data, {
      onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data);
};
