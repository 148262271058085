import { UserStatus } from 'api';
import { Label } from 'components/Label';
import styled from 'styled-components';
import { TyroTheme } from 'styles';

interface StyledTypographyProps {
  $status?: UserStatus;
}

const statusBackgroundColor = (theme: TyroTheme, status?: UserStatus) => {
  switch (status) {
    case 'INVITED':
      return theme.colors.successGreen;
    case 'DISABLED':
      return theme.colors.gray300;
    case 'ACTIVE':
      return theme.colors.neonPurple;
    default:
      return theme.colors.gray300;
  }
};

export const StyledTypography = styled(Label)<StyledTypographyProps>`
  background-color: ${({ theme, $status }) => statusBackgroundColor(theme, $status)};
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
`;
