import { FC } from 'react';
import { Button } from 'components/Button';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { ConfirmationModalProps } from './ConfirmationModal.interface';
import {
  ButtonContainer,
  StyledTitle,
  StyledSubTitle,
  Container,
  Divider,
  StyledCancel,
} from './ConfirmationModal.styles';

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  title,
  content,
  contentComponent,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <Container>
        <StyledTitle size="h3">{title}</StyledTitle>
        {content ? <StyledSubTitle>{content}</StyledSubTitle> : contentComponent}
        <Divider />
        <ButtonContainer>
          <StyledCancel
            onClick={onClose}
            size="large"
            color="light"
            title={t('cancel')}
          />
          <Button size="large" onClick={onConfirm} title={t('confirmAction')} />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
