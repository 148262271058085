import { Input as AntInput } from 'antd';
import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { FC } from 'react';
import { InputProps } from './Input.interface';
import { StyledInput, Container, StyledLabel } from './Input.styles';

const ENTER_KEY = 'Enter';
const ESC_KEY = 'Escape';

export const Input: FC<InputProps> = ({
  id,
  onChange,
  error,
  value,
  label,
  shouldFocus,
  onKey,
  onFocus,
  type = 'text',
  disabled = false,
  onBlur,
  ...other
}) => {
  const inputRef = useRef<AntInput>(null);

  useEffect(() => {
    if (shouldFocus) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFocus]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  const handleFocus = () => {
    onFocus?.();
  };
  const handleBlur = () => {
    onBlur?.();
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === ENTER_KEY && event.shiftKey && onKey) {
      onKey('shift');
    } else if (event.key === ENTER_KEY && onKey) {
      onKey('enter');
    } else if (event.key === ESC_KEY && onKey) {
      onKey('esc');
    }
  };

  return (
    <Container>
      {label && (
        <StyledLabel size="small" bold>
          {label}
        </StyledLabel>
      )}
      <StyledInput
        id={id}
        ref={inputRef}
        $error={error}
        disabled={disabled}
        type={type}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyPress}
        onChange={handleChange}
        {...other}
      />
    </Container>
  );
};
