import { ExtractionEnumInputProps } from './ExtractionEnumInput.interface';
import { useCallback, useEffect, useState } from 'react';
import { api } from 'api';
import { Select } from 'components/Select';
import { debounce } from 'lodash';

// // + annotationId, resultId do wysyłki albo best_matches per anotacja

export const ExtractionEnumInput = ({
  label,
  initialValue = '',
  onChange,
  size,
  metadata,
}: ExtractionEnumInputProps) => {
  const [enumValues, setEnumValues] = useState<Array<string>>([]);
  const [searchQuery, setSearchQuery] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((search?: string) => {
      setIsLoading(true);
      api
        .fetchExtractionEnumValues({ enumLabel: label, search })
        .then((data) => {
          const bestMatches = metadata?.best_matches;

          if (!search && bestMatches) {
            const filteredValues = data.values.filter(
              (value) => !bestMatches.includes(value),
            );
            setEnumValues([...bestMatches, ...filteredValues]);
          } else {
            setEnumValues(data.values);
          }
        })
        .finally(() => setIsLoading(false));
    }, 500),
    [label, metadata],
  );

  useEffect(() => {
    debouncedSearch();
  }, [debouncedSearch]);

  const onSearch = useCallback(
    async (value: string) => {
      setSearchQuery(value);
      await debouncedSearch(value);
      if (enumValues.includes(value)) onChange(value);
    },
    [debouncedSearch, enumValues, onChange],
  );

  const onMenuItemSelect = useCallback(
    (value: string | undefined) => {
      if (!value) return;
      setSearchQuery(value);
      onChange(value);
    },
    [onChange],
  );

  const options = enumValues.map((item) => ({ value: item, label: item }));

  return (
    <Select
      filterOption={() => true}
      value={initialValue}
      searchValue={searchQuery}
      onSearch={onSearch}
      onChange={onMenuItemSelect}
      size={size}
      options={isLoading ? [] : options}
      loading={isLoading}
      listHeight={416}
    />
  );
};
