import { Annotation, PageObjectType, TagGroup } from 'api';

export interface ExtractedFieldsListProps {
  data: Array<GroupItem>;
  onGroupCollapse: (id: string) => void;
  collapsedGroups?: Array<string>;
}

export const instanceOfPageItem = (object: any): object is PageItem => {
  return 'isPage' in object;
};

export const instanceOfTagGroupsItem = (object: any): object is TagGroupsItem => {
  return 'isTagGroup' in object;
};

export interface GroupItem {
  id: string;
  isGroup?: boolean;
  collapsed?: boolean;
  label?: string;
  labels?: Array<string>;
  items?: Array<FieldItem | PageItem>;
  type?: PageObjectType;
  annotations?: Array<Annotation>;
}

export interface FieldItem extends GroupItem {
  pageId: string;
  annotationId?: string;
  pageObjectId?: string;
  resultId: string;
}

export interface TagGroupsItem extends GroupItem {
  isTagGroup: boolean;
  pageId?: string;
  resultId: string;
  documentId: string;
  tags: Array<TagGroup>;
}

export interface PageItem extends GroupItem {
  isPage: boolean;
}
