import styled from 'styled-components';
import { TyroTheme } from 'styles';

interface PositionProps {
  $height: number;
  $width: number;
  $top?: number;
  $left?: number;
  $requiresAttention?: boolean;
  $selected?: boolean;
}

interface DotProps {
  $top: number;
  $requiresAttention: boolean;
}

const bgColor = (selected: boolean, requiresAttention: boolean) => {
  if (requiresAttention) {
    return selected ? 'rgba(182,20,103,0.4)' : 'rgba(182, 20, 103, 0.2)';
  }

  return selected ? 'rgba(70,45,140,0.4)' : 'rgba(70, 45, 140, 0.2)';
};

const border = (theme: TyroTheme, selected: boolean, requiresAttention: boolean) => {
  if (selected && requiresAttention) {
    return `1px solid ${theme.colors.darkPink}`;
  }

  if (selected) {
    return `1px solid ${theme.colors.darkPurple}`;
  }

  return '0px';
};

export const StyledLookUp = styled.div.attrs<PositionProps>(
  ({
    theme,
    $top,
    $left,
    $width,
    $height,
    $selected = false,
    $requiresAttention = false,
  }) => ({
    style: {
      top: $top,
      left: $left,
      width: $width,
      height: $height,
      backgroundColor: bgColor($selected, $requiresAttention),
    },
  }),
)<PositionProps>`
  width: 100%;
  position: absolute;
  pointer-events: auto;
  border: ${({ theme, $selected = false, $requiresAttention = false }) =>
    border(theme, $selected, $requiresAttention)};
`;

export const Dot = styled.div<DotProps>`
  position: absolute;
  top: ${({ $top }) => $top - 6}px;
  right: 0;
  z-index: 1001;
  margin-right: -10px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: ${({ theme, $requiresAttention }) =>
    $requiresAttention ? theme.colors.darkPink : theme.colors.darkPurple};
  border: ${({ theme }) => `3px solid ${theme.colors.white}`};
  box-shadow: 0 2px 4px 0 rgba(182, 20, 103, 0.3);
`;
