import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'lodash';
import { StoreProps } from 'store/store.interface';

export interface CleanTableProps {
  id: string;
}

export interface CellEdit {
  id: string;
  text?: string;
  type: 'cell' | 'header';
}

interface CellEditAction extends CellEdit {
  tableId: string;
}

const INITIAL_STATE: Dictionary<Dictionary<CellEdit>> = {};

export const tableAnnotationEditModeSlice = createSlice({
  name: 'TABLE_ANNOTATION_EDIT_MODE',
  initialState: INITIAL_STATE,
  reducers: {
    setCellEditMode: (state, action: PayloadAction<CellEditAction>) => {
      const cell = {
        id: action.payload.id,
        text: action.payload.text,
        type: action.payload.type,
      };

      if (state[action.payload.tableId]) {
        state[action.payload.tableId][action.payload.id] = cell;
      } else {
        state[action.payload.tableId] = { [action.payload.id]: cell };
      }
    },
    clearTableEditMode: (state, action: PayloadAction<CleanTableProps>) => {
      state[action.payload.id] = {};
    },
    clearTablesEditMode: () => INITIAL_STATE,
  },
});

export const {
  setCellEditMode,
  clearTableEditMode,
  clearTablesEditMode,
} = tableAnnotationEditModeSlice.actions;
export const selectTableEditMode = (state: StoreProps, tableId: string) =>
  state.tableAnnotationEditMode[tableId];
