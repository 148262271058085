import { theme } from 'styles';

const handleSize = 12;
export const style = {
  RowHandle: {
    position: 'absolute',
    left: -15,
    top: -5,
    height: 'calc(100% + 10px)',
    width: '8px',
    zIndex: 1,
    borderRadius: '10px',
    backgroundColor: theme.colors.darkPurple,
  },
  RegionHandleRight: {
    position: 'absolute',
    right: -8,
    top: 0,
    height: '100%',
    width: '8px',
    zIndex: 1,
    backgroundColor: 'blue',
    cursor: 'e-resize',
  },
  RegionHandleBottom: {
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: '100%',
    height: '8px',
    zIndex: 1,
    cursor: 's-resize',
    backgroundColor: 'blue',
  },
  RegionHandleSE: {
    position: 'absolute',
    bottom: (-2 * handleSize) / 2,
    right: (-2 * handleSize) / 2,
    width: handleSize,
    height: handleSize,
    background: 'red',
    cursor: 'se-resize',
  },
  RegionHandleSW: {
    position: 'absolute',
    bottom: (-2 * handleSize) / 2,
    left: (-2 * handleSize) / 2,
    width: handleSize,
    height: handleSize,
    background: 'red',
    cursor: 'sw-resize',
  },
  RegionHandleNE: {
    position: 'absolute',
    top: (-2 * handleSize) / 2,
    right: (-2 * handleSize) / 2,
    width: handleSize,
    height: handleSize,
    background: 'red',
    cursor: 'ne-resize',
  },
  RegionSelect: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
  },
};
