import { FC } from 'react';
import { Button } from 'components/Button';
import { QueuesFilters } from './UsersFilters';
import { UsersTable } from './UsersTable';
import { useTranslation } from 'react-i18next';
import {
  StyledTitle,
  Content,
  Container,
  Header,
  HeaderContent,
  TableContainer,
} from './Users.styles';
import { useUsersList, useRefreshList } from './Users.hooks';
import { useEditUserModalStore, useInviteUserModalStore } from 'store/reducers/modals';
import { InviteUserModal } from 'modals/InviteUserModal';
import { api } from 'api';
import { IconCheckCircle } from 'components/Icons';
import { theme } from 'styles';
import { toast } from 'react-toastify';
import { EditUserModal } from 'modals/EditUserModal';
import { useUserScope } from 'hooks';

export const Users: FC = () => {
  const { users, loading, totalSize } = useUsersList();
  const { open, setInviteUserModal } = useInviteUserModalStore();
  const { refresh } = useRefreshList();
  const { open: editModal, user, setEditUserModal } = useEditUserModalStore();
  const { t } = useTranslation();
  const { isAllowed } = useUserScope('USERS_INVITE');

  const onClose = () => {
    setInviteUserModal({ open: false });
  };

  const onConfirm = (email: string, role: string) => {
    api.inviteUser(email, role).then(() => {
      refresh();

      toast.success(t('invitationSend'), {
        position: 'bottom-left',
        icon: <IconCheckCircle />,
        progressStyle: { backgroundColor: theme.colors.darkPurple },
      });
    });
  };

  const onCloseEdit = () => {
    setEditUserModal({ open: false });
  };

  const onConfirmEdit = () => {
    setEditUserModal({ open: false });
    refresh();
  };

  return (
    <>
      <Container>
        <Header>
          <HeaderContent>
            <StyledTitle size="h2">{t('users')}</StyledTitle>
            {isAllowed && (
              <Button
                onClick={() => setInviteUserModal({ open: true })}
                shadow={false}
                size="large"
                title={t('inviteUser')}
              />
            )}
          </HeaderContent>
        </Header>
        <Content>
          <QueuesFilters />
          <TableContainer>
            <UsersTable loading={loading} data={users} totalSize={totalSize} />
          </TableContainer>
        </Content>
      </Container>
      <EditUserModal
        user={user}
        open={editModal}
        onClose={onCloseEdit}
        onConfirm={onConfirmEdit}
      />
      <InviteUserModal open={open} onClose={onClose} onConfirm={onConfirm} />
    </>
  );
};
