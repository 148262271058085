import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface EditModeProps {
  editMode: boolean;
}

interface EditModeDispatch extends BaseDispatch {
  payload: EditModeProps;
}

export const EDIT_MODE = 'EDIT_MODE';

export const selectEditMode = (state: StoreProps) => state.supervision.editMode;

export const updateEditModeState = (state: SupervisionStore, data: EditModeProps) => {
  return { ...state, editMode: data.editMode };
};

export const updateEditMode = (editMode: boolean) => (
  dispatch: (data: EditModeDispatch) => void,
) => {
  dispatch({
    type: EDIT_MODE,
    payload: { editMode },
  });
};
