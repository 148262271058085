import { FC, memo, useEffect, useRef } from 'react';
import { areEqual, ListChildComponentProps, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoizeOne from 'memoize-one';
import { PageThumbnailsListProps, isPageSection } from './PageThumbnailsList.interface';
import { usePageThumbnailsListStore } from './PageThumbnailsList.hooks';
import { RowContainer } from './PageThumbnailsList.styles';
import { PageThumbnail } from './PageThumbnail';
import { DocumentItem } from './DocumentItem';
import { countAnnotationAttentionsPage } from 'utils/helpers';

const RowItem = memo(({ data, index, style, isScrolling }: ListChildComponentProps) => {
  const { flattenedData, currentPageId, onThumbnail } = data;
  const item = flattenedData[index];

  return (
    <RowContainer
      style={{
        ...style,
      }}
    >
      {item.isGroup ? (
        <DocumentItem label={item.label} />
      ) : (
        <PageThumbnail
          onThumbnail={onThumbnail}
          pageId={item.id}
          pageNumber={item.collectionPageNum}
          documentName={item.documentName}
          height={Number(style.height) - 35}
          url={item.image.imageUrl}
          preventLoading={isScrolling}
          annotationsAttentions={countAnnotationAttentionsPage(item)}
          selected={item.id === currentPageId}
        />
      )}
    </RowContainer>
  );
}, areEqual);

const getItemData = memoizeOne((flattenedData, currentPageId, onThumbnail) => ({
  flattenedData,
  currentPageId,
  onThumbnail,
}));

export const PageThumbnailsList: FC<PageThumbnailsListProps> = ({ data }) => {
  const { currentPageId, fieldArrow, setCurrentPage } = usePageThumbnailsListStore();
  const preventScroll = useRef(false);

  const onThumbnail = (pageId: string) => {
    preventScroll.current = true;
    setCurrentPage(pageId);
  };

  const resizeList = useRef<VariableSizeList>(null);
  const itemData = getItemData(data, currentPageId, onThumbnail);

  useEffect(() => {
    if (fieldArrow) {
      preventScroll.current = true;

      for (let i = 0; i < data.length; i += 1) {
        if (data[i].id === fieldArrow.pageId) {
          resizeList.current?.scrollToItem(i, 'end');
          setTimeout(() => {
            preventScroll.current = false;
          }, 200);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldArrow]);

  useEffect(() => {
    if (!preventScroll.current) {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].id === currentPageId) {
          resizeList.current?.scrollToItem(i, 'end');
        }
      }
    } else {
      preventScroll.current = false;
    }
  }, [currentPageId, data]);

  return (
    <AutoSizer
      onResize={() => {
        resizeList.current?.resetAfterIndex(0);
      }}
    >
      {({ height, width }) => {
        return (
          <VariableSizeList
            ref={resizeList}
            height={height}
            itemCount={data.length}
            itemSize={(index) => {
              const item = data[index];

              if (isPageSection(item)) {
                const ratio = item?.image.height / item.image.width;

                return width * 0.75 * ratio + 35;
              }

              return 46;
            }}
            width={width}
            itemKey={(index) => data[index].id}
            itemData={itemData}
            useIsScrolling
          >
            {RowItem}
          </VariableSizeList>
        );
      }}
    </AutoSizer>
  );
};
