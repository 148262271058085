import { Collapse, Typography } from 'antd';
import { Headline } from 'components/Headline';
import {
  IconDelete,
  IconFile,
  IconFlag,
  IconFolder,
  IconSupervise,
  IconUpload,
  IconViewResults,
} from 'components/Icons';
import { Label } from 'components/Label';
import { PrimaryIconButton } from 'components/PrimaryIconButton';
import { ProcessProgress } from 'components/ProcessProgress';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import styled from 'styled-components';

const { Panel } = Collapse;

interface ContainerProps {
  $open: boolean;
}

interface CollapseContainerProps {
  topContainerSize: number;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 72px;
  bottom: 0;
  right: 0;
  transform: ${({ $open }) => ($open ? 'translateX(0%)' : 'translateX(100%)')};
  transition: 0.3s ease-out;
  width: 37%;
  z-index: 1000;
  height: calc(94vh - 1px);
  box-shadow: -8px 0 12px 0 rgba(151, 159, 182, 0.1);
  background-color: ${({ theme }) => theme.colors.white};
  padding-left: ${({ theme }) => theme.padding.large};
  padding-top: ${({ theme }) => theme.padding.normal};
  padding-right: ${({ theme }) => theme.padding.tiny};
`;

export const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.padding.small};
`;

export const HorizontalBottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: ${({ theme }) => theme.padding.tiny};
  margin-bottom: ${({ theme }) => theme.padding.small};
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledIconFlag = styled(IconFlag)`
  height: 16px;
  margin-right: ${({ theme }) => theme.padding.tiny};
`;

export const StyledIconEdit = styled(IconSupervise)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledIconViewResults = styled(IconViewResults)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledIconUpload = styled(IconUpload)`
  color: ${({ theme }) => theme.colors.blackPurple};
  height: 20px;
  width: 20px;
`;

export const StyledIconClose = styled(IconDelete)`
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.colors.gray300};
`;

export const StyledDocumentType = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lilac};
`;

export const StyledCollectionName = styled(Headline)`
  max-width: 80%;
  min-width: 80%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.blackPurple};
`;

export const StyledIconFile = styled(IconFile)`
  width: 15px;
`;

export const StyledIconFolder = styled(IconFolder)`
  width: 15px;
`;

export const StyledInfoLabel = styled(Label)`
  padding-bottom: ${({ theme }) => theme.padding.xtiny};
  color: ${({ theme }) => theme.colors.gray300};
`;

export const StyledInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-size: ${({ theme }) => theme.fontSize.normal};
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const GroupCollapse = styled(Collapse)`
  width: 100%;
  border: 0;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: 2px;

  .ant-collapse-header {
    font-weight: bold;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    color: ${({ theme }) => theme.colors.gray300};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  }

  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 0px;
    color: ${({ theme }) => theme.colors.lineDark};
  }
`;

export const StyledPanel = styled(Panel)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
    padding-right: ${({ theme }) => theme.padding.tiny};
    padding-bottom: 0;
    padding-top: 0;
    border: 0;
  }
  .ant-collapse-content > .ant-collapse-content-active {
    padding: 0;
    border: 0;
  }
  .ant-collapse > .ant-collapse-item {
    border: 0;
    padding: 0;
  }
`;

export const StyledProcessProgress = styled(ProcessProgress)`
  width: 32%;
`;

export const ProcessProgressContainer = styled.div`
  height: 2vh;
  display: flex;
  align-items: center;
`;

export const CollapseContainer = styled.div<CollapseContainerProps>`
  height: ${({ topContainerSize }) =>
    `calc(94vh - ${topContainerSize + 16}px - 0.75rem - 1px)`};
  overflow: scroll;
  padding-right: ${({ theme }) => theme.padding.medium};
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }
`;

export const StyledPrimaryIconButton = styled(PrimaryIconButton)`
  margin-left: 8px;
  margin-right: 12px;
`;

export const StyledSecondaryAltButton = styled(SecondaryAltButton)`
  margin-left: 8px;
`;
