import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectEditorId,
  selectTagGroupOptions,
  updateTagGroup,
} from 'store/reducers/collection';
import { selectUser } from 'store/reducers/common';

export const useGroupTagItemStore = (groupTagId: string) => {
  const dispatch = useDispatch();
  const updateTagGroupField = useCallback((e) => dispatch(updateTagGroup(e)), [dispatch]);

  const user = useSelector(selectUser);
  const editorId = useSelector(selectEditorId);
  const tagGroupsOptions = useSelector(selectTagGroupOptions);

  return {
    user,
    editorId,
    tagGroupsOptions,
    updateTagGroupField,
  };
};
