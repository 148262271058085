import { ListUser, Pagination } from 'api';
import { UsersStore } from 'store';
import { BaseDispatch, StoreProps } from 'store/store.interface';

interface UserList {
  users: Array<ListUser>;
  pagination: Pagination;
}

interface UsersDispatch extends BaseDispatch {
  payload: UserList;
}

export const SET_USERS = 'SET_USERS';

export const selectUsers = (state: StoreProps) => state.users.users;
export const selectUsersTotal = (state: StoreProps) => state.users.total;

export const updateUsersState = (state: UsersStore, data: UserList) => {
  return {
    ...state,
    users: data.users,
    offset: data.pagination.offset,
    limit: data.pagination.limit,
    total: data.pagination.total,
  };
};

export const updateUsers = (data: UserList) => (
  dispatch: (data: UsersDispatch) => void,
) => {
  dispatch({
    type: SET_USERS,
    payload: data,
  });
};
