import { FC } from 'react';
import { Popover } from 'react-tiny-popover';
import { LookUp } from 'components/LookUp';
import { EditField } from 'components/EditField';
import { LookUpWrapper } from './Annotation.styles';
import { requiresAttention } from 'utils/helpers';
import { Dot } from 'components/LookUp/LookUp.styles';
import { pageDataObjectId } from 'components/FieldArrow';
import { AnnotationProps } from './Annotation.interface';
import { useAnnotationStore } from './Annotation.hooks';

export const Annotation: FC<AnnotationProps> = ({
  item,
  pageId,
  resultId,
  documentId,
  pageHeight,
  pageWidth,
}) => {
  const {
    setFieldArrow,
    setEditField,
    editField,
    editMode,
    fieldArrow,
    setZoom,
  } = useAnnotationStore();

  const id = `field_${item.id}`;
  const { boundingBox } = item;

  const lookups = item.lookups;

  return boundingBox ? (
    <Popover
      key={id}
      containerClassName="popover-edit"
      padding={5}
      content={() => (
        <EditField
          id={item.id}
          documentId={documentId}
          pageId={pageId}
          resultId={resultId}
          type={item.label}
          text={item.value}
          requiresAttention={requiresAttention(item.status.status)}
          onClose={() => {
            setEditField(undefined);
          }}
        />
      )}
      positions={['bottom']}
      onClickOutside={() => {
        setEditField(undefined);
      }}
      isOpen={editField === id}
    >
      <LookUpWrapper
        id={pageDataObjectId(item.id)}
        className={lookups.length === 0 ? id : ''}
        $selected={id === `field_${fieldArrow?.id}`}
        $top={boundingBox?.y * pageHeight}
        $left={boundingBox.x * pageWidth}
        $width={boundingBox.width * pageWidth}
        $height={boundingBox.height * pageHeight}
        $visible={lookups.length === 0}
        onClick={(event) => {
          if (lookups.length === 0) {
            event.stopPropagation();

            if (fieldArrow?.id !== item.id) {
              setZoom({ scale: 1, isButtonAction: true });
              setFieldArrow({
                id: item.id,
                item,
                resultId,
                documentId,
                pageId,
              });
            } else {
              setFieldArrow(undefined);
            }

            if (editMode) {
              setEditField(id);
            }
          }
        }}
      >
        {lookups.map((lookup, index) => (
          <LookUp
            id={index === lookups.length - 1 ? id : undefined}
            selected={id === `field_${fieldArrow?.id}`}
            requiresAttention={requiresAttention(item.status.status)}
            key={`field_item_${index}`}
            top={lookup.boundingBox.y * pageHeight - boundingBox.y * pageHeight}
            left={lookup.boundingBox.x * pageWidth - boundingBox.x * pageWidth}
            width={lookup.boundingBox.width * pageWidth}
            height={lookup.boundingBox.height * pageHeight}
            onClick={(event) => {
              event.stopPropagation();

              if (fieldArrow?.id !== item.id) {
                setZoom({ scale: 1, isButtonAction: true });
                setFieldArrow({
                  id: item.id,
                  item,
                  pageId,
                  resultId,
                  documentId,
                });
              } else {
                setFieldArrow(undefined);
              }

              if (editMode) {
                setEditField(id);
              }
            }}
          />
        ))}
        {id === `field_${fieldArrow?.id}` && lookups.length === 0 && (
          <Dot
            $requiresAttention={requiresAttention(item.status.status)}
            $top={(boundingBox.height * pageHeight) / 2}
          />
        )}
      </LookUpWrapper>
    </Popover>
  ) : null;
};
