import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import { selectDocumentTagGroups } from 'store/reducers/collection';
import { selectFieldArrow, selectEditMode } from 'store/reducers/supervision';
import { selectPageArrow, updatePageArrow } from 'store/reducers/supervision/pageArrow';

export const useTagGroupsItemStore = (documentId: string) => {
  const dispatch = useDispatch();
  const setPageArrow = useCallback((e) => dispatch(updatePageArrow(e)), [dispatch]);

  const tagGroups = useSelector((state: StoreProps) =>
    selectDocumentTagGroups(state, documentId),
  );
  const pageArrow = useSelector(selectPageArrow);
  const documentEditMode = useSelector(selectEditMode);
  const fieldArrow = useSelector(selectFieldArrow);

  return {
    tagGroups,
    documentEditMode,
    fieldArrow,
    setPageArrow,
    pageArrow,
  };
};
