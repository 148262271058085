import { api } from 'api';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JSONPretty from 'react-json-pretty';
import Modal from 'react-modal';
import { theme } from 'styles';
import { ViewResultsModalProps } from './ViewResultsModal.interface';
import {
  CloseIcon,
  Container,
  TopContainer,
  StyledTitle,
  Loader,
} from './ViewResultsModal.styles';

export const ViewResultsModal: FC<ViewResultsModalProps> = ({
  open,
  onClose,
  collectionId,
  fileName,
}) => {
  const [result, setResult] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);

    if (collectionId) {
      api.fetchCollectionResult(collectionId).then((data) => {
        setLoading(false);
        setResult(JSON.stringify(data));
      });
    }
  }, [collectionId]);

  return (
    <Modal
      isOpen={open}
      appElement={document.getElementById('root')!}
      ariaHideApp={false}
      className={{
        base: 'modal-base-wide',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
    >
      <TopContainer>
        <StyledTitle>{`${t(
          'resultOfProcessing',
        )} ${fileName?.toUpperCase()}`}</StyledTitle>
        <CloseIcon
          onClick={() => {
            setResult(undefined);
            onClose();
          }}
        />
      </TopContainer>
      <Container>
        {!loading && (
          <JSONPretty
            style={{ fontSize: theme.fontSize.normal, color: theme.colors.blackPurple }}
            data={result}
            mainStyle="padding:0.25em"
          />
        )}
        {loading && <Loader />}
      </Container>
    </Modal>
  );
};
