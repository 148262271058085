import { memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import { DocumentItem } from '../ExtractedFields/DocumentItem';
import { PageItem } from '../ExtractedFields/PageItem';
import { TagGroupsItem as TagGroupItem } from '../ExtractedFields/TagGroupsItem';
import { Field } from 'components/Field';
import { fieldDataObjectId } from 'components/FieldArrow';
import { TableField } from 'components/TableField';
import { ComplexField } from 'components/ComplexField';
import { PageObjectType } from 'api';
import { ENV_VARIABLE } from 'variables';

export const fieldInputTypes: PageObjectType[] = ['text', 'date', 'number', 'anonymized'];

export const basicAnnotationTypes: PageObjectType[] = [
  'text',
  'binary',
  'enum',
  'date',
  'number',
  'anonymized',
];

export const extendedAnnotationTypes: PageObjectType[] = [
  'text',
  'binary',
  'enum',
  'date',
  'number',
  'anonymized',
  'complex',
];

export const fieldTypesWithoutValidators = () => {
  let defaultFieldInputTypes = fieldInputTypes;

  if (ENV_VARIABLE.DATE_VALIDATOR_ENABLED === 'true') {
    defaultFieldInputTypes = defaultFieldInputTypes.filter((item) => item !== 'date');
  }

  return defaultFieldInputTypes;
};

export const ExtractedFieldListItem = memo(
  ({ data, index, style }: ListChildComponentProps) => {
    const {
      flattenedData,
      onOpenGroup,
      setIsComplexFieldOpen,
      collapsedComplexFields,
    } = data;
    const groupItem = flattenedData[index];

    return (
      <div style={{ ...style, width: '100%' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {groupItem.isGroup && (
            <DocumentItem
              index={index}
              annotationAttentions={groupItem.annotationAttentions}
              onClick={() => onOpenGroup(groupItem)}
              collapsed={!groupItem.collapsed}
              label={groupItem.label}
              labels={groupItem.labels}
            />
          )}
          {groupItem.isPage && <PageItem label={groupItem.label} />}
          {groupItem.isTagGroup && (
            <TagGroupItem
              documentId={groupItem.documentId}
              resultId={groupItem.resultId}
              pageId={groupItem.pageId}
              tagGroups={groupItem.tags}
            />
          )}
          {groupItem.type === 'binary' && (
            <Field
              id={fieldDataObjectId(groupItem.annotationId)}
              key={groupItem.annotationId}
              documentId={groupItem.documentId}
              annotationId={groupItem.annotationId}
              pageId={groupItem.pageId}
              resultId={groupItem.resultId}
            />
          )}
          {basicAnnotationTypes
            .filter((item) => item !== 'binary')
            .includes(groupItem.type) && (
            <Field
              id={fieldDataObjectId(groupItem.annotationId)}
              key={groupItem.annotationId}
              documentId={groupItem.documentId}
              annotationId={groupItem.annotationId}
              pageId={groupItem.pageId}
              resultId={groupItem.resultId}
            />
          )}
          {groupItem.type === 'table' && (
            <TableField
              id={fieldDataObjectId(groupItem.id)}
              documentId={groupItem.documentId}
              pageObjectId={groupItem.annotationId}
              pageId={groupItem.pageId}
              resultId={groupItem.resultId}
            />
          )}
          {groupItem.type === 'complex' && (
            <ComplexField
              id={fieldDataObjectId(groupItem.annotationId)}
              key={groupItem.annotationId}
              documentId={groupItem.documentId}
              annotationId={groupItem.annotationId}
              pageId={groupItem.pageId}
              resultId={groupItem.resultId}
              onOpen={(isOpen: boolean) => setIsComplexFieldOpen(groupItem.id, isOpen)}
              isCollapsed={collapsedComplexFields.includes(groupItem.id)}
            />
          )}
        </div>
      </div>
    );
  },
  areEqual,
);
