import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parseQueryParams } from 'utils/helpers';
import { InputSearch } from 'components/InputSearch';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  FilterContainer,
  SearchContainer,
  StyledDropdownSelect,
} from './UsersFilters.styles';
import { menuRole, menuStatus } from './UsersFilters.menu';
import { FilterDatePicker } from 'components/FilterDatePicker';
import { debounce } from 'lodash';
import { useTyroHistory } from 'hooks';

export const QueuesFilters: FC = () => {
  const { t } = useTranslation();
  const { addQueryParams } = useTyroHistory();
  const { search: locationSearch } = useLocation();
  const [dateFrom, setDateFrom] = useState<string>();
  const [dateTo, setDateTo] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [status, setStatus] = useState<Array<string>>([]);
  const [role, setRole] = useState<Array<string>>([]);

  useEffect(() => {
    const { search, status, role, from, to } = parseQueryParams(locationSearch);

    status && setStatus(status as Array<string>);
    role && setRole(role as Array<string>);
    from && setDateFrom(from.toString());
    to && setDateTo(to.toString());
    setSearch(search?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((value) => {
      addQueryParams({ search: value });
    }, 500),
    [locationSearch],
  );

  const handleChange = (value?: string) => {
    setSearch(value);
    debouncedSave(value);
  };

  return (
    <FilterContainer>
      <SearchContainer>
        <InputSearch value={search} onChange={handleChange} />
      </SearchContainer>
      <Divider />
      <StyledDropdownSelect
        checkedItems={role}
        title={t('role')}
        singleSelect
        items={menuRole(t)}
        onMenuItems={(role) => {
          setRole(role);
          addQueryParams({ role });
        }}
      />
      <FilterDatePicker
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDates={(from, to) => {
          if (from && to) {
            addQueryParams({
              from,
              to,
            });
          } else {
            addQueryParams({
              from: undefined,
              to: undefined,
            });
          }
        }}
      />
      <StyledDropdownSelect
        title={t('status')}
        checkedItems={status}
        items={menuStatus(t)}
        onMenuItems={(status) => {
          setStatus(status);
          addQueryParams({ status });
        }}
      />
    </FilterContainer>
  );
};
