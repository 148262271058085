import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentPageId,
  selectFieldArrow,
  updateCurrentPage,
} from 'store/reducers/supervision';

export const usePageThumbnailsListStore = () => {
  const dispatch = useDispatch();
  const setCurrentPage = useCallback((e) => dispatch(updateCurrentPage(e)), [dispatch]);
  const fieldArrow = useSelector(selectFieldArrow);
  const currentPageId = useSelector(selectCurrentPageId);

  return {
    setCurrentPage,
    fieldArrow,
    currentPageId,
  };
};
