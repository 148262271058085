import { Dictionary } from 'lodash';
import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface FilterByStatusProps {
  status: Dictionary<Array<string>>;
}

interface FilterByStatusDispatch extends BaseDispatch {
  payload: FilterByStatusProps;
}

export const SET_FILTER_BY_STATUS = 'SET_FILTER_BY_STATUS';

export const selectFilterByStatus = (state: StoreProps) =>
  state.supervision.filterByStatus;

export const updateFilterByStatusState = (
  state: SupervisionStore,
  data: FilterByStatusProps,
) => {
  return { ...state, filterByStatus: data.status };
};

export const updateFilterByStatus = (status: Dictionary<Array<string>>) => (
  dispatch: (data: FilterByStatusDispatch) => void,
) => {
  dispatch({
    type: SET_FILTER_BY_STATUS,
    payload: { status },
  });
};
