import queryString from 'query-string';
import { chain, Dictionary, find, flatten, maxBy, minBy, sortBy, values } from 'lodash';
import moment from 'moment';
import { PAGES_CONTAINER_ID } from 'pages/Supervision';
import {
  Collection,
  QueueCollection,
  Document,
  Page,
  Lookup,
  AnnotationProcessStatus,
  TagGroup,
  UncertainItem,
  DataObject,
  BoundingBox,
  Annotation,
  CommonTypes,
} from 'api';
import { FIELD_INPUT } from 'components/Field';
import { EXTRACTED_FIELDS_ID } from 'pages/Supervision/ExtractedFields';
import { SupervisionSettings } from './helpers.interface';
import { SelectOption } from 'components/Select';
import { FieldArrow } from 'store/reducers/supervision';
import { basicAnnotationTypes } from 'pages/Supervision/ExtractedFieldListItem';

export const SUPERVISION_SETTINGS = 'supversionSettings';
export const QUEUES_PARAMS = 'queueParams';

export const DEFAULT_SETTINGS: SupervisionSettings = {
  editMode: false,
  uncertainFilter: false,
  sortFieldsBy: 'position',
};

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const REGEX_2C = /%2C/g;
const REGEX_3A = /%3A/g;

export const FILE_1_MB = 1048576;

export const ACCEPTED_FILES = {
  'application/rtf': ['.rtf'],
  'application/vnd.ms-outlook': ['.msg', '.eml'],
  'text/plain': ['.txt'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/tiff': ['.tif', '.tiff'],
  'image/png': ['.png'],
  'image/bmp': ['.bmp'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/csv': ['.csv'],
};

export const parseQueryParams = (query: string) => {
  return queryString.parse(query, {
    arrayFormat: 'comma',
  });
};

export const stringifyParams = (query: Object) =>
  queryString
    .stringify(query, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: 'comma',
    })
    .replace(REGEX_2C, ',')
    .replace(REGEX_3A, ':');

export const isFieldFullyVisible = (id: string) => {
  const element = document.getElementById(id);
  const container = document.getElementById(EXTRACTED_FIELDS_ID);

  if (element && container) {
    const topEdgePosition =
      element.getBoundingClientRect().top - container?.getBoundingClientRect().top;

    return (
      (topEdgePosition - container.clientHeight) * -1 > element.offsetHeight &&
      topEdgePosition * -1 <= 0
    );
  }

  return false;
};

export const isDataObjectFullyVisible = (id: string, fieldArrow?: FieldArrow) => {
  const element = document.getElementById(id);
  const container = document.getElementById(PAGES_CONTAINER_ID);
  const bottomEdge = fieldArrow?.item?.type === 'table' ? 400 : 0;

  if (element && container) {
    const topEdgePosition =
      element.getBoundingClientRect().top - container?.getBoundingClientRect().top;

    return (
      (topEdgePosition + bottomEdge - container.clientHeight - element.offsetHeight / 2) *
        -1 >
        element.offsetHeight && (topEdgePosition + element.offsetHeight / 2) * -1 <= 0
    );
  }

  return false;
};

export const isFieldPartiallyVisible = (id: string) => {
  const element = document.getElementById(id);
  const container = document.getElementById(EXTRACTED_FIELDS_ID);

  if (element && container) {
    const topEdgePosition =
      element.getBoundingClientRect().top - container?.getBoundingClientRect().top;

    return (
      topEdgePosition - container.clientHeight <= 0 &&
      topEdgePosition * -1 <= element.offsetHeight
    );
  }

  return false;
};

export const isPagePartiallyVisible = (id: string) => {
  const element = document.getElementById(`page_${id}`);
  const container = document.getElementById(PAGES_CONTAINER_ID);

  if (element && container) {
    const topEdgePosition =
      element.getBoundingClientRect().top - container?.getBoundingClientRect().top;

    return (
      topEdgePosition - container.clientHeight <= 0 &&
      topEdgePosition * -1 <= element.offsetHeight
    );
  }

  return false;
};

export const isGroupFullyVisible = (id: string) => {
  const element = document.getElementById(`group_${id}`);
  const container = document.getElementById(EXTRACTED_FIELDS_ID);

  if (element && container) {
    const topEdgePosition =
      element.getBoundingClientRect().top - container?.getBoundingClientRect().top;

    return (
      (topEdgePosition - container.clientHeight) * -1 > element.offsetHeight / 2 &&
      (topEdgePosition * -1 <= 0 || topEdgePosition * -1 < element.offsetHeight / 2 - 6)
    );
  }

  return false;
};

export const focusInput = (id: string) => {
  document.getElementById(`${FIELD_INPUT}${id}`)?.focus();
};

export const formatDate = (date: moment.Moment | null) => {
  return date !== null ? date.format(DATE_FORMAT).toString() + 'Z' : null;
};

export const getDirectory = (path?: string): string | undefined => {
  if (path) {
    let trimPath = path;

    if (path.startsWith('/')) {
      trimPath = path.replace('/', '');
    }
    trimPath = trimPath.split('/').slice(0, -1).join('/').replaceAll('/', '-');

    if (trimPath.length > 0) {
      return trimPath;
    }
  }

  return undefined;
};

export const collectionProcessed = (collection?: Collection | QueueCollection) => {
  if (collection) {
    if (
      collection.status === 'Completed' ||
      collection.status === 'Needs attention' ||
      collection.status === 'Accepted' ||
      collection.status === 'Edited'
    ) {
      return true;
    }

    if (collection.files.length > 1) {
      for (let i = 0; i < collection.files.length; i += 1) {
        if (
          collection.files[i].status === 'Completed' ||
          collection.files[i].status === 'Needs attention' ||
          collection.files[i].status === 'Accepted' ||
          collection.files[i].status === 'Edited'
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

export const requiresAttention = (status?: AnnotationProcessStatus) => {
  return (
    status !== 'Completed' &&
    status !== 'Accepted' &&
    status !== 'Corrected' &&
    status !== 'Manual'
  );
};

export const hasVisibleStatus = (status?: AnnotationProcessStatus) => {
  return (
    status === 'Accepted' ||
    status === 'Corrected' ||
    status === 'Manual' ||
    status === 'Needs Attention'
  );
};

export const requiresEditAttention = (status: AnnotationProcessStatus) => {
  return status === 'Needs Attention';
};

export const extractUncertainItems = (document: Document) => {
  let uncertainItems: Array<UncertainItem> = [];

  document.pages.forEach((page) => {
    page.dataObjects?.forEach((annotation) => {
      if (requiresAttention(annotation.status.status)) {
        uncertainItems = [
          ...uncertainItems,
          { field: annotation.type, status: annotation.status.status },
        ];
      }
    });

    document.tagGroups?.forEach((item) => {
      if (requiresAttention(item.status.status)) {
        uncertainItems = [
          ...uncertainItems,
          { field: item.name, status: item.status.status },
        ];
      }

      item.tags.forEach((tag) => {
        if (requiresAttention(tag.status.status)) {
          uncertainItems = [
            ...uncertainItems,
            { field: tag.tag, status: tag.status.status },
          ];
        }
      });
    });
  });

  return uncertainItems;
};

export const countUncertainItems = (document: Document) => {
  let annotationCounter = 0;

  document.pages.forEach((page) => {
    page.annotations.forEach((annotation) => {
      if (requiresAttention(annotation.status.status)) {
        annotationCounter += 1;
      }
    });

    document.tagGroups?.forEach((item) => {
      if (requiresAttention(item.status.status)) {
        annotationCounter += 1;
      }

      item.tags.forEach((tag) => {
        if (requiresAttention(tag.status.status)) {
          annotationCounter += 1;
        }
      });
    });
  });

  return annotationCounter;
};

export const countAnnotationAttentionsPage = (page: Page) => {
  let annotationCounter = 0;

  page.annotations.forEach((annotation) => {
    if (requiresAttention(annotation.status.status)) {
      annotationCounter += 1;
    }
  });

  return annotationCounter;
};

export const boundingBoxByDataObjectId = (
  page: Page,
  id: string,
): BoundingBox | undefined => {
  for (let j = 0; j < page.annotations.length; j += 1) {
    if (page.annotations[j].id === id) {
      return page.annotations[j].boundingBox;
    }
  }

  if (page.tableAnnotations) {
    for (let i = 0; i < page.tableAnnotations.length; i += 1) {
      if (page.tableAnnotations[i].id === id) {
        return page.tableAnnotations[i].boundingBox;
      }
    }
  }

  return undefined;
};

export const containsAnnotationId = (pages: Array<Page>, id?: string) => {
  for (let i = 0; i < pages.length; i += 1) {
    for (let j = 0; j < pages[i].annotations.length; j += 1) {
      if (pages[i].annotations[j].id === id) {
        return true;
      }
    }
  }

  return false;
};

export const countCollectionAttentions = (documents?: Array<Document>) => {
  let annotationCounter = 0;

  documents?.forEach((document) => {
    annotationCounter += countUncertainItems(document);
  });

  return annotationCounter;
};

export const countBoundingBox = (words: Array<Lookup>) => {
  const left = minBy(words, (item) => item.boundingBox.x);
  const top = minBy(words, (item) => item.boundingBox.y);
  const right = maxBy(words, (item) => item.boundingBox.x + item.boundingBox.width);
  const bottom = maxBy(words, (item) => item.boundingBox.y + item.boundingBox.height);

  let width = 0;
  let height = 0;

  if (right && left) {
    width = right.boundingBox.x - left.boundingBox.x;
  }

  if (bottom && top) {
    height = bottom.boundingBox.y - top?.boundingBox.y;
  }

  return {
    x: left?.boundingBox.x || 0,
    y: top?.boundingBox.y || 0,
    width,
    height,
  };
};

export const countAttentions = (annotations: Array<Dictionary<DataObject>>) => {
  let annotationCounter = 0;

  annotations.forEach((pageAnnotations) => {
    values(pageAnnotations).forEach((annotation) => {
      if (requiresAttention(annotation.status.status)) {
        annotationCounter += 1;
      }
    });
  });

  return annotationCounter;
};

export const countUncertainTagGroups = (tagGroups: Dictionary<Array<TagGroup>>) => {
  let annotationCounter = 0;

  flatten(values(tagGroups)).forEach((tagGroup) => {
    if (requiresAttention(tagGroup.status.status)) {
      annotationCounter += 1;
    }

    tagGroup.tags.forEach((tag) => {
      if (requiresAttention(tag.status.status)) {
        annotationCounter += 1;
      }
    });
  });

  return annotationCounter;
};

const median = (numbers: Array<number>) => {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};

export const sortAnnotationsByPosition = (
  documents: Array<Document>,
): Array<Document> => {
  return documents.map((item) => ({
    ...item,
    pages: item.pages.map((page) => {
      const heightMedian =
        median(
          page.dataObjects
            ?.filter((item) => basicAnnotationTypes.includes(item.type))
            .map((item) => (item as Annotation).boundingBox?.height ?? 0) || [],
        ) / 4;

      return {
        ...page,
        dataObjects: page.dataObjects?.sort((item1, item2) => {
          const item1Y =
            (item1 as Annotation).lookups?.length > 0
              ? (item1 as Annotation).lookups[0].boundingBox.y
              : (item1 as Annotation).boundingBox?.y;

          const item2Y =
            (item2 as Annotation).lookups?.length > 0
              ? (item2 as Annotation).lookups[0].boundingBox.y
              : (item2 as Annotation).boundingBox?.y;

          const item1X =
            (item1 as Annotation).lookups?.length > 0
              ? (item1 as Annotation).lookups[0].boundingBox.x
              : (item1 as Annotation).boundingBox?.x;

          const item2X =
            (item2 as Annotation).lookups?.length > 0
              ? (item2 as Annotation).lookups[0].boundingBox.x
              : (item2 as Annotation).boundingBox?.x;

          const verticalDiff = (item1Y ?? 0) - (item2Y ?? 0);

          const horizontalDiff = (item1X ?? 0) - (item2X ?? 0);

          if (Math.abs(verticalDiff) > heightMedian) {
            return verticalDiff;
          }

          return horizontalDiff;
        }),
      };
    }),
  }));
};

export const sortAnnotationsAZ = (
  commonTypes: CommonTypes,
  documents: Array<Document>,
): Array<Document> => {
  return documents.map((item) => ({
    ...item,
    pages: item.pages.map((page) => ({
      ...page,
      dataObjects: sortBy(page.dataObjects || [], (item) =>
        mapAnnotation(flattenCommonTypes(commonTypes), item.label)?.toLowerCase(),
      ),
    })),
  }));
};

export const updateUrlParam = (key: string, value: string) => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  let kvp = window.location.search.substr(1).split('&');

  if (kvp[0] === '') {
    const path =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      key +
      '=' +
      value;
    window.history.replaceState({ path: path }, '', path);
  } else {
    let i = kvp.length;
    let x;
    while (i--) {
      x = kvp[i].split('=');

      if (x[0] === key) {
        x[1] = value;
        kvp[i] = x.join('=');
        break;
      }
    }

    if (i < 0) {
      kvp[kvp.length] = [key, value].join('=');
    }

    const refresh =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      kvp.join('&');
    window.history.replaceState({ path: refresh }, '', refresh);
  }
};

export const replaceAtIndex = (array: Array<any>, index: number, item: any) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index + 1),
];

export const mapAnnotation = (annotations: Array<SelectOption>, label: string) => {
  const mapAnnotation = find(annotations, (item) => item.value === label);

  return mapAnnotation?.label || label;
};

export const supervisionSettings = (): SupervisionSettings => {
  const data = window.localStorage.getItem(SUPERVISION_SETTINGS);

  return data ? (JSON.parse(data) as SupervisionSettings) : DEFAULT_SETTINGS;
};

export const flattenCommonTypes = (commonTypes: CommonTypes) => [
  ...commonTypes.annotationsTypes,
  ...chain(commonTypes.tableAnnotationsTypes)
    .values()
    .map((item) => item.type)
    .value(),
];
