import { ALL_OPTION_ID } from 'components/DropdownSelect';
import { TFunction } from 'react-i18next';

export const menuStatus = (translation: TFunction) => [
  {
    id: ALL_OPTION_ID,
    label: translation('all'),
  },
  {
    id: '1',
    label: translation('active'),
  },
  {
    id: '2',
    label: translation('invited'),
  },
];

export const menuRole = (translation: TFunction) => [
  {
    id: ALL_OPTION_ID,
    label: translation('all'),
  },
  {
    id: '0',
    label: translation('viewer'),
  },
  {
    id: '1',
    label: translation('editor'),
  },
];
