import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number, enabled = true) => {
  const intervalId = useRef<number | null>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();

    if (typeof delay === 'number' && enabled) {
      intervalId.current = window.setInterval(tick, delay);
      return () => window.clearInterval(Number(intervalId.current));
    }
  }, [delay, enabled]);

  return intervalId.current;
};
