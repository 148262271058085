import { Typography } from 'antd';
import { IconDelete } from 'components/Icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray700};
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.5);
  padding-left: 24px;
`;

export const IconCloseContainer = styled.div`
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray300};
  :hover {
    background-color: ${({ theme }) => theme.colors.lineDark};
  }
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 20px;
`;

export const StyledIconClose = styled(IconDelete)`
  height: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.white};
`;

export const DocumentSection = styled.div`
  width: 100%;
  position: relative;
`;
