import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreProps } from 'store';
import {
  selectEditorId,
  selectTagGroupOptions,
  updateTagGroup,
} from 'store/reducers/collection';
import { selectUser } from 'store/reducers/common';

import {
  selectEditMode,
  selectGroupTagEditMode,
  selectPageArrow,
  updateFieldArrow,
  updateGroupTagEditMode,
} from 'store/reducers/supervision';

export const useGroupTagItemStore = (groupTagId: string) => {
  const dispatch = useDispatch();
  const setFieldArrow = useCallback((e) => dispatch(updateFieldArrow(e)), [dispatch]);
  const setGroupTagEditMode = useCallback((e) => dispatch(updateGroupTagEditMode(e)), [
    dispatch,
  ]);
  const updateTagGroupField = useCallback((e) => dispatch(updateTagGroup(e)), [dispatch]);
  const groupTagEditMode = useSelector((state: StoreProps) =>
    selectGroupTagEditMode(state, groupTagId),
  );
  const documentEditMode = useSelector(selectEditMode);
  const pageArrow = useSelector(selectPageArrow);
  const user = useSelector(selectUser);
  const editorId = useSelector(selectEditorId);
  const tagGroupsOptions = useSelector(selectTagGroupOptions);

  return {
    user,
    editorId,
    pageArrow,
    groupTagEditMode,
    documentEditMode,
    tagGroupsOptions,
    setFieldArrow,
    setGroupTagEditMode,
    updateTagGroupField,
  };
};
