import styled from 'styled-components';
import { Pagination } from 'antd';

export const Container = styled.div`
  width: 100%;
`;

export const StyledPagination = styled(Pagination)`
  float: right;
`;

export const EmptyContainer = styled.div`
  height: 20vh;
  display: flex;
  align-items: center;
  user-select: none;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  border-radius: 4px;
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const RowsContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
