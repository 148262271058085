import { Input } from 'antd';
import { IconDelete, IconSearch } from 'components/Icons';
import styled from 'styled-components';

export const StyledIcon = styled(IconSearch)`
  color: ${({ theme }) => theme.colors.blackPurple};
  width: 17px;
`;

export const StyledDeleteIcon = styled(IconDelete)`
  color: ${({ theme }) => theme.colors.blackPurple};
  width: 17px;
  cursor: pointer;
`;

export const StyledInput = styled(Input)`
  height: 40px;
  border-color: ${({ theme }) => theme.colors.gray400};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  border-radius: 6px;
  .ant-input {
    color: ${({ theme }) => theme.colors.darkGray};
    ::placeholder {
      color: ${({ theme }) => theme.colors.gray300};
    }
  }
`;
