import { FC, useMemo } from 'react';
import { useAnnotationsStore } from './DataObjects.hooks';
import { DataObjectsProps } from './DataObjects.interface';
import {
  applyFilters,
  findDuplicatedFieldsIdByType,
} from 'pages/Supervision/ExtractedFields/ExtractedFields.helpers';
import { Annotation } from '../Annotation/Annotation';
import {
  Annotation as IAnnotation,
  DataObject,
  PageObjectType,
  TableAnnotation as ITableAnnotation,
} from 'api';
import { Table } from '../Table/Table';
import { store } from 'store';
import { Dictionary } from 'lodash';

export const PAGE_THUMB_ID = 'page_thumb_';
export const PAGE_THUMB_CONTAINER = 'page_thumb_container';

const isAnnotationType = (type: PageObjectType) =>
  ['text', 'binary', 'complex', 'enum', 'date', 'anonymized', 'number'].includes(type);

export const DataObjects: FC<DataObjectsProps> = ({
  pageId,
  documentId,
  resultId,
  pageWidth,
  pageHeight,
}) => {
  const {
    dataObjects,
    search,
    fieldTypes,
    filterFieldsByDuplicates,
    phrases,
    statuses,
    commonTypes,
  } = useAnnotationsStore(pageId);

  const filteredDataObjects = useMemo(() => {
    const allDataObjects = store.getState().collection.dataObjects;

    return applyFilters(
      dataObjects,
      commonTypes.annotationsTypes,
      statuses,
      phrases,
      fieldTypes,
      findDuplicatedFieldsIdByType(
        allDataObjects as Dictionary<Dictionary<DataObject>>,
        filterFieldsByDuplicates,
      ),
      filterFieldsByDuplicates,
      search,
    );
  }, [
    dataObjects,
    search,
    fieldTypes,
    phrases,
    commonTypes,
    statuses,
    filterFieldsByDuplicates,
  ]);

  return (
    <>
      {filteredDataObjects.map((item) => {
        if (isAnnotationType(item.type)) {
          return (
            <Annotation
              key={item.id}
              item={item as IAnnotation}
              pageId={pageId}
              documentId={documentId}
              resultId={resultId}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
            />
          );
        }

        if (item.label) {
          return (
            <Table
              key={item.id}
              id={item.id}
              item={item as ITableAnnotation}
              pageId={pageId}
              documentId={documentId}
              resultId={resultId}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
            />
          );
        }

        return null;
      })}
    </>
  );
};
