import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface FilterByFieldTypeProps {
  fieldDuplicates?: Array<string>;
}

interface FilterByFieldDuplicatesDispatch extends BaseDispatch {
  payload: FilterByFieldTypeProps;
}

export const SET_FILTER_BY_FIELD_DUPLICATES = 'SET_FILTER_BY_FIELD_DUPLICATES';

export const selectFilterByFieldDuplicates = (state: StoreProps) =>
  state.supervision.filterByFieldDuplicates;

export const updateFilterByFieldDuplicatesState = (
  state: SupervisionStore,
  data: FilterByFieldTypeProps,
) => {
  return { ...state, filterByFieldDuplicates: [...(data.fieldDuplicates || [])] };
};

export const updateFilterByFieldDuplicates = (fieldDuplicates?: Array<string>) => (
  dispatch: (data: FilterByFieldDuplicatesDispatch) => void,
) => {
  dispatch({
    type: SET_FILTER_BY_FIELD_DUPLICATES,
    payload: { fieldDuplicates },
  });
};
