import { IconChevronDown } from 'components/Icons';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lineDark}`};
  :hover {
    background-color: ${({ theme }) => theme.colors.content};
  }
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border-radius: 0.3em;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 16px;
  align-items: center;
  cursor: pointer;
`;

export const StyledArrowDown = styled(IconChevronDown)`
  color: ${({ theme }) => theme.colors.darkPurple};
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  aling-items: center;
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 48px;
  padding-bottom: 24px;
  justify-content: space-between;
`;

export const StyledIconButton = styled(SecondaryAltButton)`
  margin-left: 8px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 32px;
  padding-bottom: 16px;
`;

export const Name = styled.span`
  user-select: none;
  color: ${({ theme }) => theme.colors.blackPurple};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;
