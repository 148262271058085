import { useAppDispatch, useAppSelector } from 'store/store.hooks';
import {
  setFrom,
  setSearch,
  setStatus,
  setTo,
  setPriority,
  setOrder,
  setPageSize,
  setPage,
  setMarkToDelete,
} from './queues';

export const useQueuesFilters = () => {
  const dispatch = useAppDispatch();
  const setSearchFilter = (e: string) => dispatch(setSearch(e));
  const setFromFilter = (e?: string) => dispatch(setFrom(e));
  const setToFilter = (e?: string) => dispatch(setTo(e));
  const setStatusFilter = (e: Array<string>) => dispatch(setStatus(e));
  const setPriorityFilter = (e: Array<string>) => dispatch(setPriority(e));
  const setOrderFilter = (e: string) => dispatch(setOrder(e));
  const setPageFilter = (e: number) => dispatch(setPage(e));
  const setPageSizeFilter = (e: number) => dispatch(setPageSize(e));
  const setMarkToDeleteFilter = (e: boolean) => dispatch(setMarkToDelete(e));
  const search = useAppSelector((state) => state.queues.search);
  const from = useAppSelector((state) => state.queues.from);
  const to = useAppSelector((state) => state.queues.to);
  const status = useAppSelector((state) => state.queues.status);
  const priority = useAppSelector((state) => state.queues.priority);
  const order = useAppSelector((state) => state.queues.order);
  const page = useAppSelector((state) => state.queues.page);
  const pageSize = useAppSelector((state) => state.queues.pageSize);
  const markToDelete = useAppSelector((state) => state.queues.markToDelete);

  return {
    search,
    from,
    to,
    status,
    priority,
    order,
    page,
    pageSize,
    markToDelete,
    setPageFilter,
    setPageSizeFilter,
    setOrderFilter,
    setPriorityFilter,
    setSearchFilter,
    setFromFilter,
    setToFilter,
    setStatusFilter,
    setMarkToDeleteFilter,
  };
};
