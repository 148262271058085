import styled from 'styled-components';
import { Input } from 'antd';
const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)<{ width: number }>`
  resize: none;
  border-radius: 6px;
  padding: 9px 16px;
  border: none;
  line-height: 24px;
  width: ${({ width }) => width}px !important;
  min-height: 42px !important;

  &.ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
  }
`;
