import styled from 'styled-components';

interface ButtonProps {
  $withShadow: boolean;
  $disabled: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const StyledButton = styled.div<ButtonProps>`
  :hover {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.gray : theme.colors.blackPurple};
    border-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.gray : theme.colors.blackPurple};
    border-radius: 6px;
  }
  height: 40px;
  width: 40px;
  border-radius: 6px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.darkPurple};
  box-shadow: ${({ $withShadow }) =>
    $withShadow ? '0 2px 6px 0 rgba(151, 159, 182, 0.3)' : 0};
  border-color: ${({ theme }) => theme.colors.darkPurple};
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0px;
  justify-content: center;
  display: flex;
`;

export const Issues = styled.div`
  background-color: ${({ theme }) => theme.colors.darkPink};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: -4px;
  right: -6px;
  user-select: none;
`;
