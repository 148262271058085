import { ReactNode } from 'react';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import { theme } from 'styles';
import { TFunction } from 'i18next';

export const getTooltipProps = (label?: ReactNode): TooltipPropsWithTitle => ({
  title: label || '',
  placement: 'topLeft',
  overlayStyle: { whiteSpace: 'pre-line', maxWidth: 500 },
  color: theme.colors.blackPurple,
});

export const binaryOptions = (t: TFunction) => {
  return [
    { label: t('true'), value: 'true' },
    { label: t('false'), value: 'false' },
  ];
};
