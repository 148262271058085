import { DataObject } from 'api';
import { SelectOption } from 'components/Select';
import { TFunction } from 'i18next';
import { chain, Dictionary, values } from 'lodash';

export const orderOptions = (t: TFunction) => [
  { value: 'position', label: t('byPosition') },
  { value: 'az', label: t('alphabetically') },
];

export const findDuplicatedAnnotationTypes = (
  dataObjects: Dictionary<Dictionary<DataObject>>,
  annotationTypes: Array<SelectOption>,
) => {
  const list = chain(dataObjects)
    .values()
    .map((item) => values(item))
    .flatten()
    .value();

  let fieldTypes: Dictionary<number> = {};

  list.forEach((item) => {
    fieldTypes[item.label] = (fieldTypes[item.label] ?? 0) + 1;
  });

  const dulpicatedFields = chain(fieldTypes)
    .keys()
    .filter((key) => fieldTypes[key] > 1)
    .value();

  return annotationTypes.filter((item) => dulpicatedFields.includes(item.value));
};
