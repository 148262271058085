import { Typography } from 'antd';
import { IconDelete } from 'components/Icons';
import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 8px 0 rgba(217, 222, 234, 0.5);
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.line}`};
  padding-left: ${({ theme }) => theme.padding.large};
`;

export const IconCloseContainer = styled.div`
  height: 5vh;
  width: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.line};
  :hover {
    background-color: ${({ theme }) => theme.colors.lineDark};
  }
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4em;
  font-weight: 600;
`;

export const StyledIconClose = styled(IconDelete)`
  height: 3vh;
  width: 3vh;
  color: ${({ theme }) => theme.colors.white};
`;

export const HorizontalContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 18%;
  border-bottom: 1px solid #dde2ed;
  padding-left: 7%;
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 82%;
  padding: ${({ theme }) => theme.padding.large};
  background-color: ${({ theme }) => theme.colors.content};
`;

export const Container = styled.div`
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding-left: ${({ theme }) => theme.padding.tiny};
  padding-right: ${({ theme }) => theme.padding.tiny};
`;

export const StyledUserName = styled(Typography)`
  white-space: nowrap;
  user-select: none;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.5em;
`;

export const StyledAvatar = styled.img`
  margin-right: ${({ theme }) => theme.padding.normal};
  margin-bottom: 2px;
  height: 75%;
  border-radius: 100px;
`;
