import styled from 'styled-components';
import { IconChevronDown } from 'components/Icons';
import { Label } from 'components/Label';

interface AttentionProps {
  $annotationAttentions: boolean;
}

interface ContainerProps {
  $showBorder: boolean;
}

export const HorizontalContainer = styled.div`
  display: flex;
  width: 65%;
  align-items: center;
`;

export const StyledName = styled.div<AttentionProps>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  color: ${({ theme, $annotationAttentions }) =>
    $annotationAttentions ? theme.colors.darkPink : theme.colors.blackPurple};
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lineDark}`};
  :hover {
    background-color: ${({ theme }) => theme.colors.content};
  }
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border-radius: 0.3em;
  cursor: pointer;
`;

export const Header = styled.div<ContainerProps>`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  background-color: ${({ theme }) => theme.colors.transparent};
  border-top: ${({ theme, $showBorder }) =>
    $showBorder ? `1px solid ${theme.colors.gray400}` : 0};

  height: 56px;
  user-select: none;
  width: 100%;
  cursor: pointer;
  align-items: center;
  display: flex;
`;

export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 8px;
  align-items: center;
`;

export const StyledArrowDown = styled(IconChevronDown)<AttentionProps>`
  color: ${({ theme, $annotationAttentions }) =>
    $annotationAttentions ? theme.colors.darkPink : theme.colors.blackPurple};
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  aling-items: center;
  padding-left: 16px;
  justify-content: space-between;
`;

export const LabelsContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`;

export const StyledLabel = styled(Label)`
  padding-left: ${({ theme }) => theme.padding.tiny};
  padding-right: ${({ theme }) => theme.padding.tiny};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkPurple};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  height: 18px;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 10px;
  line-height: 18px;
  border-radius: 4px;
  user-select: none;
  align-items: center;
`;

export const Issues = styled.div`
  background-color: ${({ theme }) => theme.colors.darkPink};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  user-select: none;
`;
