import { api, CreateFile } from 'api';
import { Dictionary, keys, some, values } from 'lodash';
import { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { getDirectory } from 'utils/helpers';

interface FolderProgress {
  uploading: boolean;
  uploadedFiles: number;
  allFiles: number;
  failed?: boolean;
}

export const useUploadFolders = () => {
  const [folders, setFolders] = useState<Dictionary<Array<FileWithPath>>>({});
  const [folderProgress, setFolderProgress] = useState<Dictionary<FolderProgress>>({});
  const [uploadingFolders, setUploadingFolders] = useState(false);

  useEffect(() => {
    if (keys(folders).length > 0) {
      uploadFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);

  useEffect(() => {
    setUploadingFolders(
      values(folderProgress).filter((item) => item.uploadedFiles !== item.allFiles)
        .length > 0,
    );
  }, [folderProgress]);

  const addFolders = (newFiles: Array<FileWithPath>) => {
    const filterFiles = newFiles.filter(
      (item) => !some(keys(folders), (folder) => folder === getDirectory(item.path)),
    );

    const mappedFoldersProgress: Dictionary<FolderProgress> = {};
    const mappedFolders: Dictionary<Array<FileWithPath>> = {};

    filterFiles.forEach((file) => {
      const folderName = getDirectory(file.path);

      if (folderName && !keys(mappedFolders).includes(folderName)) {
        mappedFolders[folderName] = [file];
      } else if (folderName) {
        mappedFolders[folderName].push(file);
      }

      if (folderName) {
        mappedFoldersProgress[folderName] = {
          uploadedFiles: 0,
          allFiles: mappedFolders[folderName].length,
          uploading: false,
        };
      }
    });

    setUploadingFolders(false);
    setFolderProgress((prev) => ({ ...prev, ...mappedFoldersProgress }));
    setFolders((prev) => ({ ...prev, ...mappedFolders }));
  };

  const clearData = () => {
    setUploadingFolders(false);
    setFolderProgress({});
    setFolders({});
  };

  const incrementFolderProgress = (folderName: string) => {
    setFolderProgress((previous) => {
      previous[folderName].uploadedFiles += 1;

      return { ...previous };
    });
  };

  const updateUploading = (folderName: string, failed = false) => {
    setFolderProgress((previous) => {
      previous[folderName].uploading = !previous[folderName].uploading;
      previous[folderName].failed = failed;

      return { ...previous };
    });
  };

  const createCollection = (name: string, fileNames: Array<string>) => {
    return api.createCollection({
      fileNames: fileNames,
      collectionName: name,
      split: false,
      highPriority: false,
    });
  };

  const uploadFile = (collectionId: string, fileId: string, file: FileWithPath) => {
    const data = new FormData();
    data.append('fileUpload', file);
    data.append('fileId', fileId);
    data.append('collectionId', collectionId);

    return api.uploadFile(data);
  };

  const getFileId = (fileName: string, files: Array<CreateFile>) => {
    for (var i = 0; i < files.length; i = i + 1) {
      if (files[i].fileName === fileName) {
        return files[i].fileId;
      }
    }

    return undefined;
  };

  const uploadFolders = async () => {
    setUploadingFolders(true);

    for (const folderName of keys(folders)) {
      if (folderProgress[folderName].uploadedFiles === 0) {
        updateUploading(folderName);

        try {
          const { collectionId, files: createdFiles } = await createCollection(
            folderName,
            folders[folderName].map((file) => file.name),
          );

          for (const file of folders[folderName]) {
            const fileId = getFileId(file.name, createdFiles);

            if (fileId) {
              incrementFolderProgress(folderName);
              await uploadFile(collectionId, fileId, file);
            }
          }

          updateUploading(folderName);
        } catch (e) {
          updateUploading(folderName, true);
        }
      }
    }
  };

  const refreshFolder = async (folder: string) => {
    setUploadingFolders(true);

    updateUploading(folder);

    if (folder) {
      try {
        const { collectionId, files: createdFiles } = await createCollection(
          folder,
          folders[folder].map((file) => file.name),
        );

        for (const file of folders[folder]) {
          const fileId = getFileId(file.name, createdFiles);

          if (fileId) {
            incrementFolderProgress(folder);
            await uploadFile(collectionId, fileId, file);
          }
        }
        updateUploading(folder);
      } catch (e) {
        updateUploading(folder, true);
      }
    }
  };

  return {
    addFolders,
    folders,
    uploadFolders,
    folderProgress,
    uploadingFolders,
    clearData,
    refreshFolder,
  };
};
