import { ProcessStatus } from 'api';
import { Label } from 'components/Label';
import styled from 'styled-components';
import { TyroTheme } from 'styles';

interface StyledTypographyProps {
  $status?: ProcessStatus;
}

const statusBackgroundColor = (theme: TyroTheme, status?: ProcessStatus) => {
  switch (status) {
    case 'Completed':
      return theme.colors.successGreen;
    case 'Needs attention':
      return theme.colors.warningYellow;
    case 'In progress':
      return theme.colors.neonPurple;
    case 'Failed':
      return theme.colors.darkPink;
    case 'Accepted':
      return theme.colors.darkPurple;
    case 'Edited':
      return theme.colors.gray200;
    default:
      return theme.colors.gray300;
  }
};

export const StyledTypography = styled(Label)<StyledTypographyProps>`
  background-color: ${({ theme, $status }) => statusBackgroundColor(theme, $status)};
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
`;
