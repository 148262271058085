import { FC, SyntheticEvent, useState } from 'react';
import {
  Label,
  Container,
  StyledAcceptContainer,
  StyledAcceptIcon,
  StyledCancelContainer,
  StyledCancelIcon,
  EditContainer,
  SelectContainer,
  StyledTag,
  TagsContainer,
  StyledEmpty,
} from './GroupTagItem.styles';
import { toast } from 'react-toastify';
import { GroupTagItemProps } from './GroupTagItem.interface';
import { Select } from 'components/Select';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { theme } from 'styles';
import { useGroupTagItemStore } from './GroupTagItem.hooks';
import { api } from 'api';
import { useCollectionSync, useUserScope } from 'hooks';
import { find, xor } from 'lodash';
import { ENV_VARIABLE } from 'variables';

export const GroupTagItem: FC<GroupTagItemProps> = ({
  tagGroup,
  resultId,
  documentId,
}) => {
  const { user, editorId, tagGroupsOptions, updateTagGroupField } = useGroupTagItemStore(
    tagGroup.id,
  );
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [tags, setTags] = useState<Array<string>>(tagGroup.tags.map((item) => item.tag));
  const { isAllowed } = useUserScope('RESULT_UPDATE');
  const { syncCollection } = useCollectionSync();

  const requiresAttention = tagGroup.status.status === 'Needs Attention';

  const onTagEdit = (selectedTags: Array<string>) => {
    let newTags = selectedTags;

    if (tagGroup.tagMode === 'one' || tagGroup.tagMode === 'zero_or_one') {
      newTags = selectedTags.length === 0 ? selectedTags : xor(tags, selectedTags);
    }

    setTags(newTags);
  };

  const onSaveClick = (event: SyntheticEvent) => {
    event.stopPropagation();

    api
      .updateGroupTags(resultId, tagGroup.id, tags)
      .then((data) => {
        updateTagGroupField({
          documentId,
          tagGroup: {
            ...data,
          },
        });

        syncCollection();
        setEditMode(false);
      })
      .catch((e) => {
        if (e.response.status === 422) {
          if (tagGroup.tagMode === 'one_or_more') {
            toast.error(t('oneTagError'), { position: 'bottom-left' });
          } else if (tagGroup.tagMode === 'one') {
            toast.error(t('onlyOneTagError'), { position: 'bottom-left' });
          }
        }
      });
  };

  const onCancelClick = (event: SyntheticEvent) => {
    event.stopPropagation();

    setTags(tagGroup.tags.map((item) => item.tag));
    setEditMode(false);
  };

  const disabledAccept =
    xor(
      tags,
      tagGroup.tags.map((item) => item.tag),
    ).length === 0;

  const allowEdit =
    !ENV_VARIABLE.KEY_CLOAK_URL ||
    (editorId && editorId === user?.sub && isAllowed) ||
    (ENV_VARIABLE.KEY_CLOAK_URL &&
      isAllowed &&
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED !== 'true');

  return (
    <Container
      $requiresAttention={requiresAttention}
      $allowEditMode={!!allowEdit}
      onClick={() => allowEdit && setEditMode(true)}
    >
      <Label>{tagGroup.name}</Label>
      <EditContainer>
        {editMode ? (
          <>
            <SelectContainer>
              <Select
                size="small"
                mode="multiple"
                placeholder={t('selectTags')}
                onChangeMultiple={onTagEdit}
                value={tags}
                options={(tagGroupsOptions[tagGroup.name] || []).map((item) => ({
                  label: item,
                  value: item,
                }))}
              />
            </SelectContainer>
            <StyledAcceptContainer $disabled={disabledAccept} onClick={onSaveClick}>
              <StyledAcceptIcon $disabled={disabledAccept} />
            </StyledAcceptContainer>
            <StyledCancelContainer onClick={onCancelClick}>
              <StyledCancelIcon />
            </StyledCancelContainer>
          </>
        ) : tags.length > 0 ? (
          <Tooltip
            title={
              <>
                {tags.length > 0
                  ? tags.map((item) => (
                      <span key={item}>
                        {item}
                        <br />
                      </span>
                    ))
                  : '-'}
              </>
            }
            placement="topLeft"
            overlayStyle={{ whiteSpace: 'pre-line', maxWidth: 500 }}
            color={theme.colors.blackPurple}
          >
            <TagsContainer>
              {tags.length > 0
                ? tags.map((item) => (
                    <StyledTag
                      $status={find(tagGroup.tags, { tag: item })?.status.status}
                      key={item}
                    >
                      {item}
                    </StyledTag>
                  ))
                : '-'}
            </TagsContainer>
          </Tooltip>
        ) : (
          <TagsContainer>
            <StyledEmpty>-</StyledEmpty>
          </TagsContainer>
        )}
      </EditContainer>
    </Container>
  );
};
