import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Select } from 'components/Select';
import { Input } from 'components/Input';
import { EditFieldProps } from './EditField.interface';
import { Container, StyledButton, CloseIcon } from './EditField.styles';
import { api, AnnotationProcessStatus, PatchAnnotation } from 'api';
import { useEditFieldStore } from './EditField.hooks';
import { useCollectionSync } from 'hooks';

export const EditField: FC<EditFieldProps> = ({
  id,
  resultId,
  documentId,
  text,
  type,
  requiresAttention,
  pageId,
  onClose,
}) => {
  const { t } = useTranslation();
  const {
    fieldArrow,
    commonTypes,
    setFieldArrow,
    setAnnotationEditMode,
    updateAnnotationField,
  } = useEditFieldStore();
  const { syncCollection } = useCollectionSync();
  const [editText, setEditText] = useState<string | undefined>(text);
  const [editType, setEditType] = useState<string | undefined>(type);

  const updateFieldArrow = (status: AnnotationProcessStatus) => {
    if (fieldArrow?.item?.id === id) {
      setFieldArrow({
        id: fieldArrow?.id,
        item: { ...fieldArrow.item, status },
        resultId,
        documentId,
      });
    }
  };

  const onSave = () => {
    let data: PatchAnnotation = {
      resultId,
      annotationId: id,
      pageId,
    };

    if (editText !== text) {
      data = { ...data, value: editText };
    }

    if (editType !== type) {
      data = { ...data, label: editType };
    }

    api.patchAnnotation(data).then((result) => {
      updateAnnotationField({ ...data, ...result, type: 'text' });
      updateFieldArrow('Corrected');
      onClose();
      setAnnotationEditMode({ annotationId: id, editMode: false });
      syncCollection();
    });
  };

  return (
    <>
      <Container $error={requiresAttention}>
        <Select
          size="small"
          label={t('fieldName')}
          onChange={setEditType}
          value={editType}
          options={commonTypes?.annotationsTypes}
        />
        <Input label={t('value')} onChange={setEditText} value={editText} />
        <StyledButton
          disabled={(editText === text && editType === type) || editText?.length === 0}
          title={t('save')}
          onClick={onSave}
        />
      </Container>
      <CloseIcon onClick={onClose} />
    </>
  );
};
