import { Switch } from 'antd';
import { Button } from 'components/Button';
import { IconTrash } from 'components/Icons';
import { Input } from 'components/Input';
import { Label } from 'components/Label';
import { SecondaryAltButton } from 'components/SecondaryAltButton';
import { Select } from 'components/Select';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
`;

export const HorizontalContainer = styled.div`
  align-items: center;
  width: 100%;
`;

export const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lineDark}`};
  :hover {
    background-color: ${({ theme }) => theme.colors.content};
  }
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border-radius: 0.3em;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 16px;
  padding-bottom: 24px;
  justify-content: space-between;
  position: relative;
`;

export const DeleteContainer = styled.div`
  display: flex;
  position: absolute;
  top: 36px;
  right: 16px;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledDeleteIcon = styled(IconTrash)`
  color: ${({ theme }) => theme.colors.darkPurple};
`;

export const StyledSelect = styled(Select)`
  width: 250px;
`;

export const StyledMultipleSelect = styled(Select)`
  width: 100%;
  min-width: 100%;
`;

export const StyledMultipleSelectTop = styled(Select)`
  width: 300px;
  min-width: 300px;
  margin-right: 18px;
`;

export const FieldContainer = styled.div`
  margin-top: 18px;
  width: 100%;
`;

export const TresholdContainer = styled.div`
  margin-right: 18px;
`;

export const SeparatorsContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 18px;
  margin-right: 18px;
`;

export const PatternContainer = styled.div`
  margin-top: 18px;
  width: 100%;
  display: flex;
  align-items: end;
`;

export const StyledIconButton = styled(SecondaryAltButton)`
  margin-left: 8px;
`;

export const StyledButton = styled(Button)`
  margin-top: 18px;
`;

export const StyledInput = styled(Input)`
  width: 400px;
`;

export const TresholdInput = styled(Input)`
  width: 70px;
`;

export const StyledLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.gray300};
  padding-bottom: 8px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 24px;
  margin-right: 18px;
`;

export const SwitchIgnoreCaseContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 24px;
  margin-right: 18px;
`;

export const SwitchLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.blackPurple};
  padding-left: 8px;
`;

export const StyledSwitch = styled(Switch)``;
