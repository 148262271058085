import { Popover } from 'components/Popover';
import { TextArea } from 'components/TextArea';
import { StyledCell } from 'components/ExtractionTable/Cell/Cell.styles';
import { useTextAreaCell } from './TextAreaCell.hooks';

interface TextAreaCellProps {
  id: string;
  corrected?: boolean;
  value?: string;
  onChange: (value: string) => void;
  width?: number;
}

export const TextAreaCell = ({
  value,
  id,
  onChange,
  width: minWidth,
  corrected,
}: TextAreaCellProps) => {
  const { width, alignConfig, autoSizeConfig } = useTextAreaCell({ minWidth, value });

  return (
    <Popover
      align={alignConfig}
      content={
        <TextArea
          autoSize={autoSizeConfig}
          width={width}
          id={id}
          onChange={onChange}
          value={value}
        />
      }
    >
      <StyledCell corrected={corrected}>
        {value}
        {corrected ? '*' : ''}
      </StyledCell>
    </Popover>
  );
};
