import { Spin, Typography } from 'antd';
import { IconDelete } from 'components/Icons';
import styled from 'styled-components';

export const CloseIcon = styled(IconDelete)`
  top: ${({ theme }) => theme.padding.normal};
  right: ${({ theme }) => theme.padding.normal};
  cursor: pointer;
  width: 26px;
  height: 26px;
  color: ${({ theme }) => theme.colors.gray300};
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray300};
  font-size: ${({ theme }) => theme.fontSize.normal};
  user-select: none;
  text-align: left;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
`;

export const TopContainer = styled.div`
  margin-left: ${({ theme }) => theme.padding.small};
  margin-right: ${({ theme }) => theme.padding.small};
  margin-top: ${({ theme }) => theme.padding.medium};
  margin-bottom: ${({ theme }) => theme.padding.normal};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 52vh;
  border: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.padding.medium};
  margin-left: ${({ theme }) => theme.padding.normal};
  margin-right: ${({ theme }) => theme.padding.normal};
  overflow-y: scroll;
`;

export const Loader = styled(Spin)`
  position: absolute;
  top: 50%;
  left: 48%;
`;
