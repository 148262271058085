import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputSearchProps } from './InputSearch.interface';
import { StyledInput, StyledIcon, StyledDeleteIcon } from './InputSearch.styles';

export const InputSearch: FC<InputSearchProps> = ({
  onChange,
  onKeyDown,
  value,
  id,
  ...other
}) => {
  const { t } = useTranslation();

  return (
    <StyledInput
      id={id}
      value={value}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      placeholder={t('search')}
      suffix={
        value ? <StyledDeleteIcon onClick={() => onChange(undefined)} /> : <StyledIcon />
      }
      {...other}
    />
  );
};
