import { IconLogOut, IconSettings } from 'components/Icons';
import { TFunction } from 'react-i18next';

export const userMenu = (t: TFunction, authenticated: boolean) => {
  let userMenu = [
    {
      id: 1,
      label: t('settings') || '',
      Icon: IconSettings,
      uid: 'user-dropdown-menu.settings',
    },
  ];

  if (authenticated) {
    userMenu = [
      ...userMenu,
      {
        id: 3,
        label: t('logOut') || '',
        Icon: IconLogOut,
        uid: 'user-dropdown-menu.logout',
      },
    ];
  }

  return userMenu;
};
