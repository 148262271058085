import { Lookup } from 'api';
import { uniqBy } from 'lodash';
import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface CreateFieldsProps {
  pageId: string;
  lookups: Array<Lookup>;
  fieldType: string;
}

interface CreateFieldsDispatch extends BaseDispatch {
  payload: CreateFieldsProps;
}

export const ADD_CREATED_FIELDS = 'ADD_CREATED_FIELDS';
export const CLEAR_CREATED_FIELDS = 'CLEAR_CREATED_FIELDS';

export const selectCreateFields = (state: StoreProps, pageId: string) =>
  state.supervision.createFields[pageId];

export const updateCreateFieldsState = (
  state: SupervisionStore,
  data: CreateFieldsProps,
) => {
  return {
    ...state,
    createFields: {
      ...state.createFields,
      [data.pageId]: {
        lookups: uniqBy(
          [...(state.createFields[data.pageId]?.lookups || []), ...data.lookups],
          'id',
        ),
        fieldType: data.fieldType,
      },
    },
  };
};

export const clearCreatedFieldsState = (state: SupervisionStore) => {
  return { ...state, createFields: {} };
};

export const addCreateFields = (data: CreateFieldsProps) => (
  dispatch: (data: CreateFieldsDispatch) => void,
) => {
  dispatch({
    type: ADD_CREATED_FIELDS,
    payload: { ...data },
  });
};

export const clearCreatedFields = () => (dispatch: (data: BaseDispatch) => void) => {
  dispatch({
    type: CLEAR_CREATED_FIELDS,
  });
};
