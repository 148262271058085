import styled from 'styled-components';
import { SortType } from './Columns.interface';

interface ColumnProps {
  size?: number;
}

interface SortIconProps {
  sortType?: SortType;
}

export const Container = styled.div`
  padding-left: 1rem;
  height: 52px;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ColumnContainer = styled.div<ColumnProps>`
  width: ${({ size }) => `${size}%`};
  display: flex;
  align-items: center;
`;

export const SortIcon = styled.img<SortIconProps>`
  height: 16px;
  user-select: none;
  transform: ${({ sortType }) => (sortType === 'd' ? 'rotate(180deg)' : 0)};
`;
