import { BaseDispatch, StoreProps, SupervisionStore } from 'store/store.interface';

interface CurrentPageProps {
  pageId?: string;
}

interface CurrentPageDispatch extends BaseDispatch {
  payload: CurrentPageProps;
}

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const selectCurrentPageId = (state: StoreProps) => state.supervision.currentPage;

export const updateCurrentPageState = (
  state: SupervisionStore,
  currentPage: CurrentPageProps,
) => {
  return { ...state, currentPage };
};

export const updateCurrentPage = (data: CurrentPageProps) => (
  dispatch: (data: CurrentPageDispatch) => void,
) => {
  dispatch({
    type: SET_CURRENT_PAGE,
    payload: data,
  });
};
