import { api } from 'api';
import { useUserScope } from 'hooks';
import { Dictionary, flatten, values } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnnotationEditMode, GroupTagEditMode } from 'store';
import {
  selectAllDataObjects,
  selectAllTagGroups,
  selectCollectionError,
  selectCollectionId,
  selectCollectionLoading,
  selectCollectionMetadata,
  selectCollectionName,
  selectCollectionStatus,
  selectCollectionTotalFiles,
  selectCollectionTotalPages,
  selectDocuments,
  selectEditorId,
  selectProcessedDocuments,
  updateCollectionEditor,
  updateCollectionMetadata,
} from 'store/reducers/collection';
import { selectUser } from 'store/reducers/common';
import {
  clearGroupTagEditMode as clearGroupTagEditModeStore,
  clearAnnotationEditMode as clearAnnotationEditModeStore,
  selectCurrentPageId,
  selectEditMode,
  selectZoom,
  updateCurrentPage,
  updateEditMode,
  updateZoom,
  updateAnnotationsEditMode,
  updateGroupTagsEditMode,
} from 'store/reducers/supervision';
import { updateFilterByStatus } from 'store/reducers/supervision/filterByStatus';
import { ENV_VARIABLE } from 'variables';

export const useHeaderStore = () => {
  const dispatch = useDispatch();
  const setEditMode = useCallback((e) => dispatch(updateEditMode(e)), [dispatch]);
  const setCurrentPage = useCallback((e) => dispatch(updateCurrentPage(e)), [dispatch]);
  const setZoom = useCallback((e) => dispatch(updateZoom(e)), [dispatch]);
  const clearGroupTagEditMode = useCallback(
    () => dispatch(clearGroupTagEditModeStore()),
    [dispatch],
  );
  const clearAnnotationEditMode = useCallback(
    () => dispatch(clearAnnotationEditModeStore()),
    [dispatch],
  );
  const setFilterByStatus = useCallback((e) => dispatch(updateFilterByStatus(e)), [
    dispatch,
  ]);
  const setAnnotationsEditMode = useCallback(
    (e) => dispatch(updateAnnotationsEditMode(e)),
    [dispatch],
  );

  const setGroupTagsEditMode = useCallback((e) => dispatch(updateGroupTagsEditMode(e)), [
    dispatch,
  ]);

  const setEditorId = useCallback((e) => dispatch(updateCollectionEditor(e)), [dispatch]);
  const setMetadata = useCallback((e) => dispatch(updateCollectionMetadata(e)), [
    dispatch,
  ]);
  const currentPageId = useSelector(selectCurrentPageId);
  const editMode = useSelector(selectEditMode);
  const totalFiles = useSelector(selectCollectionTotalFiles);
  const name = useSelector(selectCollectionName);
  const status = useSelector(selectCollectionStatus);
  const totalPages = useSelector(selectCollectionTotalPages);
  const documents = useSelector(selectDocuments);
  const dataObjects = useSelector(selectAllDataObjects);
  const tagGroups = useSelector(selectAllTagGroups);
  const zoom = useSelector(selectZoom);
  const error = useSelector(selectCollectionError);
  const loading = useSelector(selectCollectionLoading);
  const collectionStatus = useSelector(selectCollectionStatus);
  const processedDocuments = useSelector(selectProcessedDocuments);
  const metadata = useSelector(selectCollectionMetadata);
  const collectionId = useSelector(selectCollectionId);
  const user = useSelector(selectUser);
  const editorId = useSelector(selectEditorId);
  const { isAllowed } = useUserScope('RESULT_UPDATE');

  const fetchCollection = () => {
    if (ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED === 'true' && user) {
      api.fetchCollection(collectionId).then((data) => {
        if (!data.editor?.editorId && isAllowed) {
          api.addEditor(collectionId, user.sub).then(() => setEditorId(user.sub));
        } else if (editorId !== data.editor?.editorId) {
          setEditorId(data.editor?.editorId);
        }
      });
    }
  };

  const addAnnotationsEditMode = () => {
    const list = flatten(values(dataObjects).map((item) => values(item)));

    const editModeAnnotations = list.reduce((acc, item) => {
      acc[item.id] = { editMode: true };
      return acc;
    }, {} as Dictionary<AnnotationEditMode>);

    setAnnotationsEditMode(editModeAnnotations);
  };

  const addGroupTagsEditMode = () => {
    const list = flatten(values(tagGroups).map((item) => values(item)));

    const editModeTagGroups = list.reduce((acc, item) => {
      acc[item.id] = { editMode: true };
      return acc;
    }, {} as Dictionary<GroupTagEditMode>);

    setGroupTagsEditMode(editModeTagGroups);
  };

  return {
    addAnnotationsEditMode,
    addGroupTagsEditMode,
    user,
    editorId,
    collectionId,
    processedDocuments,
    collectionStatus,
    loading,
    metadata,
    error,
    clearGroupTagEditMode,
    clearAnnotationEditMode,
    setAnnotationsEditMode,
    setFilterByStatus,
    setEditMode,
    setCurrentPage,
    currentPageId,
    editMode,
    totalFiles,
    name,
    status,
    totalPages,
    dataObjects,
    tagGroups,
    documents,
    zoom,
    setZoom,
    setMetadata,
    fetchCollection,
  };
};
