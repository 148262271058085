import styled from 'styled-components';

export const StyledButton = styled.div`
  :focus {
    border: ${({ theme }) => `1px solid ${theme.colors.blackPurple}`};

    border: 1px;
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.lilac};
  }
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.transparent};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  align-items: center;
  display: flex;
  justify-content: center;
  border: 0px;
  display: flex;
  cursor: pointer;
`;
