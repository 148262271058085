import { Typography } from 'antd';
import styled from 'styled-components';

interface PageProps {
  $selected?: boolean;
}

export const Container = styled.div`
  height: auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.transparent};
  width: 75%;
`;

export const StyledImage = styled.img<PageProps>`
  height: auto;
  background-color: ${({ theme }) => theme.colors.transparent};
  width: 100%;
  align-self: center;
  object-fit: contain;
  user-select: none;
  cursor: pointer;
  box-shadow: ${({ theme }) => `0 0 11px 0 ${theme.colors.gray400}`};
  border: ${({ theme, $selected }) =>
    $selected
      ? `1px solid ${theme.colors.darkPurple}`
      : `1px solid ${theme.colors.gray400}`};
`;

export const PageNumber = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  padding-top: ${({ theme }) => theme.padding.xtiny};
  margin-bottom: ${({ theme }) => theme.padding.small};
  font-size: 14px;
  text-align: center;
  user-select: none;
`;

export const AnnotationAttentions = styled.div`
  background-color: ${({ theme }) => theme.colors.darkPink};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  font-size: 12px;
  height: 16px;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 4%;
  left: 5%;
  user-select: none;
`;
