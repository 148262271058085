import { FC, useEffect, useState } from 'react';
import { ColumnsProps, ColumnType, SortType } from './Columns.interface';
import { Container, ColumnContainer, SortIcon } from './Columns.styles';
import { useTyroHistory } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { parseQueryParams } from 'utils/helpers';
import { ColumnHeader } from 'components/ColumnHeader';

import iconSortEnabled from 'assets/icon_sort_enabled.png';
import iconSort from 'assets/icon_sort.png';

export const sortIcon = (enabled: boolean, sort?: SortType) => {
  return enabled ? (
    <SortIcon alt="sortEnabled" sortType={sort} src={iconSortEnabled} />
  ) : (
    <SortIcon alt="sort" src={iconSort} />
  );
};

export const Columns: FC<ColumnsProps> = () => {
  const [sortColumn, setSortColumn] = useState<{ column: ColumnType; sort: SortType }>();
  const { search } = useLocation();
  const { addQueryParams } = useTyroHistory();

  const { t } = useTranslation();

  useEffect(() => {
    if (sortColumn) {
      let order = sortColumn?.column;

      if (sortColumn.sort === 'd') {
        order += 'd';
      }

      addQueryParams({ order });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn]);

  useEffect(() => {
    const { order } = parseQueryParams(search);

    if (order) {
      setSortColumn({
        column: order[0] as ColumnType,
        sort: order.length > 1 ? 'd' : 'a',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <>
      <Container>
        <ColumnContainer size={40}>
          <ColumnHeader
            name={t('name')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 'n'}
            onClick={() =>
              setSortColumn({
                column: 'n',
                sort:
                  sortColumn?.column !== 'n' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
        <ColumnContainer size={30}>
          <ColumnHeader
            name={t('email')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 's'}
            onClick={() =>
              setSortColumn({
                column: 's',
                sort:
                  sortColumn?.column !== 's' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
        <ColumnContainer size={15}>
          <ColumnHeader
            name={t('created')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 'd'}
            onClick={() =>
              setSortColumn({
                column: 'd',
                sort:
                  sortColumn?.column !== 'd' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
        <ColumnContainer size={15}>
          <ColumnHeader
            name={t('status')}
            sortType={sortColumn?.sort === 'd' ? 'a' : 'd'}
            sortEnabled={sortColumn?.column === 'f'}
            onClick={() =>
              setSortColumn({
                column: 'f',
                sort:
                  sortColumn?.column !== 'f' ? 'd' : sortColumn?.sort === 'd' ? 'a' : 'd',
              })
            }
          />
        </ColumnContainer>
      </Container>
    </>
  );
};
