import { User } from 'api';
import { BaseDispatch, CommonStore, StoreProps } from 'store/store.interface';

interface UserDispatch extends BaseDispatch {
  payload: User;
}

export const SET_USER = 'SET_USER';

export const selectUser = (state: StoreProps) => state.common.user;

export const updateUserState = (state: CommonStore, user: User) => {
  return { ...state, user };
};

export const updateUser = (data: User) => (dispatch: (data: UserDispatch) => void) => {
  dispatch({
    type: SET_USER,
    payload: data,
  });
};
