import { Menu, Typography } from 'antd';
import { IconMore } from 'components/Icons';
import styled from 'styled-components';

interface StyledMenuContainerProps {
  $hovered: boolean;
}

export const MenuContainer = styled.div<StyledMenuContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 8px);
  width: 40px;
  border-radius: 6px;
  background-color: ${({ $hovered, theme }) =>
    $hovered ? theme.colors.lilac : 'transparent'};
  :hover {
    background-color: ${({ theme }) => theme.colors.lilac};
  }
`;

export const StyledMenuLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.blackPurple};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  user-select: none;
`;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
  height: 40px;
  margin-bottom: 4px;
  border-radius: 6px;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray400};
  }
`;

export const StyledMenuIcon = styled(IconMore)`
  color: ${({ theme }) => theme.colors.blackPurple};
  width: 20px;
`;
