import { IconEdit, IconTrash } from 'components/Icons';
import { TFunction } from 'react-i18next';
import { RowAction } from './Row.interface';

export const rowAction = (id: number): RowAction => {
  switch (id) {
    case 1:
      return 'edit';
    case 2:
      return 'delete';
    default:
      return 'edit';
  }
};

export const userMenu = (t: TFunction) => {
  return [
    {
      id: 1,
      label: t('editUser') || '',
      Icon: IconEdit,
    },
    {
      id: 2,
      label: t('deleteUser') || '',
      Icon: IconTrash,
    },
  ];
};
