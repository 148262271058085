import { FC } from 'react';
import { SecondaryAltButtonProps } from './SecondaryAltButton.interface';
import { StyledButton, Issues, Container } from './SecondaryAltButton.styles';

export const SecondaryAltButton: FC<SecondaryAltButtonProps> = ({
  onClick,
  onMouseDown,
  icon,
  issues = 0,
  withBackground = true,
  size = 'middle',
  ...other
}) => {
  return (
    <Container onMouseDown={onMouseDown} {...other}>
      <StyledButton buttonSize={size} $withBackground={withBackground} onClick={onClick}>
        {icon}
      </StyledButton>
      {issues > 0 && <Issues>{issues}</Issues>}
    </Container>
  );
};
