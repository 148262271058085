import { FC } from 'react';
import { Label, Container } from './TagGroupsItem.styles';
import { TagGroupsItemProps } from './TagGroupsItem.interface';
import { GroupTagItem } from '../GroupTagItem';
import { useTagGroupsItemStore } from './TagGroupsItem.hooks';

export const TagGroupsItem: FC<TagGroupsItemProps> = ({
  pageId,
  resultId,
  documentId,
}) => {
  const { setPageArrow, pageArrow, tagGroups = [] } = useTagGroupsItemStore(documentId);

  const onTagGroup = (id: string) => {
    if (pageArrow?.pageId !== pageId || id !== pageArrow?.tagGroupId) {
      setPageArrow({ pageId, tagGroupId: id });
    } else {
      setPageArrow(undefined);
    }
  };

  return (
    <Container>
      <Label>TAGS</Label>
      {tagGroups?.map((item, index) => (
        <GroupTagItem
          onTag={() => onTagGroup(item.id)}
          selected={
            (pageArrow?.pageId === pageId && pageArrow?.tagGroupId === item.id) ||
            (!pageArrow?.tagGroupId && pageArrow?.pageId === pageId && index === 0)
          }
          showDot={
            (pageArrow?.pageId === pageId && item.id === pageArrow?.tagGroupId) ||
            (!pageArrow?.tagGroupId && pageArrow?.pageId === pageId && index === 0)
          }
          documentId={documentId}
          key={item.id}
          pageId={pageId}
          tagGroup={item}
          resultId={resultId}
        />
      ))}
    </Container>
  );
};
