import { theme } from 'styles';
import styled from 'styled-components';
import { PositionProps } from './Region.interface';
import { IconDelete as Close } from 'components/Icons';
import { SecondaryAltButton } from 'components/SecondaryAltButton';

const handleSize = 10;

interface DotProps {
  $top: number;
  $requiresAttention: boolean;
}

export const ColumnLine = styled.div<PositionProps>`
  position: absolute;
  left: ${({ position }) => position}%;
  top: 0;
  z-index: 10;
  border: none;
  border-left: ${({ theme }) => `1px dashed ${theme.colors.blackPurple}`};
  height: 100%;
  width: 2px;
`;

export const Container = styled.div`
  position: absolute;
  cursor: move;
  border: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
  border-style: dashed;
  z-index: 100;
`;

export const ResizeBottomRight = styled.div`
  position: absolute;
  bottom: -${handleSize / 2}px;
  right: -${handleSize / 2}px;
  width: ${handleSize}px;
  height: ${handleSize}px;
  cursor: se-resize;
  background-color: white;
  border: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
`;

export const ResizeBottomLeft = styled.div`
  position: absolute;
  bottom: -${handleSize / 2}px;
  left: -${handleSize / 2}px;
  width: ${handleSize}px;
  height: ${handleSize}px;
  cursor: sw-resize;
  background-color: white;
  border: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
`;

export const ResizeTopRight = styled.div`
  position: absolute;
  top: -${handleSize / 2}px;
  right: -${handleSize / 2}px;
  width: ${handleSize}px;
  height: ${handleSize}px;
  cursor: ne-resize;
  background-color: white;
  border: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
`;

export const ResizeTopLeft = styled.div`
  position: absolute;
  top: -${handleSize / 2}px;
  left: -${handleSize / 2}px;
  width: ${handleSize}px;
  height: ${handleSize}px;
  background-color: white;
  border: ${({ theme }) => `2px solid ${theme.colors.darkPurple}`};
`;

export const RowLine = styled.div<PositionProps>`
  position: absolute;
  top: ${({ position }) => position}%;
  left: 0;
  z-index: 10;
  border: none;
  border-top: ${({ theme }) => `1px dashed ${theme.colors.blackPurple}`};
  width: 100%;
  height: 2px;
`;

export const CloseContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.darkPurple};
  height: 24px;
  width: 24px;
  position: absolute;
  bottom: -32px;
  right: -2px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.blackPurple};
  }
`;

export const IconClose = styled(Close)`
  width: 15px;
  height: 15px;
  color: white;
`;

export const TypeSelection = styled.div<{ showAtBottom: boolean }>`
  position: absolute;
  top: ${({ showAtBottom }) => (showAtBottom ? '8px' : '-49px')};
  left: 6px;
  padding: 8px;
  display: flex;
  height: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 10px rgba(38, 26, 89, 0.3);
`;

export const SelectContainer = styled.div`
  padding-right: 10px;
  width: 170px;
`;

export const style = {
  RowHandle: {
    position: 'absolute',
    left: -15,
    top: -5,
    height: 'calc(100% + 10px)',
    width: '8px',
    zIndex: 1,
    borderRadius: '10px',
    backgroundColor: theme.colors.darkPurple,
  },
  RegionHandleRight: {
    position: 'absolute',
    right: -8,
    top: 0,
    height: '100%',
    width: '8px',
    zIndex: 1,
    backgroundColor: 'blue',
    cursor: 'e-resize',
  },
  RegionHandleBottom: {
    position: 'absolute',
    bottom: -8,
    left: 0,
    width: '100%',
    height: '8px',
    zIndex: 1,
    cursor: 's-resize',
    backgroundColor: 'blue',
  },
  RegionHandleSE: {
    position: 'absolute',
    bottom: -handleSize / 2,
    right: -handleSize / 2,
    width: handleSize,
    height: handleSize,
    cursor: 'se-resize',
  },
  RegionHandleSW: {
    position: 'absolute',
    bottom: -handleSize / 2,
    left: -handleSize / 2,
    width: handleSize,
    height: handleSize,
    cursor: 'sw-resize',
  },
  RegionHandleNE: {
    position: 'absolute',
    top: -handleSize / 2,
    right: -handleSize / 2,
    width: handleSize,
    height: handleSize,
    cursor: 'ne-resize',
  },
  RegionSelect: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
  },
};

export const Dot = styled.div<DotProps>`
  position: absolute;
  top: ${({ $top }) => $top - 6}px;
  right: 0;
  z-index: 1001;
  margin-right: -10px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: ${({ theme, $requiresAttention }) =>
    $requiresAttention ? theme.colors.darkPink : theme.colors.darkPurple};
  border: ${({ theme }) => `3px solid ${theme.colors.white}`};
  box-shadow: 0 2px 4px 0 rgba(182, 20, 103, 0.3);
`;

export const AddButton = styled(SecondaryAltButton)`
  margin-left: 6px;
`;
