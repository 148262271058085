import { DropdownSelect } from 'components/DropdownSelect';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  width: 92%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.padding.medium};
  padding-bottom: ${({ theme }) => theme.padding.tiny};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray400}`};
`;

export const SearchContainer = styled.div`
  width: 14%;
`;

export const Divider = styled.div`
  width: 1px;
  margin-left: ${({ theme }) => theme.padding.tiny};
  margin-right: ${({ theme }) => theme.padding.small};
  height: 20px;
  background-color: ${({ theme }) => theme.colors.gray400};
`;

export const StyledDropdownSelect = styled(DropdownSelect)`
  margin-left: ${({ theme }) => theme.padding.xtiny};
`;
