import { useCallback, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { AnnotationField, ComplexFieldProps } from './ComplexField.interface';
import { useTranslation } from 'react-i18next';
import {
  StyledField,
  Type,
  ComplexTypeLabel,
  Dot,
  Annotations,
  TextContainer,
  Text,
  StyledCopy,
  ChildAnnotation,
  StatusContainer,
  MenuContainer,
  StyledAcceptContainer,
  StyledAcceptIcon,
  StyledCancelContainer,
  StyledCancelIcon,
  StyledAcceptWithoutContainer,
  StyledAcceptWithoutIcon,
  IconsContainer,
  StyledInput,
  ArrowContainer,
  ComplexFieldHeaderRow,
} from './ComplexField.styles';
import {
  hasVisibleStatus,
  mapAnnotation,
  requiresAttention,
  requiresEditAttention,
} from 'utils/helpers';
import { theme } from 'styles';
import { useComplexFieldStore } from './ComplexField.hooks';
import { fieldDataObjectId } from 'components/FieldArrow';
import { useCollectionSync, useUserScope } from 'hooks';
import { find, isBoolean, isString } from 'lodash';
import { ENV_VARIABLE } from 'variables';
import { Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Notification } from 'components/Notification';
import { FieldStatus } from 'components/FieldStatus';
import { DropdownMenu } from 'components/DropdownMenu';
import { menuMoreAttention, menuMore } from './ComplexField.menu';
import { useAnnotationDeleteModalStore } from 'store/reducers/modals';
import { AnnotationProcessStatus, api } from 'api';
import { ExtractionEnumInput } from 'components/ExtractionEnumInput';
import {
  COMPLEX_FIELD_HEADER_HEIGHT,
  COMPLEX_FIELD_ITEM_CONTAINER_VERTICAL_PADDING,
  COMPLEX_FIELD_ITEM_HEIGHT,
} from 'pages/Supervision/ExtractedFieldListItem/ExtractedFieldListItem.constants';
import { StyledArrowDown } from './ComplexField.styles';
import { binaryOptions, getTooltipProps } from 'components/Field/Field.utils';
import { fieldInputTypes } from 'pages/Supervision/ExtractedFieldListItem';
import { Select } from 'components/Select';

export const FIELD_INPUT = 'input_';

const addAsterisk = (text: string) => {
  return (
    <>
      <span style={{ color: theme.colors.darkPink }}>*</span>
      <span>{text}</span>
    </>
  );
};

export const ComplexField = ({
  documentId,
  resultId,
  pageId,
  shouldFocus,
  annotationId,
  id,
  onOpen,
  isCollapsed,
}: ComplexFieldProps) => {
  const {
    documentEditMode,
    fieldArrow,
    setFieldArrow,
    annotation,
    commonTypes,
    setZoom,
    editorId,
    user,
    annotationEditMode,
    setAnnotationEditMode,
    updateAnnotationField,
  } = useComplexFieldStore(pageId, annotationId);
  const { syncCollection } = useCollectionSync();
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const [annotationsEditText, setAnnotationsEditText] = useState<Array<AnnotationField>>(
    annotation?.annotations.map((item) => ({ id: item.id, value: item.value })) || [],
  );
  const [hover, setHover] = useState<string | undefined>();
  const { isAllowed } = useUserScope('RESULT_UPDATE');
  const editMode = annotation && annotationEditMode?.editMode;
  const { setAnnotationDeleteModal } = useAnnotationDeleteModalStore();

  useEffect(() => {
    if (
      annotation &&
      documentEditMode &&
      !isBoolean(annotationEditMode?.editMode) &&
      requiresEditAttention(annotation.status.status)
    ) {
      setAnnotationEditMode({
        resultId,
        annotationId: annotation?.id,
        editMode: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotation, editMode, documentEditMode]);

  const disabledAccept = !find(
    annotation?.annotations,
    (item, index) => item.value !== annotationsEditText[index].value,
  );

  const toggleEditMode = () => {
    setAnnotationEditMode({
      resultId,
      annotationId: annotation?.id,
      editMode: !editMode,
    });
  };

  const onRowAction = (actionId: number) => {
    switch (actionId) {
      case 1: {
        toggleEditMode();
        onFieldClick(false);
        break;
      }
      case 2: {
        onDelete();
        break;
      }
      case 3: {
        onAcceptWithoutClick();
        break;
      }
    }

    setShowMenu(false);
  };

  const onDelete = () => {
    setAnnotationDeleteModal({
      open: true,
      pageId,
      resultId,
      annotationId,
      type: 'complex',
    });
  };

  const updateFieldArrow = (status: AnnotationProcessStatus) => {
    if (fieldArrow?.item?.id === id) {
      setFieldArrow({
        id: fieldArrow?.id,
        item: { ...fieldArrow.item, status },
        resultId,
        documentId,
      });
    }
  };

  const onAccept = () => {
    api.acceptAnnotation(resultId, annotationId, 'complex').then((data) => {
      updateAnnotationField({
        ...data,
        pageId,
        resultId,
        annotationId,
        type: 'complex',
      });
      updateFieldArrow('Accepted');
      syncCollection();

      if (editMode) {
        onCancelClick();
      }
    });
  };

  const onEdit = () => {
    api
      .patchComplexField({ resultId, annotationId, annotations: annotationsEditText })
      .then((result) => {
        updateAnnotationField({
          pageId,
          resultId,
          annotationId,
          ...result,
          type: 'complex',
        });
        syncCollection();

        if (editMode) {
          toggleEditMode();
        }
      });
  };

  const onSave = () => {
    if (!disabledAccept) {
      if (resultId) {
        onEdit();
      }
    }
  };

  const onEditText = (index: number, value?: string) => {
    setAnnotationsEditText((prev) =>
      prev.map((item, itemIndex) => {
        if (itemIndex === index) {
          return { ...item, value };
        }

        return item;
      }),
    );
  };

  const onSaveClick = () => {
    onSave();
  };

  const onCancelClick = () => {
    toggleEditMode();

    setAnnotationsEditText(
      annotation?.annotations.map((item) => ({ id: item.id, value: item.value })) || [],
    );
  };

  const copiedToClipboard = () => {
    const successNotification = () => <Notification content={t('clipboardInfo') || ''} />;

    store.addNotification({
      insert: 'bottom',
      content: successNotification,
      container: 'bottom-left',
      animationIn: ['animate__animated animate__zoomIn'],
      animationOut: ['animate__animated animate__zoomOut'],
      dismiss: {
        duration: 1300,
      },
    });
  };

  const onAcceptWithoutClick = () => {
    if (resultId) {
      onAccept();
    }
  };

  const onFieldClick = (withRevert: boolean) => {
    if (!fieldArrow || fieldArrow.id !== annotationId.toString()) {
      setZoom({ scale: 1, isButtonAction: true });
      setFieldArrow({
        id: annotationId,
        item: annotation,
        pageId,
        resultId,
        documentId,
      });
    } else if (withRevert) {
      setFieldArrow(undefined);
    }
  };

  const onFieldDoubleClick = () => {
    setZoom({ scale: 1, isButtonAction: true });
    setFieldArrow({
      id: annotationId,
      item: annotation,
      pageId,
      resultId,
      documentId,
    });

    setAnnotationEditMode({
      resultId,
      annotationId: annotation?.id,
      editMode: true,
    });
  };

  const showEditMenu =
    !ENV_VARIABLE.KEY_CLOAK_URL ||
    (editorId && editorId === user?.sub && isAllowed) ||
    (ENV_VARIABLE.KEY_CLOAK_URL &&
      isAllowed &&
      ENV_VARIABLE.SINGLE_COLLECTION_EDITOR_ENABLED !== 'true');

  const onToggleOpen = useCallback(
    (e) => {
      e.stopPropagation();
      onOpen(!isCollapsed);
    },
    [isCollapsed, onOpen],
  );

  useEffect(() => {
    if (editMode) onOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  if (!annotation) return null;

  const parentAnnotationLabel = annotation?.labelRaw
    ? addAsterisk(mapAnnotation(commonTypes?.annotationsTypes, annotation?.label))
    : mapAnnotation(commonTypes?.annotationsTypes, annotation?.label);

  return annotation ? (
    <StyledField
      id={id}
      key={id}
      className={id}
      $height={
        isCollapsed
          ? COMPLEX_FIELD_HEADER_HEIGHT +
            COMPLEX_FIELD_ITEM_CONTAINER_VERTICAL_PADDING * 2 +
            annotation.annotations.length * COMPLEX_FIELD_ITEM_HEIGHT
          : COMPLEX_FIELD_HEADER_HEIGHT
      }
      $selected={id === fieldDataObjectId(fieldArrow?.id)}
      $requiresAttention={requiresAttention(annotation?.status.status)}
      onMouseEnter={() => showEditMenu && setShowMenu(true)}
      onMouseLeave={() => showEditMenu && setShowMenu(false)}
      onClick={() => {
        if (!editMode) {
          onFieldClick(true);
        }
      }}
      onDoubleClick={onFieldDoubleClick}
    >
      {!editMode ? (
        <>
          <ComplexFieldHeaderRow>
            <ArrowContainer
              onClick={onToggleOpen}
              onDoubleClick={(e) => e.stopPropagation()}
            >
              <StyledArrowDown className={isCollapsed ? 'rotate' : ''} />
            </ArrowContainer>
            <Tooltip {...getTooltipProps(parentAnnotationLabel)}>
              <ComplexTypeLabel>{parentAnnotationLabel}</ComplexTypeLabel>
            </Tooltip>

            <TextContainer
              $hover={showMenu}
              $hasVisibleStatus={hasVisibleStatus(annotation?.status.status)}
            >
              <CopyToClipboard onCopy={copiedToClipboard} text={annotation?.value || ''}>
                <Text
                  onClick={(event) => event.stopPropagation()}
                  edited={isString(annotation?.valueRaw)}
                >
                  {annotation.value}
                </Text>
              </CopyToClipboard>
            </TextContainer>
            {!showMenu && hasVisibleStatus(annotation?.status.status) && (
              <StatusContainer>
                <FieldStatus
                  status={annotation?.status.status}
                  message={annotation?.status.msg}
                />
              </StatusContainer>
            )}
            {showMenu && (
              <MenuContainer
                $requiresAttention={requiresAttention(annotation?.status.status)}
              >
                <DropdownMenu
                  onMenuItem={onRowAction}
                  items={
                    annotation.annotations.filter((item) =>
                      requiresAttention(item?.status.status),
                    ).length > 0 || requiresAttention(annotation?.status.status)
                      ? menuMoreAttention(t)
                      : menuMore(t)
                  }
                />
              </MenuContainer>
            )}
          </ComplexFieldHeaderRow>
          {isCollapsed && (
            <Annotations>
              {annotation.annotations.map((item) => {
                const childAnnotationLabel = item?.labelRaw
                  ? addAsterisk(mapAnnotation(commonTypes?.annotationsTypes, item?.label))
                  : mapAnnotation(commonTypes?.annotationsTypes, item?.label);

                return (
                  <ChildAnnotation key={item.id}>
                    <Tooltip {...getTooltipProps(childAnnotationLabel)}>
                      <Type edited={!!item?.labelRaw}>{childAnnotationLabel}</Type>
                    </Tooltip>
                    <Tooltip {...getTooltipProps(item?.value)}>
                      <TextContainer $hover={showMenu} $hasVisibleStatus={false}>
                        <CopyToClipboard
                          onCopy={copiedToClipboard}
                          text={item?.value || ''}
                        >
                          <Text
                            onMouseEnter={() => setHover(item.id)}
                            onMouseLeave={() => setHover(undefined)}
                            onClick={(event) => event.stopPropagation()}
                            edited={isString(item?.valueRaw)}
                          >
                            {isString(item?.valueRaw)
                              ? addAsterisk(item?.value || '')
                              : item?.value || ''}
                          </Text>
                        </CopyToClipboard>
                        {hover === item.id && <StyledCopy />}
                      </TextContainer>
                    </Tooltip>
                    <StatusContainer>
                      <FieldStatus
                        status={item?.status.status}
                        message={item?.status.msg}
                      />
                    </StatusContainer>
                  </ChildAnnotation>
                );
              })}
            </Annotations>
          )}
        </>
      ) : (
        <>
          <ComplexFieldHeaderRow>
            <Tooltip {...getTooltipProps(parentAnnotationLabel)}>
              <ComplexTypeLabel $withPadding>{parentAnnotationLabel}</ComplexTypeLabel>
            </Tooltip>
            <IconsContainer>
              <StyledAcceptContainer $disabled={disabledAccept} onClick={onSaveClick}>
                <StyledAcceptIcon $disabled={false} />
              </StyledAcceptContainer>
              <StyledCancelContainer onClick={onCancelClick}>
                <StyledCancelIcon />
              </StyledCancelContainer>
              {annotation.annotations.filter((item) =>
                requiresAttention(item?.status.status),
              ).length > 0 ||
                (requiresAttention(annotation?.status.status) && (
                  <StyledAcceptWithoutContainer onClick={onAcceptWithoutClick}>
                    <StyledAcceptWithoutIcon />
                  </StyledAcceptWithoutContainer>
                ))}
            </IconsContainer>
          </ComplexFieldHeaderRow>
          {isCollapsed && (
            <Annotations>
              {annotation?.annotations.map((item, index) => {
                const childAnnotationlabel = item?.labelRaw
                  ? addAsterisk(mapAnnotation(commonTypes?.annotationsTypes, item?.label))
                  : mapAnnotation(commonTypes?.annotationsTypes, item?.label);

                return (
                  <ChildAnnotation key={item.id}>
                    <Tooltip {...getTooltipProps(childAnnotationlabel)}>
                      <Type edited={!!item?.labelRaw}>{childAnnotationlabel}</Type>
                    </Tooltip>
                    <div style={{ flexGrow: 1, minWidth: 0 }}>
                      {item.type === 'enum' && (
                        <ExtractionEnumInput
                          label={item?.label || ''}
                          initialValue={annotationsEditText[index].value}
                          onChange={(value) => onEditText(index, value)}
                          size="x_small"
                          metadata={item.metadata}
                        />
                      )}
                      {item.type === 'binary' && (
                        <Select
                          onChange={(value) => onEditText(index, value)}
                          value={annotationsEditText[index].value}
                          options={binaryOptions(t)}
                          size="x_small"
                        />
                      )}
                      {fieldInputTypes.includes(item.type) && (
                        <StyledInput
                          id={`${FIELD_INPUT}${annotation?.id}`}
                          value={annotationsEditText[index].value}
                          onFocus={() => onFieldClick(false)}
                          onChange={(value) => onEditText(index, value)}
                          shouldFocus={shouldFocus}
                        />
                      )}
                    </div>
                  </ChildAnnotation>
                );
              })}
            </Annotations>
          )}
        </>
      )}
      {id === fieldDataObjectId(fieldArrow?.id) && annotation?.boundingBox && (
        <Dot $requiresAttention={requiresAttention(annotation?.status.status)} />
      )}
    </StyledField>
  ) : null;
};
