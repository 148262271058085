import { QueueCollection } from 'api';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { createContextWith } from './createContext';
import { QueueCollectionsContext } from './queueCollections.interface';

const [
  useQueueCollections,
  ContextProvider,
] = createContextWith<QueueCollectionsContext>();

const QueueCollectionsProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [collections, setCollections] = useState<Array<QueueCollection>>([]);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [selectedRowId, setSelectedRowId] = useState<string>();

  const addCollections = (data: Array<QueueCollection>) => {
    setCollections(data);
  };

  return (
    <ContextProvider
      value={{
        totalSize,
        collections,
        selectedRowId,
        setSelectedRowId,
        addCollections,
        setTotalSize,
      }}
    >
      {children}
    </ContextProvider>
  );
};

export { useQueueCollections, QueueCollectionsProvider };
